/* 
FileName:addToSprint.js
purpose:modal box when adding from card menu in backlogs
Developers:Satya Sidda[SS]

 */
import React, { useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { tasksReducer, initialState } from './tasksReducer';
import * as actions from './actions'

import { useSelector } from 'react-redux';
import { addSprint } from './Services/addSprint';
import { getSprints } from './Services/getSprints';
import { getProjects } from './Services/getProjects';
import { addEpic } from './Services/addEpic';
import Select from 'react-select';
import { useWebProperties } from '../../Common/webProperties';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//adding backlogs to the active sprint
export default function AddToSprint(props) {
    const getUser = useSelector(state => state.auth)
    const { MODULE,TARGET_DATE,PROJECT, MAINTASK, KANBAN } = useWebProperties();
    const [state, dispatch] = useReducer(tasksReducer, initialState);
   // const [sprintTargetDate, setTargetDate] = useState('')
    const[sprint_status,setSprint_status]=useState('')
    const activities=useSelector(state=>state.landingReducer.userActivities)
    const classNames = useStyles();
    const [sprintDetails, setSprintDetails] = useState([]);
    const [defaultSprint, setDefaultSprint] = useState(null);
    const [sprintTargetDate, setSprintTargetDate] = useState('');
    useEffect(() => {
        getSprints(dispatch, getUser.user, props.data.project_id);
        getProjects(dispatch, getUser.user, props.data.project_id);

        // eslint-disable-next-line
    }, [])
  //  var sprintDetails = [];
    var epicsList = [];
    // state.sprints.map((sprints) => {
    //     const [year, month, date] = sprints.startDate.split('-');
    //     const [year1, month1, date1] = sprints.targetDate.split('-');
    //     return (
    //         sprintDetails.push({ 'value': sprints.moduleId,'sprint_status':sprints.sprint_status, 'epicId': sprints.ideaId, 'label': sprints.moduleDesc.concat('[' + month + '.' + date + '.' + year + '-' + month1 + '.' + date1 + '.' + year1 + ']'), 'targetDate': sprints.targetDate })
    //     );
    // })
    state.projects.map((epics) => {
        return (
            epicsList.push({
                'value': epics.idea_id, 'label': epics.idea_title
            })
        );
    })

    useEffect(() => {
        // Populate sprintDetails
        const details = state.sprints.map((sprint) => {
            const [year, month, date] = sprint.startDate.split('-');
            const [year1, month1, date1] = sprint.targetDate.split('-');
            return {
                value: sprint.moduleId,
                sprint_status: sprint.sprint_status,
                epicId: sprint.ideaId,
                label: `${sprint.moduleDesc} [${month}.${date}.${year} - ${month1}.${date1}.${year1}]`,
                targetDate: sprint.targetDate
            };
        });

        setSprintDetails(details);

        // Set defaultSprint 
        if (details.length > 0) {
            setDefaultSprint(details[0]);
            setSprintTargetDate(details[0].targetDate);
            dispatch(actions.sprintSelected(details[0].value));
            dispatch(actions.targetDate(details[0].targetDate));
        }  
// eslint-disable-next-line
    }, [state.sprints]);
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-lg-6 p-2" style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    {(props.data.view === 'epic') ? <h5 class="modal-title p-2">ASSIGN {PROJECT}</h5> : <h5 class="modal-title p-2">ASSIGN {MODULE}</h5>}
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" className="d-flex align-items-right p-2" data-dismiss="modal" onClick={props.handleModalClose}><i class="mdi mdi-close text-black"></i></button>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <form >

                                            {(props.data.view === "epic") ?
                                                <div class="form-group row p-2" style={{ flexDirection: "row" }}>
                                                    <label for="story" className="col-form-label">Select {PROJECT}</label>
                                                    <Select
                                                        className="form-control "
                                                        placeholder={`Select ${PROJECT}`}
                                                        value={epicsList.value}
                                                        onChange={(selectedOption) => {
                                                            dispatch(actions.epicSelected(selectedOption.value))
                                                        }}
                                                        options={epicsList}
                                                    /></div>
                                                :
                                                <div>
                                                    <div class="form-group row p-2" style={{ flexDirection: "row" }}>
                                                        <label for="story" className="col-form-label">Select {MODULE}</label>
                                                        <Select
                                                            className="form-control "
                                                            placeholder={`Select ${MODULE}`}
                                                         value={defaultSprint ? defaultSprint: sprintDetails} 
                                                        //  value={sprintDetails.value}
                                                            onChange={(selectedOption) => {
                                                                dispatch(actions.sprintSelected(selectedOption.value))
                                                                dispatch(actions.targetDate(selectedOption.targetDate))
                                                                setSprintTargetDate(selectedOption.targetDate)
                                                                setSprint_status(selectedOption.sprint_status)                                        
                                                                setDefaultSprint(selectedOption);                                       

                                                            }}
                                                            // options={defaultSprint ? defaultSprint: sprintDetails}
                                                            options={sprintDetails}
                                                        />
                                                    </div>
                                                    <div> <label for="target_date" class="col-form-label pt-0" style={{ width: '150px' }}>{TARGET_DATE}<span style={{ color: "red" }} >*</span></label>
                                                        <input type="date" class="form-control" id="target_date" name="target_date" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px', marginTop: '-10px' }}
                                                            value={state.targetDate.value || sprintTargetDate}
                                                            onChange={(event) => dispatch(actions.targetDate(event.target.value))} />

                                                    </div>
                                                </div>
                                            }
                                        </form>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    {/* onClick={(event) => { addProject(state, dispatch, getUser.user, props.handleClose) }} */}
                                </div>
                                {(props.data.view === 'epic') ?
                                    <button type="button" disabled={!(epicsList.value || state.epicSelected.value)} class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={(event) => { addEpic(activities,props.data.taskId, state, dispatch, getUser.user, props.handleClose, props.data.project_id) }}>Add To {PROJECT}</button> :
                                    <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={(event) => { addSprint(activities,sprint_status,props.data.assignedTo,props.data.currentDate,sprintTargetDate,state.targetDate.value,props.data.taskId, state, dispatch, getUser.user, props.handleClose, props.data.device_id,props.data.assign_to,props.data.sprintDesc,props.data.title,props.data.storyPoints, MODULE, MAINTASK, KANBAN, props.data.project_id) }}>Add To {MODULE}</button>
                                }
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}
