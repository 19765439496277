import Alert from "../../Alert";
import API from "../../Network/API";

export async function reOrderWorkflowTemplates(stack, SUBTASKS, handleClose) {
 const reorderArray = stack ? stack.map((priority, index) => {
    return priority.template_id;
  }): null;
    try {
      var response = await API.post("tasks_workflow.php", {
        action: "reorder",        
        priority: reorderArray,
        msgId: reorderArray || " ",
      }, {}, false);
      if (response.status === 'true') {
         Alert('success', `${SUBTASKS} Reordered`);
         handleClose();
      }
    } catch (error) {
      Alert('warning', error.message);
    }
}