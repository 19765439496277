// services/Read/getAllMessages.js

import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoading, allMessages, isLoaded } from "../action"; 
export async function getAllMessages(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("squad_chat.php", {
      corp_code: getUser.corp,
      action: "get_all_messages",
      sendBy: getUser.empId,
    }, {}, false);
    if (response.status === 'True') {
      dispatch(allMessages(response.data));
    } else {
      dispatch(allMessages([]));
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded()); 
}
