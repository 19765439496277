import {  isLoaded, isLoading, assignedRoadBlocks } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

//For assigned Roadblocks
export async function getAssignedRoadBlocks(dispatch,getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("userRoadblocks.php", {
      "crop": getUser.corp,
      action: "assignedroadblocks",
    },{},false);
    if(response.status === 'True') {
      dispatch(assignedRoadBlocks(response.data))
    }
    else{
      dispatch(assignedRoadBlocks([]))
    }
  } catch (error) {
    Alert('error',error.message);
  }
  dispatch(isLoaded());
}