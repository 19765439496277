//(READ)
import { ranks, isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import ReportsAPI from "../../../Common/AgileNetwork/ReportsAPI";

export async function getRanks(dispatch, dateFilter, groupEmails = null) {
    dispatch(isLoading());
    try {
        let data = {};
        if (groupEmails) {
            data = { groupEmails }
        }
        var response = await ReportsAPI.post(`/ranks/getRanks`, { data, filter: dateFilter }, {}, false);
        if (response.length > 0) {
            dispatch(ranks(response))
        } else {
            dispatch(ranks([]))
        }
    } catch (error) {
        dispatch(ranks([]))
        Alert('error', error.message);
    }
    dispatch(isLoaded());
}