import API from "../../../Common/Network/API";
import store from "../../../Common/Store";
import { isLoaded, isLoading, kanban_roadblock, kanban_roadblock_filter } from "../actions";

export async function getRoadBlockUserStoriesToKanban(dispatch, getUser, moduleId, refresh) {
    if (refresh) { dispatch(isLoading()); }
    try {
      var response = await API.post(
        "get_user_stories.php",
        {
          crop: getUser.corp,
          action: "kanbanRoadBlocks", // action is changed from "roadBlockToKanban" (this was not supported with labels)
          moduleId: moduleId,
          projectId: getUser.projectId,
          userType: getUser.role,
          empId: getUser.empId
        },
        {},
        false
      );
      // console.log(response)
      if (response.message === "No Data Available") {
        store.dispatch(kanban_roadblock([]));
        store.dispatch(kanban_roadblock_filter([]));
      } else {
        if (!response.data) {
          store.dispatch(kanban_roadblock([]));
          store.dispatch(kanban_roadblock_filter([]));
        } else {
          store.dispatch(kanban_roadblock(response.data));
          store.dispatch(kanban_roadblock_filter(response.data));
        }
      }
    } catch (error) {
    }
  
    dispatch(isLoaded());
  }
