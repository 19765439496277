import { toBeAssignedRoadBlocks, isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

//For toBeAssigned RoadBlocks
export async function getToBeAssignedRoadBlocks(dispatch,getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("userRoadblocks.php", {
      "crop": getUser.corp,
      "action": "roadblocks"
    },{},false);
    if(response.status === 'True') {
      dispatch(toBeAssignedRoadBlocks(response.data))
    }
    else{
      dispatch(toBeAssignedRoadBlocks([]))
    }
  } catch (error) {
    Alert('error',error.message);
  }
  dispatch(isLoaded());
}