import React, { useEffect, useState } from "react";
import $ from 'jquery';
import AdminSideBar from "../Utility/SideNav";
import Header from "../../Common/TopNav";
import RankingForm from "./RankingForm";
import ReportsAPI from "../../Common/AgileNetwork/ReportsAPI";
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom';



export default function EmployeeInfo() {
    const [open, setOpen] = useState({ status: false, index: 0 })
    const { params: { empId } } = useRouteMatch();
    const [ranks, setRanks] = useState([]);
    const handleOpen = (action) => {
        if (action === "rank") {
            setOpen({ status: true, action: action });
        }
    }
    const handleClose = () => {
        setOpen({ status: false, index: 0 });
    };

    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
    }

    useEffect(() => {
        ReportsAPI.get(`/ranks/getRanks/${empId}`, {}, false)
            .then(result => {
                setRanks(result)
                console.log("hello")
            })
            .catch(err => {
            })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (ranks.length > 0) {
            $(document).ready(function () {
                window.$('#example').DataTable({
                    destroy: true,
                    dom: 'Bfrtip',
                    aLengthMenu: [
                        [25, 50, 100, 200, -1],
                        [25, 50, 100, 200, "All"]
                    ],
                    iDisplayLength: -1,
                    buttons: [
                        { extend: 'excelHtml5', text: 'Export' }
                    ]
                })
            })
        }
    }, [ranks])

    return (
        <div className="container-scroller">
            <Header />
            <div className="container-fluid page-body-wrapper">
                <AdminSideBar />
                <div className="main-panel">
                    <div className="mt-2 p-2">
                        <div className="mt-2 p-2"><span>Employee Name : Naveen Kumar Gade</span></div>
                        <button className="ml-1 badge badge-pill badge-success text-center" onClick={() => handleOpen("rank")}>Award Ranking`s</button>

                        <div className="col-lg-12 mt-2 grid-margin stretch-card">
                            <div className="card p-2">
                                {/* <div className="d-flex justify-content-between align-items-center mb-2">
                                    <h4>Awarding Rankings</h4>
                                </div> */}
                                <table
                                    search="true"
                                    id="example" className="table table-striped table-bordered rwd-table">
                                    <thead style={{ backgroundColor: '#F4FAF7' }}>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Month</th>
                                            <th>Communication</th>
                                            <th>Leadership</th>
                                            <th>team</th>
                                            <th>Ownership</th>
                                            <th>Execution</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ranks.map((rank, i) => (
                                            <tr key={rank.r_id} style={{ height: '10px' }}>
                                                <td>{i + 1}</td>
                                                <td>{rank.award_on}</td>
                                                <td>{rank.communication}</td>
                                                <td>{rank.leadership}</td>
                                                <td>{rank.team}</td>
                                                <td>{rank.ownership}</td>
                                                <td>{rank.execution}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                    {
                        open.action === "rank" ? <RankingForm open={open.status} userId={empId} handleClose={handleClose} handleModalClose={handleModalClose}
                        /> : null
                    }
                </div>
            </div>
        </div>

    )
}