//(UPDATE)
import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { Client_URL } from "../../../Common/config";

export async function deActiveAccountUser(dispatch, getUser, empId, accountId, handleClose, handleModalClose, APP_NAME) {
  dispatch(isLoading());
  try {
    var response = await API.post("manage_user.php", {
      "corp": getUser.corp,
      "action": 'deactivate_account_user',
      "empId": empId,
      accountId: accountId,
      url: Client_URL,
      appName: APP_NAME,
    }, {}, false);
    if (response.status === 'True') {
      Alert("success", 'Squad Member is Deactivated')
      handleClose();
    }
    else {
      handleClose()
    }
  } catch (error) {
    handleClose()
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}


export async function activeAccountUser(dispatch, getUser, empId, accountId, handleClose, handleModalClose, APP_NAME) {
  dispatch(isLoading());
  try {
    var response = await API.post("manage_user.php", {
      "corp": getUser.corp,
      "action": 'activate_account_user',
      "empId": empId,
      accountId: accountId,
      url: Client_URL,
      appName: APP_NAME,
    }, {}, false);
    if (response.status === 'True') {
      Alert("success", 'Squad Member is Activated')
      handleClose()
      // activateEmployee(dispatch, getUser, email, handleClose, handleModalClose) //De Activate the Employee -->GNK -01
      // Alert("success", 'Employee is Deleted')
      // handleClose()
    }
    else {
      handleClose()
    }
  } catch (error) {
    handleClose()
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}