import { isLoaded, isLoading, sprints } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

// to get active sprints in add to sprint model class
export async function getSprints(dispatch, getUser, projectId) {

  dispatch(isLoading());
  try {
    var response = await API.post("getUpdateSprint.php", {
      "crop": getUser.corp,
      "action": "getModules",
      "projectId": projectId || getUser.projectId
    }, {}, false);
    if (response.status === 'True') {
      dispatch(sprints(response.data))
      // getToDo(dispatch, getUser);
    }
    else {
      dispatch(sprints([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}