import { isLoaded, workingHours } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";



export async function getWorkingHours(dispatch, getUser) {
    try {
      var response = await API.post(
        "get_user_stories.php",
        {
          projectId: getUser.projectId,
          action: "working_hours",
        },
        {},
        false
      );
      if (response.status === "True") {
        dispatch(workingHours(response.data));
      } else {
        dispatch(workingHours([]));
      }
    } catch (error) {
      Alert("error", error.message);
    }
    dispatch(isLoaded());
  }
  