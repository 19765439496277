import { dependencyUser, isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

//For Getting Dependency Task List(Team)
export async function getDependency(dispatch, getUser,ideaId) {
  dispatch(isLoading());
  try {
    var response = await API.post("getSubtasks.php", {
      crop: getUser.corp,
      action:"setdependency",
      ideaId:ideaId
    }, {}, false);
    if (response.status === 'true') {
      dispatch(dependencyUser(response.data))
    }
    else {
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
  dispatch(isLoaded());
}
  