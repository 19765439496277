import API from "../../Network/API";
import { isLoading, isLoaded, section} from "../action";

// get list of all user stories
export async function getToDo(dispatch, getUser, moduleId) {
  // console.log(moduleId,getUser.corp)
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "crop": getUser.corp,
      "moduleId": moduleId,
      "action": "pending",
      "projectId": getUser.projectId,
      "userType": getUser.role,
      "empId": getUser.empId,
    }, {}, false);
    var toDoList = [];
    var doingList = [];
    // console.log(response.data)
    dispatch(section(response.data))
    response.data.map((pending) => {
      return (
        (pending.activeStatus === "0") ? toDoList.push(pending) 
        :(pending.activeStatus === "1")? doingList.push(pending):null
      );
    })
    // store.dispatch(todo(toDoList));
    // store.dispatch(doing(doingList));
    // store.dispatch(todoFilter(toDoList));
    // store.dispatch(doingFilter(doingList))
  } catch (error) {
    console.log(error)
  }

  dispatch(isLoaded());
}