import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { completedUserStories, isLoaded, isLoading } from "../actions";

export async function getUserStoriesBySprint(dispatch, getUser, sprintId) {
    dispatch(isLoading());
    try {
      var response = await API.post("archive.php", {
        "crop": getUser.corp,
        "action": "getStoriesBySprint",
        "projectId": getUser.projectId,
        "userType": getUser.role,
        "empId": getUser.empId,
        "sprintId": sprintId
  
      }, {}, false);
      if (response.status === 'true') {
        dispatch(completedUserStories(response.data.sort((a, b) => b.us_id - a.us_id)))
      }
      else {
        dispatch(completedUserStories([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }