import API from "../../../Common/Network/API";
import store from "../../../Common/Store";
import { isLoaded, isLoading, kanban_done, kanban_done_filter } from "../actions";


//For Kanban Completed Tasks List
export async function getDoneUserStoriesToKanban(dispatch, getUser, moduleId, refresh) {
    if (refresh) { dispatch(isLoading()); }
    try {
      var response = await API.post(
        "get_user_stories.php",
        {
          crop: getUser.corp,
          userType: getUser.role,
          empId: getUser.empId,
          moduleId: moduleId,
          action: "kanban_completed",
          projectId: getUser.projectId,
        },
        {},
        false
      );
      // console.log(response)
      if (response.status === "True") {
        store.dispatch(kanban_done(response.data));
        store.dispatch(kanban_done_filter(response.data));
      } else {
        store.dispatch(kanban_done([]));
        store.dispatch(kanban_done_filter([]));
      }
    } catch (error) {
      console.log(error);
    }
  
    dispatch(isLoaded());
  }