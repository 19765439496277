import { isLoaded, isLoading } from "../../Actions/loading";
import Alert from "../../Alert";
import API from "../../Network/API";
import { taskComments } from "../actions";

//Get User Comments 
export async function getMessages(dispatch, getUser, data) {
    dispatch(isLoading());
    try {
      var response = await API.post("squad_chat.php", {
        corp_code: getUser.corp,
        action: "get_messages",
        sendBy: getUser.empId,
        receivedBy: data.id
      }, {}, false);
      if (response.status === 'True') {
        dispatch(taskComments(response.data));
      } else {
        dispatch(taskComments([]));
      }
    } catch (error) {
      Alert('error', error.message)
    }
    dispatch(isLoaded());
  }