import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

export async function createMuralBoard(state, dispatch, getUser, muralName, handleClose) {

  dispatch(isLoading());
  try {
    var response = await API.post("mural.php", {
      crop: getUser.corp,
      muralname: muralName,
      createdBy: getUser.empId,
      action: "saveMural",
      "projectId":getUser.projectId

    }, {}, false);
    if (response.status === 'True') {
      Alert("success", response.message);

    }
    else {
      Alert('error', 'Failed');
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());

  }
  handleClose()

}
