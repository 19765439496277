import { isLoading,isLoaded,  privateToDo } from "../actions";
import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";

export async function getPrivateTodo(dispatch, getUser) {
  dispatch(isLoading());
    try {
      var response = await API.post("personal_tasks.php", {
        "crop": getUser.corp,
        "action": 'private_todo',
        "added_by": getUser.empId,
      }, {}, false);
      // console.log(response)
      if (response.status === 'True') {
        dispatch(privateToDo(response.data))
      }
      else {
        dispatch(privateToDo([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
  dispatch(isLoaded());
}