export const USERS = 'USERS';
export const RANKS = 'RANKS';
export const USER_RANKS = 'USER_RANKS';
export const ALLMESSAGES = 'ALLMESSAGES';
export const IS_LOADED = 'IS_LOADED';
export const IS_LOADING = 'IS_LOADING';

export const ranks = (ranks) => {
    return {
        type: RANKS,
        payload: ranks
    };
};

export const users = (users) => {
    return {
        type: USERS,
        payload: users
    };
};

export const userRanks = (userRanks) => {
    return {
        type: USER_RANKS,
        payload: userRanks
    };
};

export const allMessages = (allMessages) => {
    return {
        type: ALLMESSAGES,
        payload: allMessages
    };
};

export function isLoading() {
    return { type: IS_LOADING };
}
export function isLoaded() {
    return { type: IS_LOADED }
}