import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";


//Add new Kanban
export async function addKanban(state, dispatch,getUser,handleClose, KANBAN) {
    dispatch(isLoading());
        try {
            const data = {
              module_Name: "Support Board",
              empId: getUser.empId, //Async
              action: "add",
              targetDate: '0001-01-01',
              startDate:'0001-01-01',
              crop: getUser.corp,
              projectId: getUser.projectId
            };
            const response = await API.post("manage_sprints.php", data, {}, false);
            if (response.status === 'True') {
              //  dispatch(getModules(dispatch,getUser))
               Alert("success", ``);
            }
            else{
              alert("something went wrong")
            }
        } catch (error) {
            Alert("error", error.message);
        }
        handleClose()
    dispatch(isLoaded());
  }
  