import Alert from "../../Alert";
import API from "../../Network/API";
import { isLoaded, isLoading, manageTasks } from "../actions";

//For Get MainTask/UserStories List From Modules/Sprints
export async function getSprintUserStories(dispatch, getUser, moduleId) {
    dispatch(isLoading());
    try {
      var response = await API.post("getModuleMaintasks.php", {
        crop: getUser.corp,
        moduleId: moduleId,
        "projectId":getUser.projectId
      }, {}, false);
      if (response.status === 'True') {
        dispatch(manageTasks(response.data))
      }
      else {
        dispatch(manageTasks([]))
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    dispatch(isLoaded());
  }
  