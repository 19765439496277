//common/ChatRoadBlock/Services/getMessages  (READ)
import { isLoaded, isLoading } from "../../Actions/loading";
import Alert from "../../Alert";
import API from "../../Network/API";
import {  taskComments } from "../actions";


export async function getMessages(dispatch, getUser, data) {
    dispatch(isLoading());
    try {
      var response = await API.post("user_story_chat.php", {
        corp_code: getUser.corp,
        action: "getmessages",
        groupId: data.taskInfo.us_id
      }, {}, false);
      if (response.status === 'True') {
        dispatch(taskComments(response.data));
      } else {
        dispatch(taskComments([]));
      }
    } catch (error) {
      Alert('error', error.message)
    }
    dispatch(isLoaded());
  }
  