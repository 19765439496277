import { isLoaded, isLoading, savedMuralBoards } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";


export async function getMuralBoards(dispatch, getUser) {

  dispatch(isLoading());
  try {
    var response = await API.post("mural.php", {
      crop: getUser.corp,
      action: "muralBoards",
      "projectId":getUser.projectId,
      userType: getUser.role,
      createdBy: getUser.empId,


    }, {}, false);
    if (response.status === 'True') {

      dispatch(savedMuralBoards(response.data))
    }
    else {
      dispatch(savedMuralBoards([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}
  