import { isLoading, isLoaded } from "../../../Common/Actions/loading";
import API from "../../../Common/Network/API";
import store from '../../../Common/Store';
import {  done } from "../actions";

//For Completed Tasks List
export async function getDone(dispatch,getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("getSubtasks.php", {
      "crop": getUser.corp,
      "userType": getUser.role,
      "empId": getUser.empId,
      "action": "completed"
    }, {}, false);
    // console.log(response)
    store.dispatch(done(response.data));
  } catch (error) {
    console.log(error)
  }

  dispatch(isLoaded());
}