import Alert from "../../Alert";
import API from "../../Network/API";
import { isLoaded, isLoading } from "../../TasksModals/actions";
import { addCommentUserStory } from "./addCommentUserStory";

  //Update the Task Status --> GNK -->version 1.0.6.01
  export async function updateUserStory(dispatch, getUser, userStoryId,handleClose,handleModalClose,TASKS_WARNING, ROADBLOCK_WARNING
    ) {
    dispatch(isLoading());
    try {
      const response = await API.post("manage_userstories.php", {
        action: "update",
        task_id: userStoryId,
        crop: getUser.corp,
        task_status: 100,
        task_status_desc: "completed",
        task_complete_status: 1,
        empId: getUser.empId,
        projectId: getUser.projectId,
      }, {}, false);
      if (response.status === "True") {
        addCommentUserStory(dispatch, getUser, userStoryId,"2",handleClose,handleModalClose) //Add Comments for UserStory  Where We Have and Who can Add comments for that --> GNK -->version 1.0.6.01
      } else {
        const warning = response.type === "tasks" ? TASKS_WARNING : ROADBLOCK_WARNING;
        Alert('warning', warning)
        handleModalClose();
      }
    }
    catch (error) {
      Alert('error', error.message)
      handleModalClose();
    }
    dispatch(isLoaded());
  }