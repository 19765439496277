import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoading, isLoaded, allMessagesUser } from "../actions";

// get all messages from user chat
export async function getAllUserMessages(dispatch, getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("user_chat.php", {
        corp_code: getUser.corp,
        action: "get_all_messages_dash",
        sendBy: getUser.empId,
      }, {}, false);
      if (response.status === 'True') {
        dispatch(allMessagesUser(response.data));
      } else {
        dispatch(allMessagesUser([]));
      }
    } catch (error) {
      Alert('error', error.message)
    }
    dispatch(isLoaded());
  }