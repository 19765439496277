import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading, unCompletedStories } from "../actions";

//For Get Uncompleted Stories
export async function getUncompletedStories(dispatch,getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("get_epics.php", {
        "corp": getUser.corp,
        "action": "uncompleted_user_stories",
        "projectId": getUser.projectId
      },{},false);
      if(response.status === 'True') {
          dispatch(unCompletedStories(response.data))
      }
      else{
        dispatch(unCompletedStories([]))
      }
    } catch (error) {
      Alert('error',error.message);
    }
    dispatch(isLoaded());
  }