import {  isLoaded, isLoading, allMessages } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

export async function getAllTaskMessages(dispatch,getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("user_story_chat.php", {
        corp_code: getUser.corp,
        action: "getAllTaskMessages",
        "projectId":getUser.projectId
      }, {}, false);
      if (response.status === 'True') {
         dispatch(allMessages(response.data));
      }else{
        dispatch(allMessages([]));
      }
    } catch (error) {
      Alert('error',error.message)
    }
    dispatch(isLoaded());
  }
  