import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";


//Add New Module
export async function modifyModule(state, dispatch,getUser,handleClose, MODULE_MODIFY) {
    console.log(state)
    dispatch(isLoading());
    if ((state.moduleTitle.value !== "" && state.date.value !== "" && state.startDate.value !== "" )) {
        try {
            const data = {
              moduleId: state.moduleId.value,
              module_Name: state.moduleTitle.value,
              empId: getUser.empId, //Async
              action: "modify",
              targetDate: state.date.value,
              startDate:state.startDate.value,
              crop: getUser.corp
            };
            const response = await API.post("manage_sprints.php", data, {}, false);
            if (response.status === 'True') {
               //dispatch(getModules(dispatch,getUser))
               var title = { 'moduleId': state.moduleId.value, 'moduleDesc':state.moduleTitle.value, 'startDate':state.startDate.value, 'targetDate':state.date.value,sprint_status:state.sprint_status.value  }
               Alert("success", MODULE_MODIFY);
            }
        } catch (error) {
            Alert("error", error.message);
        }
        handleClose(title)
    } else {
        Alert("warning", "please fill the required fields")
    }
    dispatch(isLoaded());
  }
  