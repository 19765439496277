// userprofile/services/updateAccountType.js  (UPDATE) api

import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoading, isLoaded } from "../actions";

export async function updateAccountType(state, dispatch, getUser, license_key, handleClose) {
  dispatch(isLoading());
  if (license_key !== "") {
      try {
          const data = {
              action: 'change_account_type',
              corp: getUser.corp,
              license_key: license_key,
              empId: getUser.empId
          };
          const response = await API.post("get_user.php", data, {}, false);
          handleClose()
          alert(response.message)

      } catch (error) {
          Alert("error", error.message);
          handleClose()
      }
  } else {
      Alert("warning", "please enter license key")
  }
  dispatch(isLoaded());
}