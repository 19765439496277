import React, { useState, useEffect, useRef } from "react";
import "./chatbot.scss";
import axios from "axios"; // Use Axios for making API requests
import { useSelector } from "react-redux";
import { ChatBot_URL } from "../config";
import { emailValidation, nameValidation, phoneValidation } from "./validations";
import chatLogo from "./assets/ChatLogo.png";
import userLogo from "./assets/UserLogo.png";
import { useHistory } from "react-router-dom";
import ReportsAPI from "../AgileNetwork/ReportsAPI";
import API from "../Network/API";

function ChatBot() {
  //new change
  const [userInput, setUserInput] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const chatBottomRef = useRef(null);
  const [errorCount, setErrorCount] = useState(0);
  const [isForm, setIsForm] = useState(false);
  const [formData, setFormData] = useState({ email: "", phone: "", name: "" });
  const [formSelection, setFormSelection] = useState(1);
  const [chatBoxOpen, setChatBoxOpen] = useState(false);
  const history = useHistory();
  // eslint-disable-next-line
  const [prevHistory, setPrevHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line
  const webProperties = useSelector(
    (state) => state.landingReducer.webProperties
  );
  const APP_NAME = webProperties.APP_NAME;

  // new change

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.post(
          `${ChatBot_URL}/webhooks/rest/webhook`,
          {
            sender: "default",
            message: "welcome",
          }
        );
        setIsLoading(false);

        if (
          response.data &&
          response.data.length > 0 &&
          response.data[0].custom
        ) {
          setChatHistory((prevChatHistory) => [
            ...prevChatHistory,
            {
              user: "Bot",
              message: response.data[0].custom.message,
              options: response.data[0].custom.options,
            },
          ]);
        } else {
          throw new Error("Response format is incorrect or data is missing.");
        }
      } catch (error) {
        setIsLoading(false);
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          {
            user: "Bot",
            message:
              "Oops! It looks like something went wrong on our end. Please try again later or contact support for assistance.",
            options: [
              { title: "Contact Support", payload: "redirect_support" },
            ],
          },
        ]);
        console.error("Error fetching questions:", error);
      }
    };
    const savedChatData = JSON.parse(sessionStorage.getItem("chatData"));
    if (savedChatData) {
      setPrevHistory(savedChatData);
    }
    const chatboxStatus = sessionStorage.chatbox;
    if (chatboxStatus === "open") {
      openChat();
    }
    fetchQuestions();
  }, []);

  useEffect(() => {
    // Save chat data to sessionStorage whenever chatData changes
    if (chatHistory.length > 0) {
      sessionStorage.setItem("chatData", JSON.stringify(chatHistory));
      if (chatBottomRef.current) {
        chatBottomRef.current.scrollTop = chatBottomRef.current.scrollHeight;
      }
    }
  }, [chatHistory]);

  useEffect(() => {
    if (chatBottomRef.current && chatBoxOpen) {
      chatBottomRef.current.scrollTop = chatBottomRef.current.scrollHeight;
    }
  }, [chatBoxOpen]);

  useEffect(() => {
    if (isForm) {
      let message = "";
      switch (formSelection) {
        case 1: {
          message = "Please enter your name";
          break;
        }
        case 2: {
          message = "Please enter your email ID";
          break;
        }
        case 3: {
          message = "Please enter your phone number";
          break;
        }
        default: {
        }
      }
      setChatHistory((prevChatHistory) => [
        ...prevChatHistory,
        { user: "Bot", message: message },
      ]);
    }
  }, [isForm, formSelection]);

  useEffect(() => {
    const insertDemo = async () => {
      if (formSelection > 3) {
        setFormSelection(1);
        setIsForm(false);
        await createDemoTicket();
        await insertUserDemo("addUserDemo");
      }
    };
    insertDemo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSelection]);

  async function insertUserDemo(api) {
    try {
      await ReportsAPI.post(`/${api}`, formData, {}, false);
    } catch (error) {
      console.log("error", error.message);
    }
  }

  async function createDemoTicket() {
    try {
      setIsLoading(true);
      await API.post(
        "manage_userstories.php",
        {
          action: "addMainTaskFromOthers",
          title: `Demo request - ${formData.email}`,
          description: `Name : ${formData.name} Email: ${formData.email} Phone Number: ${formData.phone}`,
          projectId: "a4aa7017196ff7049cba05bd9c7a7679",
          email: formData.email,
        },
        {},
        false
      );
      setIsLoading(false)
      setChatHistory((prevChatHistory) => [
        ...prevChatHistory,
        {
          user: "Bot",
          message:
            "We have noted down your contact details. Our support team will contact you shortly. Thank you.",
          options: [{ title: "Main Menu", payload: "main_menu" }],
        },
      ]);
    } catch (error) {
      setIsLoading(false)
      setChatHistory((prevChatHistory) => [
        ...prevChatHistory,
        {
          user: "Bot",
          message:
            "Oops! It looks like something went wrong on our end. Please try again later or contact support for assistance.",
          options: [{ title: "Contact Support", payload: "redirect_support" }],
        },
      ]);
      console.log("Error");
    }
  }

  //############

  const handleChange = (e) => {
    setUserInput(e.target.value);
  };
  const openChat = () => {
    setChatBoxOpen(true);
    sessionStorage.chatbox = "open";
    // initializeChat(); // Load chat data when chat opens
  };

  const closeChat = () => {
    setChatBoxOpen(false);
    sessionStorage.clear();
    sessionStorage.chatbox = "close";
  };

  const handleChatSubmit = async (response) => {
    if (response.title.trim() !== "") {
      try {
        setChatHistory((prevChatData) => [
          ...prevChatData,
          { user: "You", message: response.title },
        ]);
        if (response.payload === "redirect_support") {
          history.push("/reportBug");
          return null;
        }
        setIsLoading(true)
        const apiResponse = await axios.post(
          `${ChatBot_URL}/webhooks/rest/webhook`,
          { sender: "default", message: response.payload }
        );
        const responseData = apiResponse.data;
        setIsLoading(false)

        if (responseData[0].custom.isForm) setIsForm(true);
        else if (isForm) setIsForm(false);
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          ...responseData.map((data) => ({
            user: "Bot",
            message: data.custom.message,
            options: data.custom.options,
          })),
        ]);

        setUserInput("");
      } catch (error) {
        setIsLoading(false)
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          {
            user: "Bot",
            message:
              "Oops! It looks like something went wrong on our end. Please try again later or contact support for assistance.",
            options: [
              { title: "Contact Support", payload: "redirect_support" },
            ],
          },
        ]);
        console.error("Error fetching response:", error);
      }
    }
  };

  const handleChatSubmitInput = async (response) => {
    if (userInput.trim() !== "") {
      try {
        setChatHistory((prevChatData) => [
          ...prevChatData,
          { user: "You", message: userInput },
        ]);
        setUserInput("");
        setIsLoading(true)
        const apiResponse = await axios.post(
          `${ChatBot_URL}/webhooks/rest/webhook`,
          { sender: "default", message: userInput }
        );
        const responseData = apiResponse.data;
        setIsLoading(false)
        let message = "";
        let options = [];
        if (
          responseData[0].custom.error &&
          responseData[0].custom.error_type === "conf"
        ) {
          if (errorCount > 0) {
            message =
              "I apologize for the inconvenience. It seems there's an issue I can't resolve right now. Please allow me to escalate this for you. Click on the Contact Support button below to redirect you to raise a ticket, and our dedicated support team will assist you promptly.";
            options.push({
              title: "Contact Support",
              payload: "redirect_support",
            });
          }
          setErrorCount((prev) => prev + 1);
        } else {
          setErrorCount(0);
        }

        if (responseData[0].custom.isForm) setIsForm(true);
        else if (isForm) setIsForm(false);

        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          ...responseData.map((data) => ({
            user: "Bot",
            message: message.length > 0 ? message : data.custom.message,
            options: options.length > 0 ? options : data.custom.options,
          })),
        ]);
      } catch (error) {
        setIsLoading(false)
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          {
            user: "Bot",
            message:
              "Oops! It looks like something went wrong on our end. Please try again later or contact support for assistance.",
            options: [
              { title: "Contact Support", payload: "redirect_support" },
            ],
          },
        ]);
        console.error("Error fetching response:", error);
      }
    }
  };

  const handleOptionClick = async (option) => {
    // setUserInput(option.title);
    await handleChatSubmit(option);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isForm) handleFormInputSubmit();
    else handleChatSubmitInput(userInput);
  };

  //#############

  const handleFormInputSubmit = async () => {
    setChatHistory((prevChatHistory) => [
      ...prevChatHistory,
      { user: "You", message: userInput },
    ]);
    switch (formSelection) {
      case 1: {
        if (nameValidation(userInput)) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            name: userInput,
          }));
          setFormSelection((prev) => prev + 1);
        } else {
          setChatHistory((prevChatHistory) => [
            ...prevChatHistory,
            { user: "Bot", message: "Please enter a valid name" },
          ]);
        }

        break;
      }
      case 2: {
        if (emailValidation(userInput)) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            email: userInput,
          }));
          setFormSelection((prev) => prev + 1);
        } else {
          setChatHistory((prevChatHistory) => [
            ...prevChatHistory,
            { user: "Bot", message: "Please enter a valid email ID" },
          ]);
        }
        break;
      }
      case 3: {
        if (phoneValidation(userInput)){
        setFormData((prevFormData) => ({
          ...prevFormData,
          phone: userInput,
        }));
        setFormSelection((prev) => prev + 1);
      } else {
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          { user: "Bot", message: "Please enter a valid phone number" },
        ]);
      }
        break;
      }
      default: {
      }
    }
    setUserInput("");
  };

  return (
    <div>
      <div
        id="chat-circle"
        onClick={() => openChat()}
        className="btn btn-raised"
        style={{
          backgroundColor:
            webProperties?.PRIMARY_COLOR !== "#47974b"
              ? webProperties?.PRIMARY_COLOR
              : "#47974b",
        }}
      >
        <div id="chat-overlay"></div>
        <i className="mdi mdi-comment" style={{ fontSize: "24px" }}></i>
      </div>

      {chatBoxOpen && (
        <div className="chat-box">
          {/* Chat Box Header */}
          <div
            className="chat-box-header"
            style={{
              backgroundColor:
                webProperties?.PRIMARY_COLOR !== "#47974b"
                  ? webProperties?.PRIMARY_COLOR
                  : "#47974b",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <img
              src={chatLogo}
              alt="Chat-Logo"
              style={{ width: "3rem", height: "3rem", marginBottom: "0.8rem" }}
            />
            <div>
              <h3 style={{ color: "white" }}>{APP_NAME} Assistant</h3>
            </div>
            <div>
              <span className="chat-box-toggle" onClick={closeChat}>
                <i
                  className="mdi mdi-close"
                  style={{ fontSize: "24px", color: "white" }}
                ></i>
              </span>
            </div>
          </div>

          {/* Chat Box Body */}
          <div className="chat-box-body">
            <div className="chat-box-overlay"></div>
            <div className="chat-logs" ref={chatBottomRef}>
              {chatHistory.map((entry, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "10px",
                    justifyContent: entry.user === "You" ? "right" : "left",
                  }}
                >
                  <>
                    {entry.message && (
                      <div
                        className="bot-message"
                        style={{
                          flexDirection:
                            entry.user === "You" ? "row-reverse" : "row",
                        }}
                      >
                        <img
                          src={entry.user === "Bot" ? chatLogo : userLogo}
                          alt="Logo"
                          style={{ width: "3rem", height: "3rem" }}
                        />
                        <div
                          style={{
                            backgroundColor:
                              entry.user === "You" ? "white" : "#bbdeea",
                            color: "black",
                            padding: "10px",
                            borderRadius: "10px",
                            alignSelf:
                              entry.user === "You" ? "flex-end" : "flex-start",
                            border: "1px solid #d3d3d3",
                          }}
                        >
                          {entry.message.split("/n").length > 1 ? (
                            <ol>
                              {entry.message.split("/n").map((line, index) => (
                                <li
                                  key={index}
                                  style={{
                                    margin: "5px 0",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  {line.trim()}
                                </li>
                              ))}
                            </ol>
                          ) : (
                            <>{entry.message}</>
                          )}
                        </div>
                      </div>
                    )}
                    {entry.options && (
                      <div
                        style={{
                          alignItems:
                            entry.user === "You" ? "flex-end" : "flex-start",
                        }}
                        className="chat-options-container"
                      >
                        {entry.options.map((option, index) => (
                          <button
                            key={index}
                            className="chat-options"
                            onClick={() => handleOptionClick(option)}
                          >
                            {option.title}
                          </button>
                        ))}
                      </div>
                    )}
                  </>
                </div>
              ))}
              {isLoading && (
                <div className="bot-loading">
                  <img
                    src={chatLogo}
                    alt="Chat Logo"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                  <div className="typing">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Chat Input */}
          <div className="chat-input">
            <form onSubmit={handleFormSubmit}>
              <input
                value={userInput}
                onChange={handleChange}
                type="text"
                id="chat-input"
                placeholder="Send a message..."
              />
              <button type="submit" className="chat-submit" id="chat-submit">
                <i
                  className="mdi mdi-send"
                  style={{
                    fontSize: "24px",
                    color:
                      webProperties?.PRIMARY_COLOR !== "#47974b"
                        ? webProperties?.PRIMARY_COLOR
                        : "#47974b",
                  }}
                ></i>
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatBot;
