
import { CHECK_CURRENT_SPRINT, IS_LOADED, IS_LOADING, MODIFY_PROJECT, PROJECTS, PROJECT_DESCRIPTION, PROJECT_TITLE, UNCOMPLETED_STORIES, TARGET_DATE,MODULE_TITLE } from "./actions";
import { PROJECT } from '../../Common/Headers';

import { validateName,validateProjectTitle ,validateModuleTitle} from '../../Common/validators'
const initialValue = {
    value: "",
    errorStatus: false,
    errorMessage: "Please Enter valid ",
};
export const initialState = {
    projects: [],
    projectTitle: initialValue,
    projectDescription: initialValue,
    targetDate: initialValue,
    projectId: initialValue,
    uncompletedStories: [],
    currentSprintEpics: [],
    moduleTitle :'',

};

export function ideaReducer(state = initialState, action) {

    switch (action.type) {
        case PROJECTS:
            return {
                ...state,
                projects: action.payload
            };
        case CHECK_CURRENT_SPRINT:
            return {
                ...state,
                currentSprintEpics: action.payload
            };
        case UNCOMPLETED_STORIES:
            return {
                ...state,
                uncompletedStories: action.payload
            }
        case PROJECT_TITLE:
            if (validateName(action.payload)) {
                return {
                    ...state,
                    projectTitle: {
                        ...state.projectTitle,
                        value: action.payload,
                        errorStatus: false,
                    },
                };
            } 
            else {
                return {
                    ...state,
                    projectTitle: {
                        ...state.projectTitle,
                        value: action.payload,
                        errorStatus: true,
                        errormessage: `please enter the ${PROJECT} name `
                    },
                };
            
            }

        case MODULE_TITLE:
                if(validateModuleTitle(action.payload)){
                return {
                        ...state,
                        moduleTitle: {
                            ...state.moduleTitle,
                            value: action.payload,
                              errorStatus: true,
                         errormessage: `please enter atleast four characters`
                        },
                

                }
            }
            if (validateProjectTitle(action.payload)) {
                return {
                    ...state,
                    moduleTitle: {
                        ...state.moduleTitle,
                        value: action.payload,
                        errorStatus: false,
                    },
                };
            } 
            else {
                return {
                    ...state,
                    moduleTitle: {
                        ...state.moduleTitle,
                        value: action.payload,
                        errorStatus: true,
                         errormessage: `special characters or numbers are not allowed`
                        // errormessage: `please enter the valid ${PROJECT} name `
                        //errormessage: `Input must include at least one letter. Digits and special characters alone are not permitted`

                    },
                };
            }



        case PROJECT_DESCRIPTION:
                    if(validateModuleTitle(action.payload)){
            return {
                ...state,
                projectDescription: {
                    ...state.projectDescription,
                    value: action.payload,
                    errorStatus: true,
                    // errormessage: `please enter the ${PROJECT} description/summary `
                      errormessage: `please enter  atleast four characters`
                },
            };
        }
            if (validateProjectTitle(action.payload)) {
                return {
                    ...state,
                    projectDescription: {
                        ...state.projectDescription,
                        value: action.payload,
                        errorStatus: false,
                    },
                };
            } else {
                return {
                    ...state,
                    projectDescription: {
                        ...state.projectDescription,
                        value: action.payload,
                        errorStatus: true,
                       // errormessage: `please enter the ${PROJECT} description/summary `
                       errormessage: `special characters or numbers are not allowed`
                    },
                };
            }
         
            case TARGET_DATE:
                if (action.payload !== '') {
                    return {
                        ...state,
                        targetDate: {
                            ...state.targetDate,
                            value: action.payload,
                            errorStatus: false,
                        },
                    };
                } else {
                    return {
                        ...state,
                        targetDate: {
                            ...state.targetDate,
                            value: action.payload,
                            errorStatus: true,
                            errormessage: `please select ${TARGET_DATE} `
                        },
                    };
                }
        case MODIFY_PROJECT:
            return {
                ...state,
                moduleTitle: {
                    ...state.moduleTitle,
                    value: action.payload.moduleTitle,
                    errorStatus: false
                },
                projectDescription: {
                    ...state.projectDescription,
                    value: action.payload.projectDescription,
                    errorStatus: false,
                },
                projectId: {
                    ...state.projectId,
                    value: action.payload.projectId,
                    errorStatus: false,
                },
                targetDate: {
                    ...state.targetDate,
                    value: action.payload.targetDate,
                    errorStatus: false,
                },
            }
        case IS_LOADING:
            return { ...state, isLoading: true };
        case IS_LOADED:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}
