//common/ChatRoadBlock/Services/getGroupMembersList (READ)
import { isLoaded, isLoading } from "../../Actions/loading";
import Alert from "../../Alert";
import API from "../../Network/API";
import { groupList, groupName } from "../actions";

export async function getGroupMembersList(dispatch, getUser, data) {
  const projectId = data.project_id ? data.project_id : getUser.projectId;
  const project_name = data.project_name ? data.project_name : getUser.corp;

  dispatch(isLoading());
  try {
    // var response = await API.post("", {
    var response = await API.post("user_story_details.php", {
      corp_code: project_name,
      action: data.action,
      groupId: data.taskInfo.us_id,
      projectId: projectId,
    }, {}, false);
    //Getting the Group details for Subtask start
    if (response.status === 'True' && response.task === 'subtask') {
      const al = [];
      // al.push(response.ideaByName);
      al.push(response.acceptedByName);
      al.push(response.releaseOwner);
      al.push(response.moduleCreatedBy);
      al.push(response.moduleModifiedBy);
      al.push(response.mainAssignedBy);
      al.push(response.mainAssignedTo);
      al.push(response.mainModifiedBy);
      al.push(response.subAssignedBy);
      al.push(response.subAssignedTo);
      al.push(response.subModifiedBy);
      al.push(response.rejectedByName);
      al.push(response.moduleModifiedBy);
      al.push(response.ideaModfiedName);
      al.push('Admin(admin)');
      dispatch(groupName(response.subTaskId))

      const k = (al);
      function getUnique(array) {
        var uniqueArray = [];
        // Loop through array values
        for (var value of array) {
          if (uniqueArray.indexOf(value) === -1) {
            if (value !== "NA" && value !== "()") {
              uniqueArray.push(value);
            }
          }
        }
        return uniqueArray;
      }
      var uniqueNames = getUnique(k);

      var h = uniqueNames;
      // console.log(uniqueNames+h.length)
      var details = "Admin(admin)";
      for (var i = 1; i <= h.length; i++) {//Checking the group list

        if (h[i] === "Admin(admin)") { //Remove Duplicate list
          h.splice(i, 1);
        }
        //Concat List of Group Members
        details = details + "," + h[i - 1]; //Including
        // details = h[i-1];// Excluding Admin
        dispatch(groupList((details.replace("Admin(admin),", ""))))
      }
      dispatch(groupName(response.subTaskId))
    }
    //Getting the Group details for Subtask end

    //Getting the Group details for roadblock start
    else if (response.status === 'True' && response.task === 'roadblock') {
      const al = [];
      // al.push(response.ideaByName);
      al.push(response.acceptedByName);
      al.push(response.releaseOwner);
      al.push(response.moduleCreatedBy);
      al.push(response.moduleModifiedBy);
      al.push(response.mainAssignedBy);
      al.push(response.mainAssignedTo);
      al.push(response.mainModifiedBy);
      al.push(response.subAssignedBy);
      al.push(response.subAssignedTo);
      al.push(response.subModifiedBy);
      al.push(response.rejectedByName);
      al.push(response.moduleModifiedBy);
      al.push(response.ideaModfiedName);
      al.push(response.roadBlockRequestedBy);
      al.push(response.roadBlockAssignedBy);
      al.push(response.roadBlockAssignedTo);
      al.push('Admin(admin)');
      dispatch(groupName(response.subTaskId))

      const k = (al);
      function getUnique(array) {
        var uniqueArray = [];
        // Loop through array values
        for (var value of array) {
          if (uniqueArray.indexOf(value) === -1) {
            if (value !== "NA" && value !== "()") {
              uniqueArray.push(value);
            }
          }
        }
        return uniqueArray;
      }
      uniqueNames = getUnique(k);

      h = uniqueNames;
      // console.log(uniqueNames+h.length)
      details = "Admin(admin)";
      for (i = 1; i <= h.length; i++) {//Checking the group list

        if (h[i] === "Admin(admin)") { //Remove Duplicate list
          h.splice(i, 1);
        }
        //Concat List of Group Members
        details = details + "," + h[i - 1]; //Including
        // details = h[i-1];// Excluding Admin
        dispatch(groupList((details.replace("Admin(admin),", ""))))
      }
      dispatch(groupName(response.subTaskId))
    }
    //Getting the Group details for roadblock end

    //Getting the Group details for Maintask start
    else if (response.status === 'True' && response.task === 'maintask') {
      var result = {
        assigned_to: response.assigned_to,
        assigned_by: response.assigned_by,
        project_name: response.project_name,
        story_title: response.story_title,
        data: response.data.map(function (item) {
          return {
            full_name: item.fullname,
            full_role: item.role,
            email: item.email,
            assigned_to_email: item.assigned_to_email,
            assigned_by_email: item.assigned_by_email,
            display_name: item.display_name
          };
        })
      };

      // Resulting array containing only 'fullname' and 'role'

      dispatch(groupList(result))
      // dispatch(groupName(response.subTaskId))
    }//Getting the Group details for maintask end
    else {

    }
  } catch (error) {
    Alert('error', error.message)
  }
  dispatch(isLoaded());
}