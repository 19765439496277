export const getSubStringId = (params, count) => {
  if (params !== undefined) {
    let name = params;

    if (name !== null && name.includes(" ")) {
      return name.split(' ').map(word => word[0]).join('').toUpperCase();
    } else if (name !== null) {
      return name.slice(0, 2).toUpperCase();
    } else {
      return "-";
    }
  } else {
    return "-";
  }
}

export const getSubStringSprintId = (params, count) => {
  if (params !== undefined) {
    let name = params;

    if (name !== null && name.includes(" ")) {
      return name.split(' ').map(word => word[0]).join('').toUpperCase();
    } else if (name !== null) {
      return name.slice(0, 2).toUpperCase();
    } else {
      return "";
    }
  } else {
    return "";
  }
}
