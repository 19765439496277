import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import {  isLoaded, isLoading, details } from "../action";

// for getting group list created by admin
export async function getAdminGroupDetails(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("group_chat.php", {
      action :'getAdminGroupDetails',
      created_by: getUser.userName,
      // projectId: getUser.projectId,
      // "userType": getUser.role,
      // "empId": getUser.empId,
    }, {}, false);
    if (response.status === 'True') {
      dispatch(details(response.data))
    }
    else {
      dispatch(details([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}