import {
  isLoaded,
  isLoading,
  done,
  doneFilter,
  groupDone,
} from "../actions";
import API from "../../../Common/Network/API";
import store from "../../../Common/Store";


//For Completed Tasks List
export async function getDoneUserStories(dispatch, getUser, moduleId, refresh) {
  if (refresh) { dispatch(isLoading()); }
  try {
    var response = await API.post(
      "get_user_stories.php",
      {
        crop: getUser.corp,
        userType: getUser.role,
        empId: getUser.empId,
        moduleId: moduleId,
        action: "completed",
        projectId: getUser.projectId,
      },
      {},
      false
    );
    // console.log(response)
    if (response.status === "True") {
      store.dispatch(done(response.data.filter(item => {
        return item.story_type !== "group"
      })));
      store.dispatch(doneFilter(response.data.filter(item => {
        return item.story_type !== "group"
      })));
      store.dispatch(groupDone(response.data.filter(item => {
        return item.story_type === "group"
      })))
    } else {
      store.dispatch(done([]));
      store.dispatch(doneFilter([]));
      store.dispatch(groupDone([]))
    }
  } catch (error) {
    console.log(error);
  }

  dispatch(isLoaded());
}