import Alert from "../../Alert";

import API from "../../Network/API";
import { isLoaded, isLoading, subTasks } from "../action";

export async function getSubTasks(dispatch, getUser, empId, role) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_pending_sprints.php", {
      "crop": getUser.corp,
      "empId": empId,
      "projectId":getUser.projectId
    }, {}, false);
    if (response.status === 'true') {
      dispatch(subTasks(response.data))
    }
    else {
      dispatch(subTasks([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}