import { employees, isLoaded, isLoading } from "../actions";
import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";

//For Get Employees List
export async function getEmployees(dispatch, getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("agile_squads.php", {
        "crop": getUser.corp,
        projectId: getUser.projectId,
      }, {}, false);
      if (response.status === 'True') {
        dispatch(employees(response.data))
  
      }
      else {
        dispatch(employees([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }
