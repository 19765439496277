import { isLoaded, isLoading, modules } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";


//For ToDo and Doing Subtask List based on task active status
export async function getModules(dispatch,getUser,idea_id) {
    dispatch(isLoading());
    try {
      var response = await API.post("manage_sprints.php", {
        "crop": getUser.corp,
        "idea_id":idea_id,
        "action": "get"
      },{},false);
      if(response.status === 'True') {
          dispatch(modules(response.data))
      }
      else{
        dispatch(modules([]))
      }
    } catch (error) {
      Alert('error',error.message);
    }
    dispatch(isLoaded());
  }