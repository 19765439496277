export const MetaData = {
    home: {
        title: 'Wetasker | Task Management Software',
        description: 'Welcome to Wetasker! Manage tasks efficiently to grow your business. Get organized, boost productivity, and empower your team with Wetasker.',
        keywords: 'task management software, productivity, team collaboration, business growth'
    },
    contact: {
        title: 'Contact Us | Wetasker',
        description: 'Get in touch with Wetasker! Reach out to our team for any inquiries, feedback, or support. We’re here to help you succeed.',
        keywords: 'contact us, support, feedback, customer service'
    },
    login: {
        title: 'Log In | Wetasker',
        description: 'Log in to Wetasker and access your account. Streamline task management, track progress, and collaborate with your team effortlessly.',
        keywords: 'login, sign in, account access, task management platform'
    },
    register: {
        title: 'Sign Up | Wetasker',
        description: 'Sign up for Wetasker and start managing tasks effectively. Join thousands of businesses using Wetasker to achieve greater efficiency and success.',
        keywords: 'sign up, register, task management platform, business efficiency'
    },
    careers: {
        title: 'Careers at Wetasker',
        description: 'Explore career opportunities at Wetasker and join our dynamic team. We’re always looking for talented individuals passionate about making a difference.',
        keywords: 'careers, job opportunities, employment, join our team'
    },
    faq: {
        title: 'FAQs | Wetasker',
        description: 'Find answers to frequently asked questions about Wetasker. Learn how our task management software works and how it can benefit your business.',
        keywords: 'FAQs, frequently asked questions, task management software, help'
    },
    release: {
        title: 'Release Notes | Wetasker',
        description: 'Stay updated with the latest release notes from Wetasker. Discover new features, enhancements, and bug fixes to improve your task management experience.',
        keywords: 'release notes, updates, new features, task management software'
    },
    privacyPolicy: {
        title: 'Privacy Policy | Wetasker',
        description: 'Read Wetasker’s privacy policy to understand how we collect, use, and protect your personal information. Your privacy and security are our top priorities.',
        keywords: 'privacy policy, data protection, personal information, security measures'
    },
    forgotPassword: {
        title: 'Forgot Password | Wetasker',
        description: 'Forgot your password? No worries! Recover your account password with Wetasker’s password reset feature. Keep your account secure and get back to work.',
        keywords: 'forgot password, password recovery, account security, reset password'
    },
    bugReport: {
        title: 'Bug Report | Wetasker',
        description: 'Report a bug or issue with Wetasker. Help us improve our platform by providing detailed information about any problems you encounter. Your feedback is valuable to us!',
        keywords: 'bug report, issue reporting, platform improvement, feedback'
    },
}
