// eslint-disable-next-line
import React, { useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { deleteFaq } from './network';
import { reducer, initialState } from './reducer';
import { useSelector } from 'react-redux';
import { deleteCategory } from './network';
const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

// for delete faq
export default function DeleteFaq(props) {

    // eslint-disable-next-line
    const getUser = useSelector(state => state.auth)
    // eslint-disable-next-line
    const [state, dispatch] = useReducer(reducer, initialState)
    const classNames = useStyles();

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-5 p-2" style={{ borderRadius: '10px' }}>
                                <div className="modal-header" style={{ backgroundColor: '#D2ECDF' }}>

                                    <h5 className="modal-title p-2">
                                        {props.data.action === 'delete' ? 'Delete' : 'Delete Category'} </h5>
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" className="d-flex align-items-right p-2" data-dismiss="modal" onClick={props.handleModalClose}><i className="mdi mdi-close text-black"></i></button>

                                </div>
                                <div className="modal-body">
                                    {/* {props.data.action === 'delete' ?  */}
                                    <b>Do you want to delete  {props.data.action === 'delete' ? 'Delete' : props.data.data.name}{" "}?</b>
                                    {/* // : <b>Do you want to exit from Group ?</b>} */}
                                </div>


                                <div className="modal-footer justify-space-between">

                                    {/* <button type="button" className="btn btn-outline-success" style={{ borderRadius: '20px' }}>Cancel</button> */}
                                    {props.data.action === "delete" ?
                                        (<button type="button" className="btn btn-outline-danger"

                                            onClick={(event) => { deleteFaq(dispatch, props.data.data.id, props.handleClose) }}
                                            style={{ borderRadius: '20px' }}>Delete
                                        </button>

                                        ) : (
                                            <button type="button" className="btn btn-outline-danger"

                                                onClick={(event) => { deleteCategory(dispatch, props.data.data.id, props.handleClose) }}
                                                style={{ borderRadius: '20px' }}>Delete
                                            </button>
                                        )

                                    }



                                    {/* onClick={(event) => {  deleteFaq(dispatch, props.data.data.id, props.handleClose) }}  */}
                                    {/* <button type="button" className="btn btn-outline-danger" style={{ borderRadius: '20px' }}
                                     onClick={(event) => {  exitGroup(state, dispatch, newMem, newLabel, getUser.user, props.data.data.id, props.handleClose) }}
                                    >Exit</button> */}


                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}
