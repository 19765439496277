import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import {  isLoaded, isLoading } from "../action";
import { getAdminGroupDetails } from "./getAdminGroupDetails";

// for update group
export async function updateGroup(state, mem, label, dispatch, getUser,handleClose) {
  dispatch(isLoading());
  if (state.title === "") {
    Alert("warning", "Please add Group Name ")
    dispatch(isLoaded());
  }
  else if (state.title !== "") {
    try {
      var response = await API.post("group_chat.php", {
        crop: getUser.corp,
        action: "update",
        group_name: state.title.value ? state.title.value : state.title,
        group_Id: state.id.value,
        // for members names
        members: mem,
        // for members email
        label: label,
      }, {}, false);
      if (response.status === 'True') {
        getAdminGroupDetails(dispatch, getUser)
        Alert("success", "Group updated successfully!");
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
   }else {
  Alert("warning", "Please enter required fields ")
  dispatch(isLoaded());
   }
}