import API from "../../Network/API";
import { isLoaded, isLoading, roadblocks } from "../actions";


//Roadblocks for Project
export async function getRoadblocks(dispatch, getUser, id) {
    dispatch(isLoading());
    try {
      var response = await API.post("epic_info.php", {
        "crop": getUser.corp,
        "ideaId": id,
        "action": "roadblocks",
        "projectId":getUser.projectId,
        "userType": getUser.role,
        "empId": getUser.empId
      }, {}, false);
      if (response.status === "True") {
        dispatch(roadblocks(response.data));
      } else {
        dispatch(roadblocks([]));
      }
    } catch (error) {
      console.log(error)
    }
    dispatch(isLoaded());
  }