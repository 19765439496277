import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { completedUserStories, isLoaded, isLoading } from "../actions";

export async function getUserStoriesByKanban(dispatch, getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("archive.php", {
        "crop": getUser.corp,
        "action": "getStoriesByKanban",
        "projectId": getUser.projectId,
        "userType": getUser.role,
        "empId": getUser.empId,
  
      }, {}, false);
      if (response.status === 'true') {
        dispatch(completedUserStories(response.data))
      }
      else {
        dispatch(completedUserStories([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }