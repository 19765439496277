//common/ChatMainTask/Services/getGroupMembersEmail (READ)
import { isLoaded, isLoading } from "../../Actions/loading";
import Alert from "../../Alert";
import API from "../../Network/API";
import { groupEmail } from "../actions";


export async function getGroupMembersEmail(dispatch, getUser, data) {
  const project_name = data.project_name ? data.project_name : getUser.corp;
  dispatch(isLoading());
  try {
    var response = await API.post("user_story_details1.php", {
      corp_code: project_name,
      action: data.action,
      groupId: data.subtask_id
    }, {}, false);
    //Getting the Group details for Subtask start
    if (response.status === 'True' && response.task === 'workflow_task') {
      const al = [];
      // al.push(response.ideaByName);
      al.push(response.acceptedByName);
      al.push(response.ideaModfiedName);
      al.push(response.releaseOwner);
      al.push(response.rejectedByName);
      al.push(response.moduleCreatedBy);
      al.push(response.moduleModifiedBy);
      al.push(response.mainAssignedBy);
      al.push(response.mainAssignedTo);
      al.push(response.mainModifiedBy);
      al.push('Admin(admin)');
      // console.log(response)
      // dispatch(groupName(response.subTaskId))
      const k = (al);
      // console.log(al)
      function getUnique(array) {
        var uniqueArray = [];
        // Loop through array values
        for (var value of array) {
          //    console.warn( uniqueArray.indexOf(value)+value)
          if (uniqueArray.indexOf(value) === -1) {
            if (value !== "NA" && value !== "()") {
              uniqueArray.push(value);
            }
          }
        }

        return uniqueArray;
      }
      // console.warn("Naveen"+k);
      // eslint-disable-next-line
      var uniqueNames = getUnique(k);
      // console.warn(uniqueNames)
      // eslint-disable-next-line
      var h = uniqueNames;
      // console.warn(h.length)
      // eslint-disable-next-line
      var details = "Admin(admin)";
      // eslint-disable-next-line
      for (var i = 1; i <= h.length; i++) {//Checking the group list
        if (h[i] === "Admin(admin)") {//Remove duplicate list
          h.splice(i, 1);
        }

        //Concat List of Group Members
        // eslint-disable-next-line
        details = details + "," + h[i - 1];//Including Admin  
        // details = h[i-1];// Excluding Admin
        dispatch(groupEmail(al))
      }
      // dispatch(groupName(response.subTaskId))
    }//Getting the Group details for maintask end

  } catch (error) {
    Alert('error', error.message)
  }
  dispatch(isLoaded());
}