import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { logEntry } from "../../logEntry";

export async function deleteSubTask(activities,dispatch, getUser, subtaskId, mainTaskId,priority, handleClose, handleModalClose, SUBTASK_DELETE, SUBTASK) 
{
  dispatch(isLoading());
  try { //manageSubtasks.php
    const start_time=Date.now();
    var response = await API.post("manage_tasks.php", {
      "crop": getUser.corp,
      action: 'deletesubtask',
      subtaskId: subtaskId,
      maintaskId: mainTaskId,
      "projectId":getUser.projectId,
      empId: getUser.empId,
      priority: priority,
    }, {}, false);
    const end_time=Date.now();
    if (response.status === 'true') {
      Alert("success", SUBTASK_DELETE)
      activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
        sub_activity:"Delete",
        response_time : (end_time-start_time), task_meta_data:{story_id:mainTaskId}
      },getUser)
      handleClose()
    } else if (response.status === "false") {
      handleModalClose()
      Alert("warning", `You cannot remove a ${SUBTASK} having roadblocks`)
    }
  } catch (error) {
    Alert('error', error.message);
          //error log
          activities && logEntry({user_id:getUser.userName,logging_level:2,activity_id:activities["Task Management"],
            sub_activity:"Delete",
            error_message:error.message
          },getUser)
    handleClose()
  }
  dispatch(isLoaded());
}