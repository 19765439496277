import React, { useEffect, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import $ from 'jquery';
import { useWebProperties } from '../../Common/webProperties';
import { getAccountUsers } from './Services/getAccountUsers';
import { accountReducer, initialState } from './accountReducer';
import { getAccountProjects } from './Services/getAccountProjects';


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '85%',
        height: '80vh',
        bgcolor: 'background.paper',
        border: '0',
        boxShadow: 24,
        p: 4,
    },
}));

export default function AccountInfo(props) {
    const [state, dispatch] = useReducer(accountReducer, initialState)
    const classNames = useStyles();
    const { PROJECT_NAME } = useWebProperties();
    const title = props.data.action;
    
    useEffect(() => {
        if(props.data.accountId) {
            if(props.data.action !== "users") {
                getAccountProjects(dispatch, props.data.accountId)
            } else {
                getAccountUsers(dispatch, props.data.accountId)
            }
        }
    }, [props.data])

    useEffect(() => {
        if (state.employees.length > 0 || state.projects.length > 0) {
            $(document).ready(function () {
                window.$('#user-info-table').DataTable({
                    destroy: true,
                    dom: 'Bfrtip',
                    aLengthMenu: [
                        [25, 50, 100, 200, -1],
                        [25, 50, 100, 200, "All"]
                    ],
                    iDisplayLength: -1,
                    buttons: [
                        { extend: 'excelHtml5', text: 'Export' }
                    ]
                })
            })
        }
    }, [state.employees, state.projects])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center" style={{ height: '80vh' }}>
                            <div className="modal-content  p-2" style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <div className="column">
                                        <h6 class="modal-title p-1" style={{textTransform:"capitalize"}}>{props.data.clientname} - {title}</h6>
                                    </div>
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={props.handleModalClose} className="d-flex align-items-right" data-dismiss="modal"><i class="mdi mdi-close text-black"></i></button>
                                </div>
                                <div className="modal-body" style={{ overflow: 'auto' }}>
                                    <div className="overflow-auto" style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                                        {props.data.action === 'users' && (state.employees.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>User Name</th>
                                                        <th>{PROJECT_NAME}</th>
                                                        <th>Role</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        state.employees.map(({ userName, project_name, role }, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td style={{textTransform:"capitalize"}}>{userName}</td>
                                                                    <td style={{ color: 'green', textTransform:"capitalize" }}>{project_name}</td>
                                                                    <td style={{textTransform:"capitalize"}}>{role}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{}</p>)}
                                        {props.data.action === 'projects' && (state.projects.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>{PROJECT_NAME}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        state.projects.map(({ project_name }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{}</p>)}
                                    </div>
                                </div>


                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-success" onClick={props.handleModalClose} style={{ borderRadius: '20px' }}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}