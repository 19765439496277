import { isLoaded, isLoading, workflowTemplates } from "../actions";
import Alert from "../../Alert";
import API from "../../Network/API";

export async function getWorkflowTemplates(dispatch, getUser, story_id) {
    dispatch(isLoading());
    try {
      var response = await API.post("tasks_workflow.php", {
        "corp": getUser.corp,
        "empId": getUser.empId,
        "action": "getWorkflowTemplates",
        "projectId": getUser.projectId,
        story_id: story_id,
      }, {}, false);
      if (response.status === 'True') {
        dispatch(workflowTemplates(response.data))
      }
      else {
        dispatch(workflowTemplates([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }