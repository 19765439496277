import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading, adminGroupsMsg } from "../action";


export async function getNewAdminGroup(dispatch, getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("group_chat.php", {
        // action :'getAdminGroup',
        created_by: getUser.userName,
        projectId: getUser.projectId,
        action :'getAdminGroup_msg_time',
        // "userType": getUser.role,
        // "empId": getUser.empId,
      }, {}, false);
      if (response.status === 'True') {
        dispatch(adminGroupsMsg(response.data))
      }
      else {
        dispatch(adminGroupsMsg([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }
