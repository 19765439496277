import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

//Delete Module
export async function deleteModule(dispatch,getUser,moduleId,ideaId,handleClose,handleModalClose, MODULE_DELETE) {
    dispatch(isLoading());
    try {
      var response = await API.post("manageModule.php", {
        crop: getUser.corp,
        moduleid: moduleId,
        idea_id:ideaId,
        action: "delete",
        empId:getUser.empId
      },{},false);
      if(response.status === 'True') {
        Alert("success", MODULE_DELETE)
        handleClose()
      }else if( response.status === "false")
      {
        handleModalClose()
        Alert("warning",response.message)
      }
      // handleClose()
    } catch (error) {
      Alert('error',error.message);
      handleClose()
    }
    dispatch(isLoaded());
  }