import React, { useEffect, useReducer, useState } from 'react';
import '../../../Authentication/LandingPage/style.scss';
// import { Link } from 'react-router-dom';
import Faq from "react-faq-component";
import { getDetails } from './Services/getDetails';
import { initialState, reducer } from './reducer';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import SideBar from '../../../UserModule/SquadChat/sideNav';
import Header from '../../../UserModule/Utility/TopNav/topnav';
import Alert from '../../Alert';
import API from "../../Network/API";
import { useSelector } from 'react-redux';


const styles = {
    // bgColor: 'white',
    titleTextColor: "blue",
    rowTitleColor: "blue",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

export default function UserFAQs() {

    const [state, dispatch] = useReducer(reducer, initialState);
    const [categories, setCategories] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const webProperties = useSelector(state => state.landingReducer.webProperties)
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        getFaqsCategory();
        getDetails(dispatch)
    }, [])

    var rows = state.appDetails
        .filter(function (faq) {
            const matchesCategory = faq.category_id === selectedCategoryId;
            const matchesSearchQuery = faq.question.toLowerCase().includes(searchQuery.toLowerCase()) || faq.answer.toLowerCase().includes(searchQuery.toLowerCase());
            return matchesCategory && matchesSearchQuery;
        })
        .map((faq) => ({
            title: `Q) ${faq.question}`,
            content: faq.answer,
        }));
    if (searchQuery.length > 0) {
        // eslint-disable-next-line
        var rows = state.appDetails
            .filter(function (faq) {

                const matchesSearchQuery = faq.question.toLowerCase().includes(searchQuery.toLowerCase()) || faq.answer.toLowerCase().includes(searchQuery.toLowerCase());
                return matchesSearchQuery;
            })
            .map((faq) => ({
                title: `Q) ${faq.question}`,
                content: faq.answer,
            }));
    }

    var details = { rows };

    // get categories
    const getFaqsCategory = async () => {
        try {
            var response = await API.post("faqs.php", {
                action: 'getCategory',
            }, {}, false);
            if (response.status === 'True') {

                setCategories(response.data);
            }
        } catch (error) {
            Alert(error.message)
        }
    }
    const handleCategoryClick = (categoryId) => {
        setSelectedCategoryId(categoryId);
    };
    const handleBackButtonClick = () => {
        setSelectedCategoryId(null);
        setSearchTerm('')

    };
         // Filter data based on search term
  const filteredData = details.rows.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.content.toLowerCase().includes(searchTerm.toLowerCase())
  );
    return (
        <>
            <div className="container-scroller">
                <Header />
                <div className="container-fluid page-body-wrapper">
                    <SideBar />
                    <div className="main-panel">

                        {selectedCategoryId === null ? (
                            <div>
                                <section id="section1">
                                    <div className="container">
                                        <div className="release-note mt-3">
                                            <div className="d-flex bd-highlight">
                                                <div className="p-2 flex-grow-1 bd-highlight text-center"><h2 className="mb-3">FAQ's</h2>
                                                    <div className='d-flex justify-content-end'> <input className='form-control bt-n' type="search" placeholder="Search FAQs..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} style={{ width: "30%" }} /></div>
                                                </div></div>
                                        </div>
                                    </div>
                                </section>
                                {/* <div className="container mt-3">
            <div className="accordion p-2" id="accordionExample">
                <Faq
                    data={details}
                    styles={styles}
                    config={config}
                />
            </div>
        </div> */}
                                {/* ---categories */}
                                <div className="container mt-3" style={{ backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '5px' }}>
                                    <div className="row">
                                        {/* filter search */}
                                        {(searchQuery.length > 0) ?
                                            (<div className="container">
                                                <div className="accordion" id="accordionExample">
                                                {details.rows.length >0 ?
                                               <Faq
                                               data={details}
                                               styles={styles}
                                               config={config}
                                           />
                                           : "No results found"

                                            }
                                                </div>
                                            </div>) : ""}
                                        {searchQuery.length === 0 && categories.map(item =>
                                        (<div className="col-md-6  col-12 mb-3" key={item.id}>
                                            <div className="faq-category" style={{ backgroundColor: 'white', padding: '40px' }}>

                                                <h3 onClick={() => handleCategoryClick(item.id)} className='cursor-pointer' style={{ cursor: 'pointer' }}>{item.name}</h3>
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )
                            :
                            (<>
                                <section id="section1">
                                    <div className="container">
                                        <div className="release-note mt-3">
                                            <div className="d-flex bd-highlight">
                                                <div className="p-2 flex-grow-1 bd-highlight text-center"><h2 className="mb-3">{categories.find((cat) => cat.id === selectedCategoryId).name}</h2>


                                                    {/* back button & serch */}
                                                    <div className='d-flex justify-content-between'>

                                                        <button onClick={handleBackButtonClick} style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b', color: 'white' }}>Back</button>

                                                        <input className='form-control bt-n' type="search" placeholder="Search FAQs..." 
                                                        // value={searchQuery} 
                                                        // onChange={(e) => setSearchQuery(e.target.value)}
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                         style={{ width: "30%" }} /></div>
                                                </div></div>
                                        </div>
                                    </div>
                                </section>
                                <div>
                                    {/* Render content related to selected category */}
                                    {/* <p>Category ID: {selectedCategoryId}</p>
          <p>Category Name: {categories.find((cat) => cat.id === selectedCategoryId).name}</p> */}
                                    {/* Add your code to display content related to the selected category */}
                                    <div className="container mt-3">
                                        <div className="accordion p-2" id="accordionExample">
                                            {/* <Faq
                                                data={details}
                                                styles={styles}
                                                config={config}
                                            /> */}
                                                {filteredData.length > 0 ? (
        <Faq data={{ rows: filteredData }} styles={styles} config={config} />
      ) : (
        <p>No results found</p>
      )}
          
                                        </div>
                                    </div>
                                </div>
                            </>

                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
