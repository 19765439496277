import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading } from "../actions";

//Verify Project/Epic
export async function closeEpic(dispatch,getUser,ideaId,handleClose,handleModalClose, PROJECT_CLOSE) {
    dispatch(isLoading());
    try {
      var response = await API.post("manage_epics.php", {
        corp: getUser.corp,
        idea_id: ideaId,
        action: "close_epic",
        projectId:getUser.projectId
      },{},false);
      if(response.status === 'True') {
        handleClose()
        Alert("success",PROJECT_CLOSE)
      }else if( response.status === "false")
      {
        // handleModalClose()
        Alert("warning",response.message)
      }
      // handleClose()
    } catch (error) {
      Alert('error',error.message);
      handleClose()
    }
    dispatch(isLoaded());
  }