import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading } from "../action";
import { getGroupChatDetails } from "./getGroupChatDetails";

//for create default Group
export async function createDefaultGroupChat(state, mem, label, dispatch, getUser, handleClose) {
    dispatch(isLoading());
    if (getUser.corp === "") {
      Alert("warning", "Please add Group Name ")
      dispatch(isLoaded());
    }
    else if (getUser.corp !== "") {
      try {
        var response = await API.post("group_chat.php", {
          crop: getUser.corp,
          action: "create",
          projectId: getUser.projectId,
          group_name: getUser.corp,
          created_by: getUser.userName,
          created_by_name: getUser.fullName,
          // for members names
          members: mem,
          // for members email
          label: label,
        }, {}, false);
        if (response.status === 'True') {
            getGroupChatDetails(dispatch, getUser)
          Alert("success", "Group created successfully!");
        }
        if (response.status === 'GroupExist') {
            getGroupChatDetails(dispatch, getUser)
          // Alert("warning", "Group Already Exist!");
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
      handleClose()
    } else {
      Alert("warning", "Please enter required fields ")
      dispatch(isLoaded());
    }
  }