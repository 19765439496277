import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";
import { addCommentUserStory, addTasksComment } from "./addCommentUserStory";
import { logEntry } from "../../../Common/logEntry";

export async function updateUserStory(activities, getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id, MAINTASK, calendarId) {
  if (story_type === 'group' && main_task_id === '0') {
    await callToCheckGroupTask(activities, getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id, MAINTASK, calendarId)
  } else {
    await completeTask(activities, getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id, MAINTASK, calendarId)
  }
}


const callToCheckGroupTask = async (activities, getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id, MAINTASK, calendarId) => {
  await API.post("getUpdateSprint.php", {
    story_us_id: us_id,
    action: "get_group_task_info",
  })
    .then(function (response) {
      if (response.status === 'True') {
        if (response.data.length > 0) {
          response.data.filter(y => y.complete_status !== 'completed').map(x => {
            return completeTask(activities, getUser, x.story_id, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, x.story_type, x.us_id, x.main_task_id, MAINTASK, calendarId)
          })
          completeTask(activities, getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id, MAINTASK, calendarId)
          // let isCompleted = response.data.some(obj => obj.complete_status === "completed")
          // if (!isCompleted) {
          //   handleClose();
          //   Alert('warning', "You can't update until your dependency group task completed");
          // } else {
          //   completeTask(getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id)
          // }
        }
      }
    })
    .catch(function (error) {
      console.error(error);
    });
}

const completeTask = async (activities, getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id, MAINTASK, calendarId) => {
  try {
    const start_time = Date.now();
    const response = await API.post(
      "manage_userstories.php",
      {
        action: "update",
        task_id: userStoryId,
        crop: getUser.corp,
        task_status: 100,
        task_status_desc: "completed",
        task_complete_status: 1,
        empId: getUser.empId,
        projectId: getUser.projectId,
      },
      {},
      false
    );
    const end_time = Date.now();
    if (response.status === "True" && (calendarId === null || calendarId === undefined)) {
      addCommentUserStory(getUser, userStoryId, message, "2");
      Alert('success', `${MAINTASK} completed`);
      activities && logEntry({
        user_id: getUser.userName, logging_level: 3, activity_id: activities["Task Management"],
        sub_activity: "Done",
        response_time: (end_time - start_time), task_meta_data: { story_id: userStoryId }

      },getUser)
      handleClose()
    } else {
      if (calendarId === null || calendarId === undefined) {
        const warning = response.type === "tasks" ? TASKS_WARNING : ROADBLOCK_WARNING;
        Alert('warning', warning)
        handleClose()
      }
    }
  } catch (error) {
    Alert("error", error.message);

    //error log
    activities && logEntry({
      user_id: getUser.userName, logging_level: 2, activity_id: activities["Task Management"],
      sub_activity: "Done",
      error_message: error.message
    },getUser)
    handleClose()
  }
}

export async function updateSetDone(activities, getUser, subtaskId, mainTaskId, handleClose, message, SUBTASK) {
  try {
    const start_time = Date.now();
    var response = await API.post("get_tasks.php", {
      crop: getUser.corp,
      action: 'setSubtaskDone',
      task_id: subtaskId,
      maintaskId: mainTaskId,
      "projectId": getUser.projectId
    }, {}, false);
    const end_time = Date.now();
    if (response.status === 'True') {
      addTasksComment(getUser, subtaskId, "2", message);
      Alert("success", `${SUBTASK} completed`)
      activities && logEntry({
        user_id: getUser.userName, logging_level: 3, activity_id: activities["Task Management"],
        sub_activity: "Done",
        response_time: (end_time - start_time), task_meta_data: {
          story_id: subtaskId,
        }

      },getUser)
      handleClose()
    } else if (response.status === "false") {
      Alert("warning", response.message)
      handleClose()
    }
  } catch (error) {
    Alert('error', error.message);
    //error log
    activities && logEntry({
      user_id: getUser.userName, logging_level: 2, activity_id: activities["Task Management"],
      sub_activity: "Done",
      error_message: error.message
    },getUser)
    handleClose()
  }
}