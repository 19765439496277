import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import store from "../../../Common/Store";
import { activeSprint, doing, doingFilter, done, doneFilter, isLoaded, isLoading, roadblock, roadblockFilter, todo, todoFilter } from "../actions";
import { getDoneUserStoriesToKanban } from "./getDoneUserStoriesToKanban";
import { getInvolvedEmployees } from "./getInvolvedEmployees";
import { getRoadBlockUserStoriesToKanban } from "./getRoadBlockUserStoriesToKanban";
import { getToDoUserStories } from "./getToDoUserStories";

export async function getActiveKanbansUserStories(dispatch, getUser, refresh, KANBAN) {
  if (refresh) { dispatch(isLoading()); }
  try {
    // Current Active Sprint Name with epic by -->GNK -->01 on version 1.0.4 start
    // var response = await API.post("getSprints.php", {
    //   "crop": getUser.corp,
    //   "userType": getUser.role,
    //   "empId": getUser.empId,
    //   "action": "getActiveSprint"
    // }, {}, false);
    // Current Active Sprint Name with epic by -->GNK -->01 on version 1.0.4 end

    // Current Active Sprint with out epic by -->SS -->01 on version 1.0.6 start
    var response = await API.post(
      "getUpdateSprint.php",
      {
        crop: getUser.corp,
        action: "get_kanbans",
        projectId: getUser.projectId,
      },
      {},
      false
    );
    // Current Active Sprint with out epic by -->SS -->01 on version 1.0.6 end
    if (response.status === "True") {
      const moduleId = response.data[0].moduleId;
      // console.log( response.data[0])
      store.dispatch(activeSprint(response.data[0]));
      getToDoUserStories(dispatch, getUser, moduleId, refresh, 'kanban');
      getRoadBlockUserStoriesToKanban(dispatch, getUser, moduleId, refresh);
      getDoneUserStoriesToKanban(dispatch, getUser, moduleId, refresh);
      getInvolvedEmployees(dispatch, getUser, moduleId, refresh);
    } else if (response.status === "false") {
      if (response.data.length === 0) {
        addKanban(getUser.empId, KANBAN, getUser.projectId, dispatch)
      }
    }
    else {
      store.dispatch(todo([]));
      store.dispatch(doing([]));
      store.dispatch(todoFilter([]));
      store.dispatch(doingFilter([]));
      store.dispatch(roadblock([]));
      store.dispatch(roadblockFilter([]));
      store.dispatch(activeSprint([]));
      store.dispatch(done([]));
      store.dispatch(doneFilter([]));
    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
}

function addKanban(getUser, KANBAN, projectId, dispatch) {
  try {
    const data = {
      module_Name: KANBAN,
      empId: getUser, //Async
      action: "add",
      targetDate: '0001-01-01',
      startDate: '0001-01-01',
      projectId: projectId
    };
    const response = API.post("manage_sprints.php", data, {}, false);
    if (response.status === 'True') {
      //  dispatch(getModules(dispatch,getUser))
      // Alert("success", `${KANBAN} added`);
      getActiveKanbansUserStories(dispatch, getUser, false, KANBAN)

    }
    else {
      // alert("something went wrong")
    }
  } catch (error) {
    // Alert("error", error.message);
  }
}