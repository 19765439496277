import {
    involvedEmployees,
    isLoaded,
    isLoading,
  } from "../actions";
import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";

export async function getInvolvedEmployees(dispatch, getUser, moduleId, refresh) {
    if (refresh) { dispatch(isLoading()); }
    try {
      var response = await API.post(
        "get_sprints.php",
        {
          crop: getUser.corp,
          moduleId: moduleId,
          action: "get_involved_employees",
          projectId: getUser.projectId,
        },
        {},
        false
      );
      if (response.status === "True") {
        dispatch(involvedEmployees(response.data));
        // console.log(response.data)
      } else {
        dispatch(involvedEmployees([]));
      }
    } catch (error) {
      Alert("error", error.message);
    }
    dispatch(isLoaded());
  }