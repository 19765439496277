//(Write)
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

export async function sendRankMessage(userRanks, getUser) {
  try {
    await API.post("squad_chat.php", {
      action: "send",
      corp_code: getUser.user.corp,
      sendBy: getUser.user.empId,
      receivedBy: userRanks.emp_id,
      message: `You have got the rank award with ${userRanks.percentage} % and feedback: ${userRanks.feedback}`,
      messagedBy: getUser.user.empId,
      projectId: getUser.user.projectId,
      reply_id: "",
  }, {}, false);
  } catch (error) {
    Alert('error', error.message)
  }
}