import { groups, isLoaded, isLoading } from "../actions";
import Alert from "../../Alert";
import API from "../../Network/API";

export async function getGroup(dispatch, getUser, project_id) {
  dispatch(isLoading());
  try {
    var response = await API.post("group_chat.php", {
      action: 'getGroupInfo',
      projectId: project_id || getUser.projectId,
    }, {}, false);
    if (response.status === 'True') {
      dispatch(groups(response.data))
    }
    else {
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
}