//(READ)
import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import * as actions from '../actions';

export async function getAccountProjects(dispatch, account_id) {
  // const [, extension] = (getUser.userName).split('@')
  dispatch(isLoading());
  try {
    var response = await API.post("squads.php", {
      accountId: account_id,
      action : "get_account_projects",
    }, {}, false);
    if (response.status === 'True') {
      dispatch(actions.projects(response.data))
    }
    else {
      dispatch(actions.projects([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}