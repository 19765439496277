//(READ)
import { isLoaded, isLoading, userRanks } from "../actions";
import Alert from "../../../Common/Alert";
import ReportsAPI from "../../../Common/AgileNetwork/ReportsAPI";

export async function getUserRanks(dispatch, userId, dateFilter) {
    dispatch(isLoading());
    try {
        var response = await ReportsAPI.post(`/ranks/getRanks`, { emp_id: userId, filter: dateFilter }, {}, false);
        if (response.length > 0) {
            dispatch(userRanks(response))
        } else {
            dispatch(userRanks([]))
        }
    } catch (error) {
        dispatch(userRanks([]))
        Alert('error', error.message);
    }
    dispatch(isLoaded());
}