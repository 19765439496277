import {
  isLoaded,
  isLoading,
} from "../actions";
import API from "../../../Common/Network/API";
import { getCurrentSprintUserStories } from "./getCurrentSprintUserStories";
import { addCommentUserStory } from "./addCommentUserStory";
import Alert from "../../../Common/Alert";
import { addTasksComment } from "../../Backlogs/Services/addTasksComment";
import { logEntry } from "../../../Common/logEntry";

export async function updateUserstotyStaus(activities=null,state, dispatch, getUser, todoStoryId, inProgressStoryId, moduleId, assignedTo, handleClose, MAINTASK, task_type, inprogress_task_type,SUBTASK) {
  dispatch(isLoading());
  const message = `${MAINTASK} in progress`;
  const SubMessage =`${SUBTASK} in Progress`;
  const SubMessage1 =`${SUBTASK} moved into To Do`;

  const message1 = `${MAINTASK} moved into To Do`;

  
  try {
    const start_time=Date.now()
    var response = await API.post(
      "manage_userstories.php",
      {
        crop: getUser.corp,
        empId: getUser.empId,
        todoStoryId: todoStoryId,
        inprogressStoryId: inProgressStoryId,
        action: task_type === "main_task" ? "change_userstory_status" : "change_subtask_status",
        assignedTo: assignedTo,
        inprogress_task_type: inprogress_task_type
      },
      {},
      false
    );
    const end_time=Date.now()
    if (response.status === "true") {
      // activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
      //     sub_activity:"In Progress",
      //     response_time : (end_time-start_time), task_meta_data:{story_id:inProgressStoryId}
      //   })

        activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
          sub_activity:"To Do",
          response_time : (end_time-start_time), task_meta_data:{story_id:todoStoryId}
        },getUser)
      getCurrentSprintUserStories(dispatch, getUser, moduleId);
      dispatch(isLoaded());
      if (todoStoryId !== "") {
        if (inprogress_task_type === "main_task") {
          if (task_type === "main_task") {
            addCommentUserStory(dispatch, getUser, todoStoryId, message, "1");
          } else {
            addTasksComment(dispatch, getUser, todoStoryId, "1", SubMessage);
          }
        } else {
          if (task_type === "main_task") {
            addCommentUserStory(dispatch, getUser, todoStoryId, message, "1");
          } else {
            addTasksComment(dispatch, getUser, todoStoryId, "1", SubMessage);
          }
        }
        activities && logEntry({user_id:getUser.userName,logging_level:3,
          activity_id:activities["Task Management"],sub_activity:"In Progress",
          response_time : (end_time-start_time), task_meta_data:{story_id:inProgressStoryId}
        },getUser)
      }
      if (inprogress_task_type === "main_task") {
        addCommentUserStory(dispatch, getUser, inProgressStoryId, message1, "10");
        
      } else {
        addTasksComment(dispatch, getUser, inProgressStoryId, "10", SubMessage1);

      }
      handleClose();
      // activities && logEntry({user_id:getUser.userName,logging_level:3,
      //   activity_id:activities["Task Management"],sub_activity:"To Do",
      //   response_time : (end_time-start_time), task_meta_data:{story_id:inProgressStoryId}
      // })
    } else {
      Alert("warning", response.message);
      dispatch(isLoaded());
    }
  } catch (error) {
    Alert("warning", error);
    dispatch(isLoaded());
    activities && logEntry({user_id:getUser.userName,logging_level:2,
      activity_id:activities["Task Management"],sub_activity:"To Do",
      error_message:error.message, task_meta_data:{story_id:inProgressStoryId}
    },getUser)
  }
  handleClose()
}