import { isLoading, isLoaded } from "../../../Common/Actions/loading"
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { allMessages } from "../actions";

export async function getAllTaskMessages(dispatch,getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("taskChat.php", {
      corp_code: getUser.corp,
      action: "getAllTaskMessages",
    }, {}, false);
    if (response.status === 'True') {
       dispatch(allMessages(response.data));
    }else{
      dispatch(allMessages([]));
    }
  } catch (error) {
    Alert('error',error.message)
  }
  dispatch(isLoaded());
}