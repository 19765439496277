import { isLoading, isLoaded } from "../../../Common/Actions/loading"
import API from "../../../Common/Network/API";
import store from '../../../Common/Store';
import {  roadblock } from "../actions";



//For RoadBlock List
export async function getRoadBlock(dispatch,getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("roadBlock.php", {
        "crop": getUser.corp,
        "action": "roadBlock"
      }, {}, false);
      // console.log(response)
      if(response.message === "No Data Available"){
        // store.dispatch(roadblock());
      }else{
        store.dispatch(roadblock(response.data));
      }
    } catch (error) {
      console.log(error)
    }
  
    dispatch(isLoaded());
  }