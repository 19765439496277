import { isLoaded, isLoading,  adminDetails} from "../actions";
import Alert from "../../../../Common/Alert";
import API from "../../../../Common/Network/API";

export async function getAdmin(dispatch) {
    dispatch(isLoading());
    try {
      var response = await API.post("admin_chat.php", {
        action: "get_admin",
      }, {}, false);
      if (response.status === 'True') {
        dispatch(adminDetails(response.data));
      } else {
        dispatch(adminDetails([]));
      }
    } catch (error) {
      Alert('error', error.message)
    }
    dispatch(isLoaded());
  }  
