import { groupTasks, isLoaded, isLoading,  unAssignedTasks } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";


//for getting unassigned user stories
export async function getUnassigned(dispatch, getUser) {

  dispatch(isLoading());
  try {
    var response = await API.post("backlogspage.php", {
      "crop": getUser.corp,
      "projectId": getUser.projectId,
      "userType": getUser.role,
      "empId": getUser.empId
    }, {}, false);
    if (response.status === 'true') { 
      dispatch(unAssignedTasks(response.data.filter(item => {
        return item.story_type !== "group"
      })))
      dispatch(groupTasks(response.data.filter(item => {
        return item.story_type === 'group'
      })))
    }
    else {
      dispatch(unAssignedTasks([]))
      dispatch(groupTasks([]))
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
}
