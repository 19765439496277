import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading } from "../actions";
import { checkActiveSprint } from "./checkActiveSprint";

export async function getCurrentSprints(dispatch, getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("getUpdateSprint.php", {
        "crop": getUser.corp,
        "action": "get_sprints",
        "projectId": getUser.projectId
      }, {}, false);
      if (response.status === 'True') {
        const moduleId = response.data[0].moduleId
        checkActiveSprint(dispatch, getUser, moduleId);
      }
     
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }