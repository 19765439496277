import API from "../../Network/API";
import { isLoading, isLoaded,  sprint} from "../action";
import Alert from '../../Alert';
import { getToDo } from "./getToDo";

// for getting active sprint
export async function getSprint(dispatch, getUser) {
  dispatch(isLoading());
  try {

     // Future Sprints Name list witout epic by -->SS -->02 on version 1.0.6 start
     var response = await API.post("getUpdateSprint.php", {
     "crop": getUser.corp, 
     "action": "get_sprints",
     "projectId":getUser.projectId,
     }, {}, false);
      // Future Sprints Name list witout epic by -->SS -->02 on version 1.0.6 end
      // console.log(response)
    if (response.status === 'True') {
      dispatch(sprint(response.data[0]))
      getToDo(dispatch, getUser, response.data[0].moduleId);
    }
    else {
      // dispatch(modules([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}