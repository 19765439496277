import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

export async function updateDeletedSprintUserStories(dispatch,getUser,sprintId,handleClose1) {
    dispatch(isLoading());
        try {
            const data = {
              moduleId: sprintId,
              empId: getUser.empId,
              projectId: getUser.projectId,
            };
            await API.post("update_deleted_sprint_user_stories.php", data, {}, false);
            Alert("success", 'deleted successfully');
            handleClose1();
        } catch (error) {
            Alert("error", error.message);
        }
        dispatch(isLoaded());
      }