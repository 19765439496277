import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";


//network call to commit sprint
export async function commitSprint(action, state, dispatch, getUser, sprintId, handleClose, title, targetDate, startDate) {
  dispatch(isLoading());
  try {
    const data = {
      moduleId: sprintId,
      action: "commit_sprint",
      crop: getUser.corp,
      empId: getUser.empId,
      sprint_status: (action === 'uncommit_sprint') ? "pending" : "commited"
    };
    const response = await API.post("manage_sprints.php", data, {}, false);
    if (response.status === 'True') {
      var title1 = { 'moduleId': sprintId, 'moduleDesc': title, 'startDate': startDate, 'targetDate': targetDate, sprint_status: (action === 'uncommit_sprint') ? 'pending' : 'commited' }
      Alert("success", (action === 'uncommit_sprint') ? 'Uncommited Successfully' : 'Commited Successfully');
      handleClose(title1)
    }

  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
}