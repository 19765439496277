//(READ)
import { users, isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import ReportsAPI from "../../../Common/AgileNetwork/ReportsAPI";

export async function getUsers(dispatch, dateFilter, groupEmails = null) {
  dispatch(isLoading());
  let data = {};
  if(groupEmails){
    data = { groupEmails }
  }
  try {
    var response = await ReportsAPI.post(`/ranks/users`, { data ,filter: dateFilter }, {}, false);
    if (response) {
        dispatch(users(response))
    }
    else {
      dispatch(users([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}