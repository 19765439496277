//Accounts/services/updateEmployee (UPDATE)
import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { Client_URL } from "../../../Common/config";

export async function updateEmployee(state, dispatch, getUser, handleClose) {
    dispatch(isLoading());
    if ((state.empId.value !== "" && state.email.value !== ""  && state.userName.value !== "" && state.mobile.value !== ""
     && state.clientname.value !== "" && state.no_of_projects.value !== "" && state.no_of_users.value !== "")) {
      try {
        var response = await API.post("accounts.php", {
          empId: state.empId.value,
          employeeId: state.employeeId.value,
          action: 'update_account',
          email: state.email.value,
          username: state.fullName.value,
          mobile: state.mobile.value,
          clientname: state.clientname.value,
          userscount: state.no_of_users.value,
          projectscount: state.no_of_projects.value,
          modified_by: getUser.empId,
          url: Client_URL
        }, {}, false);
        if (response.status === "True") {
          Alert("success", "Updated successfully")
          handleClose();
        } else {
          Alert("warning", response.message)
          dispatch(isLoaded());
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
    } else {
      Alert("warning", "please fill all the details")
    }
  
  }
  