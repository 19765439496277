import API from "../../../Common/Network/API";
import {  isLoading,  isLoaded, allRoadBlockMessages } from "../actions";
import Alert from "../../Alert";

export async function getAllRoadBlockMessages(dispatch,getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("roadBlockChat.php", {
        corp_code: getUser.corp,
        action: "getAllTaskMessages",
      }, {}, false);
      if (response.status === 'True') {
         dispatch(allRoadBlockMessages(response.data));
      }else{
        dispatch(allRoadBlockMessages([]));
      }
    } catch (error) {
      Alert('error',error.message)
    }
    dispatch(isLoaded());
  }
  