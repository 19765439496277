//accounts/service/getEmployee( READ)
import {  accountFeaturePermissions, isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";


export async function getAccountPermissions(dispatch, account_id) {  
    dispatch(isLoading());
    try {
      var response = await API.post("accounts.php", {
        account_id: account_id,
        action:'get_account_permissions'
      }, {}, false);
      if (response.status === 'True') {
        dispatch(accountFeaturePermissions(response.data))
      }
      else {
        dispatch(accountFeaturePermissions([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }