import React, { useReducer, useEffect, useState } from 'react';
import { initialState, reducer } from './reducer';
import { getDetails } from './network';
import SideBar from '../Utility/SideNav';
import Header from '../../Common/TopNav';
// import EditFaq from './editFaq';
import DeleteFaq from './deleteFaq';
import { Link } from 'react-router-dom';
import { useWebProperties } from '../../Common/webProperties';
import API from "../../Common/Network/API";
import Alert from '../../Common/Alert';
import EditCategory from './editCategory';
import RootLoader from '../../Common/Loader/RootLoad';

// display all faqs
export default function Categories() {

    const [state, dispatch] = useReducer(reducer, initialState);
    const [cardInfo, setCardInfo] = useState()
    const [open, setOpen] = useState({ status: false, index: 0 });
    const [categories, setCategories] = useState([]);


    const { AGILE_EDIT, DELETE } = useWebProperties();
    useEffect(() => {
        getFaqsCategory(dispatch)
        getDetails(dispatch);
    }, [])


    const handleOpen = (action, data) => {
        var info;
        setOpen({ status: true, action: action });
        if (action === "modify") {
            // var status = "backlog_addUser"
            info = { data: data }
        } else if (action === 'deleteCategory') {
            // var status = "backlog_addUser"
            info = { data: data, action: action }


        }
        setCardInfo(info)

    }
    const handleClose = () => {
        setOpen({ status: false, index: 0 })
        getFaqsCategory(dispatch)

    };
    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
        getFaqsCategory(dispatch)

    }
    const getFaqsCategory = async () => {
        try {
            var response = await API.post("faqs.php", {
                action: 'getCategory',
            }, {}, false);
            if (response.status === 'True') {
                setCategories(response.data);
            }
        } catch (error) {
            Alert(error.message)
        }
    }

    return (
        <div className="container-scroller">
            <Header />
            <div className="container-fluid page-body-wrapper">
                <SideBar />
                <div className="main-panel">
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card">
                        {state.isLoading ? <RootLoader /> :
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <h2 className="card-title" style={{ overflowWrap: "break-word", color: 'blue', backgroundColor: 'transparent' }}>FAQ's</h2>
                                    </div>

                                    <div className="container">
                                        <Link to={{ pathname: "/admin/faqs" }} style={{ color: 'white', textDecoration: 'none' }}>
                                            <button type="button" data-dismiss="modal" class="btn btn-warning" style={{ borderRadius: '20px', marginLeft: '80%', width: "10%" }}>
                                                Back
                                            </button>
                                        </Link>
                                        <br /><br />
                                    </div>
                                    {categories ?
                                        <div className="table-responsive">
                                            <table
                                                search="true"
                                                id="example" className="table table-striped table-bordered rwd-table"
                                                style={{ width: '100%', overflow: 'auto' }}
                                                data-pagination="true"
                                            >
                                                <thead style={{ backgroundColor: '#F4FAF7' }}>
                                                    <tr>
                                                        <th style={{ textTransform: "capitalize", width: '160px' }} >S.No</th>
                                                        <th style={{ alignItems: "center" }}>Name of the category</th>
                                                        <th style={{ alignItems: "center" }}>Edit</th>
                                                        <th style={{ alignItems: "center" }}>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        categories.length !== 0 ? categories.map((item) => {
                                                            return (
                                                                <tr key={item.id}>
                                                                    <td data-th="Questions" style={{ textTransform: "capitalize", paddingLeft: "2%" }}>{item.id}</td>
                                                                    <td data-th="Answers" style={{ textTransform: "capitalize", paddingLeft: "2%" }}>{item.name}</td>
                                                                    {/* <td data-th="Answers"style={{ textTransform: "capitalize", paddingLeft: "2%" }}>edit icon</td> */}

                                                                    <td data-th="Action" style={{ textTransform: "capitalize", paddingLeft: "2%" }}>
                                                                        <div className="" style={{ cursor: 'pointer', padding: '5px', marginTop: 5 }}>
                                                                            {/* eslint-disable-next-line */}


                                                                            <div className="" aria-labelledby="" style={{}}>
                                                                                <div>
                                                                                    {/* <img src={editIcon} title={ACTION_ICON} alt="logo" style={{ width: '15px', height: '15px', borderRadius: '0' }} />  */}
                                                                                    <img src="images/common/edit.svg" style={{ width: '18px', height: '18px' }} title={AGILE_EDIT} alt="" onClick={(event) => handleOpen("modify", item)} />


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td data-th="Action" style={{ textTransform: "capitalize", paddingLeft: "2%" }}>
                                                                        <div className="" style={{ cursor: 'pointer', padding: '5px', marginTop: 5 }}>
                                                                            {
                                                                                item.id !== '1' &&
                                                                                <div className="" aria-labelledby="" style={{}}>
                                                                                    <div>
                                                                                        <img src="images/common/delete.svg" style={{ width: '18px', height: '18px' }} title={DELETE} alt="" onClick={(event) => handleOpen("deleteCategory", item)} />

                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) : null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        : null}
                                </div>
                            </div>
}
                        </div>
                    </div>
                </div>
                {
                    open.action === "modify" ?
                        <EditCategory open={open.status}
                            handleClose={handleClose}
                            data={cardInfo}
                            handleModalClose={handleModalClose}
                        /> : null}
                {
                    open.action === "delete" || open.action === "exit" ?
                        <DeleteFaq open={open.status}
                            handleClose={handleClose}
                            data={cardInfo}
                            handleModalClose={handleModalClose}
                        /> : null}

                {
                    open.action === "deleteCategory" || open.action === "exit" ?
                        <DeleteFaq open={open.status}
                            handleClose={handleClose}
                            data={cardInfo}
                            handleModalClose={handleModalClose}
                        /> : null}
            </div>
        </div>
    );
}
