import React, { useEffect, useReducer, useRef} from 'react';
import {
    Inject, ScheduleComponent, Day, Week, WorkWeek,
    // eslint-disable-next-line
    Month, Agenda, EventSettingsModel, ResourceDirective, Timezone, ResourcesDirective, DragAndDrop, Resize
} from '@syncfusion/ej2-react-schedule';
// import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import API from '../Network/API';
import { applyCategoryColor } from './helper';
import { initialState, reducer } from './reducer';
// eslint-disable-next-line
import { getEmployeesDash } from './Services/getEmployeeDash';
import { getModules } from './Services/getModules';
import { getCalendarDashStory } from './Services/getCalendarDashStory';
import { getEventCalendarDash } from './Services/getEventCalendarDash';
import { getSquadsList } from './Services/getSquadList';
import SideBar from "../../UserModule/SquadChat/sideNav"
import TopNavWithOutProject from "../../UserModule/Utility/TopNav/topnav"
import { useSelector } from 'react-redux';
import { isLoaded, isLoading } from '../Actions/loading';
// eslint-disable-next-line
// import RootLoader from '../Loader/RootLoader';
import Alert from '../Alert';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { MultiSelect } from '@syncfusion/ej2-dropdowns';
import { createElement, L10n } from '@syncfusion/ej2-base';
// eslint-disable-next-line
import { Query, DataManager, Predicate } from '@syncfusion/ej2-data';
import { GridComponent } from '@syncfusion/ej2-react-grids';
import "./calendar.css"
import { Client_URL, Meet_URL } from '../config';
import { useWebProperties } from '../webProperties';
import { getAllSprints } from './Services/getAllSprints';
//import { tasksReducer, initialState as initialGroupData } from '../TasksModals/tasksReducer';
import { getMachineTimeZONE, removeDuplicatesFromString, removeSpecialCharacters } from '../commonUsage';
//import { getGroup } from '../TasksModals/Services/getGroup';
import { createCalendarTask, createPrivateToDo } from './Services/createCalendarTask';
import { getActiveSprints } from './Services/getEvent';
import { createCalendargroupTask } from './Services/createCalendarGroupTask';
import moment from 'moment';
import { isFetch, isFetched } from './action';
import RootLoader from '../Loader/RootLoader';
//import { addCommentUserStory } from '../../UserModule/Modules/Services/addCommentUserStory';
import { getEmployees } from './Services/getEmployees';
import { getSubStringId } from '../SubStringConvert';
import jwt from 'jsonwebtoken';
import { updateUserStory } from '../../UserModule/ConsolidatedToDo/Services/updateUserStory';
import { logEntry } from '../logEntry';
import { addDays, addWeeks, addMonths, addYears } from 'date-fns';
// const CryptoJS = require("crypto-js"); 
L10n.load({
    'en-US': {
        'schedule': {
            'editSeries': '',
            'deleteSeries': '',
            'editFollowingEvent': 'Following Events',
        },
    }
});


function Calender() {

    // eslint-disable-next-line
    const [state, dispatch] = useReducer(reducer, initialState);
    //const [state1, dispatch1] = useReducer(tasksReducer, initialGroupData);
    const { APP_NAME, URL, MAINTASK_ADD, MAINTASK, MODULE, TASKS_WARNING, ROADBLOCK_WARNING } = useWebProperties();
    const getUser = useSelector(state => state.auth)
    const scheduleObj = useRef(null);
    const activities = useSelector(state => state.landingReducer.userActivities)
    useEffect(() => {
        const fetchData = async () => {
            await getEmployees(dispatch, getUser.user);
            await getModules(dispatch, getUser.user);
            await getCalendarDashStory(dispatch, getUser.user, APP_NAME);
            await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities);
            await getSquadsList(dispatch, getUser.user.empId)
            await getAllSprints(dispatch, getUser.user);
            //await getGroup(dispatch1, getUser.user);
            await getActiveSprints(dispatch, getUser.user)
        }

        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateTaskMeetingUrl = async (employe) => {

        const secretKey = "RALLYWETASKER";

        // Sample payload data
        const payload = {
            "context": {
                "user": {
                    "avatar": "",
                    "name": "",
                    "email": "",
                },
            },
            "aud": "jitsi",
            "iss": "23456789",
            "sub": Meet_URL,
            "room": removeSpecialCharacters(`${getSubStringId(employe[0].name, 2)}-${employe[0].u_id}`),
            // "exp": 1700060038,
        };
        const generatedToken = jwt.sign(payload, secretKey);
        const meeting_url = `https://${Meet_URL}/${removeSpecialCharacters(`${getSubStringId(employe[0].name, 2)}-${employe[0].u_id}`)}?jwt=${generatedToken}#jitsi_meet_external_api_id=98765432&config.startWithAudioMuted=true&config.startWithVideoMuted=true&config.autoCaptionOnRecord=true`
        try {
            // eslint-disable-next-line
            const response = await API.post("manage_userstories.php", {
                action: "updateMeetingUserURL",
                us_id: employe[0].u_id,
                meeting_url: meeting_url
            }, {}, false);
            if (response.status === "True") {
                return response.meeting_url
            } else {
                return ""
            }
        } catch (error) {
            console.log(error)
        }
    }

    // for field validation
    const fields = {
        subject: { name: 'Subject', validation: { required: true } },
        startTime: { name: 'StartTime', validation: { required: true } },
        endTime: { name: 'EndTime', validation: { required: true } },
        description: { name: 'Description', validation: { required: true } },
    };
    var sprints = []
    state.modules.map((module) => {
        return (
            sprints.push({
                'value': module.moduleId, 'label': module.moduleDesc, assignbyId: module.assignbyId,
                average: module.average,
                createdBy: module.createdBy,
                created_on: module.created_on,
                ideaId: module.ideaId,
                ideaTitle: module.ideaTitle,
                moduleDesc: module.moduleDesc,
                moduleId: module.moduleId,
                startDate: module.startDate,
                status: module.status,
                targetDate: module.targetDate
            })
        );
    })
    var stories = []
    // display user story add event
    state.section.map((story) => {
        return (
            stories.push({
                Subject: story.story_title,
                EndTime: story.target_date,
                StartTime: story.target_date,
                IsAllDay: "1",
                IsReadonly: "1",
                Color: '#df5286',
                Members: story.assignedto,
                Location: story.active_status === "0" ? `To Do, ${story.project_name}`
                    : story.active_status === "1" ? `In Progress, ${story.project_name}`
                        : story.active_status === "-1" ? `Blocked, ${story.project_name}` : `Completed, ${story.project_name}`
            })
        );
    })

    const urlBase = `${URL}calendarPathSetUp?key=${getUser.user.corp}&projectId=${getUser.user.projectId}&empId=`;

    var employeeData = [{id: getUser.user.empId, name: getUser.user.fullName,
        email: getUser.user.userName, userName: getUser.user.userName, role: getUser.user.role, empStatus: getUser.user.empStatus,from:"user",}]
    //var employeeData
    var groupData

    //var combinedEmpArray //= [...employeeData]

    let recurrenceLoading = false
    let isGroupTask = false
    let groupNumber
    const getUsersList=async(projectId)=>{
        try {
            var response = await API.post("agile_squads.php", {
                "crop": getUser.corp,
                projectId: projectId || getUser.projectId,
            }, {}, false);
            if (response.status === 'True') {
                employeeData = response.data.map(employee => {
                return { ...employee, from: 'user' };
            })
        
            const deleteTask = (taskToDelete, addedEmp) => {
                const tasks = employeeData.filter(task => task.email !== taskToDelete);
                // eslint-disable-next-line
                tasks.unshift(addedEmp)
                return { tasks };
            }
            const employeeDetails = deleteTask(getUser.user.userName, {
                id: getUser.user.empId, name: getUser.user.fullName,
                email: getUser.user.userName, userName: getUser.user.userName, role: getUser.user.role, empStatus: getUser.user.empStatus,from:"user",
            })
        
            employeeData=employeeDetails.tasks.filter(user=>user.email!==""&&user.email!==null&&user.name!=='')
            return employeeData
              
            }
        } catch (error) {
            Alert('error', error.message);
        }
    }

    const getGroupList = async (projectId) => {
        try {
            var response = await API.post("group_chat.php", {
                action: 'getGroupInfo',
                projectId: projectId || getUser.projectId,
            }, {}, false);
            if (response.status === 'True') {
                groupData = response.data.map(groups => {
                    return {
                        ...groups, from: 'group', email: groups.id, name: `${groups.group_name}-[${removeDuplicatesFromString(groups.members_name)}]`
                    };
                })
                return groupData
            }
        } catch (error) {
            Alert('error', error.message);
            dispatch(isLoaded());
        }
    }

    const getDate = async (data) => {
        let newDate = new Date(data?.StartTime);
        let day = newDate.getDate();
        let month = newDate.getMonth();
        let year = newDate.getFullYear();

        return (`${year}-${month + 1}-${day}`);
    }

    const checkBetween = async (date, projectId) => {
        let activeSprint
        var response = await API.post("getUpdateSprint.php", {
            "crop": getUser.corp,
            "action": "get_sprints",
            "projectId": projectId
        }, {}, false);
        if (response.status === 'True') {
            activeSprint = response.data[0]
            let startDate = new Date(activeSprint.startDate);
            let endDate = new Date(activeSprint.targetDate);
            let checkDate = new Date(date);

            if (checkDate >= startDate && checkDate <= endDate) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const validateDate = async (data) => {
        let startDate = new Date();
        let checkDate = new Date(data);

        if (checkDate >= startDate) {
            return true
        } else {
            return false
        }
    }

    const modifyDates = async (data) => {
        const start = new Date(data?.StartTime);
        const end = new Date(data?.EndTime);
        const startDate = `${start.getMonth() + 1}/${start.getDate()}/${start.getFullYear().toString().slice(-2)} ${start.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`;
        const endDate = `${end.getMonth() + 1}/${end.getDate()}/${end.getFullYear().toString().slice(-2)} ${end.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`;
        const estimateTime=(end-start)/(1000*60)
        const targetTime=Math.ceil(estimateTime/60)
        return { startDate, endDate,targetTime }
    }
    const getRecurrenceValues = async (rule) => {
        const parts = rule.split(';'); // Split the rule by each attribute
        const values = {}; // Object to hold FREQ and UNTIL values

        parts.forEach(part => {
            const [key, value] = part.split('='); // Separate each attribute's key and value
                values[key] = value;
        });

        return values;
    };

    const generateRecurringEvents = async (startTime3, repeatStatus, repeatCount, untilDate,recurrenceValues) => {
        let dates = [];
        let currentDate = new Date(startTime3);
        const compareDate=new Date(startTime3);
        let occurrences = 0;
        let daysOfWeek
        let weekOfMonth
        if (recurrenceValues.BYDAY){
            const daysString = recurrenceValues.BYDAY;
            daysOfWeek = daysString.split(",");
        }
        if (recurrenceValues.BYSETPOS){
            weekOfMonth=recurrenceValues.BYSETPOS
        }

        const getDayCount=(day)=>{
            switch (day) {
                case 'SU':
                    return 0
                case 'MO':
                    return 1
                case 'TU':
                    return 2
                case 'WE':
                    return 3
                case 'TH':
                    return 4
                case 'FR':
                    return 5
                case 'SA':
                    return 6
                default:
                    return null;
            }
        }
    
        const incrementFunction = {
            DAILY: (date) => addDays(date, 1),   // for DAILY frequency, increment by 1 day
            WEEKLY: (date) => addWeeks(date, 1),  // for WEEKLY frequency, increment by 1 week
            MONTHLY: (date) => addMonths(date, 1), // for MONTHLY frequency, increment by 1 month
            YEARLY: (date) => addYears(date, 1)   // for YEARLY frequency, increment by 1 year
        }[repeatStatus];
    
        const untilDateString=untilDate
        let formattedUntilDateString
        if (untilDateString) {
            formattedUntilDateString = untilDateString.replace(
                /(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})(Z)/,
                '$1-$2-$3T$4:$5:$6$7'
            );
        }
        const untilDateObj =formattedUntilDateString? new Date(formattedUntilDateString):null

        const addWeeklyOccurrences=(baseDate)=>{
            daysOfWeek.forEach(day =>{
                const dayCount = getDayCount(day);
                const nextOccurrence = new Date(baseDate);
                nextOccurrence.setDate(baseDate.getDate() + ((dayCount - baseDate.getDay() + 7) % 7));
                
                if ((!untilDateObj || nextOccurrence <= untilDateObj) && (!repeatCount || occurrences < repeatCount)) {
                    dates.push(new Date(nextOccurrence));  // Add a new Date instance to avoid reference issues
                }
            })
        }
    
        // Generate dates based on COUNT or UNTIL date
        while ((repeatCount && occurrences < repeatCount) ||(untilDateObj && currentDate <= untilDateObj) ) {
            switch (repeatStatus) {
                case 'DAILY':
                    dates.push(currentDate); 
                    currentDate = incrementFunction(currentDate);
                    occurrences++;
                    break;
                case 'WEEKLY':
                    addWeeklyOccurrences(currentDate)
                    occurrences++;
                    currentDate = incrementFunction(new Date(currentDate));
                    break;
                case 'MONTHLY':
                    if (recurrenceValues.BYMONTHDAY) {
                        const nextOccurrence = new Date(currentDate);
                        nextOccurrence.setDate(recurrenceValues.BYMONTHDAY);
                        if ((!untilDateObj || nextOccurrence <= untilDateObj) && (!repeatCount || occurrences < repeatCount)) {
                            dates.push(new Date(nextOccurrence));
                            if(new Date(nextOccurrence)>=compareDate){
                                occurrences++;
                            }
                        }
                    } else{
                        const nextOccurrence = new Date(currentDate);
                        const dayCount = getDayCount(daysOfWeek[0]);
                        nextOccurrence.setDate(1);
                        const firstDay = nextOccurrence.getDay();
                        const offset = (dayCount - firstDay + 7) % 7 + (weekOfMonth-1) * 7;
                        nextOccurrence.setDate(1 + offset);
                        if ((!untilDateObj || nextOccurrence <= untilDateObj) && (!repeatCount || occurrences < repeatCount)) {
                            dates.push(new Date(nextOccurrence));
                            if(new Date(nextOccurrence)>=compareDate){
                                occurrences++;
                            };
                        }
                    }
                    currentDate = incrementFunction(currentDate);
                    break;
                case 'YEARLY':
                    if (recurrenceValues.BYMONTHDAY) {
                        const nextOccurrence = new Date(currentDate);
                        nextOccurrence.setMonth(recurrenceValues.BYMONTH-1)
                        nextOccurrence.setDate(recurrenceValues.BYMONTHDAY);
                        if ((!untilDateObj || nextOccurrence <= untilDateObj) && (!repeatCount || occurrences < repeatCount)) {
                            dates.push(new Date(nextOccurrence));
                            if(new Date(nextOccurrence)>=compareDate){
                                occurrences++;
                            };
                        }
                    } else{
                        const nextOccurrence = new Date(currentDate);
                        nextOccurrence.setMonth(recurrenceValues.BYMONTH-1)
                        const dayCount = getDayCount(daysOfWeek[0]);
                        nextOccurrence.setDate(1);
                        const firstDay = nextOccurrence.getDay();
                        const offset = (dayCount - firstDay + 7) % 7 + (weekOfMonth-1) * 7;
                        nextOccurrence.setDate(1 + offset);
                        if ((!untilDateObj || nextOccurrence <= untilDateObj) && (!repeatCount || occurrences < repeatCount)) {
                            dates.push(new Date(nextOccurrence));
                            if(new Date(nextOccurrence)>=compareDate){
                                occurrences++;
                            };
                        }
                    }
                    currentDate = incrementFunction(currentDate);
                    break;
            
                default:
                    break;
            }
        }

        return dates;
    };
    const checkRecurringEvents = async (data) => {
        recurrenceLoading = true
        const start = new Date(data.StartTime)
        const end = new Date(data.EndTime)
        const difference = end.getTime() - start.getTime()
        const recurrenceValues = await getRecurrenceValues(data.RecurrenceRule)
        const recurringGroups = groupData
        const recurringUsers = employeeData
        let recurrenceDates
        if (recurrenceValues.COUNT){
            recurrenceDates=await generateRecurringEvents(data.StartTime,recurrenceValues.FREQ,recurrenceValues.COUNT,recurrenceValues.UNTIL,recurrenceValues)
        }else if(recurrenceValues.UNTIL){
            recurrenceDates=await generateRecurringEvents(data.StartTime,recurrenceValues.FREQ,null,recurrenceValues.UNTIL,recurrenceValues)
        }else{
            Alert("error","Please select either the 'until' or 'count' option in the repeat end field.")
            await getEventCalendarDash(getUser.user, dispatch, APP_NAME,activities);
            recurrenceLoading=false
            dispatch(isFetched());
            dispatch(isLoaded());
            return
        }

        for (const item of recurrenceDates) {
            const date = new Date(item);
            const newDate = new Date(date.getTime() + difference);
            const endingTime = newDate;
            groupData = recurringGroups
            employeeData = recurringUsers
            data.RecurrenceRule = null
            data.StartTime = item
            data.EndTime = endingTime
            await createEvent(data)
        }
        recurrenceLoading = false
        groupNumber = ''
        isGroupTask = false
        await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities)
        dispatch(isFetched());
        dispatch(isLoaded());
    }

    const createEvent = async (data) => {
        let value
        if (isGroupTask) {
            value = groupNumber
        } else {
            value = data.Members[0]
        }
        const changeDate = await modifyDates(data)
        let urlBase
        let project
        if (data.ProjectName !== "") {
            project = state.allProjects.filter(item => item.id === data.ProjectName)
            urlBase = `${URL}calendarPathSetUp?key=${project[0].value}&projectId=${project[0].id}&empId=`;
        }
        let assignee
        let eventType = "user"
        if (!isNaN(parseInt(value))) {
            groupNumber = value
            isGroupTask = true
            assignee = groupData.filter((item) => item.id === value)
            const selectedMembers = (assignee[0].members_email).split(",").filter(email => email !== '')
            data.Members = selectedMembers
            eventType = "group"
        }
        try {
            const start_time = Date.now()
            // eslint-disable-next-line
            var response = await API.post("calendarDash.php", {
                urlBase: urlBase,
                addEvent: data,
                corp_code: data.ProjectName !== "" ? project[0].value : getUser.user.corp,
                action: "Add",
                emp_id: getUser.user.empId,
                projectId: data.ProjectName,
                fullName: getUser.user.fullName,
                userName: getUser.user.userName,
                url: Client_URL,
                appName: APP_NAME,
                start_time: changeDate.startDate,
                end_time: changeDate.endDate,
                event_type: eventType,
                created_time_zone: getMachineTimeZONE()
            }, {}, false);
            const end_time = Date.now()
            if (response.status === "True") {
                if (data.isGenerate) {
                    await createTask(data, assignee, response.event_id,changeDate.targetTime);
                } else {
                    dispatch(isFetched());
                    dispatch(isLoaded());
                    await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities)
                }
                activities && logEntry({
                    user_id: getUser.user.userName, logging_level: 3,
                    activity_id: activities["Calendar"], sub_activity: "Create Event", response_time: (end_time - start_time)
                }, getUser)
            }
            else {
                console.log("Something went wrong")
                await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities)
                dispatch(isLoaded());
                dispatch(isFetched());
                activities && logEntry({
                    user_id: getUser.user.userName, logging_level: 2,
                    activity_id: activities["Calendar"], sub_activity: "Create Event"
                }, getUser)

            }
        } catch (error) {
            Alert('error', error.message);
            await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities)
            dispatch(isLoaded());
            dispatch(isFetched());
            activities && logEntry({
                user_id: getUser.user.userName, logging_level: 2,
                activity_id: activities["Calendar"], sub_activity: "Create Event",
                error_message: error.message
            }, getUser)
        }
    }

    const createTask = async (data, assignee, eventId,targetTime) => {
        try {
            const date = await getDate(data);
            let epicId = ""
            if (data.ProjectName !== "") {
                const inBetween = await checkBetween(date, data.ProjectName)
                const project = state.allProjects.filter(item => item.id === data.ProjectName)
                var response = await API.post("get_epics.php", {
                    "corp": getUser.user.corp,
                    "userType": getUser.user.role,
                    "empId": getUser.user.empId,
                    "action": "get_epics",
                    "projectId": data.ProjectName
                }, {}, false);
                if (response.status === 'True') {
                    const epicData = response.data.filter(epic => epic.idea_title === 'Default Module')
                    epicId = epicData[0].idea_id
                }
                let moduleResId = ''
                let sprintDesc = ''
                var moduleRes = await API.post("getUpdateSprint.php", {
                    "crop": project[0].value,
                    "action": "getModules",
                    "projectId": project[0].id,
                }, {}, false);
                if (moduleRes.status === 'True') {
                    moduleResId = moduleRes.data[0].moduleId
                    sprintDesc = moduleRes.data[0].moduleDesc
                }
                let selectedMembers = []
                if (assignee !== undefined) {
                    const details = {
                        taskTitle: { value: data.Subject },
                        taskDescription: { value: data.Description },
                        userSelected: { value: assignee[0].id, label: assignee[0].name, from: assignee[0].from, members_email: assignee[0].members_email },
                        acceptanceCriteria: { value: data.Description },
                        storySelected: targetTime,
                        prioritySelected: "",
                        epicSelected: epicId,
                        player_id: "",
                        moduleId: inBetween ? moduleResId : null,
                        targetDate: moment(data.StartTime).format('YYYY-MM-DD'),
                        eventId: eventId,
                        from_screen: "calendar",
                        projectId: project[0].id,
                        corp: project[0].value,
                        location: data.Location,

                    }
                    await createCalendargroupTask(activities, details, dispatch, getUser.user, MAINTASK_ADD, MAINTASK, MODULE, sprintDesc)
                    if (!recurrenceLoading) {
                        await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities)
                        groupNumber = ''
                        isGroupTask = false
                        dispatch(isLoaded());
                        dispatch(isFetched());
                    }
                } else {
                    selectedMembers = data.Members
                    await selectedMembers.forEach((item) => {
                        const user = employeeData.filter((userList) => userList.email === item)
                        const details = {
                            taskTitle: { value: data.Subject },
                            taskDescription: { value: data.Description },
                            userSelected: { value: user[0].id, label: user[0].name, from: user[0].from, email: user[0].email },
                            acceptanceCriteria: { value: data.Description },
                            storySelected: targetTime,
                            prioritySelected: "",
                            epicSelected: epicId,
                            player_id: "",
                            moduleId: inBetween ? moduleResId : null,
                            targetDate: moment(data.StartTime).format('YYYY-MM-DD'),
                            eventId: eventId,
                            from_screen: "calendar",
                            projectId: project[0].id,
                            corp: project[0].value,
                            location: data.Location,
                        }
                        createCalendarTask(activities, details, dispatch, getUser.user, MAINTASK, MODULE, sprintDesc)
                    })
                    if (!recurrenceLoading) {
                        await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities)
                        dispatch(isLoaded());
                        dispatch(isFetched());
                    }
                }
            } else {
                if (assignee !== undefined) {
                    Alert('waring', "Please select project name for creating group task");
                } else {
                    let selectedMembers = []
                    selectedMembers = data.Members
                    await selectedMembers.forEach((item) => {
                        const user = employeeData.filter((userList) => userList.email === item)
                        const details = {
                            eventId: eventId,
                            taskTitle: { value: data.Subject },
                            taskDescription: { value: data.Description },
                            userSelected: user[0].id,
                            acceptanceCriteria: { value: data.Description },
                            storySelected: "1",
                            prioritySelected: "1",
                            from_screen: "calendar",
                            location: data.Location,
                        }
                        createPrivateToDo(activities, details, dispatch, getUser.user, MAINTASK)
                    })
                    if (!recurrenceLoading) {
                        await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities)
                        dispatch(isLoaded());
                        dispatch(isFetched());
                    }
                }
            }
        } catch (error) {
            console.error('Error creating task:', error);
            await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities)
            dispatch(isLoaded());
            dispatch(isFetched());
        }
    }

    const modifyEvent = async (updateObj, prevObj) => {
        dispatch(isFetch())
        const date = await getDate(updateObj)
        let inBetween
        let moduleResId = ''
        let urlBase
        if (prevObj.ProjectName !== "") {
            inBetween = await checkBetween(date, prevObj?.ProjectName)
            var moduleRes = await API.post("getUpdateSprint.php", {
                "crop": prevObj?.project_name,
                "action": "getModules",
                "projectId": prevObj?.ProjectName,
            }, {}, false);
            if (moduleRes.status === 'True') {
                moduleResId = moduleRes.data[0].moduleId
            }
            urlBase = `${URL}calendarPathSetUp?key=${prevObj?.project_name}&projectId=${prevObj?.ProjectName}&empId=`;
        }
        const changeDate = await modifyDates(updateObj)
        try {
            const start_time = Date.now()
            // eslint-disable-next-line
            var response = await API.post("calendarDash.php", {
                urlBase: urlBase,
                action: "InsertUpdate",
                corp_code: prevObj?.project_name,
                current: updateObj,
                projectId: prevObj?.ProjectName,
                fullName: getUser.user.fullName,
                userName: getUser.user.userName,
                url: Client_URL,
                appName: APP_NAME,
                target_date: moment(updateObj?.StartTime).format('YYYY-MM-DD'),
                module_id: inBetween ? moduleResId : null,
                start_time: changeDate.startDate,
                end_time: changeDate.endDate,
                created_time_zone: getMachineTimeZONE()
            }, {}, false);
            const end_time = Date.now()
            await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities)
            dispatch(isFetched())
            activities && logEntry({
                user_id: getUser.user.userName, logging_level: 3,
                activity_id: activities["Calendar"], sub_activity: "Modify Event", response_time: (end_time - start_time)
            }, getUser)
        } catch (error) {
            await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities)
            Alert('error', error.message);
            dispatch(isFetched())
            activities && logEntry({
                user_id: getUser.user.userName, logging_level: 2,
                activity_id: activities["Calendar"], sub_activity: "Modify Event",
                error_message: error.message
            }, getUser)
        }
    }

    // for handling api 
    const handleData = async (e) => {
        const data = e.result
        const length = e.result.length
        dispatch(isLoading());
        // for delete events
        if (scheduleObj.current.currentAction === "Delete" || scheduleObj.current.currentAction === "DeleteSeries") {
            const currentAction = scheduleObj.current.currentAction
            const object = scheduleObj.current.activeEventData.event
            if (object?.emp_id === getUser.user.empId) {
                dispatch(isFetch())
                try {
                    const start_time = Date.now()
                    // eslint-disable-next-line
                    var response = await API.post("calendarDash.php", {
                        //urlBase: urlBase,
                        action: currentAction,
                        current: object,
                        corp_code: object.project_name,
                        projectId: object.ProjectName,
                        fullName: getUser.user.fullName,
                        userName: getUser.user.userName,
                        url: Client_URL,
                        appName: APP_NAME,
                    }, {}, false);
                    const end_time = Date.now()
                    if (response.status === "True" && response.task_details.length > 0) {
                        await response?.task_details?.forEach(item => {
                            if (item.active_status === "1") {
                                let data = { task_id: item.task_id, story_id: item.story_id, task_type: item.task_type, project_name: item.project_name, main_task_id: item.main_task_id, story_type: item.story_type, us_id: item.us_id, story_title: item.story_title, blocked_id: item.blocked_id, userStoryId: item.userStoryId, calendarId: item?.calendar_id };
                                let message = `This meeting, ${MAINTASK} is completed`;
                                updateUserStory(activities, getUser.user, data.story_id, "handleClose", message, TASKS_WARNING, ROADBLOCK_WARNING, data.story_type, data.us_id, data.main_task_id, MAINTASK, data.calendarId)
                            }
                        })
                        dispatch(isLoaded())
                    }
                    await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities);
                    dispatch(isFetched())
                    activities && logEntry({
                        user_id: getUser.user.userName, logging_level: 3,
                        activity_id: activities["Calendar"], sub_activity: "Delete Event", response_time: (end_time - start_time)
                    }, getUser)
                } catch (error) {
                    console.log(error)
                    Alert('error', error.message);
                    dispatch(isLoaded())
                    dispatch(isFetched())
                    activities && logEntry({
                        user_id: getUser.user.userName, logging_level: 2,
                        activity_id: activities["Calendar"], sub_activity: "Delete Event",
                        error_message: error.message
                    }, getUser)
                }
            } else {
                if (object?.emp_id !== undefined) {
                    Alert("warning", "You are not authorized to delete this event")
                    await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities);
                    dispatch(isLoaded())
                    activities && logEntry({
                        user_id: getUser.user.userName, logging_level: 2,
                        activity_id: activities["Calendar"], sub_activity: "Delete Event",
                        error_message: "You are not authorized to delete this event"
                    }, getUser)
                }
            }
            // for adding events
        } else if (scheduleObj.current.currentAction === "Add" && scheduleObj.current.activeEventData.event === undefined) {
            const validate = await validateDate(e.result[length - 1].StartTime)
            if (validate) {
                if (e.result[length - 1]?.Members.length > 0 && e.result[length - 1]?.Subject.trim() !== "" && e.result[length - 1]?.Description.trim() !== "") {
                    if (e.result[length - 1].Members.length > 1 && !(e.result[length - 1].Members.every(item => isNaN(item)))) {
                        Alert("error", "Please select only users or only one group")
                        dispatch(isLoaded());
                        await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities);
                    } else {
                        dispatch(isFetch());
                        if (e.result[length - 1].RecurrenceRule !== null) {
                            await checkRecurringEvents(e.result[length - 1]);
                        } else {
                            await createEvent(e.result[length - 1]);
                        }
                    }
                } else {
                    if (e.result[length - 1]?.Subject.trim() === "" || e.result[length - 1]?.Description.trim() === "") {
                        Alert("error", "Please fill both the title and description fields and ensure they are not empty.")
                        await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities);
                        dispatch(isLoaded());
                    } else {
                        Alert("error", "Please select Attendees ")
                        await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities);
                        dispatch(isLoaded());
                    }
                }
            } else {
                if (e.result[length - 1].Id !== undefined) {
                    Alert("warning", "Please select a date and time that are not in the past")
                    dispatch(isLoaded());
                    await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities);
                }
            }

            // for edit occurrence
        } else if (scheduleObj.current.currentAction === "EditOccurrence") {
            try {
                // eslint-disable-next-line
                var response = await API.post("calendarDash.php", {
                    urlBase: urlBase,
                    addEvent: e.result[length - 1],
                    corp_code: getUser.user.corp,
                    // action:  'EditOccurrence',
                    data: data,
                    action: scheduleObj.current.currentAction,
                    // projectId: getUser.user.projectId,
                    fullName: getUser.user.fullName,
                    userName: getUser.user.userName,
                    url: Client_URL,
                    appName: APP_NAME,
                }, {}, false);
                if (response.status === "True") {
                }
            } catch (error) {
                console.log(error)
                Alert('error', error.message);
            }
            // for edit following events
        } else if (scheduleObj.current.currentAction === 'EditFollowingEvents') {
            try {
                // eslint-disable-next-line
                var response = await API.post("calendarDash.php", {
                    urlBase: urlBase,
                    data: data,
                    addEvent: e.result[length - 1],
                    current: scheduleObj.current.activeEventData.event,
                    action: scheduleObj.current.currentAction,
                    corp_code: getUser.user.corp,
                    // projectId: getUser.user.projectId,
                    fullName: getUser.user.fullName,
                    userName: getUser.user.userName,
                    url: Client_URL,
                    appName: APP_NAME,
                }, {}, false);
            } catch (error) {
                console.log(error)
                Alert('error', error.message);
            }
            // for getting resize, drag and drop and update 
        } else {
            const prevObj = scheduleObj.current.activeEventData.event
            const object = data?.filter(item => item.Id === prevObj?.Id)
            let updateObj
            let eventId
            let assignee
            if (object[0]?.Id !== undefined) {
                updateObj = object[0]
                eventId = object[0]?.Id
            }
            let validate
            if (updateObj !== undefined) {
                validate = await validateDate(updateObj.EndTime)
            }
            if (prevObj?.emp_id === getUser.user.empId && validate) {
                const addMembers = updateObj?.Members?.filter(item => !prevObj?.Members.includes(item))
                const deleteMembers = prevObj?.Members?.filter(item => !updateObj?.Members.includes(item))
                if (prevObj?.story_type === "group" || prevObj?.event_type === "group") {
                    if (addMembers?.length > 0 || deleteMembers?.length > 0) {
                        Alert("warning", "This is a Group Event. You cannot add or remove users for this event.")
                        await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities);
                    } else {
                        await modifyEvent(updateObj, prevObj)
                    }
                } else {
                    if (deleteMembers?.length > 0) {
                        const userList = await getUsersList(prevObj?.ProjectName)
                        await deleteMembers.forEach((item) => {
                            const user = userList.filter((users) => users.email === item)
                            var response = API.post("calendarDash.php", {
                                urlBase: urlBase,
                                data: data,
                                action: "RemoveEmpEventTasks",
                                assigned_to: user[0]?.id,
                                corp_code: prevObj?.project_name,
                                state: scheduleObj.current.currentAction,
                                current: scheduleObj.current.activeEventData.event,
                                projectId: prevObj?.ProjectName,
                                fullName: getUser.user.fullName,
                                userName: getUser.user.userName,
                                url: Client_URL,
                                appName: APP_NAME,
                            }, {}, false);
                            response.then(result => {
                                if (result.status === "True" && result?.task_details?.length > 0) {
                                    const deleteTask = result?.task_details?.filter(task => task.assigned_to === user[0].id)
                                    const item = deleteTask[0]
                                    if (item.active_status === "1") {
                                        let data = { task_id: item.task_id, story_id: item.story_id, task_type: item.task_type, project_name: item.project_name, main_task_id: item.main_task_id, story_type: item.story_type, us_id: item.us_id, story_title: item.story_title, blocked_id: item.blocked_id, userStoryId: item.userStoryId, calendarId: item?.calendar_id };
                                        let message = `This meeting, ${MAINTASK} is completed`;
                                        updateUserStory(activities, getUser.user, data.story_id, "handleClose", message, TASKS_WARNING, ROADBLOCK_WARNING, data.story_type, data.us_id, data.main_task_id, MAINTASK, data.calendarId)
                                    }
                                }
                            })
                        })
                    }
                    if (addMembers?.length > 0 && updateObj.isGenerate) {
                        if (!(addMembers.every(item => isNaN(item)))) {
                            Alert("error", "Please select only users")
                            dispatch(isLoaded());
                            await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities);
                        } else {
                            await getUsersList(prevObj?.ProjectName)
                            const details = {
                                Subject: updateObj.Subject,
                                Description: updateObj.Description,
                                StartTime: updateObj.StartTime,
                                Members: addMembers,
                                ProjectName: prevObj.ProjectName,
                            }
                            await modifyEvent(updateObj, prevObj)
                            await createTask(details, assignee, eventId)
                            dispatch(isLoaded());
                        }
                    } else {
                        await modifyEvent(updateObj, prevObj)
                    }
                }

                dispatch(isLoaded());
            } else {
                if (!validate && prevObj?.emp_id !== undefined) {
                    Alert("warning", "Please select a date and time that are not in the past")
                    await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities);
                }
                else if (prevObj?.emp_id !== undefined) {
                    Alert("warning", "You are not authorized to modify this event")
                    await getEventCalendarDash(getUser.user, dispatch, APP_NAME, activities);
                }
                dispatch(isLoaded());
            }
            dispatch(isLoaded());
        }

    }
    // for adding extra field to the default editor
    const editorTemplate = async (args) => {
        if (args.type === 'Editor') {
            let meetingUrl = ""
            if (state.employees.length > 0) {
                const employe = state.employees.filter(emp => emp.id === getUser.user.empId)
                meetingUrl = await updateTaskMeetingUrl(employe)
            }
            if (!args.element.querySelector('.custom-field-row')) {
                let row = createElement('div', { className: 'custom-field-row' });
                let formElement = args.element.querySelector('.e-schedule-form');
                formElement.firstChild.insertBefore(row, formElement.firstChild.firstChild);
                let container = createElement('div', { className: 'custom-field-container' });
                let inputEle = createElement('input', {
                    className: 'e-field', attrs: { name: 'EventStatus' }
                });
                container.appendChild(inputEle);
                row.appendChild(container);
                let drowDownList = new DropDownList({
                    dataSource: [
                        { text: 'Active', value: 'Active' },
                        { text: 'Cancel', value: 'Cancel' }
                    ],
                    fields: { text: 'text', value: 'value' },
                    value: args.data.EventStatus ? args.data.EventStatus : "Active",
                    floatLabelType: 'Always', placeholder: 'Event Status'
                });
                drowDownList.appendTo(inputEle);
                inputEle.setAttribute('name', 'EventStatus');

                // another field added
                let inputField = createElement('input', {
                    className: 'e-field', attrs: { name: 'ProjectName' }
                });
                container.appendChild(inputField);
                row.appendChild(container);
                let drowDownList1 = new DropDownList({
                    dataSource: state.allProjects,
                    fields: { text: 'value', value: 'id' },
                    value: args.data.ProjectName ? args.data.ProjectName : "",
                    floatLabelType: 'Always', placeholder: 'ProjectName',
                    change: function (e) {
                        //getGroup(dispatch1, getUser.user,e.value);
                        //getEmployees(dispatch,getUser.user,e.value)
                        updateMembersMultiSelect(e.value);
                    }
                });
                drowDownList1.appendTo(inputField);
                inputField.setAttribute('name', 'ProjectName');

                //add checkbox
                let generateTaskContainer = createElement('div', { className: 'custom-field-container' });
                generateTaskContainer.classList.add("generate-container");
                let checkbox = createElement('input', {
                    className: 'e-field', attrs: { type: 'checkbox', id: 'generateTask', name: 'isGenerate', checked: true }
                });
                checkbox.style.marginRight = "5px";
                checkbox.style.height = "18px";
                checkbox.style.width = "18px";
                checkbox.style.cursor = "pointer"
                generateTaskContainer.appendChild(checkbox);
                let label = createElement('label', {
                    innerHTML: 'Generate Task', attrs: { for: 'generateTask' }
                });
                label.style.marginTop = "10px";
                label.style.color = "blue";
                generateTaskContainer.appendChild(label);
                row.appendChild(generateTaskContainer);

                //add attendees
                let container2 = createElement('div', { className: 'custom-field-container' });
                row.appendChild(container2);
                container2.style.marginBottom = "20px";
                let inputField2 = createElement('input', {
                    className: 'e-field', attrs: { name: 'Members' }
                });
                container2.appendChild(inputField2);
                let drowDownList2 = new MultiSelect({
                    dataSource: employeeData,
                    fields: { text: 'name', value: 'email' },
                    value: args.data.Members ? args.data.Members : "",
                    floatLabelType: 'Always', placeholder: 'Attendees',
                    mode: 'Box',
                    allowCustomValue: false,
                    showClearButton: true,
                });
                drowDownList2.appendTo(inputField2);
                inputField2.setAttribute('name', 'Members');

                const updateMembersMultiSelect = async (projectId) => {
                    const userList = await getUsersList(projectId)
                    const groupList = await getGroupList(projectId)
                    if (userList !== undefined && groupList !== undefined) {
                        drowDownList2.dataSource = [...userList, ...groupList]
                    } else if (userList !== undefined) {
                        drowDownList2.dataSource = [...userList]
                    }
                }
                if(args.data.ProjectName){
                    updateMembersMultiSelect(args.data.ProjectName)
                }

                let locationInput = args.element.querySelector('input[name="Location"]');
                locationInput.style.color = "blue"
                locationInput.value = args.data.Location ? args.data.Location : meetingUrl;
                locationInput.style.cursor = "pointer"
                locationInput.readOnly = true;
                // locationInput.addEventListener('click', function() {
                //     if(meetingUrl.trim()!==""){
                //         window.open(meetingUrl, '_blank');
                //     }
                // })
            } else {
                let dropdownStatus = args.element.querySelector('.e-field[name="EventStatus"]');
                if (dropdownStatus) {
                    dropdownStatus.value = args.data.EventStatus ? args.data.EventStatus : "Active"
                }

                let checkboxGenerate = args.element.querySelector('.e-field[name="isGenerate"]');
                if (checkboxGenerate) {
                    checkboxGenerate.checked = true;
                }

                let locationInput = args.element.querySelector('input[name="Location"]');
                if (locationInput) {
                    locationInput.value = args.data.Location ? args.data.Location : meetingUrl;
                }

                let dropdownAttendees = args.element.querySelector('.e-field[name="Members"]');
                if (dropdownAttendees) {
                        dropdownAttendees.value= args.data.Members ? args.data.Members : "Active"
                }
            }
        }else if(args.type==='QuickInfo'){
            if(args.data.ProjectName){
                await getUsersList(args.data.ProjectName)
                await getGroupList(args.data.ProjectName)
            }
        }
    }
    // add color and styling to the event and user story
    const onEventRendered = (args) => {
        if (args.data.IsAllDay === '1') {
            args.data.CategoryColor = '#1aaa55'
        } else if (args.data.EventStatus === "Cancel" && args.data.IsAllDay === '') {
            args.data.CategoryColor = '#F57F17'
            args.data.TextLine = 'line-through'
            args.data.LineColor = 'black'
        } else {
            args.data.CategoryColor = '#357CD2'
        }
        applyCategoryColor(args, scheduleObj.currentView);
    }

    // eslint-disable-next-line 
    const events = [...state.events].filter(val => {
        if (new Date(val.EndTime) >= new Date()) {
            return val
        }
    })

    // for serch events
    const globalSearch = (args) => {
        let searchString = args.target.value;
        if (searchString !== '') {
            new DataManager(scheduleObj.current.eventsData).executeQuery(new Query().search(searchString, ['Subject', 'Location', 'Description'], null, true, true)).then((e) => {
                if (e.result.length > 0) {
                    showSearchEvents('show', e.result);
                }
                else {
                    showSearchEvents('hide');
                }
            });
        }
        else {
            showSearchEvents('hide');
        }
    }
    // display searched events
    const showSearchEvents = (type, data) => {
        if (type === 'show') {
            if (document.getElementById('grid').classList.contains('e-grid')) {
                let gridObj = document.querySelector('#grid').ej2_instances[0];
                gridObj.dataSource = data;
                gridObj.dataBind();
            }
            else {
                let gridObj = new GridComponent({
                    dataSource: data,
                    height: 505,
                    width: 'auto',
                    columns: [
                        { field: 'Subject', headerText: 'Subject', width: 120 },
                        { field: 'Location', headerText: 'Location', width: 120 },
                        { field: 'StartTime', headerText: 'StartTime', width: 120, format: { type: 'dateTime', format: 'M/d/y hh:mm a' } },
                        { field: 'EndTime', headerText: 'EndTime', width: 120, format: { type: 'dateTime', format: 'M/d/y hh:mm a' } },
                    ]
                });
                gridObj.appendTo(document.querySelector('#grid'));
            }
        }
        else {
            let gridObj = document.querySelector('#grid').ej2_instances;
            if (gridObj && gridObj.length > 0 && !gridObj[0].isDestroyed) {
                gridObj[0].destroy();
            }
        }
    }
    return (

        <div className="container-scroller">
            <TopNavWithOutProject />
            <div className="container-fluid page-body-wrapper">
                <SideBar />
                <div className="main-panel">
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="d-flex justify-content-between col-12 row p-2">
                                            <h2 className="card-title cd" style={{ paddingTop: 20, paddingLeft: 20 }}>CALENDAR</h2>


                                            <div className="d-flex justify-content-end mt-2">
                                                <div class="input-group-prepend" style={{ marginTop: 5 }}>
                                                    <span class="text" style={{ color: 'black', marginTop: '3px', fontSize: '13px', paddingRight: 10 }}>Search:</span>
                                                </div>
                                                <input type="text" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', width: 250, height: '35px' }}
                                                    onKeyUp={(args) => globalSearch(args)} />
                                            </div>
                                        </div>

                                        {state.isLoading || state.fetching ?
                                            <>
                                                <RootLoader />
                                                <p style={{ textAlign: 'center', width: '100vw' }}>Data is updating; please don't navigate back from this page.</p>
                                            </> :
                                            <div>
                                                <div id="grid"></div>
                                                <ScheduleComponent
                                                    height='500px'
                                                    ref={scheduleObj}
                                                    // eventSettings={{ dataSource: state.user }}
                                                    eventSettings={{ dataSource: events, fields: fields, editFollowingEvents: true }}
                                                    dataBinding={handleData}
                                                    popupOpen={editorTemplate}
                                                    group={{
                                                        allowGroupEdit: true
                                                        // for show members list and remove multiple meet column for multiple people
                                                        // , resources: ['Conferences'] 
                                                    }}
                                                    eventRendered={onEventRendered}
                                                >
                                                    {/* <ResourcesDirective>
                                                    <ResourceDirective field='Members' title='Attendees' name='Conferences' allowMultiple={true}
                                                        //   dataSource={state.employees}
                                                        dataSource={combinedEmpArray}
                                                        textField='name' idField='email'>
                                                    </ResourceDirective>
                                                </ResourcesDirective> */}


                                                    <Inject
                                                        services={[Day, Week, WorkWeek, Month, Agenda, DragAndDrop, Resize]} />
                                                </ScheduleComponent>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    );
}

export default Calender;