import ReportsAPI from "../../AgileNetwork/ReportsAPI";
import Alert from "../../Alert";
import { logEntry } from "../../logEntry";
import * as actions from "../actions";

const modify_list = (points_list)=>{
    const modified_points=points_list.map((line,index)=>{
        return `${index + 1}. ${line}`;
    })
    return modified_points.join('\n');
}

const getAiTaskDetails= async (task_description,dispatch,setIsLoading,getUser,activity_id)=>{
    try {
        setIsLoading(true);
        const start_time=Date.now()
        var response= await ReportsAPI.post("/ai/getAiData",{task_detail:task_description},{},false);
        const end_time=Date.now()
        const description= response?.task_details?.description;
        const acceptance_list = response?.task_details?.acceptance;
        const acceptance=acceptance_list ? modify_list(acceptance_list.split('\\n')):""

        const task_title = response?.task_details?.task_name;
        
        dispatch(actions.taskTitle(String(task_title)));
        dispatch(actions.taskDescription(String(description)));
        dispatch(actions.acceptanceCriteria(String(acceptance)));
        setIsLoading(false);
        logEntry({user_id:getUser.user.userName,logging_level:3,
            activity_id:activity_id,
            ai_prompt:task_description,
            response_time:(end_time-start_time)
          },getUser)

    } catch (error) {
        Alert('error',"Our AI functionality is temporarily down. We are working on it and hope to have it back up and running shortly.")
        setIsLoading(false);
        logEntry({user_id:getUser.user.userName,logging_level:2,
            activity_id:activity_id,
            ai_prompt:task_description,error_message:error.message
          },getUser)
        console.log('error', error.message);
    }
}

export default getAiTaskDetails;