/*
Filename:index.js
Purpose:forget password screen
Developer-Satya Sidda[SS]
Created Date:8/3/2021
*/
// import React, { useReducer } from 'react'
// import RootLoader from '../../Common/Loader/RootLoader';
// import { updateCurrentPassword, updateNewPassword, updateConfirmPassword } from './actions';
// import { reducer,initialState } from './reducer';


export default function forgetPassword () {
 
//  const [state, dispatch] = useReducer(reducer, initialState)


// //rendering forget password ui
//     return(
//         <div className="container-scroller">
       
//         <div className="container-fluid page-body-wrapper">
           
//             <div className="main-panel">
//                 <div className="mt-2">
//                     <div className="col-md-12 grid-margin stretch-card">
//                         <div className="card">
//                             <div className="card-body">
                               
//                                 <form className="forms-sample">
//                                     <div className="form-group row" >

                                       

//                                         <label className="col-sm-3 col-form-label pt-3">Username<span className="required text-danger">*</span></label>
//                                         <div className="col-sm-9">
//                                             <input type="text"
//                                                 className="col-sm-6 form-control form-control-sm"
//                                                 style={{borderWidth:'0.1px'}}
//                                                 // eslint-disable-next-line
//                                                 type="text"
//                                                 id="username"
//                                                 placeholder="username"
//                                                 // value={state.currentpassword.value}
//                                                 // onChange={(event) => {
//                                                 //     dispatch(updateCurrentPassword(event.target.value));
//                                                 // }}
//                                                 required
//                                             />
//                                             {/* <small style={{ color: "red", fontSize: '12px' }}>{state.mobile.errorStatus ? state.mobile.errorMessage : ""}</small> */}
//                                         </div>
//                                     </div>
//                                     <div className="form-group row" >

                                       

//                                         <label className="col-sm-3 col-form-label pt-3">Hint<span className="required text-danger">*</span></label>
//                                         <div className="col-sm-9">
//                                             <input type="text"
//                                                 className="col-sm-6 form-control form-control-sm"
//                                                 style={{borderWidth:'0.1px'}}
//                                                 // eslint-disable-next-line
//                                                 type="text"
//                                                 id="hint"
//                                                 placeholder="Squad Name "
//                                                 // value={state.currentpassword.value}
//                                                 // onChange={(event) => {
//                                                 //     dispatch(updateCurrentPassword(event.target.value));
//                                                 // }}
//                                                 required
//                                             />
//                                         </div>
//                                     </div>
//                                     <div className="form-group row" >
//                                         <label className="col-sm-3 col-form-label pt-3">New Password <span className="required text-danger">*</span></label>
//                                         <div className="col-sm-9">
//                                             <input type="text"
//                                                 className="col-sm-6 form-control form-control-sm"
//                                                 style={{borderWidth:'0.1px'}}
//                                                 // eslint-disable-next-line
//                                                 type="password"
//                                                 autoComplete="new-password"
//                                                 id="new-password"
//                                                 placeholder="*********"
//                                                 // value={state.newpassword.value}
//                                                 // onChange={(event) => {
//                                                 //     dispatch(updateNewPassword(event.target.value));
//                                                 // }}

//                                                 required
//                                             // data-toggle="tooltip" data-placement="right" title="Please enter new password for login" 
//                                             />
//                                             <span style={{ color: "red", fontSize: '12px' }}>
//                                                 {/* {state.newpassword.errorStatus ? state.newpassword.errormessage : ""} */}
//                                                 </span>
//                                             {/* <small style={{ color: "red", fontSize: '12px' }}>{state.mobile.errorStatus ? state.mobile.errorMessage : ""}</small> */}
//                                         </div>
//                                     </div>
//                                     <div className="form-group row" >
//                                         <label className="col-sm-3 col-form-label pt-3">Confirm Password <span className="required text-danger">*</span></label>
//                                         <div className="col-sm-9">
//                                             <input
//                                                 type="password"
//                                                 autoComplete="confirm-password"
//                                                 className="col-sm-6 form-control form-control-sm"
//                                                 style={{borderWidth:'0.1px'}}
//                                                 // className={state.confirmpassword.errorStatus && (state.newpassword.value === state.confirmpassword.value) ? "form-control errorstyle" : "form-control"}
//                                                 id="confirm-password"
//                                                 placeholder="*********"
//                                                 // value={state.confirmpassword.value}
//                                                 // onChange={(event) => {
//                                                 //     dispatch(updateConfirmPassword(event.target.value));
//                                                 // }}
//                                                 required
//                                             />
//                                             {/* {state.confirmpassword.errorStatus ? <span style={{ color: "red", fontSize: '12px' }}>{state.confirmpassword.errormessage}</span> : (state.newpassword.value === state.confirmpassword.value) && state.newpassword.value !== "" && state.confirmpassword.value !== "" ? <span style={{ color: "green", fontSize: '12px' }}> password matched </span> : state.confirmpassword.value === "" ? " " : <span style={{ color: "red", fontSize: '12px' }}>password not matched</span>} */}
//                                             {/* <small style={{ color: "red", fontSize: '12px' }}>{state.mobile.errorStatus ? state.mobile.errorMessage : ""}</small> */}
//                                         </div>
//                                     </div>
//                                     <div className=" col-md-12 d-flex justify-content-end" style={{ marginTop: '0px' }}>
//                                         {true ? <RootLoader /> :
//                                             <button type="submit" className="btn btn-success border mr-2"
//                                                 onClick={(event) => {
//                                                     //  updatePassword(state, dispatch, getUser.user)
//                                                      }
//                                                      } style={{ borderRadius: '30px' }}>Save</button>
//                                                     }
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//      </div>

//     )
}