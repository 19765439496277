// common/services/getProjects.js  (READ)

import { isLoaded, isLoading, sprints } from "../actions";
import Alert from "../../Alert";
import API from "../../Network/API";

export async function getProjectSprints(dispatch, getUser, projectId) {
  dispatch(isLoading());
  try {
    var response = await API.post("getUpdateSprint.php", {
      "projectId": projectId,
      "crop": getUser.corp,
      "action": "getModules",
    }, {}, false);
    if (response.status === 'True') {
      dispatch(sprints(response.data))
    }
    else {
      dispatch(sprints([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}
