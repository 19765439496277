import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoading, isLoaded, projects } from "../actions";

//For Completed Projects/Epics
export async function getCompletedEpics(dispatch, getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("get_epics.php", {
        "corp": getUser.corp,
        "userType": getUser.role,
        "empId": getUser.empId,
        "action": "completed",
        "projectId":getUser.projectId,
      }, {}, false);
  
      if (response.status === 'True') {
        dispatch(projects(response.data))
      }
      else {
        dispatch(projects([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }