import Alert from "../../Alert";
import API from "../../Network/API";
import { employees, isLoaded, isLoading } from "../action";// getting group chat details

  // for getting employee details
  export async function getEmployees(dispatch, getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("agile_squads.php", {
        "crop": getUser.corp,
        projectId: getUser.projectId,
      }, {}, false);
      if (response.status === 'True') {
        dispatch(employees(response.data))
      }
      else {
        dispatch(employees([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }