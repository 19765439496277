import { isLoaded, isLoading } from "../actions";
import Alert from "../../Alert";
import API from "../../Network/API";

// delete template
export async function deleteTemplate(state, dispatch, getUser, id, handleClose) {
    dispatch(isLoading());
      try {
        var response = await API.post("manage_template.php", {
          crop: getUser.corp,
          action: "delete",
          us_id: id,
        }, {}, false);
        if (response.status === 'True') {
          Alert("success", "Template deleted successfully!");
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
      handleClose()
  }