import { isLoaded, isLoading, users } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

//For Getting Users List(Team)
export async function getUsers(dispatch, getUser, project_id) {
  dispatch(isLoading());
  try {
    var response = await API.post("agile_squads.php", {
      "crop": getUser.corp,
      "projectId": project_id || getUser.projectId
    }, {}, false);
    if (response.status === 'True') {
      dispatch(users(response.data))
    }
    else {
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
}