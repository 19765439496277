import Alert from "../../Alert";
import API from "../../Network/API";
import { isLoaded, isLoading, details, groupName, groupList, groupEmail } from "../action";// getting group chat details

export async function getGroupChatDetails(dispatch, getUser, data) {
  dispatch(isLoading());
  try {
    var response = await API.post("group_chat.php", {
      action: 'getGroupChatDetails',
      created_by: getUser.userName,
      group_Id: data.id,
    }, {}, false);
    if (response.status === 'True') {
      dispatch(groupName(response.data[0].group_name))
      dispatch(groupList(response.data[0].members_name.split(',')))
      dispatch(groupEmail(response.data[0].members_email.split(',')))
    }
    else {
      dispatch(details([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}