export const CONSOLIDATED_TODO_TO = "CONSOLIDATED_TODO_TO"
export const CONSOLIDATED_CALENDAR_TODO_TO = "CONSOLIDATED_CALENDAR_TODO_TO"
export const CONSOLIDATED_TODO_BY = "CONSOLIDATED_TODO_BY"
export const PRIVATE_TODO = "PRIVATE_TODO"
export const IS_LOADING = 'IS_LOADING'
export const IS_LOADED = 'IS_LOADED'
export const ALL_TASKS = 'ALL_TASKS'
export const CONSOLIDATED_SUB_TODO_TO = "CONSOLIDATED_SUB_TODO_TO"
export const CONSOLIDATED_SUB_TODO_BY = "CONSOLIDATED_SUB_TODO_BY"
export const CONSOLIDATED_SUB_COMPLETED = "CONSOLIDATED_SUB_COMPLETED"
export const CONSOLIDATED_COMPLETED = "CONSOLIDATED_COMPLETED"

export const consolidatedToDoTo = (consolidatedToDoTo) => {
    return {
        type: CONSOLIDATED_TODO_TO,
        payload: consolidatedToDoTo
    };
};

export const consolidatedCalendarToDoTo = (consolidatedCalToDoTo) => {
    return {
        type: CONSOLIDATED_CALENDAR_TODO_TO,
        payload: consolidatedCalToDoTo
    };
};
export const consolidatedToDoBy = (consolidatedToDoBy) => {
    return {
        type: CONSOLIDATED_TODO_BY,
        payload: consolidatedToDoBy
    };
};
export const consolidatedSubToDoTo = (consolidatedSubToDoTo) => {
    return {
        type: CONSOLIDATED_SUB_TODO_TO,
        payload: consolidatedSubToDoTo
    };
};
export const consolidatedSubToDoBy = (consolidatedSubToDoBy) => {
    return {
        type: CONSOLIDATED_SUB_TODO_BY,
        payload: consolidatedSubToDoBy
    };
};
export const privateToDo = (privateToDo) => {
    return {
        type: PRIVATE_TODO,
        payload: privateToDo
    };
};
export function isLoading() {
    return { type: IS_LOADING };
}
export function isLoaded() {
    return { type: IS_LOADED }
}
export function allTasks(allTasks) {
    return {
        type: ALL_TASKS,
        payload: allTasks
    }
}
export const consolidatedCompleted = (consolidatedToDoBy) => {
    return {
        type: CONSOLIDATED_COMPLETED,
        payload: consolidatedToDoBy
    };
};
export const consolidatedSubCompleted = (consolidatedToDoBy) => {
    return {
        type: CONSOLIDATED_SUB_COMPLETED,
        payload: consolidatedToDoBy
    };
};