import Alert from "../../Alert";
import API from "../../Network/API";
import { isLoaded, isLoading } from "../action";
import { getGroupDetails } from "./getGroupDetails";

// for exit group
export async function archiveGroupChat(state, dispatch, newMem, newLabel, getUser, id, handleClose, value) {
  dispatch(isLoading());
  try {
    var response = await API.post("group_chat.php", {
      crop: getUser.corp,
      newMem: newMem,
      newLabel: newLabel,
      action: "archive_chat",
      created_by: getUser.userName,
      group_Id: id,
      value: value
    }, {}, false);
    if (response.status === 'True') {
      getGroupDetails(dispatch, getUser)
      Alert("success", `Successfully ${value==="true" ? 'Archived' : 'Reopened'}!`);
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
  handleClose()
}