import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoading, isLoaded, allGroupMessages} from "../actions";

// get all messages from group chat
export async function getAllGroupMessages(dispatch, getUser, refresh) {
    if(refresh) {dispatch(isLoading());}
    try {
      var response = await API.post(
        "group_chat.php",
        {
          corp_code: getUser.corp,
          action: "getAllGroupMessagesDash",
          created_by: getUser.userName,
        },
        {},
        false
      );
      if (response.status === "True") {
        dispatch(allGroupMessages(response.data));
      } else {
        dispatch(allGroupMessages([]));
      }
    } catch (error) {
      Alert("error", error.message);
    }
    dispatch(isLoaded());
  }