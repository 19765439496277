// common/services/getAgileProjects.js (READ)

import { agileProjects } from "../actions";
// import Alert from "../../Common/Alert";
import API from "../../../Common/Network/API";

export async function getAgileProjects(dispatch,empId){
  try {
    var response = await API.post("squads.php", {
      empId: empId,
      action:"get_squads"
    }, {}, false);
    if (response.status === 'True') {
      dispatch(agileProjects(response.data))
    } else {
      dispatch(agileProjects([]))
    }
  } catch (error) {
  }
}