import React from "react";
import { useWebProperties } from "../../Common/webProperties";
import { getSubStringId } from "../../Common/SubStringConvert";
import store from "../../Common/Store";

export default function SubTasksInfo(props) {

    const getUser = store.getState().auth.user;

    const { SUBTASK, ASSIGNED_TO, ASSIGNED_BY, STATUS } = useWebProperties();

    const subtasks = props.data?.tasks !== undefined || props.data?.tasks !== false ? props.data?.tasks?.filter((o) => o.active_status === "1" || o.status === "pending") : [];
    const PendingSubtask = subtasks && subtasks?.length > 0 ? subtasks[0] : {};
    const { t_id, task_title, assignedby, assignedto, status, active_status, priority } = PendingSubtask;

    if (!subtasks?.length || props.data?.tasks === undefined || props.data?.tasks === false) {
        return null;
    }

    return (
        <div>
            {
                props.type === "small_info" ?
                    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <div>
                            <p className="text-success" style={{ fontWeight: 'bold' }}>{getSubStringId(getUser.corp, 3)}-{t_id}-{task_title} </p>
                        </div>
                        <div >
                            <div style={{ display: 'inline-flex' }}>
                                {/* <div
                                    className="d-flex justify-content-center mt-2 mr-2"
                                    style={{ backgroundColor: '#000080', width: 20, height: 20, cursor: 'pointer' }}
                                    title={`Assigned by: ${assignedby}`}
                                >
                                    {" "}
                                    <i
                                        className="text"
                                        style={{
                                            color: "white",
                                            fontSize: 10,
                                            fontWeight: "bold",
                                            paddingTop: 3,
                                        }}
                                    >
                                        {getSubStringId(assignedby, 2)}{" "}
                                    </i>
                                </div> */}
                                <div
                                    className="d-flex justify-content-center mt-2  ml-1 p-1"
                                    style={{ backgroundColor: "#019be1", width: 20, height: 20, cursor: 'pointer',paddingLeft:'9px' }}
                                    title={`Assigned to: ${assignedto}`}
                                >
                                    <i
                                        className="text"
                                        style={{
                                            color: "white",
                                            fontSize: 10,
                                            fontWeight: "bold",
                                            //  paddingTop: 3,
                                            
                                        }}
                                    >
                                        {getSubStringId(assignedto, 2)}{" "}
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div >
                    :
                    < div >
                        <div className="pt-2">
                            <text style={{ fontWeight: "bold", fontSize: 12 }}>
                                {SUBTASK} Information:
                            </text>
                        </div>
                        <div className="pt-2">
                            <text style={{ fontWeight: "bold", fontSize: 12 }}>
                                Completed {SUBTASK}: ({priority}/{subtasks.length})
                            </text>
                        </div>
                        {/* {state.manageSubTasks.filter((o) => o.priorityLevel === "1").map(({ t_id, task_title, assignedby, assignedto, status }, index) => */}
                        <div className="overflow-auto mt-1" style={{ padding: '10px', overflow: 'auto', border: '0.2px solid grey', borderRadius: '10px' }}>
                            <p className="text-success" style={{ fontWeight: 'bold' }}>{getSubStringId(getUser.corp, 3)}-{t_id}-{task_title} </p>
                            <p style={{ fontSize: '12px' }}>{ASSIGNED_BY} : {assignedby} </p>
                            <p style={{ fontSize: '12px' }}>{ASSIGNED_TO} : {assignedto}</p>
                            <p style={{ fontSize: '12px' }}>{STATUS} : {active_status === "1" ? 'In Progress' : status} </p>
                        </div>
                        {/* )} */}
                    </div >
            }
        </div>
    )

}