import { isLoaded, isLoading, kanbanSelected } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";


export async function getKanbans(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post(
      "getUpdateSprint.php",
      {
        crop: getUser.corp,
        action: "getKanbanList",
        projectId: getUser.projectId,
      },
      {},
      false
    );
    if (response.status === "True") {
      dispatch(kanbanSelected(response.data[0].moduleId))
      // dispatch(sprints(response.data));
    } else {
      dispatch(kanbanSelected({}))
    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());

}