import { isLoading, isLoaded } from "../../../Common/Actions/loading"
import API from "../../../Common/Network/API";
import store from '../../../Common/Store';
import {  tasksCount } from "../actions";


//For Tasks Count (Pending Project,RoadBlock,SubTasks and MainTasks)
export async function getTasksCount(dispatch,getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("getEmployeeInfo.php", {
      "crop": getUser.corp,
      "empId": getUser.empId,
    }, {}, false);
    store.dispatch(tasksCount(response));
  } catch (error) {
    console.log(error)
  }
  dispatch(isLoaded());
}