import { isLoading, isLoaded,redirect} from "./actions";
import Alert from "../../Common/Alert";
import API from "../../Common/Network/API";
import { Client_URL } from "../../Common/config";
import { logEntry } from "../../Common/logEntry";

export async function register(activities,state, dispatch, APP_NAME) {
  dispatch(isLoading());
  if( state.newpassword.value !=="" && state.email.value !== ""){
  try {
    if (state.newpassword.value.length >= 5 && state.newpassword.value === state.confirmpassword.value) {
      const start_time = Date.now()
        var response = await API.post("authentication.php", {
        username: state.email.value,
        password: state.newpassword.value,
        action: "register",
        url: `${Client_URL}`,
        appName: APP_NAME,
      }, {}, false);
      const end_time = Date.now()
      if(response.status === "True"){
        Alert('success', response.message)
        dispatch(redirect("emailredirect")) 
        activities && logEntry({user_id:state.email.value,logging_level:3,activity_id:activities['User Sign Up'],response_time:(end_time-start_time)})
      }else{
        Alert('warning', response.message)
        activities && logEntry({user_id:state.email.value,logging_level:2,activity_id:activities['User Sign Up'],error_message:response.message})
      }
    }
    else {
      Alert("warning","Both passwords must be same ");
      activities && logEntry({user_id:state.email.value,logging_level:2,activity_id:activities['User Sign Up'],error_message:'Both passwords must be same'})
    }
  } catch (err) {
    Alert("error",err.message);
    activities && logEntry({user_id:state.email.value,logging_level:1,activity_id:activities['User Sign Up'],error_message:err.message})
  }}else{
    Alert("warning","Fill All Fields")
  }

  dispatch(isLoaded());

}