import { useEffect, useState } from 'react';
import { getStatus } from './LocalStorage';

function MouseMoveDetector({ onGlobalMouseMove, onInactive }) {
  const [lastMouseMoveTime, setLastMouseMoveTime] = useState(Date.now());

  useEffect(() => {
    const handleGlobalMouseMove = (event) => {
      // Update the last mouse move time when a mouse move occurs
      setLastMouseMoveTime(Date.now());
      // Pass the event to the callback function provided as prop
      onGlobalMouseMove(event);
    };

    // Add event listener for mousemove on the document
    document.addEventListener('mousemove', handleGlobalMouseMove);

    // Check for inactivity after 10 minutes
    const inactivityTimer = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime - lastMouseMoveTime >=  10*60 * 1000&&getStatus('status')==='Available') {
        // If no mouse movement for 10 minutes, invoke the onInactive callback
        onInactive();
      }
    }, 1000); // Check every second

    // Cleanup function to remove the event listener and clear the timer when component unmounts
    return () => {
      document.removeEventListener('mousemove', handleGlobalMouseMove);
      clearInterval(inactivityTimer);
    };
  }, [lastMouseMoveTime, onGlobalMouseMove, onInactive]);

  // This component doesn't render anything, it's just for detecting mouse moves
  return null;
}

export default MouseMoveDetector;
