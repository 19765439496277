import Alert from "../../Alert";
import API from "../../Network/API";
import { mainTaskMessages } from "../actions";

export async function getUserstoriesMessages(dispatch, getUser) {
  try {
    var response = await API.post("user_story_chat.php", {
      corp_code: getUser.corp,
      action: "getAllTaskMessages",
      "projectId": getUser.projectId
    }, {}, false);
    if (response.status === 'True') {
      dispatch(mainTaskMessages(response.data));
    } else {
      dispatch(mainTaskMessages([]));
    }
  } catch (error) {
    Alert('error', error.message)
  }
}
