import { toBeAssignedRoadBlocks, isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

//For toBeAssigned RoadBlocks
export async function getToBeAssignedRoadBlocks(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("manage_roadblocks.php", {
      "crop": getUser.corp,
      // "action": "roadblocks",
      action: 'task_resolutions',
      "projectId": getUser.projectId
    }, {}, false);
    if (response.status === 'true') {
        dispatch(toBeAssignedRoadBlocks(response.data))
    }
    else {
      dispatch(toBeAssignedRoadBlocks([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}
