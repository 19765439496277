import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { completedSprints } from "../actions";


//for getting completed sprints 
export async function getCompletedSprints(dispatch, getUser) {
    try {
      var response = await API.post("archive.php", {
        "crop": getUser.corp,
        "action": "getCompletedSprints",
        "projectId": getUser.projectId,
        "userType": getUser.role,
        "empId": getUser.empId
  
      }, {}, false);
      if (response.status === 'True') {
        dispatch(completedSprints(response.data))
      }
      else {
        dispatch(completedSprints([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
  }