import React, { useEffect, useReducer, useState } from "react";
import $ from 'jquery';
import { DateRangePicker } from 'react-date-range';
import { startOfWeek, endOfWeek } from 'date-fns';
import { Modal } from 'react-bootstrap';
import AdminSideBar from "../Utility/SideNav";
import Header from "../../Common/TopNav";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';
import Select from 'react-select'
import API from '../../Common/Network/API';
import { useWebProperties } from "../../Common/webProperties";
import { useSelector } from "react-redux";
import { removeDuplicatesFromString } from '../../Common/commonUsage';
import { getRanks } from "./Services/getRanks";
import { rankReducer, initialState } from "./rankReducer";
import RootLoader from "../../Common/Loader/RootLoader";
import UserChat from "../../Common/SquadChat";
import { getAllMessages } from "./Services/getAllMessages";
import UserRanksInfo from "./userRanksInfo";




export default function Ranks() {
    const history = useHistory();
    const [state, dispatch] = useReducer(rankReducer, initialState)
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [info, setInfo] = useState();
    const { SELECT_DATE_RANGE, DATE, CHAT } = useWebProperties();
    const [filter, setFilter] = useState([
        {
            startDate: startOfWeek(new Date(), { weekStartsOn: 0 }),
            endDate: endOfWeek(new Date(), { weekEndsOn: 7 }),
            key: 'selection'
        }
    ])

    const getUser = useSelector(state => state.auth);
    const [groupDetails, setGroupDetails] = useState([])
    const [selectedGroupDetails, setSelectedGroupDetails] = useState([]);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(() => {
        getAllMessages(dispatch, getUser.user);
        getAdminGroupDetails(getUser.user)
        // getRanks(dispatch, filter);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (state.ranks.length > 0) {
            $(document).ready(function () {
                window.$('#example').DataTable({
                    destroy: true,
                    dom: 'Bfrtip',
                    aLengthMenu: [
                        [25, 50, 100, 200, -1],
                        [25, 50, 100, 200, "All"]
                    ],
                    iDisplayLength: -1,
                    buttons: [
                        { extend: 'excelHtml5', text: 'Export' }
                    ]
                })
            })
        }
    }, [state.ranks, state.allMessages])

    async function getAdminGroupDetails(getUser) {
        try {
            var response = await API.post("group_chat.php", {
                action: 'getAdminGroupDetails',
                created_by: getUser.userName,
                projectId: getUser.projectId,
                "userType": getUser.role,
                "empId": getUser.empId,
            }, {}, false);
            if (response.status === 'True') {
                setGroupDetails(response.data);
            }
            else {
                setGroupDetails([])
            }
        } catch (error) {
            console.log('error', error.message);
        }
    }

    useEffect(() => {
        if (selectedGroupDetails.value === 'All') {
            getRanks(dispatch, filter);
        } else {
            getRanks(dispatch, filter, selectedGroupDetails.emails);
        }
        // eslint-disable-next-line
    }, [selectedGroupDetails])
    // const handleSelectedGroups = (selectedOption) => {
    //     if (selectedOption.value === 'All') {
    //         getRanks(dispatch, filter);
    //     } else {
    //         getRanks(dispatch, filter, selectedOption.emails);
    //     }
    // }
    const handleDateSearch = () => {
        if (selectedGroupDetails.value === 'All') {
            getRanks(dispatch, filter);
        } else {
            getRanks(dispatch, filter, selectedGroupDetails.emails);
        }
        handleClose();
    }

    const child = document.getElementById('childElement');
    if (child) {
        child.remove();
    }

    const handleOpen = (index, action, sno) => {
        setOpen({ status: true, index: index, action: action });
        var info = {}; 
        if(action === "ranksInfo") {
            info = {
                filter: filter,
                userId: state.ranks[index].rank_award_to,
                userName: state.ranks[index].fullname,
                action: action,
            }
        } else {
         info = {
                id: state.ranks[index].rank_award_to,
                employeeId: state.ranks[index].rank_award_by,
                name: state.ranks[index].fullname,
                action: action,
                email: state.ranks[index].email,
                userName: state.ranks[index].fullname,
                sno: sno
            }
        }
        setInfo(info)
    };
    const handleChatClose = () => {
        setOpen({ status: false, index: 0 });
        getAllMessages(dispatch, getUser.user);
    };
    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
        getAllMessages(dispatch, getUser.user);
    }

    const getMessagesCount = (data, msg, emp) => {
        const msgCount = msg.filter(message => message.sendBy === emp.rank_award_to).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })

        return (
            <i>
                {
                    msgCount.length > 0 ?
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px' }}
                                onClick={(event) => handleOpen(data, "UserChat", msgCount)} />
                            <span style={{ color: 'red', fontWeight: "bold", marginLeft: "-2px" }}>{msgCount.length > 9 ? "9+" : msgCount.length}</span>
                        </div>
                        :
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px' }}
                                onClick={(event) => handleOpen(data, "UserChat", msgCount)} />
                        </div>
                }
            </i>
        )
    }


    return (
        <div className="container-scroller">
            <Header />
            <div className="container-fluid page-body-wrapper">
                <AdminSideBar />
                <div className="main-panel">
                    <div className="mt-2 p-2">
                        <div className="d-flex justify-content-between row">
                            <div className="d-flex" >
                                {
                                    <button className="btn btn-success p-2 border" style={{ marginLeft: 10, height: 'fit-content' }} onClick={handleShow}>{SELECT_DATE_RANGE}</button>
                                }
                                {
                                    <div style={{ marginTop: 5 }}>
                                        <text style={{ fontSize: 14, fontWeight: 'bold', paddingLeft: 10 }} className=' text-success'> {DATE}: {(moment(filter[0].startDate).format('ll'))} - {(moment(filter[0].endDate).format('ll'))} </text>
                                    </div>
                                }
                            </div>
                            <div className="d-flex col-lg-4 col-sm-6" >
                                <label className='mr-2 mb-0 mt-2' htmlFor='groupSelect'>Group:</label>
                                <Select
                                    className="form-control"
                                    placeholder="Select Group"
                                    options={[
                                        { 'value': 'All', 'label': 'All' },
                                        ...(groupDetails?.map(group => {
                                            return {
                                                'value': group.id,
                                                'label': `${group.group_name}-[${removeDuplicatesFromString(group.members_name)}]`,
                                                'emails': group.members_email
                                            };
                                        }))
                                    ]}
                                    onChange={(selectedOption) => {
                                        // handleSelectedGroups(selectedOption, dispatch)
                                        setSelectedGroupDetails(selectedOption)
                                    }}
                                />
                            </div>
                            {/* <CSVLink className="downloadbtn btn btn-primary " filename={`AllReports-${new Date().getFullYear()}.csv`} data={csvData}>Export to CSV</CSVLink> */}
                            {/* <div className='mt-1 pt' style={{ display: 'flex', alignItems: 'center', marginLeft: isMobile ? '0' : '70%' }}>
                                            <label className='mr-2 mb-0' htmlFor='searchInput'>Search:</label>
                                            <input type="text" className="form-control" style={{ height: '30px', width: '200px' }} aria-label="Search" aria-describedby="basic-addon2" onChange={handleSearch} value={searchQuery} />
                                        </div> */}
                        </div>
                        <div className="col-12 " >
                            <Modal style={{ textAlign: 'center' }} size="xl" show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body>
                                    <DateRangePicker
                                        onChange={item => setFilter([item.selection])}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        ranges={filter}
                                        direction="horizontal"
                                    />
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-success" type="button" onClick={() => {
                                        handleDateSearch();
                                    }}>Search</button>
                                </Modal.Footer>
                            </Modal>

                        </div>
                        <button className="ml-1 mt-2 badge badge-pill badge-success text-center" onClick={() => history.push(`/admin/ranks/new`)}>Award Ranking`s</button>
                        {state.isLoading ? <RootLoader /> :
                            <div className="col-lg-12 mt-1 grid-margin stretch-card">
                                <div className="card p-2 mt-2">
                                    {/* <div className="d-flex justify-content-between align-items-center mb-2">
                                    <h4>Awarding Rankings</h4>
                                </div> */}
                                    <table
                                        id="example" className="table table-striped table-bordered rwd-table tableColor"
                                        search="true">
                                        <thead style={{ backgroundColor: '#F4FAF7' }}>
                                            <tr>
                                                <th>UserName</th>
                                                <th>Communication</th>
                                                <th>Leadership</th>
                                                <th>team</th>
                                                <th>Ownership</th>
                                                <th>Execution</th>
                                                <th>Percentage</th>
                                                <th>Chat</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state.ranks.length > 0 ? state.ranks.map((rank, i) => (
                                                <tr key={i} style={{ height: '10px' }}>
                                                    <td onClick={(event) => handleOpen(i, "ranksInfo")}>{rank.fullname}</td>
                                                    <td>{rank.communication}</td>
                                                    <td>{rank.leadership}</td>
                                                    <td>{rank.team}</td>
                                                    <td>{rank.ownership}</td>
                                                    <td>{rank.execution}</td>
                                                    <td>{rank.percentage} %</td>
                                                    {/* <td>Chat</td> */}
                                                    <td>
                                                        <button type="button" style={{ backgroundColor: 'transparent', border: "0", padding: "0", marginLeft: '15px' }} >
                                                            {
                                                                getMessagesCount(i, state.allMessages, rank)
                                                            }
                                                        </button>
                                                    </td>
                                                </tr>
                                            )) : null}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        }
                        {
                            open.action === "UserChat" ? <UserChat open={open.status} handleClose={handleChatClose} data={info} handleModalClose={handleModalClose}
                            /> : null
                        }
                        {
                            open.action === "ranksInfo" ? <UserRanksInfo open={open.status} data={info} handleClose={handleClose} handleModalClose={handleModalClose}
                            /> : null
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}