//(READ)
import {  employees, isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

export async function getAccountUsers(dispatch, account_id, role = null) {

  dispatch(isLoading());

  try {
    var response = await API.post("agile_squads.php", {
       "userType": role,
       "action": 'account_users',
       accountId: account_id,
    }, {}, false);
    if (response.status === 'True') {
      dispatch(employees(response.data))
    }
    else {
      dispatch(employees([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}