import { isLoaded } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { addCommentUserStory } from "../../Modules/Services/addCommentUserStory";
import { logEntry } from "../../../Common/logEntry";

//Add userstory to sprint
export async function addSprint(activities=null,sprint_status, assignedto, currentDate, sprintTargetDate, targetDate, taskId, state, dispatch, getUser, handleClose, device_id, assign_to, sprintDesc, title, storyPoints, MODULE, MAINTASK, KANBAN, projectId) {
 
 if (!state.sprintSelected.value) {

    Alert('warning',`Please Select ${MODULE}`)
   }

  //console.log('STATE:',state);
  else if (assignedto === null) {
    Alert(`warning', 'Assign squad member to ${MAINTASK}`);
  }
  else if (targetDate < currentDate && currentDate && targetDate !== '') {
    Alert('warning', 'Select valid date');

  }
  else if (targetDate > sprintTargetDate && currentDate && targetDate !== '') {
    Alert('warning', `Target Date should not exceed ${MODULE} target date`);
  }
  else if (sprint_status === 'commited') {
    Alert('warning', `You cannot add more ${MAINTASK} to this ${MODULE} as it is committed. If you would still like to add more ${MAINTASK} then change the status of the ${MODULE} to uncommitted`);
  }

  else if (state.sprintSelected !== "") {
  
    //using filter method
    const temp1 = state.sprintSelected.value;
    const temp2 = state.sprints;
    //console.log('temp2:',temp2);
    const temp3 = temp2.filter((sprint) => {
      return sprint.moduleId === temp1;

    })
    //console.log('temp3 is :',temp3)

    const message = `This ${MAINTASK} added in ${MODULE} ${(getUser.corp).substring(0, 3).toUpperCase()} - ${temp3[0]?.moduleDesc}`
    //console.log('sprint details:',sprintDetails[0].label);
    try {
      const start_time=Date.now();
      var response = await API.post("getUpdateSprint.php", {
        crop: getUser.corp,
        action: "sprint_update",
        sprintId: state.sprintSelected.value,
        updatedBy: getUser.empId,
        userStoryId: taskId,
        "projectId": projectId || getUser.projectId,
        targetDate: (targetDate === '') ? sprintTargetDate : targetDate,
        device_id: device_id === null ? state.device_id : device_id,
        sprintDesc: sprintDesc,
        assign_to: assign_to === null ? state.userSelected : assign_to,
        emp_id: getUser.empId,
        title: title,
        storyPoints: storyPoints,
      }, {}, false);
      const end_time=Date.now(); 
      if (response.status === 'True') {
        //  getGroupTaskInfo(currentDate, sprintTargetDate, targetDate, taskId, state, dispatch, getUser, handleClose, device_id, assign_to, sprintDesc, title, storyPoints, MODULE, MAINTASK,)
        addCommentUserStory(dispatch, getUser, taskId, message, "11");
        activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
          sub_activity:"Add To Timeline",
          response_time : (end_time-start_time), task_meta_data:{story_id:taskId}
  
        },getUser)
        Alert("success", "UPDATED");
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  } else {
    Alert("warning", "Something went wrong")
  }
}