import Alert from "../../Alert";

import API from "../../Network/API";
import {isLoaded, isLoading,  roadBlocks } from "../action";

export async function getRoadBlocks(dispatch, getUser, empId, role) {
  dispatch(isLoading());
  try {
    var response = await API.post("manage_roadblocks.php", {
      "crop": getUser.corp,
      "empId":empId,
      "action": "userRoadblocks",
      "projectId":getUser.projectId
    }, {}, false);
    if (response.status === 'True') {
      dispatch(roadBlocks(response.data))
    }
    else {
      dispatch(roadBlocks([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}