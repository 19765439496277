import Alert from "../../Alert";

import API from "../../Network/API";
import { isLoaded, isLoading,  projects } from "../action";


export async function getProjects(dispatch, getUser, empId, role) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_epics.php", {
      "corp": getUser.corp,
      empId: empId,
      // userType: role, //Based For User level only showing 
      action: 'approved',
      projectId:getUser.projectId
    }, {}, false);
    if (response.status === 'True') {
      dispatch(projects(response.data))
    }
    else {
      dispatch(projects([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}