import React, { useEffect, useReducer, useState } from 'react';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { getAccounts } from './Services/getAccounts';
import { accountReducer, initialState } from './accountReducer';
import AdminTopNav from '../Utility/TopNav';
import AdminSideBar from '../Utility/SideNav';
import UserChat from '../../Common/SquadChat';
import Switch from "react-switch";
// import { activeEmployee, deActiveEmployee } from '../Team/network'
import AddAccount from './addAccount';
import EditAccount from './editAccount';
import { useWebProperties } from '../../Common/webProperties';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Link } from '@material-ui/core';
import API from '../../Common/Network/API';
import { getAccountFeature } from './Services/getAccountFeatues';


export default function AgileAccount() {
    const history = useHistory()
    const getUser = useSelector(state => state.auth)
    const [state, dispatch] = useReducer(accountReducer, initialState)
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [info, setInfo] = useState();
    const { CLIENT_NAME, ACCOUNTS } = useWebProperties();

    useEffect(() => {
        getAccountFeature(dispatch, getUser.user);
        getAccounts(dispatch, getUser.user);
        // eslint-disable-next-line
    }, [])
    const empSorted = state.employees;

    useEffect(() => {
        if (empSorted.length > 0) {
            $(document).ready(function () {
                window.$('#example').DataTable({
                    destroy: true,
                    retrieve: true,
                    fixedHeader: true,
                    "ordering": false
                })
            })
        }
    }, [empSorted, state.accountFeatures])

    // Squads list can be enable or disable on user convience
    const isProjectsEnable = async (feature_id, account_id, permission_status) => {
        try {
            var response = await API.post("accounts.php", {
                feature_id: feature_id,
                account_id: account_id,
                action: "update_account_permissions",
                permission_status: permission_status === '1' ? '0' : '1',
            }, {}, false);
            if (response.status === 'True') {
                getAccounts(dispatch, getUser.user);
            }
        } catch (error) {
            // Alert('error',error.message)
        }
    }

    const handleOpen = (index, action, sno) => {
        if (action === "Add") {
            setOpen({ status: true, action: action });
        } else {
            setOpen({ status: true, index: index, action: action });
            var info = {
                id: empSorted[index].id,
                employeeId: empSorted[index].id,
                name: empSorted[index].name,
                action: action,
                email: empSorted[index].email,
                mobile: empSorted[index].mobileNumber,
                userName: empSorted[index].userName,
                clientname: empSorted[index].clientname,
                no_of_projects: empSorted[index].projectscount,
                no_of_users: empSorted[index].userscount,
                workingStatus: empSorted[index].workingStatus,
            }
            setInfo(info)
        }
    };
    const handleClose = () => {
        setOpen({ status: false, index: 0 });
        getAccounts(dispatch, getUser.user);
    };
    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
    }


    return (
        <div className="container-scroller">
            <AdminTopNav />
            <div className="container-fluid page-body-wrapper">
                <AdminSideBar />
                <div className="main-panel">
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="justify-content-between row">
                                        <h4 className="card-title ml-2">{ACCOUNTS}</h4>
                                        <div className="d-flex justify-content-end mb-0">
                                            <button onClick={() => handleOpen("", "Add")} style={{ backgroundColor: 'transparent', border: '0' }} type="button" > <img src="images/common/adduser.svg" alt="logo" style={{ width: '25px', height: '25px' }} /><span className="m-1">Add Account</span></button>
                                            {
                                                open.action === "Add" ? <AddAccount open={open.status} handleClose={handleClose} handleModalClose={handleModalClose}
                                                /> : null
                                            }
                                        </div>
                                    </div>
                                    {state.employees.length !== 0 && state.accountFeatures.length > 0?
                                        <div className="table-responsive ">
                                            <table
                                                search="true"
                                                id="example" className="table table-striped table-bordered rwd-table" style={{ width: '100%', overflow: 'auto' }}
                                                data-pagination="true"
                                            >
                                                <thead style={{ backgroundColor: '#F4FAF7' }}>
                                                    <tr>
                                                        <th style={{ textTransform: "capitalize" }} >{CLIENT_NAME}</th>
                                                        <th>Category</th>
                                                        {state.accountFeatures && state.accountFeatures.map(({ name }) => {
                                                            return <th>{name}</th>
                                                        })}
                                                        {/* <th>{USERNAME}</th>
                                                        <th>{EMAIL}</th>
                                                        <th>{CONTACT}</th>
                                                        <th>No of Projects</th>
                                                        <th>{NO_OF_USERS}</th>
                                                        <th style={{ width: '20px' }}>Edit</th> */}
                                                        {/* {(getUser.user.role !== "Contributor") ? <th style={{ width: '20px' }}>Delete</th> : null} */}
                                                        {/* <th style={{ width: '20px' }}>{CHAT}</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        empSorted.length !== 0 ? empSorted.map((employee, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    {/* <td data-th={CLIENT_NAME} style={{ textTransform: "capitalize", fontWeight: '600' }}>{employee.clientname.toUpperCase()}</td> */}
                                                                    <td><Link onClick={() => history.push(`/accounts/profile/${employee.accountId}`)} style={{ color: 'blue', fontWeight: 'bold', textTransform: "capitalize" }}>{employee.clientname.toUpperCase()}</Link></td>
                                                                    <td style={{ fontWeight: 'bold', textTransform: "capitalize" }}>{employee.category_name}</td>
                                                                    {employee.feature_permissions.map((permissions) => {
                                                                        return <td style={{ textAlign: 'center', fontWeight: '600' }}>
                                                                            <Switch
                                                                                checked={(permissions.permission_status === "1") ? true : false}
                                                                                onChange={() => isProjectsEnable(permissions.feature_id, permissions.account_id, permissions.permission_status)}
                                                                                handleDiameter={25}
                                                                                offColor="#CD5C5C"
                                                                                onColor="#008000"
                                                                                offHandleColor="#fff"
                                                                                onHandleColor="#fff"
                                                                                height={30}
                                                                                width={100}
                                                                                borderRadius={6}
                                                                                activeBoxShadow="0px 0px 1px 2px #fffc35"
                                                                                uncheckedIcon={
                                                                                    <div style={{ paddingTop: 9, marginLeft: -15, width: 61 }}>
                                                                                        <b style={{ color: 'white' }} >Disabled</b>
                                                                                    </div>
                                                                                }
                                                                                checkedIcon={
                                                                                    <div style={{ paddingTop: 9, marginLeft: 15, width: 60 }}>
                                                                                        <b style={{ color: 'white' }}>Enabled</b>
                                                                                    </div>
                                                                                }
                                                                                className="react-switch"
                                                                                id="small-radius-switch"
                                                                            />
                                                                        </td>
                                                                    })}
                                                                    {/* <td data-th={USERNAME}style={{ textAlign: 'center',width:110 }}>{name}</td>                                                                     
                                                                    <td data-th={EMAIL}>{employee.email}</td>
                                                                    <td data-th={CONTACT} style={{ textAlign: 'start' }}>{employee.mobileNumber}</td>
                                                                    <td data-th={NO_OF_PROJECTS} >{employee.projectscount}</td>
                                                                    <td data-th={NO_OF_USERS}>{employee.userscount}</td> */}
                                                                    {/* <td data-th="Edit"style={{ textAlign: 'start' }}><span onClick={() => handleOpen(index, "Edit")}><img src="images/common/edit.svg" style={{ width: '18px', height: '18px' }} alt="" /></span></td> */}
                                                                    {/* <td style={{ textAlign: 'start' }}><span onClick={() => history.push(`/accounts/${employee.accountId}`)}><img src="images/common/delete.svg" style={{ width: '18px', height: '18px' }} alt="" /></span></td> */}
                                                                    {/* <td style={{width:'8px'}}><button style={{ backgroundColor: 'transparent',border:"0", width:'5px',padding:"0"}} type="button" > <img src="images/common/chat.svg" alt="logo" style={{ width: '20px', height: '20px' }} onClick={(event) => handleOpen(index, "UserChat")} /></button></td> */}
                                                                    {/* <td style={{ width: '8px' }}>
                                                                        <button type="button" style={{ backgroundColor: 'transparent', border: "0", width: '5px', padding: "0", marginLeft: '15px' }} >
                                                                            {
                                                                                getMessagesCount(index, state.allMessages, employee)
                                                                            }
                                                                        </button>
                                                                    </td> */}
                                                                </tr>
                                                            )
                                                        }) : null}
                                                </tbody>
                                            </table>
                                        </div>
                                        : null}
                                    {/* {
                                        open.action === "Delete" ? <DeleteEmployee open={open.status} handleClose={handleClose} data={info} handleModalClose={handleModalClose}
                                        /> : null
                                    }*/}
                                    {
                                        open.action === "Edit" ? <EditAccount open={open.status} handleClose={handleClose} data={info} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "UserChat" ? <UserChat open={open.status} handleClose={handleClose} data={info} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}