import { IS_LOADED, IS_LOADING, MODULES, MODULE_TITLE, INVOVLED_EMPLOYEES, TO_DO, DOING, ROADBLOCK, DONE, TASKS_COUNT, TASK_STATUS, TASK_DESCRIPTION, USER_ROADBLOCKS, ALLMESSAGES, ACTIVE_SPRINT, SELECT_SPRINT, ACTIVE_USER_STORY, TO_DO_FILTER, DOING_FILTER, DONE_FILTER, ROADBLOCK_FILTER, WORKING_HOURS, GROUP_TO_DO, GROUP_DOING, GROUP_ROADBLOCK, GROUP_DONE, KANBAN_TO_DO, KANBAN_DOING, KANBAN_ROADBLOCK, KANBAN_ROADBLOCK_FILTER, KANBAN_DONE_FILTER, KANBAN_DOING_FILTER, KANBAN_TO_DO_FILTER, KANBAN_DONE } from "./actions";

const initialValue = {
    value: "",
    errorStatus: false,
    errorMessage: "Please Enter valid ",
};
export const initialState = {
    modules: [],
    activeSprint: {},
    moduleTitle: initialValue,
    involvedEmployees: [],
    todo: [],
    doing: [],
    workingHours: [],
    workingDays: [],
    done: [],
    todoFilter: [],
    doingFilter: [],
    doneFilter: [],
    roadblockFilter: [],
    userRoadblock: [],
    tasksCount: {},
    taskStatus: 0,
    allMessages: [],
    taskDescription: "",
    sprintSelected: initialValue,
    activeUserStory: [{ openStatus: false }],
    groupTodo: [],
    groupDoing: [],
    groupRoadblock: [],
    groupDone: [],
    kanban_todo: [],
    kanban_doing: [],
    kanban_done: [],
    kanban_roadblock: [],
    kanban_todo_filter: [],
    kanban_doing_filter: [],
    kanban_done_filter: [],
    kanban_roadblock_filter: [],
};

export function moduleReducer(state = initialState, action) {
    switch (action.type) {
        case MODULES:
            return {
                ...state,
                modules: action.payload
            };
        case ACTIVE_USER_STORY:
            return {
                ...state,
                activeUserStory: action.payload
            };
        case INVOVLED_EMPLOYEES:
            return {
                ...state,
                involvedEmployees: action.payload
            };
        case MODULE_TITLE:
            return {
                ...state,
                moduleTitle: {
                    ...state.moduleTitle,
                    value: action.payload,
                    errorStatus: false,
                },
            };
        case SELECT_SPRINT:
            return {
                ...state,
                sprintSelected: action.payload
            };
        case ACTIVE_SPRINT:
            return {
                ...state,
                activeSprint: action.payload
            };
        case TO_DO:
            return {
                ...state,
                todo: action.payload
            };
        case ALLMESSAGES:
            // console.log(action.payload)
            return {
                ...state,
                allMessages: action.payload
            };
        case WORKING_HOURS:
            return {
                ...state,
                workingHours: action.payload
            };
        case DOING:
            return {
                ...state,
                doing: action.payload
            };
        case DONE:
            return {
                ...state,
                done: action.payload
            };
        case ROADBLOCK:
            return {
                ...state,
                roadblock: action.payload
            };
        case TO_DO_FILTER:
            return {
                ...state,
                todoFilter: action.payload
            };
        case DOING_FILTER:
            return {
                ...state,
                doingFilter: action.payload
            };
        case DONE_FILTER:
            return {
                ...state,
                doneFilter: action.payload
            };
        case ROADBLOCK_FILTER:
            return {
                ...state,
                roadblockFilter: action.payload
            };
        case TASKS_COUNT:
            return {
                ...state,
                tasksCount: action.payload
            };
        case TASK_STATUS:
            return {
                ...state,
                taskStatus: action.payload
            };
        case TASK_DESCRIPTION:
            return {
                ...state,
                taskDescription: action.payload
            };
        case USER_ROADBLOCKS:
            return {
                ...state,
                userRoadblock: action.payload
            };
        case IS_LOADING:
            return { ...state, isLoading: true };
        case IS_LOADED:
            return {
                ...state,
                isLoading: false,
            };
        case GROUP_TO_DO:
            return {
                ...state,
                groupTodo: action.payload
            };
        case GROUP_DOING:
            return {
                ...state,
                groupDoing: action.payload
            };
        case GROUP_ROADBLOCK:
            return {
                ...state,
                groupRoadblock: action.payload
            };
        case GROUP_DONE:
            return {
                ...state,
                groupDone: action.payload
            };
        case KANBAN_TO_DO:
            return {
                ...state,
                kanban_todo: action.payload
            };
        case KANBAN_DOING:
            return {
                ...state,
                kanban_doing: action.payload
            };
        case KANBAN_DONE:
            return {
                ...state,
                kanban_done: action.payload
            };
        case KANBAN_ROADBLOCK:
            return {
                ...state,
                kanban_roadblock: action.payload
            };
        case KANBAN_TO_DO_FILTER:
            return {
                ...state,
                kanban_todo_filter: action.payload
            };
        case KANBAN_DOING_FILTER:
            return {
                ...state,
                kanban_doing_filter: action.payload
            };
        case KANBAN_DONE_FILTER:
            return {
                ...state,
                kanban_done_filter: action.payload
            };
        case KANBAN_ROADBLOCK_FILTER:
            return {
                ...state,
                kanban_roadblock_filter: action.payload
            };
        default:
            return state;
    }
}
