/*
FileName:UserModule/Team/addEmployee.js
purpose:adding squad team
Developers:Naveen Kumar Gade - NKG, Satya Sidda - SS
Created Date:
Updated Date:27/2/2021
 */
import React, { useReducer, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector } from 'react-redux';
import { empReducer, initialState } from './empReducer';
import * as actions from './actions';
import RootLoader from '../../Common/Loader/RootLoader';
import Select from 'react-select';
import { useWebProperties } from '../../Common/webProperties';
import { createEmployee } from './Services/createEmployee';
import { getSquadList } from './Services/getSquadList';




const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//For Add New Employee
export default function AddEmployee(props) {

    const classNames = useStyles();
    const getUser = useSelector(state => state.auth)
    const [state, dispatch] = useReducer(empReducer, initialState)
    const [isEditable,setIsEditable] = useState(true)
    const [isMobileEditable,setIsMobileEditable] = useState(true)
    const { CONTRIBUTOR, SCRUM_MASTER, PRODUCT_OWNER, LIMITED_ACCESS_CONTRIBUTOR, APP_NAME, NEW_SQUAD_MEMBER, SUPPORT_ROLE, SUPPORT_MANAGER, L1, L2 } = useWebProperties();
    const [, extension] = (getUser.user.userName).split('@')
    useEffect(() => {
        getSquadList(dispatch, getUser.user); 
        // eslint-disable-next-line
    }, [])
    var employee = [];

    state.squadList.map((employees) => {
        return (
            (employees.userName !== getUser.user.userName && employees.userName.includes(extension)) ? employee.push({ 'value': employees.id, 'label': employees.name, 'userName': employees.userName, 'mobileNumber': employees.mobileNumber }) : null
        );
    })

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-lg-6 col-sm-12 p-2" style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <h5 class="modal-title p-2">{NEW_SQUAD_MEMBER}</h5>
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" className="d-flex align-items-right p-2" data-dismiss="modal" onClick={props.handleModalClose}><i class="mdi mdi-close text-black"></i></button>
                                </div>
                                <div className="modal-body " style={{ height: '400px', overflow: 'scroll' }}>
                                    <div>

                                        <form >

                                            <div class="form-group required row p-4" style={{ marginTop: '-30px' }}>
                                                {/* <label for="user" className="col-form-label pt-2" style={{ width: '150px' }}>Select Squad Member</label> */}
                                                <Select
                                                    className="form-control"
                                                    placeholder="Search ...."
                                                    value={employee.value}
                                                    isClearable={true}
                                                    onChange={(selectedOption,triggeredAction) => {
                                                        if (triggeredAction.action==='clear') {
                                                            dispatch(actions.empId(''))
                                                            dispatch(actions.fullName(''))
                                                            dispatch(actions.email(''))
                                                            dispatch(actions.mobile(''))
                                                            dispatch(actions.roleSelected(''))
                                                            setIsEditable(true)
                                                        }
                                                        else{
                                                        dispatch(actions.empId(selectedOption.value))
                                                        dispatch(actions.fullName(selectedOption.label))
                                                        dispatch(actions.email(selectedOption.userName))
                                                        dispatch(actions.mobile(selectedOption.mobileNumber))
                                                        dispatch(actions.roleSelected(selectedOption.value))
                                                        setIsEditable(false)
                                                        if(state.mobile.errorStatus) setIsMobileEditable(true)
                                                        }
                                                    }}
                                                    options={employee}
                                                />

                                                {/* <label for="recipient-ID" class="col-form-label pt-4 control-label'" style={{ width: "80px" }}>Emp ID<span style={{ color: "red" }} >*</span></label>
                                                <input type="text" required="required" class="form-control col-10 ml-2 " id="recipient-empid" name="Emp ID" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.empId.value}
                                                    onChange={(event) => dispatch(actions.empId(event.target.value))} /> */}
                                                {/* <div class="form-group"> */}
                                                <label for="recipient-name" class="col-form-label pt-4" style={{ width: "100px" }}>Full name<span style={{ color: "red" }} >*</span></label>
                                                <input type="text" class="form-control col-10 ml-2 " id="recipient-fullName" name="Name" style={{ backgroundColor: isEditable ? 'transparent' : '#e9ecef', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.fullName.value}
                                                    onChange={(event) => dispatch(actions.fullName(event.target.value))}
                                                    disabled = {!isEditable}
                                                    />
                                                <span class=" col-10 ml-2 " style={{ color: "red", fontSize: '12px', paddingLeft: "15%" }}>{state.fullName.errorStatus ? state.fullName.errormessage : ""}</span>
                                            </div>
                                            <div class="form-group required row p-4" style={{ marginTop: "-70px" }}>
                                                <label for="recipient-email" class="col-form-label pt-4" style={{ width: "100px" }}>Email<span style={{ color: "red" }} >*</span></label>
                                                <input type="text" class="form-control col-10 ml-2 " id="recipient-email" name="Email" style={{ backgroundColor: isEditable ? 'transparent' : '#e9ecef', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.email.value}
                                                    onChange={(event) => dispatch(actions.email(event.target.value))}
                                                    disabled = {!isEditable}
                                                    />
                                                <span class=" col-10 ml-2 " style={{ color: "red", fontSize: '12px', paddingLeft: "15%" }}>{state.email.errorStatus ? state.email.errormessage : ""}</span>
                                            </div>
                                            <div class="form-group required row p-4" style={{ marginTop: "-70px" }}>
                                                <label for="recipient-uname" class="col-form-label pt-4" style={{ width: "100px" }}>Phone number</label>
                                                 <input class="form-control col-10 ml-2" id="recipient-mobile" style={{ backgroundColor: isMobileEditable ? 'transparent' : '#e9ecef', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }} type="tel" minLength={10} maxLength={10} pattern="^\d{10}$" required 
                                                  value={state.mobile.value}
                                                  onInput={(event) => {
                                                    const inputValue = event.target.value.replace(/\D/g, '');
                                                    dispatch(actions.mobile(inputValue));
                                                }}
                                                disabled = {!isMobileEditable}                                                 
                                                 />
                                                 <span class=" col-10 ml-2 " style={{ color: "red", fontSize: '12px', paddingLeft: "15%" }}>{state.mobile.errorStatus ? state.mobile.errormessage : ""}</span>
                                            </div>

                                            <div class="form-group required row p-4" style={{ marginTop: "-70px" }}>
                                                <label for="recipient-name" class="col-form-label pt-4" style={{ width: "100px" }}>Username<span style={{ color: "red" }} >*</span></label>
                                                <input type="text" class="form-control col-10 ml-2 " id="recipient-name" name="Username" style={{ backgroundColor: isEditable ? 'transparent' : '#e9ecef', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.email.value}
                                                    readonly />

                                            </div>


                                            <div class="form-group row pl-3" style={{ marginTop: '-30px' }}>
                                                <label for="recipient-name" class="col-form-label pt-2">Select Role :</label>
                                                <div class="form-check form-check-inline pl-2">
                                                    <input class="form-check-input" type="radio" name="userType1" id={CONTRIBUTOR} value='Contributor'
                                                        onChange={(event) => dispatch(actions.userType(event.target.value))} checked={state.userType.value === 'Contributor' || state.userType.value === 'Limited Access Contributor' ? true : false} />
                                                    <label>{CONTRIBUTOR}</label>
                                                </div>
                                                {/* checking count of scrum master *[SS]/}
                                               {/* {(state.scrumMasterCount.value) <= '0'? */}
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="userType1" id={PRODUCT_OWNER} value='Product Owner'
                                                        onChange={(event) => dispatch(actions.userType(event.target.value))} />
                                                    <label>{PRODUCT_OWNER}</label>
                                                </div>
                                                {/* :null} */}
                                                {/* checking count of product owner master *[SS]/}
                                                {/* {(state.productOwnerCount.value) <= '0'? */}

                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="userType1" id={SCRUM_MASTER} value='Scrum Master'
                                                        onChange={(event) => dispatch(actions.userType(event.target.value))} />
                                                    <label>{SCRUM_MASTER}</label>
                                                </div>

                                            </div>
                                            {state.userType.value === 'Contributor' || state.userType.value === 'Limited Access Contributor' ? <div class="form-group row pl-3" style={{ marginTop: '-30px' }}>
                                                <label for="recipient-name" class="col-form-label pt-2">{CONTRIBUTOR} Access :</label>
                                                <div class="form-check form-check-inline pl-2">
                                                    <input class="form-check-input" type="radio" name="accessType1" id={CONTRIBUTOR} value='Contributor'
                                                        onChange={(event) => dispatch(actions.userType(event.target.value))} checked={state.userType.value === 'Contributor' ? true : false} />
                                                    <label>Full Access</label>
                                                </div>

                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="accessType1" id={LIMITED_ACCESS_CONTRIBUTOR} value='Limited Access Contributor'
                                                        onChange={(event) => dispatch(actions.userType(event.target.value))} />
                                                    <label>Limited Access</label>
                                                </div>



                                            </div> : null}

                                            {state.userType.value === 'Contributor' || state.userType.value === 'Limited Access Contributor' ?
                                                <div class="form-group row pl-3" style={{ marginTop: '-30px' }}>
                                                    <label for="recipient-name" class="col-form-label pt-2">{SUPPORT_ROLE}:</label>
                                                    <div class="form-check form-check-inline pl-2">
                                                    <input class="form-check-input" type="checkbox" name="supportRole" id={L1} value='L1'
                                                            onChange={(event) => {
                                                                if (event.target.checked) {
                                                                    dispatch(actions.supportRole(event.target.value))
                                                                } else {
                                                                    dispatch(actions.supportRole(null))
                                                                }
                                                            }
                                                            }
                                                            checked={state.supportRole.value === 'L1' ? true : false}
                                                        />
                                                        <label>L1</label>
                                                    </div>

                                                    <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox" name="supportRole" id={L2} value='L2'
                                                            onChange={(event) => {
                                                                if (event.target.checked) {
                                                                    dispatch(actions.supportRole(event.target.value))
                                                                } else {
                                                                    dispatch(actions.supportRole(null))
                                                                }
                                                            }} checked={state.supportRole.value === 'L2' ? true : false} />
                                                        <label>L2</label>
                                                    </div>
                                                    <div class="form-check form-check-inline pl-2">
                                                    <input class="form-check-input" type="checkbox" name="supportRole" id={SUPPORT_MANAGER} value='Support Manager'
                                                            onChange={(event) => {
                                                                if (event.target.checked) {
                                                                    dispatch(actions.supportRole(event.target.value))
                                                                } else {
                                                                    dispatch(actions.supportRole(null))
                                                                }
                                                            }} checked={state.supportRole.value === 'Support Manager' ? true : false} />
                                                        <label>Support Manager</label>
                                                    </div>
                                                </div> : null}


                                        </form>
                                    </div>
                                </div>
                                {state.isLoading ? < RootLoader /> :
                                    <div class="modal-footer">
                                        <button type="button" name='add' class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={() => {  
                                           createEmployee(state, dispatch, getUser.user, props.handleClose, APP_NAME, SCRUM_MASTER, PRODUCT_OWNER)
                                        }} >Add</button>
                                    </div>}

                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}