// userprofile/services/getUserReports.js (READ)

import { isLoaded, isLoading, projectEfficiency } from "../actions";
import { Reports_URL } from "../../../Common/config";

export async function getUserReports(dispatch, getUser,filter) {
  dispatch(isLoading());
  
  fetch(`${Reports_URL}/getUserReports`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "user_id": getUser.empId,
        filter
      })
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
          dispatch(projectEfficiency(data))
      })
      .catch((error) => {
        console.error('Error:', error);
        dispatch(projectEfficiency([]))

      });
  dispatch(isLoaded());
}