import { isLoaded, isLoading, pendingTasks } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

//for getting recent sprint userstories
export async function getPending(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("backlogspage.php", {
      "crop": getUser.corp,
      "userType": getUser.role,
      "empId": getUser.empId,
      "action": "pending",
      "projectId": getUser.projectId
    }, {}, false);
    if (response.status === 'true') {
      dispatch(pendingTasks(response.data))
    }
    else {
      dispatch(pendingTasks([]))
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
}