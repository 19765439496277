import { isLoading, isLoaded } from "../../../Common/Actions/loading"
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import {  userRoadblock } from "../actions";

//RoadBlock List
export async function getUserRoadblocks(dispatch,getUser,subTaskId) {
    dispatch(isLoading());
    try {
      var response = await API.post("manage_roadblocks.php", {
        "crop": getUser.corp,
        action: "getting",
        subTaskId:subTaskId
      },{},false);
      if(response.status === 'True') {
        dispatch(userRoadblock(response.data))
      }
      else{
        dispatch(userRoadblock([]))
      }
    } catch (error) {
      Alert('error',error.message);
    }
    dispatch(isLoaded());
  }