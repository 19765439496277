/* 
FileName:index.js
purpose:To se all the archive data
Developers:Naveen Kumar Gade[NKG],Satya Sidda[SS]

 */
import React from 'react';
import SideBar from '../Utility/SideNav';
import TopNav from '../Utility/TopNav';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Archive1 from './Archive1';
import KanbanArchive from './kanbanArchive';
import { useWebProperties } from '../../Common/webProperties';
import { useState } from 'react';

export default function Archive() {
    const { KANBAN, MODULE, SUPPORT_ENABLE } = useWebProperties();
    const [activeTab, setActiveTab] = useState("sprint");
    const [searchTerm, setSearchTerm] = useState("");

    const handleTabSelect = (tabKey) => {
        setActiveTab(tabKey);
        setSearchTerm("");
    };

    return (
        <div className="container-scroller">
            <TopNav />
            <div className="container-fluid page-body-wrapper">
                <SideBar />
                <div className="main-panel">
                    <div className="mt-2">
                        <Tabs
                            defaultActiveKey="sprint"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            activeKey={activeTab}
                            onSelect={handleTabSelect}
                        >
                            <Tab eventKey="sprint" title={`${MODULE}s`}>
                                <Archive1  searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
                            </Tab>
                            {
                                SUPPORT_ENABLE === '1' ?

                                    <Tab eventKey="profile" title={KANBAN}>
                                        <KanbanArchive searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
                                    </Tab>
                                    :
                                    null
                            }
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>

    )
}