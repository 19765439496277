import API from "../../../Common/Network/API";
import {  maintasks,  isLoading,  isLoaded } from "../actions";
import Alert from "../../Alert";

export async function getDashboardMainTasks(dispatch,getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("getManageMaintasks.php", {
        "crop": getUser.corp,
        "empId": getUser.empId,
        "action": "pending"
      },{},false);
      if(response.status === 'true') {
        dispatch(maintasks(response.data))
      }else{
        dispatch(maintasks([]))
      }
    } catch (error) {
      Alert('error',error.message);
      dispatch(isLoaded());
    }
    dispatch(isLoaded());
  }
