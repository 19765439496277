import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { getModules } from "./getModules";

//Add New Module
export async function addModule(state, dispatch,getUser,handleClose, MODULE_ADD) {
    dispatch(isLoading());
    // console.log(id)
    // console.log(state.startDate.value,)
    if(state.date.value < state.startDate.value){
      Alert("warning", "End Date should be greater than Start Date")
    }
    else if (( state.moduleTitle.value !== "" && state.date.value !== "" && state.startDate.value !== ""  && !(state.moduleTitle.errorStatus) )) {
        try {
            const data = {
              module_Name: state.moduleTitle.value,
              empId: getUser.empId, //Async
              action: "add",
              targetDate: state.date.value,
              startDate:state.startDate.value,
              crop: getUser.corp,
              projectId: getUser.projectId
            };
            const response = await API.post("manage_sprints.php", data, {}, false);
            if (response.status === 'True') {
               dispatch(getModules(dispatch,getUser))
               Alert("success", MODULE_ADD);
            }
        } catch (error) {
            Alert("error", error.message);
        }
        handleClose()
    } else {
        Alert("warning", "please fill the required fields")
    }
    dispatch(isLoaded());
}