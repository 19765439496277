import {
    isLoaded,
    isLoading,
  } from "../actions";
import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";
import moment from "moment";
import { getModules } from "./getModules";

export async function createDefaultModule(dispatch, getUser, MODULE_ADD, MODULE) {
    dispatch(isLoading());
    try {
      const data = {
        // module_Name:`Default: ${moment().add(1, 'months').format('MMMM')}`,
        module_Name: `Default ${typeof MODULE !== 'undefined' ? MODULE : "Sprint"}` ,
        empId: getUser.empId, //Async
        action: "addDefaultSprint",
        targetDate: moment().endOf('month').format('YYYY-MM-DD'),
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        corp: getUser.corp,
        projectId: getUser.projectId
      };
      const response = await API.post("manage_sprints.php", data, {}, false);
      if (response.status === 'True') {
        Alert("success", MODULE_ADD);
        dispatch(getModules(dispatch, getUser, MODULE_ADD, MODULE))
        window.location.reload(true)
      }else{
        Alert("success", MODULE_ADD);
        dispatch(getModules(dispatch, getUser, MODULE_ADD, MODULE))
        window.location.reload(true)
      }
    } catch (error) {
      Alert("error", error.message);
    }
    dispatch(isLoaded());
  }