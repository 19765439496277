import React, { useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector } from 'react-redux';
import { moduleReducer, initialState } from "./moduleReducer";
import { getSubStringId } from "../../Common/SubStringConvert";
import { useWebProperties } from '../../Common/webProperties';
import { isMobile } from 'react-device-detect';
import API from '../../Common/Network/API';
import { updateUserStory } from './Services/updateUserStory';
import { updateUserStoryToKanban } from "./Services/updateUserStoryToKanban";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//For Add New Project
export default function CompleteReason(props) {

    const getUser = useSelector(state => state.auth)
    const [taskData, setTaskData] = useState([])
    const [taskDescription, setTaskDescription] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const { MAINTASK, TASKS_WARNING, ROADBLOCK_WARNING } = useWebProperties();
    // eslint-disable-next-line
    const [state, dispatch] = useReducer(moduleReducer, initialState);
    const classNames = useStyles();
    const [loader, setLoader] = useState(false)
    
    const activities = useSelector(state => state.landingReducer.userActivities)


    useEffect(() => {
        getTaskInfoWithId()
        // eslint-disable-next-line
    }, [])

    const getTaskInfoWithId = async () => {
        try {
            const response = await API.post(
                "getUpdateSprint.php",
                {
                    action: "getTaskInfoWithId",
                    story_us_id: props.data.taskId,
                    crop: getUser.corp,
                },
                {},
                false
            );
            if (response.status === "True") {
                setTaskData(response.data)
            } else {
                setTaskData([])
            }
        } catch (error) {
            console.log("error", error.message);
        }
    }

    const completeTask = () => {
        setLoader(true)
        if (taskDescription === null) {
            setErrorMessage(`Please enter complete reason`)
            setLoader(false)
        } else {
            setErrorMessage(null)
            let message = `${taskDescription}, ${MAINTASK} is completed`;
            if (props.data.board === 'kanbanBoard') {
                updateUserStoryToKanban(dispatch, getUser.user, props.data.taskId, props.data.sprintId, props.handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING,props.data.activities);
            } else {
                updateUserStory(dispatch, getUser.user, props.data.taskId, props.data.sprintId, props.handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING,activities);
            }


        }
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className={isMobile ? `modal-content p-2` : `modal-content col-5 p-2`} style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    {
                                        taskData.length > 0 &&
                                        <h5 class="modal-title p-2">{getSubStringId(taskData[0]?.project_name, 2)}{'-'}
                                            {taskData[0]?.main_task_id !== null && taskData[0]?.main_task_id !== "0" ? `${taskData[0]?.main_task_id}-` : ''}
                                            {taskData[0]?.us_id}-{taskData[0]?.story_title}</h5>
                                    }
                                </div>
                                <div className="modal-body">
                                    <label>Complete Reason</label>
                                    <textarea className='form-control' onChange={(event) => setTaskDescription(event.target.value)} />
                                    {errorMessage !== null && <label style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</label>}
                                </div>
                                <div class="modal-footer justify-space-between">
                                    <button type="button" class="btn btn-outline-danger" onClick={props.handleModalClose} style={{ borderRadius: '20px' }}>Cancel</button>

                                    <button disabled={loader} type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={() => {
                                        completeTask()
                                    }}  >Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}
