// // userprofile/services/getUserReports.js (READ)

// import { isLoaded, isLoading, ranks } from "../actions";
// import { Reports_URL } from "../../../Common/config";

// export async function getUserRanks(dispatch, getUser,filter) {
//   dispatch(isLoading());
// console.log("Ranks API");
//   fetch(`${Reports_URL}/ranks/getRanks/${getUser.empId}`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       // body: JSON.stringify({
//       //   "user_id": getUser.empId,
//       //   filter
//       // })
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         return response.json();
//       })
//       .then((data) => {
//           dispatch(ranks(data))
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//         dispatch(ranks([]))

//       });
//   dispatch(isLoaded());
// }


//(READ)
import { ranks, isLoadingRanks, isLoadedRanks } from "../actions";
import Alert from "../../../Common/Alert";
import ReportsAPI from "../../../Common/AgileNetwork/ReportsAPI";

export async function getUserRanks(dispatch, getUser, dateFilter) {
    dispatch(isLoadingRanks());
    try {
        var response = await ReportsAPI.post(`/ranks/getRanks`, { emp_id: getUser.empId, filter: dateFilter }, {}, false);
        if (response.length > 0) {
            dispatch(ranks(response))
        } else {
            dispatch(ranks([]))
        }
    } catch (error) {
        dispatch(ranks([]))
        Alert('error', error.message);
    }
    dispatch(isLoadedRanks());
}