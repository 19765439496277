import { allMessages} from "../actions";
import Alert from "../../../../Common/Alert";
import API from "../../../../Common/Network/API";

export async function getAllMessages(dispatch, getUser, admin) {
    try {
      var response = await API.post("squad_chat.php", {
        action: "get_all_messages",
        sendBy: getUser.empId,
      }, {}, false);
      if (response.status === 'True') {
        dispatch(allMessages(response.data));
      } else {
        dispatch(allMessages([]));
      }
    } catch (error) {
      Alert('error', error.message)
    }
  }