import moment from "moment";

export default function convertUTCtoLocalTime(time) {
    if (!time) {
        return '';
    }

    // Local time zone (system timezone)
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    // Convert the input UTC time string to a Moment.js object in UTC timezone
    const utcMoment = moment.utc(time);

    // Convert the UTC time to the local time zone
    const localTime = utcMoment.clone().tz(timeZone);

    // Format the local time as desired (MM/DD/YYYY hh:mm:ss A z)
    return localTime.format("MM/DD/YYYY hh:mm:ss A z");
}