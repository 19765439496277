import { RANKS, USERS, USER_RANKS, IS_LOADING, IS_LOADED, ALLMESSAGES } from "./actions";


export const initialState = {
    users: [],
    ranks: [],
    allMessages: [],
    userRanks: [],
    isLoading: false,
}

export function rankReducer(state = initialState, action) {
    switch (action.type) {
        case RANKS:
            return {
                ...state,
                ranks: action.payload
            };
        case USERS:
            return {
                ...state,
                users: action.payload
            };
        case USER_RANKS:
            return {
                ...state,
                userRanks: action.payload
            };
        case ALLMESSAGES:
            return {
                ...state,
                allMessages: action.payload
            };
        case IS_LOADING:
            return { ...state, isLoading: true };
        case IS_LOADED:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}