import { isLoaded, isLoading, involvedEmployees } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";


//for getting involved scrum team in active sprint
export async function getInvolvedEmployees(dispatch, getUser, moduleId) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_sprints.php", {
      "crop": getUser.corp,
      "moduleId": moduleId,
      "action": "get_involved_employees",
      "projectId": getUser.projectId
    }, {}, false);
    if (response.status === 'True') {
      dispatch(involvedEmployees(response.data))
    }
    else {
      dispatch(involvedEmployees([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}