////common/ChatMainTask/Services/getMessages (READ)
import { isLoaded, isLoading } from "../../Actions/loading";
import Alert from "../../Alert";
import API from "../../Network/API";
import {  taskComments } from "../actions";

export async function getMessages(dispatch, getUser, data) {
  const project_name = data.project_name ? data.project_name : getUser.corp;
  dispatch(isLoading());
  try {
    var response = await API.post("user_story_chat.php", {
      corp_code: project_name,
      action: "getmessages",
      groupId: `${data.id}-${data.subtask_id}`
    }, {}, false);
    if (response.status === 'True') {
      dispatch(taskComments(response.data));
    } else {
      dispatch(taskComments([]));
    }
  } catch (error) {
    Alert(error.message)
  }
  dispatch(isLoaded());
}