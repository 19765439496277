import {  isLoaded, isLoading, roadBlocks } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

//RoadBlock List
export async function getRoadBlockList(dispatch,getUser,subTaskId) {
    dispatch(isLoading());
    try {
      var response = await API.post("roadBlock.php", {
        "crop": getUser.corp,
        action: "getting",
        subTaskId:subTaskId
      },{},false);
      if(response.status === 'True') {
        dispatch(roadBlocks(response.data))
      }
      else{
        dispatch(roadBlocks([]))
      }
    } catch (error) {
      Alert('error',error.message);
    }
    dispatch(isLoaded());
  }