import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { allMessages } from "../actions";


export async function getAllMessages(dispatch, getUser) {
    try {
      var response = await API.post("user_chat.php", {
        corp_code: getUser.corp,
        action: "get_all_messages",
        sendBy: getUser.empId,
        projectId: getUser.projectId,
      }, {}, false);
      if (response.status === 'True') {
        dispatch(allMessages(response.data));
      } else {
        dispatch(allMessages([]));
      }
    } catch (error) {
      Alert('error', error.message)
    }
  }
  