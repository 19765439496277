import Alert from "../../Alert";
import API from "../../Network/API";
import { isLoaded, isLoading } from "../../TasksModals/actions";

//Add Comments for UserStory  Where We Have and Who can Add comments for that --> GNK -->version 1.0.6.01
export async function addCommentUserStory(dispatch, getUser, userStoryId,cardId,handleClose,handleModalClose) {
    var message = "User story is done/completed";
    dispatch(isLoading());
    try {
      const response = await API.post("story_comments.php", {
        action: "add_story_comment",
        storyId: userStoryId,
        corp: getUser.corp,
        userId: getUser.empId,
        message: message,
        cardId: cardId
      }, {}, false);
      console.log(response)
      if (response.status === "True") {
        handleClose();
      } else {
        Alert('warning', response.message)
        handleModalClose();
      }
    }
    catch (error) {
      Alert('error', error.message)
      handleModalClose();
    }
    dispatch(isLoaded());
  }