import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading } from "../actions";

//Reject Project/Epic
export async function rejectEpic(dispatch,getUser,ideaId,handleClose, PROJECT_REJECT) {
    dispatch(isLoading());
    try {
      var response = await API.post("manage_epics.php", {
        corp: getUser.corp,
        empId: getUser.empId,
        action: "reject",
        idea_id: ideaId,
        projectId: getUser.projectId
      },{},false);
      if(response.status === 'True') {
        Alert("success", PROJECT_REJECT);
      }
    } catch (error) {
      Alert('error',error.message);
    }
    handleClose()
    dispatch(isLoaded());
  }