import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

//For Get MainTask/UserStories List From Modules/Sprints
export async function updateRoadblockTask(getUser, us_id, data, handleClose) {
  data.map(item => {
    return apiCall(item.id)
  })

  async function apiCall(id) {
    try {
      var response = await API.post("manage_userstories.php", {
        crop: getUser.corp,
        us_id: us_id,
        action: 'addRoadblockToTask',
        roadblocks: id
      }, {}, false);
      if (response.status === 'True') {
        handleClose()
      }
    } catch (error) {
      Alert('error', error.message);
    }
  }

}
