import { isLoaded, isLoading } from "../actions";
import Alert from "../../Alert";
import API from "../../Network/API";

//Add Comments for UserStory  Where We Have and Who can Add comments for that
export async function addCommentUserStory(dispatch, getUser, userStoryId, message,cardId) {

    dispatch(isLoading());
    try {
      const response = await API.post("story_comments.php", {
        action: "add_story_comment",
        storyId: userStoryId,
        corp: getUser.corp,
        userId: getUser.empId,
        message: message,
        cardId: cardId,
        "projectId":getUser.projectId
      }, {}, false);
      if (response.status === "True") {  
      } else {
        Alert('warning', response.message)
      }
    }
    catch (error) {
      Alert('error', error.message)
    }
    dispatch(isLoaded());
  }