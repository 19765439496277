import Alert from "../../../Common/Alert";
import { logEntry } from "../../../Common/logEntry";
import API from "../../../Common/Network/API";
import { addCommentUserStory } from "../../Modules/Services/addCommentUserStory";

//Update the Task Status
export async function updateUserStory(dispatch, getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id,activities) {

  if (story_type === 'group' && main_task_id === '0') {
    callToCheckGroupTask(dispatch, getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id,activities)
  } else {
    completeTask(dispatch, getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id,activities)
  }
  handleClose();
}

const callToCheckGroupTask = (dispatch, getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id,activities) => {
  API.post("getUpdateSprint.php", {
    story_us_id: us_id,
    action: "get_group_task_info",
  })
    .then(function (response) {
      if (response.status === 'True') {
        if (response.data.length > 0) {
          response.data.filter(y => y.complete_status !== 'completed').map(x => {
            return completeTask(dispatch, getUser, x.story_id, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, x.story_type, x.us_id, x.main_task_id,activities)
          })
          completeTask(dispatch, getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id,activities)
          // let isCompleted = response.data.some(obj => obj.complete_status === "completed")
          // if (!isCompleted) {
          //   handleClose();
          //   Alert('warning', "You can't update until your dependency group task completed");
          // } else {
          //   completeTask(getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id)
          // }
        }
      }
    })
    .catch(function (error) {
      console.error(error);
    });
}

const completeTask = async (dispatch, getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id,activities) => {
  try {
    const start_time=Date.now();
    const response = await API.post(
      "manage_userstories.php",
      {
        action: "update",
        task_id: userStoryId,
        crop: getUser.corp,
        task_status: 100,
        task_status_desc: "completed",
        task_complete_status: 1,
        empId: getUser.empId,
        projectId: getUser.projectId,
      },
      {},
      false
    );
    const end_time=Date.now();
    if (response.status === "True") {
      addCommentUserStory(dispatch, getUser, userStoryId, message, "2");
      activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
        sub_activity:"Done",
        response_time : (end_time-start_time), task_meta_data:{story_id:userStoryId}

      },getUser)
      handleClose()
    } else {
      const warning = response.type === "tasks" ? TASKS_WARNING : ROADBLOCK_WARNING;
      Alert('warning', warning)
    }
  } catch (error) {
    Alert("error", error.message);
    //error log
    activities && logEntry({user_id:getUser.userName,logging_level:2,activity_id:activities["Task Management"],
      sub_activity:"Done",
      error_message:error.message
    },getUser)
  }
}
