import { isLoaded, isLoading, currentSprintTasks, currentGroupTasks } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { getInvolvedEmployees } from "./getInvolvedEmployees";

//for getting current sprint user stories

export async function getCurrentSprint(dispatch, getUser, sprintId) {
  dispatch(isLoading());
  try {
    var response = await API.post("backlogspage.php", {
      "crop": getUser.corp,
      "userType": getUser.role,
      "empId": getUser.empId,
      "sprintId": sprintId,
      "action": "currentsprint",
      "projectId": getUser.projectId
    }, {}, false);
    if (response.status === 'true') {
      getInvolvedEmployees(dispatch, getUser, sprintId);
      let currentStories=[]
      let pending = []
      let completed = []
      response.data.forEach(element => {
        if (element.completeStatus === 'pending') {
          pending.push(element)
        }
        else {
          completed.push(element)
        }
      });
      currentStories=pending.concat(completed)
      dispatch(currentGroupTasks(currentStories.filter(item => {
        return item.story_type === 'group'
      })))
      dispatch(currentSprintTasks(currentStories.filter(item => {
        return item.story_type !== "group"
      })))
    }
    else {
      dispatch(currentSprintTasks([]))
      dispatch(currentGroupTasks([]))
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());
  }
  dispatch(isLoaded());

}

