import Axios from "axios";
import Alert from "../../../Common/Alert";
import { isLoading, isLoaded, timesheet } from "../actions";
import { Reports_URL } from "../../../Common/config";

export async function getTimesheet(dispatch, getUser, startDate, endDate, select) {
  // const [, extension] = (getUser.userName).split('@')
  dispatch(isLoading());
  try {
    var response = await Axios.post(`${Reports_URL}/getUserTimeSheets`, {
      "filter": [{
        "startDate": startDate,
        "endDate": endDate,
        "key": "selection"
      }],
      "user_id": select

    }, {}, false);

    dispatch(timesheet(response.data))
    console.log(response)
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}

