
import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

function trimObj(obj) {
  if (!Array.isArray(obj) && typeof obj != 'object') return obj;
  return Object.keys(obj).reduce(function(acc, key) {
    acc[key.trim()] = typeof obj[key] == 'string'? obj[key].trim() : trimObj(obj[key]);
    return acc;
  }, Array.isArray(obj)? []:{});
}
export async function updateMuralBoard(state, dispatch, getUser,id,data) {
  dispatch(isLoading());
  try {
    var response = await API.post("mural.php", {
      crop: getUser.corp,
      id: id,
      savedboard:trimObj(data),
      createdBy: getUser.empId,
      action: "updateMural",
      "projectId":getUser.projectId
    }, {}, false);
    if (response.status === 'True') {
      Alert("success", response.message);
      dispatch(isLoaded());
    }
    else {
      Alert('error', response.message);
      dispatch(isLoaded());
    }
  } catch (error) {
    Alert('error', error.message);
    dispatch(isLoaded());

  }

}