import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector } from 'react-redux';
import { getSubStringId } from "../../Common/SubStringConvert";
import { useWebProperties } from '../../Common/webProperties';
import { isMobile } from 'react-device-detect';
import { updateSetDone, updateUserStory } from './Services/updateUserStory';
import { pauseUserStory } from './Services/pauseUserStory';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//For Add New Project
export default function MyTaskerCompleteReason(props) {

    console.log(props)

    const getUser = useSelector(state => state.auth)
    const [taskDescription, setTaskDescription] = useState('')
    const [errorMessage, setErrorMessage] = useState(null)
    const { MAINTASK, TASKS_WARNING, ROADBLOCK_WARNING, SUBTASK } = useWebProperties();
    // eslint-disable-next-line
    const classNames = useStyles();
    const [loader, setLoader] = useState(false)
    const activities = useSelector(state => state.landingReducer.userActivities)
 
    const completeTask = () => {
        setLoader(true)
        if (!taskDescription || taskDescription.trim() === "") {
            setErrorMessage(`Please enter complete reason`)
            setLoader(false)
        } else {
            setErrorMessage(null)
            if (props.data.task_type === 'sub_task') {
                let message = `${taskDescription}, ${SUBTASK} is completed`;
                updateSetDone(activities, getUser.user, props.data.task_id, props.data.story_id, props.handleClose, message, SUBTASK)
            } else {
                let message = `${taskDescription}, ${MAINTASK} is completed`;
                updateUserStory(activities, getUser.user, props.data.story_id, props.handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, props.data.story_type, props.data.us_id, props.data.main_task_id, MAINTASK)
            }
        }
    }

    const pauseTask = () => {
        setLoader(true)
        if (!taskDescription || taskDescription.trim() === "") {
            setErrorMessage(`Please enter pause reason`)
            setLoader(false)
        } else {
            setErrorMessage(null)
            if (props.data.task_type === 'sub_task') {
                let message = `${taskDescription}, ${SUBTASK} is paused`;
                pauseUserStory(activities, getUser.user, "", props.data.task_id, props.data.assignedTo, props.handleClose, message, MAINTASK, props.data.task_type, props.data.task_type, SUBTASK)
            } else {
                let message = `${taskDescription}, ${MAINTASK} is paused`;
                pauseUserStory(activities, getUser.user, "", props.data.story_id, props.data.assignedTo, props.handleClose, message, MAINTASK, props.data.task_type, props.data.task_type, SUBTASK)
            }
        }
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className={isMobile ? `modal-content p-2` : `modal-content col-5 p-2`} style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <h5 class="modal-title p-2">{getSubStringId(props.data.project_name, 3)}{'-'}{props.data.main_task_id !== null && props.data.main_task_id !== "0" ? `${props.data.main_task_id}-` : ''}{props.data.us_id}{'-'}{props.data.story_title}{props.data.blocked_id !== null && <span style={{ width: '100px', cursor: 'pointer', fontWeight: 'bold', color: 'white', backgroundColor: 'rgb(255, 170, 51)', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(props.data.project_name, 3)}{'-'}{props.data.userStoryId}</span>}</h5>

                                </div>
                                <div className="modal-body">
                                    <label>Complete Reason / Pause Reason</label>
                                    <textarea className='form-control' onChange={(event) => setTaskDescription(event.target.value)} />
                                    {
                                    taskDescription.trim() ==='' &&
                                     errorMessage !== null && <label style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</label>}
                                </div>
                                <div class="modal-footer justify-space-between">
                                    <button type="button" class="btn btn-outline-danger" onClick={() => props.handleClose()} style={{ borderRadius: '20px' }}>Cancel</button>
                                    <button disabled={loader} type="button" class="btn btn-outline-info" style={{ borderRadius: '20px' }} onClick={() => {
                                        pauseTask()
                                    }}  >Pause</button>
                                    <button disabled={loader} type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={() => {
                                        completeTask()
                                    }}  >Complete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}
