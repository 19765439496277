import Alert from "../../Alert";
import API from "../../Network/API";

export async function reOrderSubTasks(stack, SUBTASKS, handleClose) {
 // const reorderArray = [];
 // const rankListSort = items.sort((a, b) => a.priorityLevel - b.priorityLevel);
 const reorderArray = stack ? stack.map((priority, index) => {
    // return reorderArray.push({ t_id: priority.t_id, priority: rankListSort[index].priorityLevel });
    return priority.t_id;
  }): null;
    try {
      var response = await API.post("manage_tasks.php", {
        action: "reorder",        
        priority: reorderArray,
        msgId: reorderArray || " ",
      }, {}, false);
      if (response.status === 'true') {
         Alert('success', `${SUBTASKS} Reordered`);
         handleClose();
      }
    } catch (error) {
      Alert('warning', error.message);
    }
}