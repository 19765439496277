//accounts/services/getSquadList (READ)
import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import * as actions from '../actions';

export async function getAccountFeature(dispatch, getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("categories.php",{
        action : "account_features"
      } , {}, false);
      if (response.status === 'True') {
        dispatch(actions.accountFeatures(response.data))
      }
      else {
        dispatch(actions.accountFeatures([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }