import moment from "moment";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading, projects } from "../actions";

//For Get Projects/Epics List
export async function getEpics(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_epics.php", {
      "corp": getUser.corp,
      "userType": getUser.role,
      "empId": getUser.empId,
      "action": "get_epics",
      "projectId": getUser.projectId
    }, {}, false);
    if (response.status === 'True') {
      let defaultEpic = response.data.filter(item => {
        return item.idea_title === 'Default Module'
      })
      if (defaultEpic?.length === 0) {
        createDefaultEpic(dispatch, getUser)
      }
      dispatch(projects(response.data))
    }
    else {
      dispatch(projects([]))
      createDefaultEpic(dispatch, getUser)
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}

export async function createDefaultEpic(dispatch, getUser) {
  dispatch(isLoading());
  try {
    const data = {
      proj_title: `Default Module`,
      proj_desc: `Default Module`,
      empId: getUser.empId, //Async
      action: "add",
      corp: getUser.corp,
      projectId: getUser.projectId,
      targetDate: moment().startOf('year').format('YYYY-MM-DD'),
    };
    const response = await API.post("manage_epics.php", data, {}, false);
    if (response.status === 'True') {
      dispatch(getEpics(dispatch, getUser))
    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
}