export const emailValidation = (email) => {
    const regexPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regexPattern.test(email);
}
export const nameValidation = (name) => {
    const regexPattern = /^[a-zA-Z\s-]+$/;
    return regexPattern.test(name);
}

export const phoneValidation = (phone)=>{
    const regexPattern=/^\d{10}$/;
    return regexPattern.test(phone)
}