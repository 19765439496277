import {
  isLoaded,
  isLoading,
  modules
} from "../actions";
import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";
import { createDefaultModule } from "./createDefaultModule";


//For ToDo and Doing Subtask List based on task active status
export async function getModules(dispatch, getUser, MODULE_ADD, MODULE) {
    dispatch(isLoading());
    try {
      // Future Sprints Name list with epic by -->GNK -->01 on version 1.0.4 start
  
      // var response = await API.post("get_sprints.php", {
      //   "crop": getUser.corp,
      //   "userType": getUser.role,
      //   "empId": getUser.empId,
      //   "action": "getModules"
      // }, {}, false);
      // Future Sprints Name list with epic by -->GNK -->01 on version 1.0.4 end
  
      // Future Sprints Name list with out epic by -->SS -->02 on version 1.0.6 start
      var response = await API.post(
        "getUpdateSprint.php",
        {
          crop: getUser.corp,
          action: "getModules",
          projectId: getUser.projectId,
        },
        {},
        false
      );
      // Future Sprints Name list with out epic by -->SS -->02 on version 1.0.6 end
      if (response.status === "True") {
        dispatch(modules(response.data));
      } else {
        dispatch(createDefaultModule(dispatch, getUser, MODULE_ADD, MODULE));
        console.log(response)
      }
    } catch (error) {
      Alert("error", error.message);
    }
    dispatch(isLoaded());
  
  }