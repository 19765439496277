import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { addCommentUserStory } from "../../Modules/Services/addCommentUserStory";


//Add userstory to sprint
export async function addKanban(assignedto, taskId, state, dispatch, getUser, handleClose, device_id, assign_to, sprintDesc, title, storyPoints, KANBAN, MODULE, MAINTASK) {
  dispatch(isLoading());
  if (assignedto === null) {
    Alert(`warning', 'Assign squad member to ${MAINTASK}`);
  }
  else {
    const message = `This ${MAINTASK} added in ${KANBAN}`
    try {
      var response = await API.post("getUpdateSprint.php", {
        crop: getUser.corp,
        action: "kanban_update",
        sprintId: state.kanbanSelected.value,
        updatedBy: getUser.empId,
        userStoryId: taskId,
        "projectId": getUser.projectId,
        device_id: device_id === null ? state.device_id : device_id,
        sprintDesc: sprintDesc,
        assign_to: assign_to === null ? state.userSelected : assign_to,
        emp_id: getUser.empId,
        title: title,
        storyPoints: storyPoints,
      }, {}, false);
      if (response.status === 'True') {
        addCommentUserStory(dispatch, getUser, taskId, message, "11");
        Alert("success", "UPDATED");
      }
      else {
        Alert("warning", "Something went wrong")
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  }
}