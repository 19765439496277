import { isLoading, isLoaded } from "../../../Common/Actions/loading"
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { getToDo } from "./getToDo";



//For Active the Task from ToDo to Doing
export async function getActive(dispatch,getUser,subtaskId, SUBTASK_ACTIVE) {

  dispatch(isLoading());
  try {
    var response = await API.post("manage_userstories.php", {
      "crop": getUser.corp,
      "story_id": subtaskId,
      "empId": getUser.empId,
      "action": "activate_user_story"
    }, {}, false);
    if (response.status === 'true') {
      getToDo(dispatch,getUser);
      Alert("success",SUBTASK_ACTIVE);
    } else {
      Alert("warning",response.message)
    }
  } catch (error) {
    console.log(error)
  }

  dispatch(isLoaded());
}