import API from "../../Network/API";
import { isLoading, isLoaded,  calendarDashStory } from "../action";
import Alert from '../../Alert';

// for calendar Dash
// getting user stories related to user
export async function getCalendarDashStory(dispatch,getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "calendarDash",
    },{},false);
    // console.log("stories",response)
    if(response.status === 'True') {
        dispatch(calendarDashStory(response.data))
    }
    else{
      dispatch(calendarDashStory([]))
    }
  } catch (error) {
    Alert('error',error.message);
  }
  dispatch(isLoaded());
}