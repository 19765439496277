import { isLoading, isLoaded } from "../../../Common/Actions/loading"
import API from "../../../Common/Network/API";
import store from '../../../Common/Store';
import { doing, todo } from "../actions";

//For ToDo and Doing Subtask List based on task active status
export async function getToDo(dispatch,getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("getSubtasks.php", {
      "crop": getUser.corp,
      "userType": getUser.role,
      "empId": getUser.empId,
      "action": "pending"
    }, {}, false);
        var toDoList = [];
        var doingList = [];
        response.data.map((pending) => {
            return (
           (pending.activeStatus === "0") ? toDoList.push(pending) : doingList.push(pending)
            );
        })
    store.dispatch(todo(toDoList));
    store.dispatch(doing(doingList));
  } catch (error) {
    console.log(error)
  }

  dispatch(isLoaded());
}