import API from "../../Network/API";
import { isLoading, isLoaded, section } from "../action";

// for getting active sprint
export async function getAllSprints(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "crop": getUser.corp,
      "action": "pendingAll",
      "userType": getUser.role,
      "empId": getUser.empId,
    }, {}, false);
    var toDoList = [];
    var doingList = [];
    // console.log(response.data)
    dispatch(section(response.data))
    response.data.map((pending) => {
      return (
        (pending.activeStatus === "0") ? toDoList.push(pending)
          : (pending.activeStatus === "1") ? doingList.push(pending) : null
      );
    })
    // store.dispatch(todo(toDoList));
    // store.dispatch(doing(doingList));
    // store.dispatch(todoFilter(toDoList));
    // store.dispatch(doingFilter(doingList))
  } catch (error) {
    console.log(error)
  }
  dispatch(isLoaded());
}