import API from "../../Network/API";
import { isLoading, isLoaded,  events } from "../action";
import Alert from '../../Alert';
import { Client_URL } from "../../config";
import { updateUserStory } from "../../../UserModule/ConsolidatedToDo/Services/updateUserStory";

const filterToDeleteEvents = async (deleteEvents, getUser, APP_NAME,activities) => {
  try {
      const deletePromises = deleteEvents.map(async item => {
          const response = await API.post("calendarDash.php", {
              action: "Delete",
              current: item,
              corp_code: item.project_name,
              projectId: item.ProjectName,
              fullName: getUser.fullName,
              userName: getUser.userName,
              url: Client_URL,
              appName: APP_NAME,
              deleteType:"auto_delete"
          }, {}, false);

          if (response.status === "True" && response.task_details?.length > 0) {
              response.task_details.forEach(task => {
                  if (task.active_status === "1") {
                    let data = { task_id: item.task_id, story_id: item.story_id, task_type: item.task_type, project_name: item.project_name, main_task_id: item.main_task_id, story_type: item.story_type, us_id: item.us_id, story_title: item.story_title, blocked_id: item.blocked_id, userStoryId: item.userStoryId,calendarId:item?.calendar_id };
                    let message = `This meeting, This Task is completed`;
                    updateUserStory(activities,getUser.user, data.story_id, "handleClose", message, "TASKS WARNING", "ROADBLOCK WARNING", data.story_type, data.us_id, data.main_task_id, "MAINTASK",data.calendarId);
                  }
              });
          }
      });

      await Promise.all(deletePromises);
  } catch (error) {
      console.error('Error deleting some events:', error);
  }
};

//to get event for calear Dash
export async function getEventCalendarDash(getUser,dispatch, APP_NAME,activities) {
  dispatch(isLoading());
  try {
    var response = await API.post("calendarDash.php", {
      action: "GetEventCalendarDash",
      // corp_code: getUser.corp,
      // projectId: getUser.projectId,
      userName: getUser.userName,
      url: Client_URL,
      appName: APP_NAME,
  }, {}, false);
  if (response.status === 'True') {
    dispatch(events(response.data))
    dispatch(isLoaded());

    //for automatically delete the past events
    if(response.data.length>0){
      // eslint-disable-next-line
      const deleteEvents=response.data.filter(item=>{
        if(new Date(item.EndTime) < new Date()){
            return item
        }
      })
      if(deleteEvents.length>0){
        await filterToDeleteEvents(deleteEvents,getUser, APP_NAME,activities)
      }
    }
  }
  else {
    dispatch(events([]))
  }
  } catch (error) {
      console.log(error)
      Alert('error', error.message);
  }
  dispatch(isLoaded());
}