import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";

  export async function addTasksComment(getUser, taskId, cardId, message) {
    try {
      await API.post("task_comments.php", {
        action: "add_tasks_comments",
        taskId: taskId,
        corp: getUser.corp,
        userId: getUser.empId,
        message: message,
        cardId: cardId
      }, {}, false);
    }
    catch (error) {
      Alert('error', error.message)
    }
  }