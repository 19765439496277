import React, { useReducer, useEffect, useState } from 'react';
import SideBar from '../Utility/SideNav';
import Header from '../../Common/TopNav';
import { initialState, reducer } from './reducer';
import API from "../../Common/Network/API";
import { isLoading, isLoaded } from "./action";
import Alert from '../../Common/Alert';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
// import RootLoader from '../../Common/Loader/RootLoad';

// add faqs
export default function FAQs() {
  // eslint-disable-next-line
  const [state, dispatch] = useReducer(reducer, initialState);
  const [categories, setCategories] = useState([])
  const [showInputField, setShowInputField] = useState(false); // State to control visibility of input field

  useEffect(() => {
    getFaqsCategory()
  }, [])
  // get all faqs
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      ques1: '',
      ans1: '',
      category: "1" // Default category
    }
  ]);

  const handleChangeInput = (id, event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setInputFields(prevFields =>
      prevFields.map(field =>
        field.id === id ? { ...field, [name]: value } : field
      )
    );

    if (value === 'add_category') {
      setShowInputField(true)
    }

  };
  // 
  const handleChange = (id, event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setInputFields(prevFields =>
      prevFields.map(field =>
        field.id === id ? { ...field, [name]: value } : field
      )
    );

    if (value === 'add_category') {

      setShowInputField(true)

    }
  };


  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        ques1: '',
        ans1: '',
        category: "1" // Default category
      }
    ]);

  };

  const hasNonEmptyQuesOrAns = (arr) => {
    return arr.some(item => item.ques1 !== "" || item.ans1 !== "");
  };

  // for adding faqs
  const submitData = async () => {

    if (hasNonEmptyQuesOrAns(inputFields) === true) {

      dispatch(isLoading());
      try {
        const faqsData = inputFields.map(({ ques1, ans1, category }) => ({
          ques1: ques1,
          ans1: ans1,
          category
        }));

        const response = await API.post("faqs.php", {
          data: faqsData,
          action: 'add',
        }, {}, false);
        if (response.status === 'True') {
          Alert("success", "Added Successfully!");
          setCategories([
            {
              id: uuidv4(),
              ques1: '',
              ans1: '',
              category: "1" // Default category
            }
          ]);
          setInputFields([
            {
              id: uuidv4(),
              ques1: '',
              ans1: '',
              category: "1" // Default category
            }
          ]);
        }
      } catch (error) {
        Alert("error", error.message);
      }
      dispatch(isLoaded());
    } else {
      Alert("warning", "Please enter question and answer");
    }
  };
  // add new category
  const addCategory = async () => {
    try {
      const response = await API.post("faqs.php", {
        category_name: inputFields[0].category,
        action: 'addCategory',
      }, {}, false);
      if (response.status === 'True') {

        setShowInputField(false);
        getFaqsCategory();



      }
    }
    catch (error) {

      Alert("error", error.message);
    }
    dispatch(isLoaded());
  }

  const closeCategory = () => {
    setShowInputField(false);
  }
  const getFaqsCategory = async () => {
    try {
      var response = await API.post("faqs.php", {
        action: 'getCategory',
      }, {}, false);
      if (response.status === 'True') {
        setCategories(response.data);
      }
    } catch (error) {
      Alert(error.message)
    }
  }

  return (
    <div className="container-scroller">
      <Header />
      <div className="container-fluid page-body-wrapper">
        <SideBar />
        <div className="main-panel">
          <div className="mt-2">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div>
                    <h2 className="card-title" style={{ overflowWrap: "break-word", color: 'blue', backgroundColor: 'transparent' }}>FAQ's</h2>
                  </div>
                  {/*view category list  */}
                  <div className="row">
                    <div className="col-12 lg:col-6 d-flex justify-content-end">
                      <Link to={{ pathname: "/admin/categories" }} style={{ color: 'white', textDecoration: 'none' }}>
                        <button type="button" className="btn btn-success" style={{ borderRadius: '20px' }}>
                          View Category
                        </button>
                      </Link>
                      &nbsp;&nbsp;

                      <Link to={{ pathname: "/admin/details" }} style={{ color: 'white', textDecoration: 'none' }}>
                        <button type="button" data-dismiss="modal" className="btn btn-warning" style={{ borderRadius: '20px' }}>
                          View Details
                        </button>
                      </Link>
                    </div>
                  </div>
                  <br />
                  {/* {state.isLoading ? <RootLoader /> :  */}
                  <form >
                    {inputFields.map((inputField, sr) => {
                      sr *= 1
                      return (
                        <div key={inputField.id}>

                          <div className="form-group row">
                            <label for="ques1" className="col-form-label pt-3 mr-2" >{sr + 1}.</label>
                            <label for="ques1" className="col-form-label pt-3" ><i class="fa fa-quora" aria-hidden="true"></i>Question:</label>
                            <input type="text" className="form-control col-10 ml-3 ques"
                              style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                              id="ques1" placeholder="Enter Question " name="ques1"
                              value={inputField.ques1}
                              onChange={event => handleChangeInput(inputField.id, event)} />
                          </div>
                          <div className="form-group row">
                            <label for="ans1" className="col-form-label pt-3 ml-4">Answer:</label>
                            <input type="text" className="form-control col-10 ml-4 ans"
                              style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                              id="ans1" placeholder="Enter Answer" name="ans1"
                              value={inputField.ans1}
                              onChange={event => handleChangeInput(inputField.id, event)} />
                          </div><br />
                          <div className="form-group row">
                            <label for="category" className="col-form-label pt-3 ml-4">Category:</label>
                            {!showInputField ? (
                              <select
                                className="form-control col-10 ml-4"
                                id="category"
                                name="category"
                                value={inputField.category}
                                onChange={event => handleChange(inputField.id, event)}
                              >
                                <option value='add_category' >Add category</option>
                                {
                                  categories.map(item => {
                                    return <option value={item.id}>{item.name}</option>
                                  })
                                }
                              </select>
                            ) : (
                              <>
                                <div className="form-group row col-10">
                                  <div className="col-8">
                                    <input
                                      style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                      type="text"
                                      className="form-control ans"
                                      placeholder="Enter category"
                                      name="category"
                                      onChange={event => handleChangeInput(inputField.id, event)}
                                    />
                                  </div>
                                  <div className="col-2">
                                    <button
                                      type="button"
                                      id="addCat"
                                      className="btn btn-primary btn-block"
                                      onClick={() => { addCategory() }}
                                    >
                                      Add
                                    </button>
                                  </div>
                                  <div className="col-2">
                                    <button
                                      type="button"
                                      id="closeCat"
                                      className="btn btn-danger btn-block"
                                      onClick={() => { closeCategory() }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>

                              </>
                            )
                            }


                            {/* {showInputField && (
          
      )} */}
                          </div>
                          <br />

                        </div>
                      )
                    })}
                  </form>
                  {/* } */}
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>

                    <button
                      className="btn btn-success" style={{}} type="button"
                      onClick={handleAddFields}
                    >Add More</button>
                    &nbsp;&nbsp;
                    {/* {state.isLoading ? <RootLoader /> : */}
                    <button
                      className="btn btn-primary" style={{}} type="button"
                      onClick={() => { submitData() }}
                    >Submit</button>
                  </div>
                  {/*   } */}
                  <br /><br /><br /><br />


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
