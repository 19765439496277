import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading, allMessages } from "../actions";

export async function getAllUserStoriesMessages(dispatch, getUser, refresh) {
  if (refresh) { dispatch(isLoading()); }
  try {
    var response = await API.post(
      "user_story_chat.php",
      {
        corp_code: getUser.corp,
        action: "getAllTaskMessages",
      },
      {},
      false
    );
    if (response.status === "True") {
      dispatch(allMessages(response.data));
    } else {
      dispatch(allMessages([]));
    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
}