import Alert from "../../Alert";
import API from "../../Network/API";
import { isLoaded, isLoading, taskComments } from "../action";// getting group chat details

// get all messages to display in group chat modal
export async function getGroupChatMessages(dispatch, getUser, data) {
    dispatch(isLoading());
    try {
      var response = await API.post("group_chat.php", {
        corp_code: getUser.corp,
        action: "getmessages",
        group_Id: data.id
      }, {}, false);
      if (response.status === 'True') {
        dispatch(taskComments(response.data));
      } else {
        dispatch(taskComments([]));
      }
    } catch (error) {
      Alert(error.message)
    }
    dispatch(isLoaded());
  }
