/*
FileName:UserModule/Team/GeneratePassword.js
purpose: Generate new password
Developers:Naveen Kumar Gade - NKG
Created Date:
Updated Date:27/2/2024
 */
import React, { useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { empReducer, initialState } from './empReducer';
import * as actions from './actions';
import RootLoader from '../../Common/Loader/RootLoader';
import { useWebProperties } from '../../Common/webProperties';
import { generatePassword } from './Services/generatePassword';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));
// Generate Password from Admin
export default function GeneratePassword(props) {

    const classNames = useStyles();
    const [state, dispatch] = useReducer(empReducer, initialState);
    const { APP_NAME } = useWebProperties();
    useEffect(() => {
        dispatch(actions.setModifyEmployee(props.data.id, props.data.employeeId, props.data.name, props.data.email, props.data.mobile, props.data.userName, props.data.team, props.data.designation, props.data.reportingManager, props.data.functionalManager, props.data.userType, props.data.userStatus))
        // eslint-disable-next-line
    }, [])

    const buttonState = (state.password.value === state.confirmpassword.value) && state.password.value !== "" && state.confirmpassword.value !== "";

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-lg-5 col-sm-12 p-2" style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <h5 class="modal-title p-2">Generate Password</h5>
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" className="d-flex align-items-right p-2" data-dismiss="modal" onClick={props.handleModalClose}><i class="mdi mdi-close text-black"></i></button>
                                </div>
                                <div className="modal-body " style={{ height: '200px' }}>
                                    <div>
                                        <form >
                                            <div class="form-group required row p-4" style={{ marginTop: '-30px' }}>
                                                <label for="recipient-password" class="col-form-label pt-4" style={{ width: "150px" }}>Password<span style={{ color: "red" }} >*</span></label>
                                                <input type="password" class="form-control col-6 ml-2 " id="recipient-fullName" name="Name" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.password.value}
                                                    onChange={(event) => dispatch(actions.password(event.target.value))} />
                                                <span style={{ color: "red", fontSize: '12px', paddingLeft: "30%" }}>{state.password.errorStatus ? state.password.errormessage : ""}</span>
                                            </div>
                                            <div class="form-group required row p-4" style={{ marginTop: "-70px" }}>
                                                <label for="recipient-confirm-password" class="col-form-label pt-4" style={{ width: "150px" }}>Confirm Password<span style={{ color: "red" }} >*</span></label>
                                                <input type="password" class="form-control col-6 ml-2 " id="recipient-email" name="Email" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.confirmpassword.value}
                                                    onChange={(event) => dispatch(actions.confirmPassword(event.target.value))} />
                                                {state.confirmpassword.errorStatus ? <span style={{ color: "red", fontSize: '12px', paddingLeft: "30%" }}>{state.confirmpassword.errormessage}</span>
                                                    : (state.password.value === state.confirmpassword.value) && state.password.value !== "" && state.confirmpassword.value !== "" ?
                                                        <span style={{ color: "green", fontSize: '12px', paddingLeft: "30%" }}> password matched </span> : state.confirmpassword.value === "" ? " "
                                                            : <span style={{ color: "red", fontSize: '12px', paddingLeft: "30%" }}>password not matched</span>}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {state.isLoading ? < RootLoader /> :
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} disabled={!buttonState} onClick={() => generatePassword(state, dispatch, props.handleClose, APP_NAME)} >Generate</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}