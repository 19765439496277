import React, { useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector } from 'react-redux';
import { moduleReducer, initialState } from "./moduleReducer";
import { getSubStringId } from "../../Common/SubStringConvert";
import { useWebProperties } from '../../Common/webProperties';
import { updateUserstotyStaus } from './Services/updateUserstotyStaus';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//For Add New Project
export default function ChangeUserstoryStatus(props) {

    const getUser = useSelector(state => state.auth)
    const { MAINTASK, PRIVATE_TODO ,SUBTASK} = useWebProperties();
    const [state, dispatch] = useReducer(moduleReducer, initialState);
    const classNames = useStyles();
    const inProgressProjectLabel = props.data.projectName ? getSubStringId(props.data.projectName, 3) : 'PT';
    const projectName = props.data.projectName ? props.data.projectName : PRIVATE_TODO;
    const toDoProjectLabel = props.data.toDoProjectName ? getSubStringId(props.data.toDoProjectName, 3) : 'PT';
    const activities= useSelector(state => state.landingReducer.userActivities)

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className={isMobile ? `modal-content p-2` : `modal-content col-5 p-2`} style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <h5 class="modal-title p-2">{toDoProjectLabel}{'-'}{props.data.todo_us_id}  {props.data.story_title}</h5>
                                </div>
                                <div className="modal-body">
                                    <b style={{ backgroundColor: 'papayawhip' }}>User has <span style={{ color: 'blue' }}>{inProgressProjectLabel}{'-'}{props.data.us_id}: </span><span style={{ color: 'teal' }}>{'       "' + props.data.inprogress_story_title + '"'}</span> In Progress on <span style={{ color: 'rgb(143, 0, 255)' }}>{projectName}</span></b>
                                    <p style={{ color: 'green', marginTop: '10px' }} ><b>CONFIRM if you want to move {MAINTASK} into In Progress</b></p>
                                </div>
                                <div class="modal-footer justify-space-between">
                                    <button type="button" class="btn btn-outline-danger" onClick={props.handleModalClose} style={{ borderRadius: '20px' }}>Cancel</button>

                                    <button disabled={state.isLoading} type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} 
                                    onClick={(event) => { 
                                        updateUserstotyStaus(activities,state, dispatch, getUser.user, props.data.todo, props.data.inprogress, props.data.currentSprint, props.data.assignedTo, props.handleClose, MAINTASK, props.data.task_type, props.data.inprogress_task_type,SUBTASK);
                                        if(props.data.meeting_url!==null&&props.data.meeting_url!==undefined){
                                            window.open(props.data.meeting_url, '_blank');
                                        }
                                        }}  >Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}
