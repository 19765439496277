import {
    isLoaded,
    isLoading,
    allMessages,
  } from "../actions";
import API from "../../../Common/Network/API";
import store from "../../../Common/Store";
import Alert from "../../../Common/Alert";

export async function getAllUserStoryMessages(dispatch, getUser, refresh) {
    if (refresh) { dispatch(isLoading()); }
    try {
      var response = await API.post(
        "user_story_chat.php",
        {
          corp_code: getUser.corp,
          action: "getAllTaskMessages",
        },
        {},
        false
      );
      // console.log(response.data)
      if (response.status === "True") {
        dispatch(allMessages(response.data));
        store.dispatch(allMessages(response.data));
      } else {
        dispatch(allMessages([]));
      }
    } catch (error) {
      Alert("error", error.message);
    }
    dispatch(isLoaded());
  }