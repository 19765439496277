import React, { useEffect, useState } from 'react'
import ReportsAPI from '../../Common/AgileNetwork/ReportsAPI';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import UserProjectInfo from './userProjectInfo';
import { useWebProperties } from '../../Common/webProperties';
import moment from 'moment';
import { getDecimalHours } from '../../Common/convertDecimalHoursToTime';
//import ProjectCosting from './ProjectCosting';
import RootLoader from '../../Common/Loader/RootLoader';
export default function ProjectReports({filter}) {
    // eslint-disable-next-line
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [info, setInfo] = useState();
    //const [activeComponent, setActiveComponent] = useState(true);

  //  const showUserReport = () => setActiveComponent(true);
  //  const showUserCosting = () => setActiveComponent(false);
    const [loader, setLoader] = useState(false);


    const [result, setResult] = useState([])
    const getUser = useSelector(state => state.auth);
    const { PROJECT_NAME, TOTAL_USERS, ACTIVE_USERS, 
        TOTAL_HOURS_SPENT, MAINTASKS } = useWebProperties();

    const [, extension] = (getUser.user.userName).split('@')

    async function Network(api, state) {
        setResult([])
        setLoader(true)
        let dateFilter = [{
            "startDate": moment(filter[0].startDate).format("YYYY-MM-DD"),
            "endDate": moment(filter[0].endDate).format("YYYY-MM-DD"),
            "key": "selection"
        }]
        try {
            setMessage('');
            const response = await ReportsAPI.post(`/${api}`, { filter: dateFilter, account_id: extension === 'agile24x7.com' ? "" : getUser.user.account_id }, {}, false)
            /* Commented for vaidated the old filter code on Super admin and Client accounts
            var projects = [];
            extension === 'agile24x7.com'
                ?
                response.map((x) => {
                    return (x.email ? projects.push(x) : null)
                })
                :
                response.map((x) => {
                    return (x.email.includes(extension) ? projects.push(x) : null)
                })
            */
            state(response)
        }
        catch (error) {
            setMessage(error.message)
            setLoader(false)
            console.log('error', error.message)
        }
    }
    useEffect(() => {
        Network('projectReport', setResult)
        // eslint-disable-next-line
    }, [filter])
    const [message, setMessage] = useState("")

    useEffect(() => {
        if (result.length > 0) {
            $(document).ready(function () {
                window.$('#example').DataTable({
                    destroy: true,
                    dom: 'Bfrtip',
                    aLengthMenu: [
                        [25, 50, 100, 200, -1],
                        [25, 50, 100, 200, "All"]
                    ],
                    iDisplayLength: -1,
                    buttons: [
                        { extend: 'excelHtml5', text: 'Export' }
                    ]
                })
            })
        }
    }, [result])

    const handleOpen = (index, action, column_name) => {
        setOpen({ status: true, index: index, action: action, column_name });
        let dateFilter = [{
            "startDate": moment(filter[0].startDate).format("YYYY-MM-DD"),
            "endDate": moment(filter[0].endDate).format("YYYY-MM-DD"),
            "key": "selection"
        }]
        var info = {};
        if (action === "activeUsers") {
            info = {
                project_id: result[index].project_id,
                project_name: result[index].project_name,
                action: 'getProjectActiveStoryUsers',
                dateFilter,
                column_name
            }
        } else if (action === "hours") {
            info = {
                project_id: result[index].project_id,
                project_name: result[index].project_name,
                action: 'getProjectWorkingHours',
                dateFilter,
                column_name
            }
        } else if (action === 'getIndividualUserstory') {
            info = {
                project_id: result[index].project_id,
                project_name: result[index].project_name,
                action: 'getIndividualUserstory',
                dateFilter,
                column_name
            }
        } else {
            info = {
                project_id: result[index].project_id,
                project_name: result[index].project_name,
                action: 'userReports',
                column_name
            }
        }
        setInfo(info);
    };
    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
    }
    // const csvData = [
    //     [PROJECT_NAME, TOTAL_HOURS_SPENT, ACTIVE_USERS, INACTIVE_USERS, TOTAL_USERS],
    //     ...result.map(({ project_name, hours, Working_users_per_projects, user_stories_per_project, Total_users_per_project }) => [
    //         project_name, Math.round(hours), Working_users_per_projects, user_stories_per_project, Total_users_per_project
    //     ]),
    // ];
    // const handleComponent=()=>{
    //     setActiveComponent(!false)
    // }
    return (
        <div className="container-scroller">  
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                {
                                    message && (<><br /><br /><span style={{ color: 'red', marginLeft: '10%' }}>{message}</span></>)
                                }
                              {/* costing */}
                                         {/* <div className="d-flex align-items-center justify-content-between mt-1"> */}

{/* <div> */}


                                <div className='p-2'>
                                    <div className='pt-2' />
                                     {result.length !== 0 ?
                                        <div className="table-responsive">
                                            <table
                                                search="true"
                                                id="example" className="table table-striped table-bordered rwd-table"
                                                style={{ width: '100%', overflow: "auto" }}
                                                data-pagination="true"
                                            >
                                                <thead style={{ backgroundColor: '#F4FAF7' }}>
                                                    <tr>
                                                        <th>{PROJECT_NAME}</th>
                                                        <th>{TOTAL_HOURS_SPENT}</th>
                                                        <th>{ACTIVE_USERS}</th>
                                                 {/* <th>{ACTIVE_USERS}</th>  */}
                                                        <th>No of {MAINTASKS}</th>
                                                        <th>{TOTAL_USERS}</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.length !== 0 ? result.map((x, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td style={{ height: 40, fontWeight: 'bold', color: 'green' }}>{x.project_name}</td>
                                                                    <td style={{ height: 40 }} onClick={() => handleOpen(index, "hours", TOTAL_HOURS_SPENT)}><p style={{ fontWeight: 'bold', color: 'blue' }}>{getDecimalHours(x.hours)}</p></td>
                                                                    <td style={{ height: 40 }} onClick={() => handleOpen(index, "activeUsers", ACTIVE_USERS)}><p style={{ fontWeight: 'bold', color: 'blue' }}>{x.Working_users_per_projects}</p></td>
                                                         {/* <td>{x.active_users_per_project}</td>  */}
                                                                    <td style={{ height: 40 }} onClick={() => handleOpen(index, "getIndividualUserstory", `No of ${MAINTASKS}`)}><p style={{ fontWeight: 'bold', color: 'blue' }}>{x.user_stories_per_project}</p></td>
                                                                    <td style={{ height: 40 }} onClick={() => handleOpen(index, "projects", TOTAL_USERS)}><p style={{ fontWeight: 'bold', color: 'blue' }}>{x.Total_users_per_project}</p></td>
                                                                </tr>
                                                            )
                                                        }) : null}
                                                </tbody>
                                            </table>
                                        </div>
                                        : 
                                        loader ?
                                        <RootLoader /> :
                                        null}                                        
                                        
                                    {
                                        (open.action === "projects" || open.action === "hours" || open.action === "activeUsers" || open.action === "getIndividualUserstory") ? <UserProjectInfo open={open.status} data={info} handleClose={handleClose} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                
        </div>
    )
}