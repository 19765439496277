import React, { useEffect, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import $ from 'jquery';
import { initialState, rankReducer } from './rankReducer';
import { getUserRanks } from './Services/getUserRanks';
import moment from 'moment';
import './ranks.css';
import RootLoader from '../../Common/Loader/RootLoader';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '85%',
        height: '80vh',
        bgcolor: 'background.paper',
        border: '0',
        boxShadow: 24,
        p: 4,
    },
}));

export default function UserRanksInfo(props) {
    const [state, dispatch] = useReducer(rankReducer, initialState)
    const classNames = useStyles();

    useEffect(() => {
        getUserRanks(dispatch, props.data.userId, props.data.filter)
        // eslint-disable-next-line
    }, [props.data])

    useEffect(() => {
        if (state.userRanks.length > 0) {
            $(document).ready(function () {
                window.$('#user-info-table').DataTable({
                    destroy: true,
                    dom: 'Bfrtip',
                    aLengthMenu: [
                        [25, 50, 100, 200, -1],
                        [25, 50, 100, 200, "All"]
                    ],
                    iDisplayLength: -1,
                    buttons: [
                        { extend: 'excelHtml5', text: 'Export' }
                    ]
                })
            })
        }
    }, [state.userRanks])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center" style={{ height: '80vh' }}>
                            <div className='modal-content  p-2' style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <div className="column">
                                        <h6 class="modal-title p-1">UserName : {props.data.userName}</h6>
                                    </div>
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={props.handleModalClose} className="d-flex align-items-right" data-dismiss="modal"><i class="mdi mdi-close text-black"></i></button>
                                </div>
                                <div className="modal-body" style={{ overflow: 'auto' }}>
                                    {state.isLoading ? <RootLoader /> :
                                        <div className="overflow-auto" style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                                            {state.userRanks.length > 0 ?
                                                <table className='table table-bordered mb-1 tableUserRanks' id="user-info-table" search="true">
                                                    <thead style={{ backgroundColor: '#F4FAF7' }}>
                                                        <tr>
                                                            <th>Award On</th>
                                                            <th title='Does the person understand from others communication and vice versa'>Communication</th>
                                                            <th title='Capability to eliminate roadblocks for others and ensure availability to assist in removing their barriers'>Leadership</th>
                                                            <th title='Demonstrating teamwork and being available when needed to support the team and achieve their goals'>team</th>
                                                            <th title='Takes personal responsibility for tasks, actively works towards achieving organizational goals, and feels accountable for any failures'>Ownership</th>
                                                            <th title='Delivers within committed timelines, especially when it matters most, and understands the criticality to deliver what is needed'>Execution</th>
                                                            <th>Percentage(%)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {state.userRanks.length > 0 ? state.userRanks.map((rank, i) => (
                                                            <tr key={rank.r_id} style={{ height: '10px' }}>
                                                                <td>{(moment(rank.start_date).format('ll'))} - {(moment(rank.end_date).format('ll'))}</td>
                                                                <td>{rank.communication}</td>
                                                                <td>{rank.leadership}</td>
                                                                <td>{rank.team}</td>
                                                                <td>{rank.ownership}</td>
                                                                <td>{rank.execution}</td>
                                                                <td>{rank.percentage} %</td>
                                                            </tr>
                                                        )) : null}
                                                    </tbody>
                                                </table>
                                                : null}
                                        </div>
                                    }
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-success" onClick={props.handleModalClose} style={{ borderRadius: '20px' }}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}