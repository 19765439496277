import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ReportsAPI from '../../Common/AgileNetwork/ReportsAPI';
import { useSelector } from 'react-redux';
import moment from 'moment';
import $ from 'jquery';
import { getSubStringId } from '../../Common/SubStringConvert';
import { useWebProperties } from '../../Common/webProperties';
import { getDecimalHours } from '../../Common/convertDecimalHoursToTime';
import { getOnlyTimeZone } from '../../Common/showOnlyTimeZone';
import convertPSTtoLocalTime from '../../Common/convertPSTtoLocalTime';
import { isMobile } from "react-device-detect";
import MainTaskChatBox from '../../Common/ChatMainTask';
import ChatWorkflow from '../../Common/ChatWorkflow';
import RootLoader from '../../Common/Loader/RootLoader';
import Alert from '../../Common/Alert';
import API from '../../Common/Network/API';
import ChatBox from '../../Common/SquadChat';

//import { FilePond, registerPlugin } from 'react-filepond'
// import { create } from "../../../vendor/doka.esm.min";
// import { isLoaded, isLoading } from '../Actions/loading';
//import { isLoaded, isLoading } from '../../Common/Actions/loading';
// import 'filepond/dist/filepond.min.css';
// import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '85%',
        height: '80vh',
        bgcolor: 'background.paper',
        border: '0',
        boxShadow: 24,
        p: 4,
    },
}));

export default function UserProjectInfo(props) {
    const getUser = useSelector(state => state.auth)
    const [result, setResult] = useState([])
    const [message, setMessage] = useState("")
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [cardInfo, setCardInfo] = useState()
    const [loader, setLoader] = useState(false)
    const [editIndex, setEditIndex] = useState(null);
    const [editData, setEditData] = useState({});
    const [allUserMessages, setAllUserMessages] = useState([])
    const [allTaskMessages, setAllTaskMessages] = useState([])
    const classNames = useStyles();
    const [title,setTitle] = useState()
    //const [docFile, setDocFile] = useState({});
    let  totalPay = 0;
    const [values, setValues] = useState({
        start_date: '',
        end_date: '',
        text: '',
        budget: 0,
      });
    // const [text, setText] = useState('one');
    const [editing, setEditing] = useState({
        start_date: false,
        end_date: false,
        text: false,
        budget: false,
      });
      const [tempValues, setTempValues] = useState({ ...values });

      // Reusable handler for toggling edit state
  const toggleEdit = (field) => {
    setEditing((prevEditing) => ({
      ...prevEditing,
      [field]: !prevEditing[field],
    }));
    if (!editing[field]) {
        // If entering edit mode, set tempValues to current values
        setTempValues({ ...values });
      }
      setValues(result[0])
  };
  // Handler for updating temporary values
  const handleTempChange = (event) => {
    const { name, value } = event.target;
    setTempValues((prevTempValues) => ({
      ...prevTempValues,
      [name]: value,
    }));
  };

  // Handler for updating values (dates or text)
//   const handleChangeProject = (event) => {
//     const { name, value } = event.target;
//     setTempValues((prevTempValues) => ({
//         ...prevTempValues,
//         [name]: value,
//       }));
//   };
  const handleSubmit = async(field) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: tempValues[field],
    }));
    toggleEdit(field);
    // Network('updateProjectInfo', { project_id: props.data.project_id,budget:900}, ...setResult)
    try {
const startDate =new Date(tempValues.start_date ? tempValues.start_date : result[0].start_date? result[0].start_date:'' ); 
const endDate1 =new Date(tempValues.end_date ? tempValues.end_date : result[0].end_date?result[0].end_date:'' );
// Validate that end_date is not earlier than start_date
if(endDate1 && startDate>endDate1){
    Alert('warning','Start date cannot be greater than End date.')
}

else if (startDate && endDate1<startDate){
    Alert('warning','End date cannot be earlier than Start date.')
}
else{
    let payloadValue = tempValues[field];

        // If the field is a date, format it to remove the time part
        if (field === 'end_date' || field === 'start_date') {
          payloadValue = new Date(payloadValue).toISOString().split('T')[0]; // Extract only the date part
        }

        // Create the data object for the API request
        let data = {
          project_id: props.data.project_id,
          [field]: payloadValue,
        };
        const response = await ReportsAPI.post(`/${'updateProjectInfo'}`, data, {}, false)
    
        // Check for response success
        if (response) {
            
          Network('getProjectDetails', { project_id: props.data.project_id,filter: props.data.dateFilter }, setResult)

        } else {
        
        }
}  
      } catch (error) {
        console.error(error);
      }

  };
  const handleCancel = (field) => {
    toggleEdit(field);
    // Reset tempValues to the original values
    setTempValues({ ...values });
  };

    const { ASSIGNED_STORY_POINTS, MAINTASKNAME, MAINTASKS, PROJECTS_INVOLVED, STORY_POINTS, CHAT, MAINTASK, SCRUM_MASTER, PRODUCT_OWNER, role_array,AGILE_PROJECT_NAME } = useWebProperties();
    const properties = useSelector(state => state.landingReducer.properties);
    const accessRole = (role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER);
    async function Network(api, data, state) {
        setResult([])
        setLoader(true)
        try {
            const response = await ReportsAPI.post(`/${api}`, data, {}, false)
            state(response)
            setLoader(false)
        }
        catch (error) {
            setLoader(false)
            setMessage(error.message)
        }
    }

    async function NetworkSupportProjectReport(api, data, state) {
        try {
            const response = await ReportsAPI.post(`/${api}`, data, {}, false)
            setResult([])
            state(response)
        }
        catch (error) {
            setMessage(error.message)
        }
    }
    useEffect(() => {
        getAllUserMessages(getUser.user)
        getAllTaskMessages(getUser.user)
        fetchTitle(props.data.action)
        if (props.data.action ==='costingProject'){
            Network('getcostingProjects', { user_id: props.data.user_id,filter: props.data.dateFilter }, setResult)

        }
        if (props.data.action ==='getTotalUsers'){
            Network('getProjectCostingUsers', { project_id: props.data.project_id,filter: props.data.dateFilter }, setResult)

        }
        //getproject based users
        if (props.data.action ==='totalUsers'){
            Network('getTotalUsers', { emp_id: props.data.user_id,filter: props.data.dateFilter }, setResult)

        }
// action: "totalTasks"

         if (props.data.action ==='totalTasks'){
            Network('getcostingTasks', { emp_id: props.data.user_id,filter: props.data.dateFilter }, setResult)

        }

//working project based tasks & project working hours
        if (props.data.action ==='totalProjectTasks' || props.data.action==='totalProjectTasks_hours' || props.data.action ==='totalProjectIncome' ){
            Network('getcostingTasks', { project_id: props.data.project_id,filter: props.data.dateFilter }, setResult)

        }
        //project info details
        if(props.data.action ==='getProjectInfo'){
            Network('getProjectDetails', { project_id: props.data.project_id,filter: props.data.dateFilter }, setResult)

        }

        if (props.data.action === 'userReports') {
            Network('projectUserReport', { project_id: props.data.project_id }, setResult)
        }
        if (props.data.action === 'getProjectActiveStoryUsers') {
            Network('getProjectActiveStoryUsers', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === 'getProjectWorkingHours') {
            Network('getProjectWorkingHours', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === 'getIndividualUserstory') {
            Network('getIndividualUserstory', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getUserTimeSheets") {
            Network('getWorkingHours', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getAllStories") {
            Network('getAllStories', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getOnlyStories") {
            Network('getAllStories', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getUtilizationStories") {
            Network('getAllStories', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getUtilizationHours") {
            Network('getWorkingHours', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getUserProjects") {
            Network('getUserProjectsById', { user_id: props.data.user_id, filter: props.data.dateFilter, account_id: props.data.account_id }, setResult)
        }
        if (props.data.action === "projectName") {
            Network('projectName', { email: props.data.email }, setResult)
        }
        if (props.data.action === "getUserAccounts") {
            Network('getUserAccounts', { user_id: props.data.user_id }, setResult)
        }
        if (props.data.action === "getTotalTicketsReport") {
            NetworkSupportProjectReport('getTotalTicketsReport', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "responseTime") {
            NetworkSupportProjectReport('getResponseTimeReport', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "totalResolved" || props.data.action === "totalPending") {
            NetworkSupportProjectReport('getPendingCompletedReport', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
        }
        if(props.data.action === "getUtsTasks"){
            let epic_id = ""
            switch (props.data.metric){
                case "Total Hours Worked on Support":
                    epic_id = props.data["_epicid_support"]
                    props.data["Associate"] && setTitle(props.data["Associate"].concat(' - ',"Support"))
                    break
                case "Total Hours Worked on Testing":
                    epic_id = props.data["_epicid_testing"]
                    props.data["Associate"] && setTitle(props.data["Associate"].concat(' - ',"Testing"))
                    break
                case "Total Hours Worked on UX":
                    epic_id = props.data["_epicid_ux"]
                    props.data["Associate"] && setTitle(props.data["Associate"].concat(' - ',"UX"))
                    break
                default:
                    epic_id =null
            }
            NetworkSupportProjectReport(props.data.action,{project_id:props.data.project_id, filter:props.data.filter,emp_id:props.data["_emp_id"],epic_id:epic_id},setResult)
        }

        if (props.data.action === "getUtsUserDetails") {
            NetworkSupportProjectReport('getUtsUserDetails', { project_id: props.data.project_id, emp_id: props.data["_emp_id"] }, setResult)
        }

        if (props.data.action === "getSprintReportTasks"){
            NetworkSupportProjectReport('getSprintReportTasks', { project_id: props.data.project_id, emp_id: props.data.emp_id, sprint_id: props.data.sprint_id}, setResult)
        }

        // eslint-disable-next-line
    }, [])

    const getAllUserMessages = async (getUser) => {
        try {
            var response = await API.post("squad_chat.php", {
                corp_code: getUser.corp,
                action: "get_all_messages",
                sendBy: getUser.empId,
            }, {}, false);
            if (response.status === 'True') {
                (setAllUserMessages(response.data));
            } else {

            }
        } catch (error) {
            Alert('error', error.message)
        }
    }

    const getAllTaskMessages = async (getUser) => {
        try {
            var response = await API.post(
                "user_story_chat.php",
                {
                    corp_code: getUser.corp,
                    action: "getAllTaskMessages",
                },
                {},
                false
            );
            if (response.status === "True") {
                setAllTaskMessages(response.data);
            } else {
            }
        } catch (error) {
            Alert("error", error.message);
        }
    }

    useEffect(() => {
        if (result.length > 0) {
            $(document).ready(function () {
                window.$('#user-info-table').DataTable({
                    destroy: true,
                    dom: 'Bfrtip',
                    aLengthMenu: [
                        [25, 50, 100, 200, -1],
                        [25, 50, 100, 200, "All"]
                    ],
                    iDisplayLength: -1,
                    buttons: [
                        { extend: 'excelHtml5', text: 'Export' }
                    ]
                })
            })
        }
    }, [result])

    const fetchTitle = (title) => {
        switch (title) {
            case PROJECTS_INVOLVED:
                setTitle(PROJECTS_INVOLVED.concat(" - ", props.data.email))
                break
            case 'getTotalTicketsReport':
            case 'responseTime':
            case 'totalResolved':
            case 'totalPending':
            case 'getSprintReportTasks':
                setTitle(props.data.column_name)
                break
            case 'getUtsUserDetails':
                setTitle(props.data["Associate"])
                break
            default:
                setTitle(props.data.project_name || props.data.name)
                break
        }
    }

    const modCol5Array = ['getAllStories', 'getIndividualUserstory', 'getUserTimeSheets', 'getUtilizationStories', 'getUtilizationHours', 'getUserProjects', 'getOnlyStories']

    const modalBoox = (modCol5Array.includes(props.data.action)) ? "modal-content  p-2" : "modal-content  p-2"

    const getMessagesCount = (id, msg, empId, data) => {
        const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })
        return (
            <i>
                {
                    msgCount.length > 0 ?
                        <div style={{ display: 'flex', justifyContent: 'start' }}>
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={() => handleOpen("maintask", data, msgCount)} />
                            <span style={{ color: 'red', fontWeight: "bold", marginTop: '-5px' }}>{msgCount.length > 9 ? "+9" : msgCount.length}</span>
                        </div>
                        :
                        <div style={{ display: 'flex', justifyContent: 'start' }}>
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={() => handleOpen("maintask", data, msgCount)} />
                        </div>
                }
            </i>
        )
    }
    const getWorkflowMessagesCount = (msg, data, empId) => {
        let parts = data.split("-");
        let id = parts[0];
        let subtask_id = parts[1]

        const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === data).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })

        return (
            <i>
                {
                    msgCount.length > 0 ?
                        <div style={{ display: 'flex', justifyContent: 'start' }}>
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={() => handlChatOpen("workflow_task", id, subtask_id, msgCount)} />
                            <span style={{ color: 'red', fontWeight: "bold", marginTop: '-5px' }}>{msgCount.length > 9 ? "+9" : msgCount.length}</span>
                        </div>
                        :
                        <div style={{ display: 'flex', justifyContent: 'start' }}>
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={() => handlChatOpen("workflow_task", id, subtask_id, msgCount)} />
                        </div>
                }
            </i>
        )
    }

    const handleOpen = (action, data, sno) => {
      
        var info
        setOpen({ status: true, action: action });
        if (action === 'maintask') {
            info = {
                us_id: data.id, action: action, id: data.id, sno: sno, main_task_id: data.main_task_id,
                story_type: data.story_type, project_id: data.project_id, project_name: data.project_name
            }
        }
        if (action === 'totalTasks') {
            info = {
                us_id: data.id, action: action, id: data.id, sno: sno, main_task_id: data.main_task_id,
                story_type: data.story_type, project_id: data.project_id, project_name: data.project_name
            }
        }
        setCardInfo(info);

    };

    const handlChatOpen = (action, us_id, subtask_id, sno) => {
        var info
        setOpen({ status: true, action: action });
        if (action === 'workflow_task') {
            info = { id: us_id, subtask_id: subtask_id, action: action, sno: sno }
        }
        setCardInfo(info)
    }

    const handleClose = () => {
        setOpen({ status: false, index: 0 })
        getAllUserMessages(getUser.user)
        getAllTaskMessages(getUser.user)
    };

    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
        getAllUserMessages(getUser.user)
        getAllTaskMessages(getUser.user);
    }

    const handleTimeFlag = async (flag, story_id, task_type, main_task_id, story_type, us_id, project_name, project_id, assigned_to, assigned_by, device_id, player_id, type) => {

        if (getUser.user.role === 'Admin') {
            setLoader(true)
            const response = await ReportsAPI.post(`/createTimeSheetFlag`,
                {
                    flag: flag,
                    story_id: story_id,
                    task_type: task_type,
                },
                {}, false)
            if (response.message === 'flag created') {
                if (type === 'getIndividualUserstory') {
                    Network('getIndividualUserstory', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
                }
                if (type === 'getWorkingHours') {
                    let message
                    if (flag === '1') {
                        message = `Flag Created: The target time for ${MAINTASK} ${getSubStringId(project_name, 2)}-${us_id} has been exceeded. Please provide an explanation for the delay.`;
                    } else {
                        message = `Flag Closed: The issue regarding ${MAINTASK} ${getSubStringId(project_name, 2)}-${us_id} has been resolved. The flag is now closed.`;
                    }
                    sendMessageToEmp(message, project_id, device_id, player_id, assigned_to)
                    Network('getWorkingHours', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
                }
            }
        } else {
            if (getUser.user.empId !== assigned_to) {
                if (getUser.user.empId === assigned_by || accessRole) {
                    setLoader(true)
                    const response = await ReportsAPI.post(`/createTimeSheetFlag`,
                        {
                            flag: flag,
                            story_id: story_id,
                            task_type: task_type,
                        },
                        {}, false)
                    if (response.message === 'flag created') {
                        if (type === 'getIndividualUserstory') {
                            let message
                            if (flag === '1') {
                                message = `Flag Created: The target time for ${MAINTASK} ${getSubStringId(project_name, 2)}-${us_id} has been exceeded. Please provide an explanation for the delay.`;
                            } else {
                                message = `Flag Closed: The issue regarding ${MAINTASK} ${getSubStringId(project_name, 2)}-${us_id} has been resolved. The flag is now closed.`;
                            } sendMessageToEmp(message, project_id, device_id, player_id, assigned_to)
                            Network('getIndividualUserstory', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
                        }
                        if (type === 'getWorkingHours') {
                            Network('getWorkingHours', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
                        }
                    }
                } else {
                    Alert('warning', 'You cannot have access to flag timesheet records.')

                }
            } else {
                Alert('warning', 'You cannot have access to flag timesheet records.')
            }
        }
    }

    const handleEditClick = (index, rowData) => {
        setEditIndex(index);
        setEditData(rowData);
    };

    const handleSaveClick = async () => {

        let start_time = moment(editData.start_time).format('YYYY-MM-DD HH:mm:ss')
        let end_time = moment(editData.end_time).format('YYYY-MM-DD HH:mm:ss')

        let startMoment = moment(start_time, 'YYYY-MM-DD HH:mm:ss');
        let endMoment = moment(end_time, 'YYYY-MM-DD HH:mm:ss');

        if (endMoment.isBefore(startMoment)) {
            Alert('warning', `${MAINTASK} End time cannot be before ${MAINTASK} start time`);
        } else {
            setLoader(true)
            setEditIndex(null);
            setEditData({});
            const response = await ReportsAPI.post(`/editTimeSheetRecord`,
                {
                    t_id: editData.t_id,
                    start_time: startMoment,
                    end_time: endMoment,
                },
                {}, false)
            if (response.message === 'Time sheet record updated') {
                Network('getWorkingHours', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)

            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditData(prevState => ({ ...prevState, [name]: value }));
    };


    const sendMessageToEmp = async (message, project_id, device_id, player_id, assigned_to) => {
        try {
            // eslint-disable-next-line
            await API.post("squad_chat.php", {
                action: "send",
                corp_code: getUser.user.corp,
                sendBy: getUser.user.empId,
                receivedBy: assigned_to,
                message: message,
                messagedBy: getUser.user.empId,
                device_id: device_id, //for notifications
                player_id: player_id, //for notifications
                projectId: project_id,
                reply_id: "",
            }, {}, false);
        } catch (error) {
            console.log(error)
        }
    }


    const getUserMessagesCount = (msg, emp) => {
        const msgCount = msg?.filter(message => message.sendBy === emp?.user_id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })

        return (
            <i>
                {
                    msgCount.length > 0 ?
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-10px" }}
                                onClick={() => handleOpenChat(emp, "user_chat", msgCount)} />
                            <span style={{ color: 'red', fontWeight: "bold", marginLeft: "-2px", cursor: 'pointer' }}>{msgCount.length > 9 ? "9+" : msgCount.length}</span>
                        </div>
                        :
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-10px", cursor: 'pointer' }}
                                onClick={() => handleOpenChat(emp, "user_chat", msgCount)} />
                        </div>
                }
            </i>
        )
    }

    const handleOpenChat = (emp, action, sno) => {
        setOpen({ status: true, action: action });
        var info = {
            id: emp.user_id,
            name: emp.name,
            action: action,
            email: emp.email,
            device_id: emp.device_id,
            player_id: emp.player_id,
            sno: sno,
            u_id: emp.u_id
        }

        setCardInfo(info);
    };

    //
      // console.log(BaseUrl)
    // for sending file or images
  //  const fileHandle = async (file) => {
   //     if (file !== "" && file.name !== undefined) {
    //setDisable(true)
          //  dispatch(isLoading());
         //   const data = new FormData();
         //    data.append('file', file);
         //    data.append('message', file.name);
            // // required data for send message
            // data.append('action', 'media');
            // data.append('projectId', getUser.user.projectId);
            // data.append('corp_code', getUser.user.corp);
            // data.append('sendBy', getUser.user.empId);
            // data.append('receivedBy', props.data.id);
            // data.append('messagedBy', getUser.user.empId);
            // data.append('api', CHATAPI);
            // data.append('reply_id', commentDetails.id ? commentDetails.id : "");
          //  try {
                // eslint-disable-next-line
            //    var response = await API.post("squad_send_file.php", data
            //        , {}, false);
            //        console.log('file is upload')
                // getMessages(dispatch, getUser.user, props.data);
                // dispatch(actions.comment(""));
                // setChat(0)
               //  setDocFile({})
                // setDisable(false)
                // setHandleHover(false);
                // setCommentDetails({});
                // setHideButton(false)
          //  } catch (error) {
           //     console.log(error)
                // setDisable(false)
                // setHideButton(false)
         //   }
            // setDisable(false)
            // dispatch(isLoaded());
            // setHideButton(false)
       // } else {
            // setDisable(false)
            // setHideButton(false)
            // setErrorMessage("Please type the message")

      //  }
   // }
    // const handleDoc = (file) => {
    //     const fileData = file.file
    //     // console.log("file",fileData)
    //     setDocFile(fileData)
    // }
    // project dates
    // const handleDateChange = (event) => {
    //     const { name, value } = event.target;
    //     setDates({
    //       ...dates,
    //       [name]: value
    //     });
    //   };

      
    // const toggleEdit = () => {
    //     setIsEditable(!isEditable);
    //   };
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center" style={{ height: '80vh' }}>
                            <div className={modalBoox} style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <div className="column">
                                        {props.data.action === 'getTotalTicketsReport' || props.data.action === 'responseTime' || props.data.action === 'totalResolved' || props.data.action === 'totalPending' || props.data.action === 'getUtsTasks' || props.data.action === 'getUtsUserDetails'
                                        || props.data.action === 'getSprintReportTasks'
                                            ?
                                            (<h6 class="modal-title p-1">{title}</h6>)
                                            :
                                            (
                                                props.data.action === "getUserTimeSheets" ?
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div>
                                                            <h6 class="modal-title p-1">{title} - {props.data.column_name}</h6>
                                                        </div>
                                                        {
                                                            props.data.from === 'admin' ?
                                                                <div style={{ marginLeft: '35px' }}>
                                                                    {
                                                                        getUserMessagesCount(allUserMessages, props.data)
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    :
                                                    <h6 class="modal-title p-1">{title} - {props.data.column_name}</h6>
                                            )
                                        }
                                    </div>
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={props.handleModalClose} className="d-flex align-items-right" data-dismiss="modal"><i class="mdi mdi-close text-black"></i></button>
                                </div>
                                <div className="modal-body" style={{ overflow: 'auto' }}>
                                    <div className="overflow-auto" style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                                        {props.data.action === 'Projects Involved' && (result.length > 0 ?
                                            result.map(({ project_name }, index) => {
                                                return (
                                                    <p className="text-left" > <h6 className="pt-1" style={{ color: 'grey', textTransform: "capitalize" }}>{index + 1}. {project_name}</h6></p>
                                                )
                                            }) : <p>{message}</p>)}
                                        {props.data.action === 'userReports' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>User</th>
                                                        <th>Active Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ working_status, fullname }, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{fullname}</td>
                                                                    <td style={{ color: working_status === 'Active' ? 'green' : 'red' }}>{working_status}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getUserProjects' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>Project</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ project_name }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>)}
                                        {/* costing projects */}
                                        {props.data.action === 'costingProject' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>Project ID</th>
                                                        <th>Project Name</th>
                                                        <th>Total Tasks</th>
                                                        <th>Total Hours </th>
                                                        {/* <th>Bill Rate</th> */}
                                                        <th>Pay Rate ($)</th>
                                                        {/* <th>Net Income </th> */}
                                                        <th>Total Cost ($)
                                                            <br/>(Total Hours * Pay Rate)
                                                        </th>




                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ Project_Name,Tasks,Bill_Rate,Working_Hours}, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{Project_Name}</td>
                                                                    <td>{Tasks}</td>
                                                                    <td>{Working_Hours !== null ? getDecimalHours(Working_Hours) : '0'}</td>                     
                                                                    {/* <td>{Bill_Rate}</td> */}

                                                                    <td>{props.data.pay_rate}</td>
                                                                    
                                                                    {/* <td>{ (x.data[0].Total_No_of_Hours_Worked !== null && x.pay_rate !==0) ? (x.data[0].Total_No_of_Hours_Worked * x.pay_rate):"0"}</td> */}

                                                                    <td>{(Working_Hours!== null && props.data.pay_rate!==0)? (`${(Working_Hours * props.data.pay_rate).toFixed(2)}`) : '0'}</td>
 
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{''}</p>)}

                                        
                                        {/* get costing total tasks */}
                                        {props.data.action === 'totalTasks' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>Task ID</th>
                                                        <th>Tasks</th>
                                                        <th>Project Name</th>
                                                        {/* <th>Estimated Hours</th> */}
                                                        {/* <th>Start Time</th>
                                                        <th>End Time </th> */}
                                                        <th>Working Hours </th>
                                                        <th>Pay Rate ($)</th>
                                                        <th>Task Cost ($) 
                                                            <br/>(working hours * pay rate)
                                                            </th>
                                                        <th>Status</th>
                                                        <th>Chat</th>
                                                    </tr>
                                                </thead>
                                                 <tbody>
                                                    {
                                                        result.map(({ project_name,us_id,story_title,story_points,start_time,end_time,working_hours ,  task_type,story_id,main_task_id,story_type,project_id,task_status}, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    {/* <td>{us_id}</td> */}
                                                                    <td>{getSubStringId(project_name, 2)}-{us_id}</td>

                                                                    <td>{story_title}</td>
                                                                    <td>{project_name}</td>
                                                                    {/* <td>{story_points}</td> */}

                                                                    {/* <td>{start_time !== null ? getDecimalHours(start_time) : '0'}</td>                      */}

                                                                    {/* <td>{end_time !== null ? <>{moment(end_time).format('MM/DD/YYYY hh:mm:ss A')}{" "}{getOnlyTimeZone()}</> : '0'}</td>                      */}
                                                                    
                                                                    <td>{working_hours !== null ? getDecimalHours(working_hours) : '0'}</td>  
                                                                    <td>{props.data.pay_rate!==0 ? props.data.pay_rate: 0}</td>
                                                                    <td>{(props.data.pay_rate!==0 && working_hours !== null && working_hours !==0) ? (props.data.pay_rate * working_hours).toFixed(2):0 }</td>
                                                                    <td>{task_status}</td>                   
                                                                    {/* <td>{(Working_Hours!== null && props.data.pay_rate!==0)? (`${Working_Hours * props.data.pay_rate}/hr`) : '0'}</td> */}
                                                                   
                                                                     <td>
                                                                                     {
                                                                                         task_type === 'main_task' ?

                                                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '20px', padding: "0", marginRight: 10, marginBottom: 'auto', marginTop: '5px', display: isMobile ? "none" : {} }}>
                                                                                                {
                                                                                                   getMessagesCount(us_id, allTaskMessages, getUser.user.empId, {
                                                                                                    id: us_id,
                                                                                                    userstory_id: story_id,
                                                                                                    main_task_id: main_task_id,
                                                                                                    story_type: story_type,
                                                                                                    project_name: project_name,
                                                                                                    project_id: project_id
                                                                                                })
                                                                                                }
                                                                                            </button>
                                                                                            :
                                                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '20px', padding: "0", marginRight: 10, marginBottom: 'auto', marginTop: '5px', display: isMobile ? "none" : {} }} >
                                                                                                {
                                                                                                    getWorkflowMessagesCount(allTaskMessages, us_id, getUser.user.empId)
                                                                                                }
                                                                                            </button>

                                                                                    }
                                                                                </td>

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody> 
                                            </table>
                                            : <p>{''}</p>)}
                                       {/*  */} 

                                       {/*project- total user */}
                                       {props.data.action === 'getTotalUsers' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>User Name</th>
                                                        <th>Role</th>
                                                     


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ hours_worked,pay_rate,total_tasks,user_name,user_role}, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{user_name}</td>
                                                                    <td>{user_role? user_role :''}</td>
                                                           

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>)}

                                        {/*Total project tasks  */}
                                        {props.data.action === 'totalProjectTasks' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>Task ID</th>
                                                        <th>Task Title</th>
                                                      
                                                        <th>Assigned To</th>
                                                      
                                                        <th>Status</th>
                                                        <th>Chat</th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ project_name,us_id,story_title,story_points,start_time,end_time,working_hours,story_id,main_task_id,story_type,project_id,task_type,task_status,pay_rate,assignedto}, index) => {
                                                            return (
                                                                <tr key={index}>
                                                        <td>{getSubStringId(project_name, 2)}-{us_id}</td>
                                                        <td>{story_title}</td>
                                                        <td>{assignedto ? assignedto : ''}</td>                                                       
                                         
                                                                    <td>{task_status}</td>
                                                                   
                                                                    <td>
                                                                                     {
                                                                                         task_type === 'main_task' ?

                                                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '20px', padding: "0", marginRight: 10, marginBottom: 'auto', marginTop: '5px', display: isMobile ? "none" : {} }}>
                                                                                                {
                                                                                                   getMessagesCount(us_id, allTaskMessages, getUser.user.empId, {
                                                                                                    id: us_id,
                                                                                                    userstory_id: story_id,
                                                                                                    main_task_id: main_task_id,
                                                                                                    story_type: story_type,
                                                                                                    project_name: project_name,
                                                                                                    project_id: project_id
                                                                                                })
                                                                                                }
                                                                                            </button>
                                                                                            :
                                                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '20px', padding: "0", marginRight: 10, marginBottom: 'auto', marginTop: '5px', display: isMobile ? "none" : {} }} >
                                                                                                {
                                                                                                    getWorkflowMessagesCount(allTaskMessages, us_id, getUser.user.empId)
                                                                                                }
                                                                                            </button>

                                                                                    }
                                                                                </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>)}

                                        {/* project working hours */}
                                        {props.data.action === 'totalProjectTasks_hours' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>Task ID</th>
                                                        <th>Task Title</th>                                                  
                                                        <th>Working Hours </th>
                                                      
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ project_name,us_id,story_title,story_points,start_time,end_time,working_hours,story_id,main_task_id,story_type,project_id,task_type,task_status,pay_rate,assignedto}, index) => {
                                                            return (
                                                                <tr key={index}>
                                                        <td>{getSubStringId(project_name, 2)}-{us_id}</td>
                                                        <td>{story_title}</td>
                                                         <td>
                                                        {working_hours === null || parseFloat(working_hours) === 0 ? '0' : getDecimalHours(working_hours)}
                                                         </td>                                                            
                                                        </tr>
                                                            )
                                                        })
                                                     
                                                    }
                                                    
                                                </tbody>
                                                {/* summary */}
                                                <tbody>
                                                <tr className="table-success">
                                                        <td >
                                                            <p style={{opacity:'0'}} >z</p>
                                                        </td>
                                                       <td style={{ textAlign: 'right', fontWeight: 'bold', }} 
                                                       className="table-success">Total :</td>
                                                       <td  className="table-success" style={{ fontWeight: 'bold', }} > {props.data.totalHoursWorked === 0 ? '0' :
                                                       `${getDecimalHours(props.data.totalHoursWorked)}`
                                                        }</td>
                                                        </tr>
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>)}

                                        {/* project based Income */}
                                        {props.data.action === 'totalProjectIncome' && (result.length > 0 ?
                                        <>                                          <table
                                         // className='table table-sm table-striped table-bordered'
                                           className='table table-bordered mb-1'
                                           id="user-info-table"
                                           >
                                          <thead>
                                              <tr>
                                                  <th>Task ID</th>
                                                  <th>Task Title</th>
                                                  <th>Total Hours</th>
                                                  <th>Pay Rate (₹)</th>
                                                  <th>Task Cost (₹)</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              {
                                                  result.map(({ project_name, us_id, story_title, working_hours, pay_rate }, index) => {
                                                    totalPay +=parseInt(pay_rate);

                                                    
                                                    return(
                                                      <tr key={index}>
                                                          <td>{getSubStringId(project_name, 2)}-{us_id}</td>
                                                          <td>{story_title}</td>
                                                          <td>
                                                              {working_hours === null || parseFloat(working_hours) === 0 ? '0' : getDecimalHours(working_hours)}
                                                          </td>
                                                          <td>{pay_rate}</td>
                                                          <td>{((working_hours !== null && pay_rate !== 0) || parseFloat(working_hours) !== 0) ? `${(working_hours * pay_rate).toFixed(2)}` : '0'}</td>
                                                      </tr>
                                                  )}
                                                
                                                )
                                              }

                                          </tbody>
                                          {/* summary */}
                                          <tbody>
                                          <tr  className="table-success"  style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                   <td   className="table-success">
                                                    <p style={{opacity:'0'}} >z</p></td>
                                                  <td className='' style={{ textAlign: 'right', fontWeight: 'bold' }}>Total :</td>
                                                  <td style={{ textAlign: 'left',  fontWeight: 'bold'}}>{props.data.totalHoursWorked === 0 ? '0' :
                                                       `${getDecimalHours(props.data.totalHoursWorked)}`
                                                        }</td>
                                                 <td style={{ textAlign: 'left', fontWeight: 'bold'  }}>{totalPay}</td>
                                                  <td style={{ textAlign: 'left', fontWeight: 'bold'  }}> {props.data.totalProjectIncome}</td>
                                                 
                                              </tr>

                                          </tbody>
                                      </table>
                                       
                                      </>

                                      
                                            : <p>{message}</p>)}

                                        {/* projects information at prject costing */}
                                        {props.data.action === 'getProjectInfo'  ?
                                        <>
                                                                                                 
                                         {result.map(({ project_name,project_id,start_date,end_date,budget}, index) => {

                                        return(

                                        
                                            <div>

                                            <div className="card">
                                                <div className="card-body m-1">
                                                    <div className='card-header'>
                                                    <span className="text-success text-bold" style={{ fontSize: 15 }}> Project Information</span>

                                                    </div>
                                                    
                                                     <div className='m-2'>

                                                     

                                                 <div className="col-12 col-md-6 mb-3 d-flex justify-content-between ">
                                                <h1 className="h5 mt-3">Project ID :</h1>
                                                <p className='mt-3'>{project_id ? project_id :''}</p>
                                             </div>
                                               <div className="col-12 col-md-6 mb-3 d-flex justify-content-between ">
                                                <h1 className="h5 mt-3">Start Date:</h1>
                                                {editing.start_date ? (
            <div className="d-flex align-items-end">
              <input
                type="date"
                name="start_date"
                 value={tempValues.start_date || start_date}
                // value={start_date}

                onChange={handleTempChange}
              />
              {getOnlyTimeZone()}
              <button className="btn btn-danger mt-1 mr-1 ml-1" onClick={() => handleCancel('start_date')}>
                Cancel
              </button>
              <button className="btn btn-primary mt-1" onClick={() => handleSubmit('start_date')}>
                Save
              </button>
            </div>
          ) : (
            <div className="d-flex align-items-end">
              <p>{start_date? start_date: ''}</p>

              <img
                alt=""
                style={{ width: '20px', height: '20px', marginLeft: '10px', cursor: 'pointer' }}
                src={"/images/common/flag-edit.png"}
                onClick={() => toggleEdit('start_date')}
              />
            </div>
          )}
        

                                              </div> 
                                              <div className="col-12 col-md-6 mb-3 d-flex justify-content-between ">
                                                <h1 className="h5 mt-3">End Date:</h1>
                                                {/*  */}
                                                {editing.end_date ? (
            <div className="d-flex align-items-end">
              <input
                type="date"
                name="end_date"
               value={tempValues.end_date || end_date}
                onChange={handleTempChange}
              />
              {getOnlyTimeZone()}
              <button className="btn btn-danger mt-1 mr-1 ml-1" onClick={() => handleCancel('end_date')}>
                Cancel
              </button>
              <button className="btn btn-primary mt-1" onClick={() => handleSubmit('end_date')}>
                Save
              </button>
            </div>
          ) : (
            <div className="d-flex align-items-end">
              <p>{end_date? end_date: ''}</p>

              <img
                alt=""
                style={{ width: '20px', height: '20px', marginLeft: '10px', cursor: 'pointer' }}
                src={"/images/common/flag-edit.png"}
                onClick={() => toggleEdit('end_date')}
              />
            </div>
          )}


                                              </div> 
                                              <div className="col-12 col-md-6 mb-3 d-flex justify-content-between ">
                                                <h1 className="h5 mt-3">Budget:</h1>
                                                {editing.budget ? (
            <div className="d-flex align-items-end">
              <input
                type="number"
                name="budget"
                value={tempValues.budget}
                onChange={handleTempChange}
                style={{ backgroundColor: 'transparent', border: '1px solid black' }}
                autoFocus
              />
              <button className="btn btn-danger mt-1 mr-1 ml-1" onClick={() => handleCancel('budget')}>
                Cancel
              </button>
              <button className="btn btn-primary mt-1" onClick={() => handleSubmit('budget')}>
                Submit
              </button>
            </div>
          ) : (
            <div className="d-flex align-items-end">
              <p>{budget? budget:''}</p>
              <img
                alt=""
                style={{ width: '20px', height: '20px', marginLeft: '10px', cursor: 'pointer' }}
                src={"/images/common/flag-edit.png"}
                onClick={() => toggleEdit('budget')}
              />
            </div>
          )}
        </div>
                                              </div>
        
                                                    </div>




                                                  </div>
                                                </div>
                                        ) 
                                        })
                                    }




                                        
                                        
                                
                                         {/* // Risks and Issues */}
                                          {/* <div className="card">
                                         <div className="card-body m-1">
                                             <div className='card-header'>
                                             <span className="text-success text-bold" style={{ fontSize: 15 }}> Risks & Issues</span>

                                             </div>
                                              <div>

                                          <div className="col-12 col-md-6 d-flex justify-content-between ">
                                            <button className='btn btn-green text-bold' >Current Risks</button>
                                            {isEditing ? (
                                                <div  className='col-12 col-md-6 m-1 d-flex justify-content-center'>
                                                <input type="text"value={text}  style={{ backgroundColor: 'transparent', border: '1px solid black',  }}
                                                  onChange={(e) => setText(e.target.value)}
                                                   autoFocus
                                                  />
                                             <button className="btn btn-danger m-1" onClick={()=>setIsEditing(false)}>
                                                  Cancel
                                                </button> &nbsp;&nbsp;
                                                  <button className="btn btn-primary m-1" onClick={handleSubmit}>
                                                  Submit
                                                </button>
                                                </div>
                                                      ) : (
                                                        <div className="d-flex align-items-end">
                                                        <h1 className="h5">{text}</h1>                                                                                         
                                                         <img alt="" style={{ width: '20px', height: '20px', marginLeft: '10px', cursor: 'pointer' }} src={"/images/common/flag-edit.png"}
                                                         onClick={handleEdit}
                                                          />
                                                        
                                                      </div>
                                            )}

                                        
                                      </div> 
                                      {/* <div className="col-12 col-md-6 d-flex justify-content-between">
          <button className="btn btn-green text-bold">Current Risks</button>
          {editing.text ? (
            <div className="col-12 col-md-6 m-1 d-flex justify-content-center">
              <input
                type="text"
                name="text"
                value={tempValues.text}
                onChange={handleTempChange}
                style={{ backgroundColor: 'transparent', border: '1px solid black' }}
                autoFocus
              />
              <button className="btn btn-danger m-1" onClick={() => handleCancel('text')}>
                Cancel
              </button>
              <button className="btn btn-primary m-1" onClick={()=>handleSubmit('text')}>
                Submit
              </button>
            </div>
          ) : (
            <div className="d-flex align-items-end">
              <h1 className="h5">{values.text}</h1>
              <img
                alt=""
                style={{ width: '20px', height: '20px', marginLeft: '10px', cursor: 'pointer' }}
                src={"/images/common/flag-edit.png"}
                onClick={() => toggleEdit('text')}
              />
            </div>
          )}
        </div>
                                    
 
                                             </div> 




                                           </div>
                                         </div>
                                         {/* Documents & Resources */}
                                         {/* <div className="card">
                                         <div className="card-body m-1">
                                             <div className='card-header'>
                                             <span className="text-success text-bold" style={{ fontSize: 15 }}> Documents & Resources</span>

                                             </div>
                                             <div className="col-12 col-md-6 mb-3  d-flex justify-content-between " style={{float:'right'}}>
                             

                                          <FilePond
                                                                allowFileTypeValidation={true}
                                                                fileValidateTypeLabelExpectedTypes={['allTypes']}
                                                                allowMultiple={true}
                                                                // maxFiles={3}
                                                                maxFiles={1}
                                                                instantUpload={false}
                                                                storeAsFile={true}
                                                                // eslint-disable-next-line
                                                                credits={"", ""}
                                                                onaddfilestart={(file) => {
                                                                    handleDoc(file)
                                                                   // setHideButton(true)
                                                                }}
                                                                // for show uplode icon
                                                                // allowProcess = {false}
                                                                // start for image editor
                                                                allowReorder={true}
                                                                // imageEditInstantEdit = {true}
                                                                onpreparefile={(file, output) => {
                                                                    // console.log("prepare file", file.getMetadata());
                                                                    // console.log("prepare", output);
                                                                    const img = document.createElement("img");
                                                                    img.src = URL.createObjectURL(output);
                                                                }}
                                                                imageResizeUpscale={false}
                                                                imageResizeMode={"contain"}
                                                                imageEditIconEdit='<svg width="26" height="26" viewBox="0 0 26 26" 
                                                                  xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M8.5 17h1.586l7-7L15.5 8.414l-7 7V17zm-1.707-2.707l8-8a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414l-8 8A1 1 0 0 1 10.5 19h-3a1 1 0 0 1-1-1v-3a1 1 0 0 1 .293-.707z"
                                                                   fill="blue" fill-rule="nonzero" stroke="blue" stroke-width="2"></path></svg>'
                                                                // imageEditEditor={create({
                                                                //     onconfirm: () => {
                                                                //        // setHideButton(true)
                                                                //     },
                                                                //     cropMinImageWidth: 128,
                                                                //     cropMinImageHeight: 128
                                                                // })}
                                                                // end for image editor
                                                                server={{
                                                                    // fake server to simulate loading a 'local' server file and processing a file
                                                                    process: (fieldName, file, metadata, load) => {
                                                                        // simulates uploading a file
                                                                        setTimeout(() => {
                                                                            load(Date.now())
                                                                            fileHandle(file)
                                                                            // handleImageChange(file)
                                                                        }, 1000);
                                                                    },
                                                                    load: (source, load) => {
                                                                        // simulates loading a file from the server
                                                                        fetch(source).then(res => res.blob()).then(load);
                                                                    }
                                                                }}
                                                            >
                                          </FilePond>

                                        
                                      </div>
                                              <div>

                                     
                                    
 
                                             </div>
                                   </div>
                                         </div> */}
                                    
                                     </>
                                    //  project details infomation
                                            // <table className='table table-bordered mb-1' id="user-info-table">
                                            //     <thead>
                                            //         <tr>
                                            //             <th>Project Name</th>                                                        
                                            //         </tr>
                                            //     </thead>
                                            //     <tbody>
                                            //         {
                                            //             result.map(({ project_name,us_id,story_title,story_points,start_time,end_time,working_hours,story_id,main_task_id,story_type,project_id,task_type,task_status,pay_rate,assignedto}, index) => {
                                            //                 return (
                                            //            <tr key={index}>
                                            //             <td>{}</td>
                                            //             <td>{}</td>
                                                                   
                                            //             </tr>
                                            //                 )
                                            //             })
                                            //         }
                                            //     </tbody>
                                            // </table>
                                            : <p>{message}</p>}


                                        {props.data.action === 'getUserAccounts' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>Account</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ client_name }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{client_name ? client_name : "Test Account"}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>)}

                                        {props.data.action === 'getProjectWorkingHours' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>User</th>
                                                        <th>Working Hours</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ hours, fullname }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{fullname}</td>
                                                                    <td>{getDecimalHours(hours)}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </table> : <p>{message}</p>)}
                                        {props.data.action === 'getIndividualUserstory' && (
                                            loader ?
                                                <RootLoader />
                                                :
                                                result.length > 0 ?
                                                    <div>
                                                        <table className='table table-bordered mb-1 rwd-table' id="user-info-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>SNo</th>
                                                                    <th>User</th>
                                                                    <th>{MAINTASKNAME}</th>
                                                                    <th>Estimated Hours</th>
                                                                    <th>Working Hours</th>
                                                                    <th>Chat</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    result.map(({ story_title, fullname, working_hours, us_id, story_points, story_id, story_type, task_type, main_task_id, project_name, time_sheet_flag, project_id, assigned_to, assigned_by, device_id, player_id }, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{fullname}</td>
                                                                                <td>{getSubStringId(project_name, 2)}-{us_id}-{story_title}</td>
                                                                                <td>{story_points}</td>
                                                                                <td>
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <div>
                                                                                            {getDecimalHours(working_hours)}
                                                                                        </div>
                                                                                        {/* <div><img alt="" style={{ width: '15px', height: '15px', margin: '5px' }} src={"/images/common/flag-edit.png"} /></div> */}
                                                                                        {
                                                                                            time_sheet_flag === '1' ?
                                                                                                <div><img alt="" style={{ width: '20px', height: '20px', marginLeft: '20px', cursor: 'pointer' }} src={"/images/common/red-flag.png"} onClick={() => handleTimeFlag("0", story_id, task_type, main_task_id, story_type, us_id, project_name, project_id, assigned_to, assigned_by, device_id, player_id, 'getIndividualUserstory')} /></div>
                                                                                                :
                                                                                                <div><img alt="" style={{ width: '20px', height: '20px', marginLeft: '20px', cursor: 'pointer' }} src={"/images/common/black-flag.png"} onClick={() => handleTimeFlag("1", story_id, task_type, main_task_id, story_type, us_id, project_name, project_id, assigned_to, assigned_by, device_id, player_id, 'getIndividualUserstory')} /></div>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        task_type === 'main_task' ?

                                                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '20px', padding: "0", marginRight: 10, marginBottom: 'auto', marginTop: '5px', display: isMobile ? "none" : {} }} >
                                                                                                {
                                                                                                    getMessagesCount(us_id, allTaskMessages, getUser.user.empId, {
                                                                                                        id: us_id,
                                                                                                        userstory_id: story_id,
                                                                                                        main_task_id: main_task_id,
                                                                                                        story_type: story_type,
                                                                                                        project_name: project_name,
                                                                                                        project_id: project_id
                                                                                                    })
                                                                                                }
                                                                                            </button>
                                                                                            :
                                                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '20px', padding: "0", marginRight: 10, marginBottom: 'auto', marginTop: '5px', display: isMobile ? "none" : {} }} >
                                                                                                {
                                                                                                    getWorkflowMessagesCount(allTaskMessages, us_id, getUser.user.empId)
                                                                                                }
                                                                                            </button>

                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    : <p>{message}</p>)}
                                        {props.data.action === 'getUserTimeSheets' && (loader ?
                                            <RootLoader />
                                            :
                                            result.length > 0 ?
                                                <div>
                                                    <table className='table table-bordered mb-1' id="user-info-table">
                                                        <thead>
                                                            <tr>
                                                                <th>SNO</th>
                                                                {/* <th>{PROJECT}</th> */}
                                                                <th>{AGILE_PROJECT_NAME}</th>
                                                                <th>{MAINTASKS || properties.USER_STORIES}</th>
                                                                <th>Date</th>
                                                                <th>Start time</th>
                                                                <th>End time</th>
                                                                <th>Estimated Hours</th>
                                                                <th>Working Hours</th>
                                                                <th>Actions</th>
                                                                <th>Chat</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                result.map((rowData, index) => {
                                                                    const {
                                                                        story_title, total_working_hours, updated_date, us_id, start_time, end_time, story_points,
                                                                        time_sheet_flag, project_name, project_id, main_task_id, task_type, story_type, story_id, assigned_to, device_id, player_id, assigned_by
                                                                    } = rowData;

                                                                    if (editIndex === index) {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{editData.project_name}</td>
                                                                                <td>{`${getSubStringId(editData.project_name, 3)}-${editData.us_id}-${editData.story_title}`}</td>
                                                                                <td>{moment(editData.updated_date).format('MM/DD/YYYY')}</td>
                                                                                <td><input type="datetime-local" name="start_time" value={start_time ? moment(editData.start_time).format('YYYY-MM-DDTHH:mm:ss') : ''} onChange={handleChange} />{" "}{getOnlyTimeZone()}</td>
                                                                                <td><input type="datetime-local" name="end_time" value={end_time ? moment(editData.end_time).format('YYYY-MM-DDTHH:mm:ss') : ''} onChange={handleChange} />{" "}{getOnlyTimeZone()}</td>
                                                                                <td>{editData.story_points}</td>
                                                                                <td>{getDecimalHours(editData.total_working_hours)}</td>
                                                                                <td>
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <img alt="" style={{ width: '20px', height: '20px', marginLeft: '10px', cursor: 'pointer' }} src={"/images/common/save.png"} onClick={() => handleSaveClick()} />
                                                                                        <img alt="" style={{ width: '20px', height: '20px', marginLeft: '20px', cursor: 'pointer' }} src={"/images/common/cancel.png"} onClick={() => setEditIndex(null)} />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{project_name}</td>
                                                                                <td>{`${getSubStringId(project_name, 3)}-${us_id}-${story_title}`}</td>
                                                                                <td>{moment(updated_date).format('MM/DD/YYYY')}</td>
                                                                                <td>{start_time === null ? moment(updated_date).format('MM/DD/YYYY') : <>{moment(start_time).format('MM/DD/YYYY hh:mm:ss A')}{" "}{getOnlyTimeZone()}</>}</td>
                                                                                <td>{end_time === null ? moment(updated_date).format('MM/DD/YYYY') : <>{(moment(end_time).format('MM/DD/YYYY hh:mm:ss A'))}{" "}{getOnlyTimeZone()}</>}</td>
                                                                                <td>{story_points}</td>
                                                                                <td>
                                                                                    <div>{getDecimalHours(total_working_hours)}</div>
                                                                                </td>
                                                                                <td>
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        {time_sheet_flag === '1' ? (
                                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                {
                                                                                                    props.data.from === 'admin' ?
                                                                                                        <img alt="" style={{ width: '20px', height: '20px', marginLeft: '10px', cursor: 'pointer' }} src={"/images/common/flag-edit.png"} onClick={() => handleEditClick(index, rowData)} />
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                                <img alt="" style={{ width: '20px', height: '20px', marginLeft: props.data.from !== 'admin' ? '50px' : '20px', cursor: 'pointer' }} src={"/images/common/red-flag.png"} onClick={() => handleTimeFlag("0", story_id, task_type, main_task_id, story_type, us_id, project_name, project_id, assigned_to, assigned_by, device_id, player_id, 'getWorkingHours')} />
                                                                                            </div>
                                                                                        ) : (

                                                                                            <img alt="" style={{ width: '20px', height: '20px', marginLeft: '50px', cursor: 'pointer' }} src={"/images/common/black-flag.png"} onClick={() => handleTimeFlag("1", story_id, task_type, main_task_id, story_type, us_id, project_name, project_id, assigned_to, assigned_by, device_id, player_id, 'getWorkingHours')} />
                                                                                        )}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        task_type === 'main_task' ?

                                                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '20px', padding: "0", marginRight: 10, marginBottom: 'auto', marginTop: '5px', display: isMobile ? "none" : {} }} >
                                                                                                {
                                                                                                    getMessagesCount(us_id, allTaskMessages, getUser.user.empId, {
                                                                                                        id: us_id,
                                                                                                        userstory_id: story_id,
                                                                                                        main_task_id: main_task_id,
                                                                                                        story_type: story_type,
                                                                                                        project_name: project_name,
                                                                                                        project_id: project_id
                                                                                                    })
                                                                                                }
                                                                                            </button>
                                                                                            :
                                                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '20px', padding: "0", marginRight: 10, marginBottom: 'auto', marginTop: '5px', display: isMobile ? "none" : {} }} >
                                                                                                {
                                                                                                    getWorkflowMessagesCount(allTaskMessages, us_id, getUser.user.empId)
                                                                                                }
                                                                                            </button>

                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                })
                                                            }
                                                        </tbody>
                                                    </table >
                                                </div>

                                                : <p>{message}</p>)}
                                        {props.data.action === 'getProjectActiveStoryUsers' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        <th>Users</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ fullname }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{fullname}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getOnlyStories' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        {/* <th>{PROJECT}</th> */}
                                                        <th>{AGILE_PROJECT_NAME}</th>
                                                        <th>{MAINTASKS}</th>
                                                        <th>{ASSIGNED_STORY_POINTS}</th>
                                                        <th>Complete Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.filter((info) => props.data.task_status === "all" ? props.data.task_status !== info.complete_status : props.data.task_status === info.complete_status).map(({ story_title, project_name, complete_status, us_id, story_points }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                    <td>{getSubStringId(project_name, 3)}{'-'}{us_id}{'-'}{story_title}</td>
                                                                    <td>{story_points}</td>
                                                                    <td style={{ color: complete_status === 'pending' ? "red" : "green" }}>{`${complete_status}`.toUpperCase()}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table >
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getAllStories' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        {/* <th>{PROJECT}</th> */}
                                                        <th>{AGILE_PROJECT_NAME}</th>
                                                        <th>{MAINTASKS}</th>
                                                        <th>Complete Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.filter((info) => props.data.task_status === "all" ? props.data.task_status !== info.complete_status : props.data.task_status === info.complete_status).map(({ story_title, project_name, complete_status, us_id }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                    <td>{getSubStringId(project_name, 3)}{'-'}{us_id}{'-'}{story_title}</td>
                                                                    <td style={{ color: complete_status === 'pending' ? "red" : "green" }}>{`${complete_status}`.toUpperCase()}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table >
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getUtilizationStories' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        {/* <th>{PROJECT}</th> */}
                                                        <th>{AGILE_PROJECT_NAME}</th>
                                                        <th>{MAINTASKS}</th>
                                                        {/* <th>{KANBAN}</th> */}
                                                        <th>{STORY_POINTS}</th>
                                                        <th>{MAINTASKS} Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.filter((info) => props.data.task_status === "all" ? props.data.task_status !== info.complete_status : props.data.task_status === info.complete_status).map(({ story_title, project_name, complete_status, story_points, kanban_status, us_id }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                    <td>{getSubStringId(project_name, 3)}{'-'}{us_id}{'-'}{story_title}</td>
                                                                    {/* <td style={{ color: kanban_status === '0' ? "red" : "green" }}>{kanban_status === "0" ? "NO" : "YES"}</td> */}
                                                                    <td>{story_points}</td>
                                                                    <td style={{ color: complete_status === 'pending' ? "red" : "green" }}>{`${complete_status}`.toUpperCase()}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table >
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getUtilizationHours' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        {/* <th>{PROJECT}</th> */}
                                                        <th>{AGILE_PROJECT_NAME}</th>
                                                        <th>{MAINTASKS}</th>
                                                        {/* <th>{KANBAN}</th> */}
                                                        <th>Date</th>
                                                        <th>Start Time</th>
                                                        <th>End Time</th>
                                                        <th>Estimated Hours</th>
                                                        <th>Working Hours</th>
                                                        {/* <th>{MAINTASKS} Status</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.filter((info) => props.data.task_status === "all" ? props.data.task_status !== info.complete_status : props.data.task_status === info.complete_status).map(({ story_title, project_name, complete_status, working_hours, updated_date, kanban_status, us_id, total_working_hours, start_time, end_time, story_points }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                    <td>{getSubStringId(project_name, 3)}{'-'}{us_id}{'-'}{story_title}</td>
                                                                    {/* <td style={{ color: kanban_status === '0' ? "red" : "green" }}>{kanban_status === "0" ? "NO" : "YES"}</td> */}
                                                                    <td>{moment(updated_date).format('MM/DD/YYYY')}</td>
                                                                    <td>{start_time === null ? moment(updated_date).format('MM/DD/YYYY') : <>{moment(start_time).format('MM/DD/YYYY hh:mm:ss A')}{" "}{getOnlyTimeZone()}</>}</td>
                                                                    <td>{end_time === null ? moment(updated_date).format('MM/DD/YYYY') : <>{(moment(end_time).format('MM/DD/YYYY hh:mm:ss A'))}{" "}{getOnlyTimeZone()}</>}</td>
                                                                    <td>{story_points}</td>
                                                                    <td>{getDecimalHours(total_working_hours)}</td>
                                                                    {/* <td style={{ color: complete_status === 'pending' ? "red" : "green" }}>{`${complete_status}`.toUpperCase()}</td> */}
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table >
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getTotalTicketsReport' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>Created Date & Time</th>
                                                        <th>Platform</th>
                                                        <th>Main Ticket into RB</th>
                                                        <th>Generated Ticket in TODO</th>
                                                        <th>Support Ticket Details</th>
                                                        <th>Assigned by</th>
                                                        <th>Assigned to</th>
                                                        <th>Reassigned to Developer</th>
                                                        <th>Generated Ticket Status</th>
                                                        <th>Main Ticket Status</th>
                                                        <th>Priority/Severity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map((info, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{convertPSTtoLocalTime(info.date)} </td>
                                                                    <td>{info.device_type_blocked || info.device_type_main || 'NA'}</td>
                                                                    <td>{info.mtrb}</td>
                                                                    <td>{info.gtodo ? info.gtodo : 'NA'}</td>
                                                                    <td>{info.support_tkt_details ? info.support_tkt_details : info.name}</td>
                                                                    <td>{info.assigned_by_name}</td>
                                                                    <td>{info.assigned_to_name}</td>
                                                                    <td>{info.reassign_developer ? info.reassign_developer : 'NA'}</td>
                                                                    <td>{info.gen_tkt_status ? info.gen_tkt_status : 'NA'}</td>
                                                                    <td>{info.main_tkt_status}</td>
                                                                    <td>{info.priority_level ? info.priority_level : 'NA'}</td>
                                                                </tr>

                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>
                                        )}
                                        {props.data.action === 'responseTime' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>Task Id</th>
                                                        <th>Ticket Generated Date & Time</th>
                                                        <th>Name</th>
                                                        <th>Assigned by</th>
                                                        <th>Assigned to</th>
                                                        <th>Responded By</th>
                                                        <th>Initial Response Time(Support Team)</th>
                                                        <th>Response Time(User)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map((info, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{info.task_id} </td>
                                                                    <td>{info.created_date ? convertPSTtoLocalTime(info.created_date) : "NA"}</td>
                                                                    <td>{info.name ? info.name : "NA"}</td>
                                                                    <td>{info.assigned_by_name}</td>
                                                                    <td>{info.assigned_to_name}</td>
                                                                    <td>{info.messaged_by_name ? info.messaged_by_name : "NA"}</td>
                                                                    <td>{info.support_resp_time ? `${info.support_resp_time} secs` : 'Not Responding'}</td>
                                                                    <td>{info.user_resp_time ? `${info.user_resp_time} secs` : 'Not Responding'}</td>
                                                                </tr>

                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>
                                        )}
                                        {props.data.action === 'totalResolved' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>Task Id</th>
                                                        <th>Created Date & Time</th>
                                                        <th>Name</th>
                                                        <th>Assigned by</th>
                                                        <th>Assigned to</th>
                                                        <th>Completed Date & Time</th>
                                                        <th>Ticket Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.flatMap((info, index) => {
                                                            const elements = [];
                                                            if (info.main_tkt_status === 'completed') {
                                                                elements.push(
                                                                    <tr key={index}>
                                                                        <td>{info.mtrb} </td>
                                                                        <td>{convertPSTtoLocalTime(info.date)}</td>
                                                                        <td>{info.name}</td>
                                                                        <td>{info.assigned_by_name}</td>
                                                                        <td>{info.assigned_to_name}</td>
                                                                        <td>{convertPSTtoLocalTime(info.main_tkt_end_date)}</td>
                                                                        <td>Main Ticket</td>
                                                                    </tr>
                                                                );
                                                            }

                                                            if (info.gtodo && info.gen_tkt_status === 'completed') {
                                                                elements.push(
                                                                    <tr key={`${index}-gtodo`}>
                                                                        <td>{info.gtodo} </td>
                                                                        <td>{convertPSTtoLocalTime(info.date)}</td>
                                                                        <td>{info.name}</td>
                                                                        <td>{info.assigned_to_name}</td>
                                                                        <td>{info.reassign_developer}</td>
                                                                        <td>{convertPSTtoLocalTime(info.blocked_tkt_end_date)}</td>
                                                                        <td>Blocked Ticket</td>
                                                                    </tr>
                                                                );
                                                            }
                                                            return elements;
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>
                                        )}
                                        {props.data.action === 'totalPending' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>Task Id</th>
                                                        <th>Ticket Generated Date & Time</th>
                                                        <th>Name</th>
                                                        <th>Assigned by</th>
                                                        <th>Assigned to</th>
                                                        <th>Ticket Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.flatMap((info, index) => {
                                                            const elements = [];
                                                            if (info.main_tkt_status === 'pending') {
                                                                elements.push(
                                                                    <tr key={index}>
                                                                        <td>{info.mtrb} </td>
                                                                        <td>{convertPSTtoLocalTime(info.date)}</td>
                                                                        <td>{info.name}</td>
                                                                        <td>{info.assigned_by_name}</td>
                                                                        <td>{info.assigned_to_name}</td>
                                                                        <td>Main Ticket</td>
                                                                    </tr>
                                                                );
                                                            }

                                                            if (info.gtodo && info.gen_tkt_status === 'pending') {
                                                                elements.push(
                                                                    <tr key={`${index}-gtodo`}>
                                                                        <td>{info.gtodo} </td>
                                                                        <td>{convertPSTtoLocalTime(info.date)}</td>
                                                                        <td>{info.name}</td>
                                                                        <td>{info.assigned_to_name}</td>
                                                                        <td>{info.reassign_developer}</td>
                                                                        <td>Blocked Ticket</td>
                                                                    </tr>
                                                                );
                                                            }
                                                            return elements;
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>
                                        )}
                                        {props.data.action === 'getUtsTasks' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        <th>Project Name</th>
                                                        <th>Task Id</th>
                                                        <th>Task Title</th>
                                                        <th>Date</th>
                                                        <th>Estimated Hours</th>
                                                        <th>Working Hours</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map((record, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{record.project_name}</td>
                                                                    <td>{record.us_id}</td>
                                                                    <td>{record.story_title}</td>
                                                                    <td>{convertPSTtoLocalTime(record.date)}</td>
                                                                    <td>{record.estimated_hours}</td> 
                                                                    <td>{record.working_hours ? getDecimalHours(record.working_hours) : '-' }</td>                    
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>

                                                <tbody>
                                                <tr className="table-success">
                                                        <td >
                                                            <p style={{opacity:'0'}} >z</p>
                                                        </td>
                                                        <td >
                                                            <p style={{opacity:'0'}} >z</p>
                                                        </td>
                                                        <td >
                                                            <p style={{opacity:'0'}} >z</p>
                                                        </td>
                                                        <td >
                                                            <p style={{opacity:'0'}} >z</p>
                                                        </td>
                                                        <td >
                                                            <p style={{opacity:'0'}} >z</p>
                                                        </td>
                                                       <td style={{ textAlign: 'right', fontWeight: 'bold', }} 
                                                       className="table-success">Total :</td>
                                                       <td  className="table-success" style={{ fontWeight: 'bold', }} > {props.data[props.data.metric] === 0 ? '0' :
                                                       `${getDecimalHours(props.data[props.data.metric])}`
                                                        }</td>
                                                        </tr>
                                                </tbody>

                                            </table>
                                            : <p>{message}</p>)}
                                        
                                        {props.data.action === 'getUtsUserDetails' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>E-mail</th>
                                                        <th>Phone Number</th>
                                                        <th>User Type</th>
                                                        <th>Support Role</th>
                                                        <th>Shift Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map((uts_user, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{uts_user.name || '-'}</td>
                                                                    <td>{uts_user.email || '-'}</td>
                                                                    <td>{uts_user.mobile_number || '-'}</td>
                                                                    <td>{uts_user.user_type || '-'}</td>
                                                                    <td>{uts_user.support_role || '-'}</td>
                                                                    <td>{uts_user.shift_time || '-'}</td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>
                                        )}
                                        {props.data.action === 'getSprintReportTasks' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        <th>Task ID</th>
                                                        <th>Task Title</th>
                                                        <th>Status</th>
                                                        <th>Task Target Time </th>
                                                        <th>Task Completed Time </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map((record, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{record.task_id}</td>
                                                                    <td>{record.task_title}</td>
                                                                    <td>{record.status}</td>
                                                                    <td>{record.target_time}</td>
                                                                    <td>{record.completed_time ? getDecimalHours(record.completed_time) : '-' }</td>                    
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>)}
                                    </div>
                                </div>
                                {
                                   ( open.action === "maintask" || open.action === "totalTasks") ? <MainTaskChatBox open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                    /> : null
                                }
                                {
                                    (open.action === "workflow_task"|| open.action === "totalTasks") ? <ChatWorkflow open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                    /> : null
                                }
                                {open.action === "user_chat" ? (
                                    <ChatBox
                                        open={open.status}
                                        handleClose={handleClose}
                                        data={cardInfo}
                                        handleModalClose={handleModalClose}
                                    />
                                ) : null}

                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-success" onClick={props.handleModalClose} style={{ borderRadius: '20px' }}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}