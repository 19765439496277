import { isLoaded, isLoading } from "../../Actions/loading";
import Alert from "../../Alert";
import API from "../../Network/API";
import {  taskComments } from "../actions";

//Get Group Comments 
export async function getMessages(dispatch,getUser,data) {
    dispatch(isLoading());
    try {
      var response = await API.post("taskChat.php", {
        corp_code: getUser.corp,
        action: "getmessages",
        groupId: data.id
      }, {}, false);
      if (response.status === 'True') {
         dispatch(taskComments(response.data));
         Alert(JSON.stringify(response.data.path))
      }else{
        dispatch(taskComments([]));
      }
    } catch (error) {
      Alert('error',error.message)
    }
    dispatch(isLoaded());
  }