import { MetaData } from "../MetaData";
import { Helmet } from "react-helmet";
import React from "react";

const Layout=({pageName,children})=>{
    const {title,description,keywords}=MetaData[pageName];

    return(
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name='description' content={description}/>
                <meta name='keywords' content={keywords}/>
            </Helmet>
                  {children}
        </div>
    )

}
export default Layout;
Layout.defaultProps={
    title:'Task management application',
    description:'It is Task manager applicaion',
}

