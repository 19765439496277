import Alert from "../../Alert";

import API from "../../Network/API";
import { awards,isLoaded, isLoading } from "../action";

export async function getUserThanksPoints(dispatch, getUser,empId) {
  dispatch(isLoading());
  try {
    var response = await API.post("usersAwards.php", {
      "crop": getUser.corp,
      empId: empId,
      action: 'getUserAwards',
    }, {}, false);
    // console.log(response)
    if (response.status === 'True') {
      dispatch(awards(response.data))
    }
    else {
      dispatch(awards([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}
  