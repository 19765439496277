import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading } from "../actions";

export async function addTasksComment(dispatch, getUser, taskId, cardId, message) {
    dispatch(isLoading());
    try {
      await API.post("task_comments.php", {
        action: "add_tasks_comments",
        taskId: taskId,
        corp: getUser.corp,
        userId: getUser.empId,
        message: message,
        cardId: cardId
      }, {}, false);
    }
    catch (error) {
      Alert('error', error.message)
    }
    dispatch(isLoaded());
  }