import { isLoaded, workingDays } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";


export async function getWorkingDays(dispatch, getUser) {
  try {
    var response = await API.post(
      "active_time.php",
      {
        projectId: getUser.projectId,
      },
      {},
      false
    );
    if (response.status === "True") {
      dispatch(workingDays(response.data));
    } else {
      dispatch(workingDays([]));
    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
}