import Alert from "../../Alert";

import API from "../../Network/API";
import { isLoaded, isLoading, manageTasks } from "../action";

export async function getManageTasks(dispatch, getUser, empId, role) {
  dispatch(isLoading());
  try {
    var response = await API.post("backlogspage.php", {
      "crop": getUser.corp,
      empId: empId,
      userType: getUser.role, //Based For User level only showing 
      action: 'Empcurrentsprint',
      projectId:getUser.projectId
    }, {}, false);
    if (response.status === 'true') {
      dispatch(manageTasks(response.data))
    }
    else {
      dispatch(manageTasks([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}