import Alert from "../../../Common/Alert";
import { isLoaded, isLoading } from "../actions";
import { getDoneUserStories } from "./getDoneUserStories";
import { getInvolvedEmployees } from "./getInvolvedEmployees";
import { getToDoUserStories } from "./getToDoUserStories";
import { getRoadBlockUserStories } from "./getRoadblockUserStories";

//Get Sprint Cycle User stories if any action performed in sprint cycle only User stories of that sprint can modified and it doesn't goes to active sprint -->GNK --> version 1.0.6.03
export async function getCurrentSprintUserStories(dispatch, getUser, sprintId) {
    dispatch(isLoading());
    try {
      getToDoUserStories(dispatch, getUser, sprintId);
      getRoadBlockUserStories(dispatch, getUser, sprintId);
      // getRoadBlockToKanban(dispatch, getUser, sprintId);
      getDoneUserStories(dispatch, getUser, sprintId);
      getInvolvedEmployees(dispatch, getUser, sprintId);
    } catch (error) {
      Alert("error", error.message);
    }
    dispatch(isLoaded());
  }