import Alert from "../../Alert";

import API from "../../Network/API";
import { isLoaded, isLoading,  roadBlockMessages } from "../action";

export async function getRoadBlockMessages(dispatch,getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("roadblock_chat.php", {
      corp_code: getUser.corp,
      action: "getAllTaskMessages",
    }, {}, false);
    if (response.status === 'True') {
       dispatch(roadBlockMessages(response.data));
    }else{
      dispatch(roadBlockMessages([]));
    }
  } catch (error) {
    Alert('error',error.message)
  }
  dispatch(isLoaded());
}