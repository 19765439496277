import API from "../../../Common/Network/API";
import {  isLoading, isLoaded } from "../actions";
import Alert from "../../Alert";

  //Update the Roadblock Status
  export async function updateStatus(state,dispatch,getUser,roadblockId,handleClose,isChecked){
    // console.log(state.taskDescription + state.taskStatus + isChecked)
    dispatch(isLoading());
    if (state.roadblockDescription !== "" && state.roadblockStatus !== "") {
        try {
            const response = await API.post("userRoadblocks.php", {
                action: "updateRoadblock",
                roadblockId: roadblockId,
                crop: getUser.user.corp,
                roadblockStatus: state.roadblockStatus,
                statusDescription: state.roadblockDescription,
                task_complete_status: isChecked ? 1 : 0,
                empId: getUser.user.empId,
            }, {}, false);
            if (response.status === "True") {
                Alert('success', "Your Roadblock is Updated Successfully")
            } else {
                Alert('warning', response.message)
            }
        }
        catch (error) {
            Alert('error', error.message)
            handleClose()
        }
    }
    else {
        Alert('warning', "please confirm all details before update the roadblock")
    }
    dispatch(isLoaded());
    handleClose()
}