import API from "../../Network/API";
import { isLoaded, isLoading,  tasksCount } from "../action";

export async function getTasksCount(dispatch, getUser, empId) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_employee_info.php", {
      "crop": getUser.corp,
      "empId": empId,
      "projectId": getUser.projectId
    }, {}, false);
    dispatch(tasksCount(response));
  } catch (error) {
    console.log(error)
  }
  dispatch(isLoaded());
}