import Alert from "../../Alert";
import API from "../../Network/API";
import { isLoaded, isLoading, details } from "../action";// getting group chat details

export async function getGroupDetails(dispatch, getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("group_chat.php", {
        "crop": getUser.corp,
        created_by: getUser.userName,
      }, {}, false);
      if (response.status === 'True') {
        dispatch(details(response.data))
      }
      else {
        dispatch(details([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }