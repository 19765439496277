import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading } from "../actions";
import { getEpics } from "./getEpics";

//Update Project/Epic Info
export async function updateEpic(state, dispatch,getUser,handleClose, PROJECT_MODIFY) {
    dispatch(isLoading());
    if ((state.moduleTitle.value.trim() !== "" && state.projectDescription.value.trim() !== "" && state.targetDate.value !== "" && !(state.moduleTitle.errorStatus || state.projectDescription.errorStatus || state.targetDate.errorStatus))) {
        try {
            const data = {
              proj_title: state.moduleTitle.value,
              proj_desc: state.projectDescription.value,
              idea_id:state.projectId.value,
              empId: getUser.empId, //Async
              action: "modify",
              corp: getUser.corp,
              projectId: getUser.projectId,
              targetDate: state.targetDate.value
            };
            const response = await API.post("manage_epics.php", data, {}, false);
            if (response.status === 'True') {
               dispatch(getEpics(dispatch,getUser))
               Alert("success", PROJECT_MODIFY);
            }
        } catch (error) {
            Alert("error", error.message);
        }
        handleClose()
    } else {
        Alert("warning", "please fill project title and project description and target date")
    }
    dispatch(isLoaded());
  }