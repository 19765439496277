import { isLoaded, isLoading } from "../actions";
import Alert from "../../Alert";
import API from "../../Network/API";

//For edit template
export async function updateUserstoryTemplate(state, dispatch, getUser,handleClose, us_id) {
    dispatch(isLoading());
     if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
      try {
        var response = await API.post("manage_template.php", {
          crop: getUser.corp,
          action: "editTemplate",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          added_by: getUser.empId,
          acceptanceCriteria: state.acceptanceCriteria.value,
          "projectId":getUser.projectId,
          device_id: state.device_id,
          player_id: state.player_id,
          us_id: us_id,
          added_to: state.userSelected === "" ? state.id.value : state.userSelected,
          storyPoints: state.storySelected === "" ? state.storyPoints.value : state.storySelected,
          priorityLevel: state.prioritySelected === "" ? state.priorityLevel.value : state.prioritySelected,
          idea_id: state.epicSelected === "" ? state.ideaId.value : state.epicSelected,
  
        }, {}, false);
        if (response.status === 'True') {
          Alert("success", "Template updated successfully");
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
      handleClose()
    }
   
    else {
      Alert("warning", "Please enter required fields ")
      dispatch(isLoaded());
    }
  }