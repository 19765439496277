import React, { useReducer, useState } from 'react';
import Header from '../../Common/TopNav';
// import ToBeAssignedRoadBlock from './toBeAssigned';
// import AssignedRoadBlock from './assigned';
import RootLoader from '../../Common/Loader/RootLoader';
import { roadBlockReducer, initialState } from './roadBlockReducer';
import SideBar from '../Utility/SideNav';
import { useWebProperties } from '../../Common/webProperties';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import BlockedList from './blockedList';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export default function EmployeesRoadBlocks() {
    const getUser = useSelector(state => state.auth)
    // const [check, setCheck] = useState("ToBeCritical")
    const [state, dispatch] = useReducer(roadBlockReducer, initialState)
    const [flag, setFlag] = useState(0)
    const { MODULES, SCRUM_BOARD, KANBAN, SUPPORT_ENABLE } = useWebProperties();

    if (getUser.user.role === 'Contributor' || getUser.user.role === 'Limited Access Contributor') {
        return <Redirect to={`/${MODULES}`} />
    }

    return (
        <div className="container-scroller">
            <Header />
            <div className="container-fluid page-body-wrapper">
                <SideBar />
                <div className="main-panel">
                    <div className="mt-2">
                        <Tabs
                            defaultActiveKey="sprint"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            onClick={() => setFlag(flag + 1)}
                        >
                            <Tab eventKey="sprint" title={`${SCRUM_BOARD}`}>
                                {state.isLoading ? <RootLoader /> : <BlockedList dispatch={dispatch} kanban_status={'0'} flag={flag} />}
                            </Tab>
                            {
                                SUPPORT_ENABLE === '1' ?
                                    <Tab eventKey="profile" title={KANBAN}>
                                        {state.isLoading ? <RootLoader /> : <BlockedList dispatch={dispatch} kanban_status={'1'} flag={flag} />}
                                    </Tab>
                                    :
                                    null
                            }
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>

    )
}