import Alert from "../../Alert";
import API from "../../Network/API";
import { users,isLoaded, isLoading } from "../action";

//For Getting Users List(Team)
export async function getUsers(dispatch, getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("agile_squads.php", {
        "crop": getUser.corp,
        "projectId": getUser.projectId
      }, {}, false);
      if (response.status === 'True') {
        dispatch(users(response.data))
      }
      else {
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
  }