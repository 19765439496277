// userprofile/services/getUserThanksPoints.js  (READ)

import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading, awards } from "../actions";

export async function getUserThanksPoints(dispatch, getUser) {
  dispatch(isLoading());
  try {
      var response = await API.post("usersAwards.php", {
          "crop": getUser.corp,
          empId: getUser.empId,
          action: 'getUserAwards',
      }, {}, false);
    //   console.log(response)
      if (response.status === 'True') {
          dispatch(awards(response.data))
      }
      else {
          dispatch(awards([]))
      }
  } catch (error) {
      Alert('error', error.message);
  }
  dispatch(isLoaded());
}