import React from 'react';
import '../../../Authentication/LandingPage/style.scss';
import { Link } from 'react-router-dom';
import { useWebProperties } from '../../webProperties';
import { useSelector } from 'react-redux';
import Layout from '../../../Optimisation/MetaData/Layout/Layout';

export default function Careers() {

    const { APP_NAME, IMG_SRC } = useWebProperties();
    const webProperties = useSelector(state => state.landingReducer.webProperties)
    const properties = useSelector(state => state.landingReducer.properties)

    return (
        <Layout pageName='careers'>
                   <div>
            <nav className="navbar sticky-top navbar-expand-lg navbar-light" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}>
                <button style={{ background: 'white' }} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
                    aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                    {/* eslint-disable-next-line */}
                    <a className="navbar-brand"><Link to={{ pathname: "/" }}>
                        {/* <img src="images/common/agile2.png" width="170" alt="" /></Link> */}
                        <img className="agile-supportlogo" src={properties?.IMG_SRC || IMG_SRC} width="170" alt="" /></Link>

                    </a>
                    <form className="form-inline my-2 my-lg-0 ml-auto" style={{ paddingTop: "16px" }}>
                        {/* <!-- <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"> --> */}
                        {/* eslint-disable-next-line */}
                        <a className="btn my-2 my-sm-0 ml-auto" id="loginbtn" type="submit"><Link style={{ color: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }} to={{ pathname: "/login" }}>Login</Link></a>
                        {/* eslint-disable-next-line */}
                        <a style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }} className="btn my-2 my-sm-0" id="signupbtn-support" type="submit"><Link style={{ color: 'white' }} to={{ pathname: "/" }}>Home </Link></a>
                        {/* support page button */}
                    </form>
                </div>
            </nav>

            <section id="section1">
                <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '95%' }}>
                    <div className="release-note mt-1">
                        <div className="d-flex bd-highlight"> <div className="p-1 flex-grow-1 bd-highlight"><h2>Careers</h2></div>  </div>
                    </div>
                </div>
            </section>

            {/* collapse view */}
            <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '95%' }}>
                <iframe title="Careers" src="https://rcats.novisync.com/careers?header-view=true&company-name=weTasker" width="100%" height="600px" style={{ border: 'none' }}></iframe>
            </div>
            <footer id="footer-releasenote" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}>
                <div style={{ color: 'white' }}>
                    {webProperties?.APP_NAME || APP_NAME} © Copyright {new Date().getFullYear()}. All Rights Reserved.
                </div>
            </footer>
        </div>
        </Layout>
 
    )
}
