/*
FileName: UserModule/TopNav/index.js
Purpose : Top Nav for Getting the Squads List, Status,User profile and Logout
Developers: Naveen Kumar Gade --> GNK
Changes : 01-->GNK
*/
import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect,useHistory  } from 'react-router-dom';
import { logoutUser, setCurrentUser } from '../../../Common/Actions';
import { getStatus, setStatus, setToken, setRoleCount, getRoleCount } from '../../../Common/LocalStorage';
import API from '../../../Common/Network/API';
import jwt_decode from "jwt-decode";
import Alert from '../../../Common/Alert';
import store from '../../../Common/Store';
import { isLoading, isLoaded } from '../../../Common/Actions/loading';
import RootLoader from '../../../Common/Loader/RootLoader';
import { serverCheck } from '../../../Maintenance/UnderMaintenance/Services/serverCheck';
import { maintenanceReducer, serverInitialState } from '../../../Maintenance/UnderMaintenance/maintenanceReducer';
import { getAdmin } from './Services/getAdmin';
import { getAllMessages } from './Services/getAllMessages';
import { empReducer, initialState } from './reducer';
import { useWebProperties } from '../../../Common/webProperties';
import { Client_URL, ONE_SIGNAL_ID } from '../../../Common/config';
import { isMobile } from 'react-device-detect';
import { getSubStringId } from '../../../Common/SubStringConvert.js'
import { setPlayerId } from '../../../Authentication/Login/network';
import OneSignal from 'react-onesignal';
import { MESSAGE_REFRESH_TIMER } from '../../../Common/commonUsage.js';
import moment from 'moment';
import { logEntry } from '../../../Common/logEntry.js';

export default function TopNav() {
  const dispatch = useDispatch();
   const history = useHistory();

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };
  const getUser = useSelector(state => state.auth)

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  const loaderStatus = useSelector(state => state.loading.status)
  const activities = useSelector(state => state.landingReducer.userActivities)
  const [userStatus, UpdateUserStatus] = useState('');
  const [userSquad, UpdateUserSquad] = useState(getUser.user.corp);//Show the Squad Name
  const [accountInfo, setAccountInfo] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [userSquadList, UpdateUserSquadList] = useState([]) //Show the list of Squad Names Based on username
  const [statusList, updateStatusList] = useState([])
  const [redirect, setRedirect] = useState(false);
  const [squadName, updateSquadName] = useState('')
  const [show, updateShow] = useState(false)
  const [state, dispatch1] = useReducer(maintenanceReducer, serverInitialState)
  const [data, setData] = useReducer(empReducer, initialState)
  const [isSidebarIconOnly, setIsSidebarIconOnly] = useState(false);
  const { IMG_SRC, VERSION, VERSION_DATE, AGILE_PROJECT_NAME, PROFILE, CHANGE_PASSWORD, LOGOUT, NEW_PROJECT,
    SUPPORT, PAYEMENT, CALENDAR, MESSAGES, TODO, CHAT, APP_NAME, KANBAN, MPM_ENABLE, SUPPORT_ENABLE } = useWebProperties();

  useEffect(() => {
    if (getUser?.user?.corp && getUser.user.corp !== userSquad) {
      UpdateUserSquad(getUser.user.corp);
    }
    // eslint-disable-next-line
  }, [getUser?.user?.corp, userSquad]);

  const onLogout = async () => {
    if (userStatus === 'Available') {
      await API.post("user_status.php", {
        corp: getUser.user.corp,
        action: 'update_status',
        status: "Logout",
        empId: getUser.user.empId
      }, {}, false);
    }
    dispatch(logoutUser);
    setRedirect(true)
  }

  const getUserStatus = async () => {
    var response = await API.post("user_status.php", {
      corp: getUser.user.corp,
      action: 'get_status',
      empId: getUser.user.empId
    }, {}, false);
    if (response.status === "True") {
      setStatus(response.user_status)
    }
  }
  useEffect(() => {
    // Get the token from localStorage
    const authToken = localStorage.getItem('auth');
    if (!authToken) {
      history.push('/login');
    }
  }, [history]);
  useEffect(() => {
    getAccounts(getUser.user.empId);
    serverCheck(dispatch1)
    getStatusOfEmployee();
    getUserStatus()
    getSquadsList(getUser.user.empId); // GNK --> 01
    getAdmin(setData, getUser)
    // getAllMessages(setData, getUser.user)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const fetchUserStoryMessages = () => {
      getAllMessages(setData, getUser.user)
    };

    // Function to fetch messages and schedule the next fetch after 10 seconds
    const fetchDataAndScheduleNext = () => {
      fetchUserStoryMessages();
      setTimeout(fetchDataAndScheduleNext, MESSAGE_REFRESH_TIMER); // Schedule next fetch after 10 seconds
    };

    // Initial fetch when component mounts
    fetchUserStoryMessages();

    // Schedule the next fetch after 10 seconds
    const timerId = setTimeout(fetchDataAndScheduleNext, MESSAGE_REFRESH_TIMER);

    // Clean up the timer on component unmount
    return () => clearTimeout(timerId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPlayerId(getUser.user)
  }, [getUser]);

  useEffect(() => {
    OneSignal.init({
      appId: ONE_SIGNAL_ID, //testing
    });
    OneSignal.getUserId().then(function (userId) {
      console.log("OneSignal User ID:", userId);
      setToken('player_id', userId) //store the token information  with exp
    });
  }, []);


  useEffect(() => {
    UpdateUserStatus(getStatus('status'))
    // eslint-disable-next-line
  }, [getStatus('status')])

  const getAccounts = async (empId) => {
    try {
      var response = await API.post("accounts.php", {
        empId: empId,
        action: "get_user_accounts"
      }, {}, false);
      if (response.status === 'True') {
        setAccounts(response.data);
        setAccountInfo(response.data[0]);
      } else {
        setAccounts([])
      }
    } catch (error) {
    }
  }

  if (redirect) {
    if ((getRoleCount('roleCount') >= 1)) {
      window.location.reload();
    } else {
      return <Redirect to="/" />
    }
  }
  //For Redirecting Maintenance Page 
  if (state.server.value === "not connected" || state.server.value === "Network Error") {
    // console.log(JSON.stringify(state.server.value))
    return <Redirect to="/UnderMaintenance" />
  }
  const getStatusOfEmployee = async () => {
    // console.log(getUser.user.corp)
    try {
      var response = await API.post("user_status.php", {
        "corp": getUser.user.corp,
        "action": 'status',
      }, {}, false);
      if (response.status === 'True') {
        updateStatusList(response.data)
      } else {
        updateStatusList([])
      }
    } catch (error) {
      // Alert('error',error.message)
    }
  }
  //Update the Status of Employee
  const UserStatus = async (status) => {
    try {
      const response = await API.post("user_status.php", {
        corp: getUser.user.corp,
        action: 'update_status',
        status: status,
        empId: getUser.user.empId
      }, {}, false);
      if (response.status === 'True') {
        setStatus('status', status)
        UpdateUserStatus(status)
        activities && logEntry({
          user_id: getUser.user.userName, logging_level: 3,
          activity_id: activities["User Status Change"], sub_activity: status
        },getUser)
        // getUser.user.empStatus = status
        // dispatch(setCurrentUser(getUser.user));
      } else {
        UpdateUserStatus(getStatus('status'))
        activities && logEntry({
          user_id: getUser.user.userName, logging_level: 2,
          activity_id: activities["User Status Change"], sub_activity: getStatus('status')
        },getUser)
      }
    } catch (error) {
      // Alert('error',error.message)
      activities && logEntry({
        user_id: getUser.user.userName, logging_level: 2,
        activity_id: activities["User Status Change"], sub_activity: getStatus('status')
      },getUser)
    }
    // dispatch(isLoaded());
  }
  // GNK --> start 01
  // Squads List Getting Based on UserName
  const getSquadsList = async (empId) => {
    try {
      var response = await API.post("squads.php", {
        empId: empId,
        action: "get_squads"
      }, {}, false);
      if (response.status === 'True') {
        UpdateUserSquadList(response.data)
      } else {
        UpdateUserSquadList([])
      }
    } catch (error) {
      // Alert('error',error.message)
    }
  }


  //Squad Change (Shift the squad)
  const SquadChangeStatus = async (projectInfo) => {
 
    try {
      const response = await API.post("squad_login.php", {
        username: getUser.user.empId,
        project_id: projectInfo.id,
        corp: projectInfo.value,
      }, {}, false);
      if (response.status === 'TRUE') {
        localStorage.removeItem('persist:main-root')
        setToken('auth', response.jwt) //store the token information  with exp
        const tokenDetails = jwt_decode(response.jwt);
        setRoleCount('roleCount', tokenDetails.data.roleCount)
        dispatch(setCurrentUser(tokenDetails.data)); //store the user information
        setStatus('status', tokenDetails.data.empStatus)
        setRedirect(true)
        UpdateUserSquad(squadName)
      } else {
        UpdateUserSquad(getUser.user.corp)
      }
    } catch (error) {
      // Alert('error',error.message)
      UpdateUserSquad(getUser.user.corp)
    }
    // dispatch(isLoaded());
  }
  // GNK --> End 01
  // Agile Project Validation GNK --->start 03
  const onValidate = () => {
    var  validationRule = /^[a-zA-Z0-9 ]+$/;
    if (squadName === "") {
      Alert('warning', "please give the project name")
    } else if (!validationRule.test(squadName)) {
      Alert('warning', " please do not use '.' and '-' in between words")
    } else if (squadName.length < 4 || squadName.length > 150) {
      Alert('warning', " project name should having length 4 to 150 characters")
    } else {
      return true
    }
  }
  // GNK --> End 03
  // Agile Project Creation GNK --->start 02
  const onProjectCreate = async () => {
    var user = 'admin@'
    var [, extension] = getUser.user.userName.split('@')
    store.dispatch(isLoading());
    if (onValidate()) { //Validate the project name
      try {
        const start_time = Date.now()
        const response = await API.post("squads.php", {
          adminUsername: user.concat(extension),
          extension: extension,
          username: getUser.user.userName,
          squadName: squadName,
          action: "new_squad",
          empId: getUser.user.empId,
          appName: APP_NAME,
          url: Client_URL,
          account_id: accounts.length === 1 ? getUser.user.account_id : accountInfo.account_id
        }, {
        });
        const end_time = Date.now()
        if (response.status === "True") {
          addKanban(getUser.user.empId, KANBAN, response.projectId)
          createDefaultEpic(getUser.user.empId, response.projectId, squadName)
          Alert('success', response.message)
          setRedirect(true)
          activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Project Management"],
            sub_activity:"Create Project",
            response_time : (end_time-start_time)
          },getUser)
        } else {
          Alert('warning', response.message)
        }
      }
      catch (error) {
        Alert('error', error.message)
        activities && logEntry({user_id:getUser.user.userName,logging_level:2,activity_id:activities["Project Management"],
          sub_activity:"Create Project", error_message:error.message
        },getUser)
      }
    } else {
      // Alert('warning', "please give the project name")
    }
    updateShow(!show)
    store.dispatch(isLoaded());
  }
  function addKanban(getUser, KANBAN, projectId) {
    try {
      const data = {
        module_Name: KANBAN,
        empId: getUser, //Async
        action: "add",
        targetDate: '0001-01-01',
        startDate: '0001-01-01',
        projectId: projectId
      };
      const response = API.post("manage_sprints.php", data, {}, false);
      if (response.status === 'True') {
        //  dispatch(getModules(dispatch,getUser))
        // Alert("success", `${KANBAN} added`);
      }
      else {
        // alert("something went wrong")
      }
    } catch (error) {
      Alert("error", error.message);
    }
  }
  //create defaultModule
  async function createDefaultEpic(empId, project_id, corp) {
    try {
      const data = {
        proj_title: `Default Module`,
        proj_desc: `Default Module`,
        empId: empId, //Async
        action: "add",
        corp: corp,
        projectId: project_id,
        targetDate: moment().startOf('year').format('YYYY-MM-DD'),
      };
      const response = await API.post("manage_epics.php", data, {}, false);
      if (response.status === 'True') {
        console.log("Default Module Created")
      }
    } catch (error) {
      Alert("error", error.message);
    }
  }
  const toggleSidebarIconClass = () => {
    setIsSidebarIconOnly(prevState => !prevState);
    // Toggle the class on the body element
    const body = document.getElementsByTagName('body')[0];
    if (isSidebarIconOnly) {
      body.classList.remove('sidebar-icon-only');
    } else {
      body.classList.add('sidebar-icon-only');
    }
  }
  // console.log("userSquad list",userSquadList)
  // console.log("squad", accountInfo)
  // Agile Project Creation GNK --->End 02
  return (
    <div>
      {/* <ChatBot /> */}
      <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="navbar-brand-wrapper d-flex justify-content-center">
          <div className="navbar-brand-inner-wrapper d-flex justify-content-between align-items-center w-100 topbar topbar-minimize">
            <a className="navbar-brand brand-logo" href="/"><img src={IMG_SRC} alt="logo" /></a>
            <p className="navbar-brand brand-logo pt-3" style={{ fontSize: '10px', paddingRight: '0px', display: isMobile ? "none" : {} }}>{VERSION} <br />{VERSION_DATE}</p>
            <a className="navbar-brand brand-logo-mini" href="/"><img src={IMG_SRC} alt="logo" /></a>
            <button className="navbar-toggler align-self-center" type="button" data-toggle="minimize" onClick={toggleSidebarIconClass}>
              <span className="mdi mdi-sort-variant"></span>
            </button>
          </div>
        </div>
        <div className="navbar-menu-wrapper d-flex  justify-content-end">
          {
            isMobile &&
            <p className="navbar-brand brand-logo pt-3 vrsi" style={{ fontSize: '10px', paddingRight: '0px', backgroundColor: 'white' }}>{VERSION} <br />{VERSION_DATE}</p>
          }

          <div className="mt-2 mr-2">

            <li className="nav-item nav-profile dropdown show" title={AGILE_PROJECT_NAME} style={isMobile ? { marginLeft: '60%' } : {}}>
              {/* {!show ?<span className="text-black mr-2">{AGILE_PROJECT_NAME}</span>:null} */}
              {/* eslint-disable-next-line */}
              <a style={{ border: '2px solid grey', borderRadius: '30px', marginTop: '3px' }} className="btn dropdown-toggle custom-height-button" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="text-black " style={{  display: 'inline-block', maxWidth: isMobile? '30px': '150px', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',lineHeight: '1.3', verticalAlign: 'middle', }}>{isMobile ? getSubStringId(userSquad, 3) : userSquad }</span>
              </a>
              <div className="dropdown-menu dropdown-menu-left navbar-dropdown" aria-labelledby="dropdownMenuLink" style={{ maxHeight: '290px', overflowY: 'auto' }}>

                {/* {!show ? 
              <a className="dropdown-item" onClick={() => updateShow(!show)}>Add Project</a> :

              <div><input style={{ borderRadius: '30px', backgroundColor: 'white',marginTop:'12px' ,borderColor:'black',marginRight:'-50px'}}  type="text" className="form-control-sm " id="projectName" name="projectName" placeholder="Agile Project Name*" onChange={(event) => { updateSquadName(event.target.value)}} /> 
              <button style={{ backgroundColor: 'green',border:'0px',padding:'3px',textAlign:"center",color:"white",borderRadius: '10px',fontSize:'12px'}}  onClick={() => onProjectCreate()}>ADD </button>
              </div>} */}
                {userSquadList.length > 0 ? userSquadList.filter(projects => projects.is_enable === '1').map((list, index) => {
                  return (
                    // eslint-disable-next-line 
                    <a className="dropdown-item" onClick={() =>{ SquadChangeStatus(list);
                      activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                          sub_activity:`Project-${list.value}`
                         },getUser)
                    }}>{list.value}</a>

                  )
                }) : null}
              </div>
            </li>
          </div>
{!isMobile &&
          <div  className='' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' ,marginTop:'0px' }} >
            {loaderStatus ? <RootLoader /> :
              show ?
              
                <div className="nav-item" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <input style={{ borderRadius: '30px', backgroundColor: 'white', borderColor: 'black', marginLeft: '8px', maxWidth: '7rem',minWidth:'5rem' ,height:'40px',border:'2px solid gray'}} type="text" className="form-control-sm " id="projectName" name="projectName" placeholder="Enter Project Name*" onChange={(event) => { updateSquadName(event.target.value) }} />
              {squadName.length >= 3 && !(accounts.length === 1) && (
              <li className="nav-item nav-profile dropdown show ml-1 " title={AGILE_PROJECT_NAME} style={isMobile ? { marginLeft: '60%' } : {}}>
              {/* eslint-disable-next-line */}
              <a style={{ border: '2px solid grey', borderRadius: '30px' }} className="btn dropdown-toggle custom-height-button" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="text-black">{accountInfo.client_name}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-left navbar-dropdown" aria-labelledby="dropdownMenuLink" style={{ maxHeight: '290px', overflowY: 'auto' }}>
                {accounts.length > 0 && squadName.length >= 3 ? accounts.map((list, index) => {              
                  return (
                    // eslint-disable-next-line 
                    <a className="dropdown-item" onClick={() => setAccountInfo(list)}>{list.client_name}</a>

                            )
                          }) : null}
                        </div>
                      </li>)}
                    <button className='' style={{ backgroundColor: 'green', border: '0px', padding: '5px', textAlign: "center", color: "white", borderRadius: '10px', fontSize: '12px', marginLeft: '5px' }} onClick={() => onProjectCreate()} disabled={(squadName === '')}>ADD </button>
                    {/* {show && !loaderStatus ? <button style={{ backgroundColor: 'transparent', border: '0px', height: '30px', width: '20px', marginLeft: '2px' }}  ><img style={{ width: '25px', height: '25px', marginTop: '12px'  }} onClick={() => updateShow(!show)} src="images/common/addremove.png" alt="profile" /></button> : null} */}

                  </div> : null}
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '2px' }}>
                {!show && !loaderStatus ? <button data-toggle="tooltip" data-placement="bottom" title="Create New Agile Project" style={{ backgroundColor: 'transparent', border: '0px', height: '30px', flexDirection: 'row', }} onClick={() => updateShow(!show)}><img src="images/common/add.png" title='New Project' alt="logo" style={{ width: '20px', height: '20px' }} /> Add {NEW_PROJECT}</button> : null}

                {show && !loaderStatus ? <img className='' style={{ width: '25px', height: '25px', }} onClick={() => updateShow(!show)} src="images/common/addremove.png" alt="profile" /> : null}
              </div>
              {/* {show && !loaderStatus ? <button style={{ backgroundColor: 'transparent', border: '0px', height: '30px', width: '20px', marginLeft: '2px' }} onClick={() => updateShow(!show)} ><img style={{ width: '25px', height: '25px', marginTop: '12px' }} src="images/common/addremove.png" alt="profile" /></button> : null} */}

            </div>
          }
          <ul className="navbar-nav navbar-nav-right">
            {
              !isMobile &&
              <li className="" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '0px', marginLeft: '0px' }}>
                {
                  MPM_ENABLE === '1' ?
                    <button type="button" title={TODO} style={{ backgroundColor: 'transparent', border: "0", padding: '1px' }}>
                      <Link to={{ pathname: "/dashboard/todo" }}
                              onClick={() => {
                                activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                                  sub_activity:`${TODO}`
                                },getUser)
                              }}
                      
                      >
                        <img src="images/common/todo.png" alt="TODO logo" style={{ width: '35px', height: '35px', marginTop: '0px' }} />
                      </Link>
                      <p style={{ marginTop: "-5px" }}>{TODO}</p>
                    </button>
                    :
                    null
                }
                <button type="button" title={MESSAGES} style={{ backgroundColor: 'transparent', border: "0", padding: '1px' }}>
                  {data.allMessages.length > 0 ?
                    <div className="">
                      <Link to={{ pathname: "/squadChat" }} 
                              onClick={() => {
                                activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                                  sub_activity:'Squad Chat'
                                },getUser)
                              }}
                      
                      >
                        <img src="images/common/chat.svg" alt="Chat logo" style={{ width: '47px', height: '27px', marginTop: '2px' }} />
                        <span style={{ color: 'red', fontWeight: "bold", marginLeft: "-10px", marginTop: "-29px" }}>{data.allMessages.length > 9 ? "+9" : data.allMessages.length}</span>
                      </Link>
                    </div> :
                    <div className="">
                      <Link to={{ pathname: "/squadChat" }} 
                         onClick={() => {
                          activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                            sub_activity:'Squad Chat'
                          },getUser)
                        }}
                      
                      >
                        <img src="images/common/chat.svg" alt="Chat logo" style={{ width: '47px', height: '27px', marginTop: '2px' }} />
                      </Link>
                    </div>
                  }
                  <p>{CHAT}</p>
                </button>
                <button type="button" title={CALENDAR} style={{ backgroundColor: 'transparent', border: "0", padding: '1px' }}>
                  <Link to={{ pathname: "/calendarDash" }} 
                  
                  onClick={() => {
                    activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                      sub_activity:`${CALENDAR}`
                    },getUser)
                  }}
                  >
                    <img src="images/common/calender.jpeg" alt="Calendar logo" style={{ width: '24px', height: '24px', marginTop: '5px', marginLeft: "7px" }} />
                  </Link>
                  <p>{CALENDAR}</p>
                </button>
                {
                  SUPPORT_ENABLE === '1' ?
                    <button type="button" title={SUPPORT} style={{ backgroundColor: 'transparent', border: "0", padding: '10px' }}>
                      <Link to={{ pathname: "/reportBugUser" }}
                         onClick={() => {
                          activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                            sub_activity:`${SUPPORT}`
                          },getUser)
                        }}
                      
                      >
                        <img src="images/common/support.png" alt="logo" style={{ width: '24px', height: '24px', marginTop: '5px', marginLeft: "7px" }}
                        />
                      </Link>
                      <p style={{ paddingLeft: '6px' }}>{SUPPORT}</p>
                    </button>
                    :
                    null
                }
              </li>
            }
            <li className="nav-item nav-profile dropdown show" style={isMobile ? { margin: '25px' } : {}}>
              <div className="status-container"><span className="text-black mr-2">Status</span>
                {
                  isMobile ?
                    // eslint-disable-next-line
                    <a style={{ border: '2px solid grey', borderRadius: '30px', width: '120px !important', marginRight: '65px !important' }} className="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span className="text-black">{userStatus? userStatus : 'Available'}</span>
                    </a>
                    :
                    // eslint-disable-next-line
                    <a style={{ border: '2px solid grey', borderRadius: '30px' }} className="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span className="text-black">{userStatus? userStatus : 'Available'}</span>
                    </a>
                }

                <div className="dropdown-menu dropdown-menu-right navbar-dropdown" labelled="dropdownMenuLink">
                  {statusList.length > 0 ? statusList.map((list, index) => {
                    return (
                      // eslint-disable-next-line 
                      <a className="dropdown-item " onClick={() => UserStatus(list.value)}>{list.value}</a>

                    )
                  }) : null}
                </div></div>
            </li>
            <li className="nav-item nav-profile dropdown">
              <div className='row' style={isMobile ? { marginLeft: '25%' } : {}}>
                {/* {getUser.user.email_status === '1' ? <span className="text-success mr-3 ">{VERIFIED_ACCOUNT} </span>:<span className="text-danger mr-3">{NOT_VERIFIED_ACCOUNT}</span>} */}
                {/* eslint-disable-next-line */}
                <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
                  <img src="images/common/user1.svg" alt="profile" style={isMobile ? { width: '30px' } : {}} />
                  <span style={isMobile ? { display: 'none' } : {}} className="nav-profile-name">{getUser.user.fullName}</span>
                  {/* {getUser.user.email_status === '1' ?  <span className='text-success' title={VERIFIED_ACCOUNT} style={{marginTop:'10px',fontSize:'20px',fontWeight:'bold'}}>☑</span> : null} */}
                </a>
                {/* {
                  !isMobile &&
                  <div id="google_translate_element"></div>
                } */}
                <div className="dropdown-menu navbar-dropdown" labelled="profileDropdown">
                  {/* eslint-disable-next-line */}
                  <Link className="dropdown-item" to={{ pathname: "/userProfile" }}
                          onClick={() => {
                            activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                              sub_activity:`${PROFILE}`
                            },getUser)
                          }}
                  
                  >
                    <i className="mdi mdi-account text-primary"></i>
                    {PROFILE}
                  </Link>

                  <Link className="dropdown-item" to={{ pathname: "/payement" }}
                  
                  onClick={() => {
                    activities &&  logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                      sub_activity:`${PAYEMENT}`
                    },getUser)
                  }}
                  >
                    <i className="mdi mdi-paypal text-primary"></i>
                    {PAYEMENT}
                  </Link>
                  <Link className="dropdown-item" to={{ pathname: "/changePasswordUser" }}>
                    <i className="mdi mdi-security text-primary"></i>
                    {CHANGE_PASSWORD}
                  </Link>
                  {/* eslint-disable-next-line */}
                  <a className="dropdown-item" onClick={() => onLogout()}>
                    <i className="mdi mdi-logout text-primary"></i>
                    {LOGOUT}
                  </a>
                </div>
              </div>
            </li>

          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center hmb" type="button" data-toggle="offcanvas">
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
      {
        isMobile &&
        <nav class="navbar navbar-expand-lg navbar-white bg-white fixed-bottom-nav" style={{
          zIndex: 1100,
          borderTop: '1.1px solid #b4b4b4'
        }}>
          <div className="navbar-menu-wrapper d-flex justify-content-center" style={{ width: '90%' }}>
            <ul className="navbar-nav navbar-nav-right nbr">
              <li className="nav-item">
                {
                  MPM_ENABLE === '1' ?
                    <button type="button" title={TODO} style={{ backgroundColor: 'transparent', border: "0" }}>
                      <Link to={{ pathname: "/dashboard/todo" }}
                            
                  onClick={() => {
                    activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                      sub_activity:`${TODO}`
                    },getUser)
                  }}
                      
                      >
                        <img src="images/common/todo.png" alt="logo" style={{ width: '35px', height: '35px', marginTop: '0px' }}
                        />
                      </Link>
                      <p style={{ marginTop: "-5px" }}>{TODO}</p>
                    </button>
                    :
                    null
                }
                <button type="button" title={MESSAGES} style={{ backgroundColor: 'transparent', border: "0", padding: isMobile ? 0.5 : 1 }} >
                  {data.allMessages.length > 0 ?
                    <div className="row">
                      <Link to={{ pathname: "/squadChat" }} 
                      
                            
                  onClick={() => {
                    activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                      sub_activity:"Squad Chat"
                    },getUser)
                  }}
                      
                      >
                        <img src="images/common/chat.svg" alt="logo" style={{ width: '47px', height: '27px', marginTop: '2px' }}
                        /><span style={{ color: 'red', fontWeight: "bold", marginLeft: "-10px", marginTop: "-29px" }}>{data.allMessages.length}</span>
                      </Link>
                    </div> :
                    <div className="row">
                      <Link to={{ pathname: "/squadChat" }}
                                        onClick={() => {
                                          activities &&  logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                                            sub_activity:"Squad Chat"
                                          },getUser)
                                        }}
                      
                      >
                        <img src="images/common/chat.svg" alt="logo" style={{ width: '47px', height: '27px', marginTop: '2px' }}
                        /></Link>
                    </div>
                  }
                  <p>{CHAT}</p>
                </button>
                <button type="button" title={CALENDAR} style={{ backgroundColor: 'transparent', border: "0", }}>
                  <Link to={{ pathname: "/calendarDash" }}
                  
                  onClick={() => {
                    activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                      sub_activity:`${CALENDAR}`
                    },getUser)
                  }}
                  
                  >
                    <img src="images/common/calender.jpeg" alt="logo" style={{ width: '24px', height: '24px', marginTop: '5px', marginLeft: "7px" }}
                    />
                  </Link>
                  <p>{CALENDAR}</p>
                </button>
                {
                  SUPPORT_ENABLE === '1' ?
                    <button type="button" title={SUPPORT} style={{ backgroundColor: 'transparent', border: "0", padding: isMobile ? 0.5 : 1 }}>
                      <Link to={{ pathname: "/reportBugUser" }}
                                        onClick={() => {
                                          activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                                            sub_activity:`${SUPPORT}`
                                          },getUser)
                                        }}
                      
                      >
                        <img src="images/common/support.png" alt="logo" style={{ width: '24px', height: '24px', marginTop: '5px', marginLeft: "7px" }}
                        />
                      </Link>
                      <p>{SUPPORT}</p>
                    </button>
                    :
                    null
                }
              </li>
            </ul>
          </div>
          {/* <div style={{ marginLeft: '30%' }} id="google_translate_element"></div> */}
        </nav>

      }
    </div>
  )

}