import { isLoaded, isLoading, activeSprint } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { getInvolvedEmployees } from "./getInvolvedEmployees";
import { getCurrentSprint } from "./getCurrentSprint";

//for getting active sprint id
export async function getActiveSprints(dispatch, getUser) {
  dispatch(isLoading());
  try {
    // Current Active Sprint Name with epic by -->GNK -->01 on version 1.0.4 start
    // var response = await API.post("getSprints.php", {
    //   "crop": getUser.corp,
    //   "userType": getUser.role,
    //   "empId": getUser.empId,
    //   "action": "getActiveSprint"
    // }, {}, false);
    // Current Active Sprint Name with epic by -->GNK -->01 on version 1.0.4 end

    // Current Active Sprint witout epic by -->SS -->01 on version 1.0.6 start
    var response = await API.post("getUpdateSprint.php", {
      "crop": getUser.corp,
      "action": "get_sprints",
      "projectId": getUser.projectId
    }, {}, false);
    // Current Active Sprint witout epic by -->SS -->01 on version 1.0.6 end
    if (response.status === 'True') {
      const moduleId = response.data[0].moduleId
      dispatch(activeSprint(response.data[0]))
      getInvolvedEmployees(dispatch, getUser, moduleId);
      getCurrentSprint(dispatch, getUser, moduleId)
    }
    else {
      // getUnactiveSprintTasks(dispatch, getUser)
      dispatch(activeSprint([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}