import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoading, isLoaded, allMessages } from "../actions";

// for getting all user story messages
export async function getAllUserstoriesMessages(dispatch, getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post(
        "user_story_chat.php",
        {
          corp_code: getUser.corp,
          action: "getAllTaskMessagesDash",
        },
        {},
        false
      );
      if (response.status === "True") {
        dispatch(allMessages(response.data));
      } else {
        dispatch(allMessages([]));
      }
    } catch (error) {
      Alert("error", error.message);
    }
    dispatch(isLoaded());
  }