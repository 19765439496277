import { isLoaded, isLoading } from "../actions";
import Alert from "../../Alert";
import API from "../../Network/API";

export async function deleteMainTask(dispatch, getUser, maintaskId, moduleId, handleClose, handleModalClose, MAINTASK_DELETE) {
    dispatch(isLoading());
    try {
      var response = await API.post("tasksDelete.php", {
        "crop": getUser.corp,
        action: 'maintaskdelete',
        maintaskId: maintaskId,
        moduleId: moduleId,
        "projectId":getUser.projectId
      }, {}, false);
      if (response.status === 'True') {
        Alert("success", MAINTASK_DELETE)
        handleClose()
      } else if (response.status === "false") {
        handleModalClose()
        Alert("warning", response.message)
      }
      else {
      }
    } catch (error) {
      Alert('error', error.message);
      handleClose()
    }
    dispatch(isLoaded());
  }