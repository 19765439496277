import Alert from "../../Alert";
import API from "../../Network/API";
import { isLoaded, isLoading } from "../../TasksModals/actions";

  //Verify SUubTask
  export async function verifySubTask(dispatch,getUser,subtaskId,mainTaskId,handleClose,handleModalClose, SUBTASK_VERIFY) {
      dispatch(isLoading());
      try {
        var response = await API.post("manageSubtasks.php", {
          crop: getUser.corp,
          subtaskid: subtaskId,
          maintaskId:mainTaskId,
          action: "verify",
          emp_id: getUser.empId,
        },{},false);
        // console.log(response)
        if(response.status === 'true') {
          Alert("success",SUBTASK_VERIFY)
          handleClose()
        }else if( response.status === "false")
        {
          handleModalClose()
          Alert("warning","you can not verify tasks untill subtasks are verified")
        }else{
  
        }  
      } catch (error) {
        Alert('error',error.message);
        handleClose()
      }
      dispatch(isLoaded());
    }