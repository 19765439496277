import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store, { Persister } from './Components/Common/Store';
import { PersistGate } from 'redux-persist/integration/react'; // Correct import
import { UserProperties } from './Components/Common/webProperties';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={Persister}>
        <UserProperties>
          <App />
        </UserProperties>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
