//(READ)
import {  isLoaded, isLoading,  allMessages } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

export async function getAllMessages(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("squad_chat.php", {
      corp_code: getUser.corp,
      action: "get_all_messages",
      sendBy: getUser.empId,
    }, {}, false);
    // console.log(response.data)
    if (response.status === 'True') {
      dispatch(allMessages(response.data));
    } else {
      dispatch(allMessages([]));
    }
  } catch (error) {
    Alert('error', error.message)
  }
  dispatch(isLoaded());
}