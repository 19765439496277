import Alert from "../../Alert";
import { logEntry } from "../../logEntry";
import API from "../../Network/API";
import { isLoaded, isLoading } from "../../TasksModals/actions";
import { addCommentUserStory } from "../../TasksModals/Services/addCommentUserStory";


//Verify MainTask
export async function verifyMainTask(dispatch,getUser,mainTaskId,handleClose,handleModalClose, MAINTASK_VERIFY, MAINTASK_VERIFY_FAIL,activities) {

    dispatch(isLoading());
    try {
      const start_time=Date.now();
      var response = await API.post("getUpdateSprint.php", {
        crop: getUser.corp,
        userStoryId: mainTaskId,
        action: "verify",
        emp_id: getUser.empId,
      },{},false);
      const end_time=Date.now();
      if(response.status === 'True') {
        Alert("success", MAINTASK_VERIFY)
      
        addCommentUserStory(dispatch, getUser, mainTaskId, `${MAINTASK_VERIFY}`, "5")
        activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
          sub_activity:"Verify",
          response_time : (end_time-start_time), task_meta_data:{story_id:mainTaskId}

        },getUser)


        handleClose()
      }else if( response.status === "false")
        {
       
        handleModalClose()
        Alert("warning", MAINTASK_VERIFY_FAIL )
      }else{

      }  
    } catch (error) {
      Alert('error',error.message);
          //error log
          activities && logEntry({user_id:getUser.userName,logging_level:2,activity_id:activities["Task Management"],
      sub_activity:"Verify",
      error_message:error.message
    },getUser)
      // handleClose()
    }
    dispatch(isLoaded());
  }