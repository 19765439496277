import Alert from "../../Alert";
import API from "../../Network/API";
import { isLoaded, isLoading } from "../action";

// for updating chat count
export async function updateGroupChat(sno, dispatch, getUser) {
    const msgId = sno ? sno.map((msgId, index) => {
      return msgId.sno
    }) : null
    dispatch(isLoading());
    try {
      // eslint-disable-next-line
      var response = await API.post("group_chat.php", {
        action: "updateChat",
        corp_code: getUser.corp,
        messagedBy: getUser.empId,
        msgId: msgId ? msgId : " "
      }, {}, false);
    } catch (error) {
      Alert('error', error.message)
    }
    dispatch(isLoaded());
  }
