// userprofile/services/Update/updatePaidGenerateLicense.js

import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoading, isLoaded } from "../actions";

export async function updatePaidGenerateLicense(state, dispatch, getUser, transactionId, license_validity, handleClose) {
  const [username, extension] = getUser.userName.split('@');
  var dig1 = username.substring(0, 3);
  var dig2 = extension.substring(0, 5);
  var dig3 = Math.floor(Math.random() * 90000) + 10000;
  var license_key = (dig1.concat(dig2, dig3)).toUpperCase();

  dispatch(isLoading());
  try {
    const data = {
      action: 'generate_license',
      corp: getUser.corp,
      empId: getUser.empId,
      email: state.email.value,
      fullName: state.fullName.value,
      license_key: license_key,
      license_type: (license_validity === 'MONTHLY' || license_validity === 'ANNUAL') ? 'PAID' : 'FREE',
      transactionId: transactionId,
      license_validity: license_validity
    };
    const response = await API.post("get_user.php", data, {}, false);
    Alert("success", response.message);
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
  handleClose();
}
