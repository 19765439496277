import API from "../../../Common/Network/API";
import {  projects,  isLoading,  isLoaded } from "../actions";
import Alert from "../../Alert";

  //For Get Projects List
  export async function getProjects(dispatch,getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("get_epics.php", {
        "corp": getUser.corp,
        "empId": getUser.empId,
        "action": "approved"
      },{},false);
      if(response.status === 'True') {
          dispatch(projects(response.data))
      }
      else{
        dispatch(projects([]))
      }
    } catch (error) {
      Alert('error',error.message);
    }
    dispatch(isLoaded());
  }
