import API from "../../../Common/Network/API";
import {  maintasks,  isLoading, isLoaded } from "../actions";
import Alert from "../../Alert";

export async function getMainTasks(dispatch,getUser,empId,sprintId) {
    dispatch(isLoading());
    try {
      var response = await API.post("backlogspage.php", {
        "crop": getUser.corp,
        "empId": empId,
        "action": "Empcurrentsprint",
        "sprintId": sprintId,
        "projectId":getUser.projectId

      },{},false);
      if(response.status === 'true') {
        dispatch(maintasks(response.data))
      }else{
        dispatch(maintasks([]))
      }
    } catch (error) {
      Alert('error',error.message);
      dispatch(isLoaded());
    }
    dispatch(isLoaded());
  }