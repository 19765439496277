// common/services/deletePrivateToDo.js  (DELETE)
import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

export async function deletePrivateToDo(state, dispatch, getUser, pt_id, handleClose,MAINTASK_DELETE) {
  dispatch(isLoading());
    try {
      var response = await API.post("personal_tasks.php", {
        action: "delete",
        pt_id: pt_id,
      }, {}, false);
      if (response.status === 'True') {
        Alert("success", MAINTASK_DELETE);
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
} 