import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { updateDeletedSprintUserStories } from "./updateDeletedSprintUserStories";

export async function deleteSprint(state, dispatch, getUser, sprintId, handleClose, handleClose1, DELETE_SPRINT_ALERT) {
  dispatch(isLoading());
  try {
    const data = {
      moduleId: sprintId,
      action: "delete",
      crop: getUser.corp,
      empId: getUser.empId
    };
    const response = await API.post("manage_sprints.php", data, {}, false);
    if (response.status === 'True') {
      //dispatch(getModules(dispatch,getUser))
      updateDeletedSprintUserStories(dispatch,getUser,sprintId, handleClose1)
      // Alert("success", 'deleted successfully');
      handleClose()
    }
    else {
      Alert("warning", DELETE_SPRINT_ALERT);
      handleClose1()
    }
  } catch (error) {
    Alert("error", error.message);
  }
  handleClose()
  dispatch(isLoaded());
}

