import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import {  isLoaded, isLoading } from "../action";
import { getAdminGroupDetails } from "./getAdminGroupDetails";

// for create group
export async function createGroup(selectedValue,state, mem, label, dispatch, getUser,handleClose) {
  // console.log(state.title)
  // console.log(selectedValue.length)
  dispatch(isLoading());
  if (state.title === "") {
    Alert("warning", "Please add Group Name ")
    dispatch(isLoaded());
  }
  else if(selectedValue.length <= 0){
    Alert("warning", "Please add Group Members")
    dispatch(isLoaded());
  }
  
  else {
    try {
      var response = await API.post("group_chat.php", {
        crop: getUser.corp,
        action: "create",
        // projectId: getUser.projectId,
        group_name: state.title,
        created_by: getUser.userName,
        created_by_name: getUser.fullName,
        // for members names
        members: mem,
        // for members email
        label: label,
      }, {}, false);
      if (response.status === 'True') {
        getAdminGroupDetails(dispatch, getUser)
        Alert("success", "Group created successfully!");
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
   }
}
