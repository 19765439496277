import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import store from "../../../Common/Store";
import { isLoaded, isLoading, modules } from "../actions";

export async function getKanbans(dispatch, getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post(
        "getUpdateSprint.php",
        {
          crop: getUser.corp,
          action: "getKanbanList",
          projectId: getUser.projectId,
        },
        {},
        false
      );
      if (response.status === "True") {
        dispatch(modules(response.data));
      } else {
        store.dispatch(modules([]));
      }
    } catch (error) {
      Alert("error", error.message);
    }
    dispatch(isLoaded());
  
  }