// common/services/getProjects.js  (READ)

import { isLoaded, isLoading, projects } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

export async function getProjects(dispatch, getUser,projectId) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_epics.php", {
      "corp": getUser.corp,
      "userType": getUser.role,
      "empId": getUser.empId,
      "action": "get_epics",
      "projectId":projectId
    }, {}, false);
    if (response.status === 'True') {
      dispatch(projects(response.data))
    }
    else {
      dispatch(projects([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}
