//common/ChatRoadBlock/Services/updateChat  (UPDATE)
import { isLoaded, isLoading } from "../../Actions/loading";
import Alert from "../../Alert";
import API from "../../Network/API";

export async function updateChat(sno, dispatch, getUser) {
  const msgId = sno ? sno.map((msgId, index) => {
    return msgId.sno
  }) : null
  dispatch(isLoading());
  try {
    // eslint-disable-next-line
    var response = await API.post("roadblock_chat.php", {
      action: "updateChat",
      corp_code: getUser.corp,
      messagedBy: getUser.empId,
      msgId: msgId ? msgId : " "
    }, {}, false);
  } catch (error) {
    Alert('error', error.message)
  }
  dispatch(isLoaded());
}