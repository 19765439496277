//(UPDATE)
import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { Client_URL } from "../../../Common/config";

export async function generatePassword(state, dispatch, handleClose, APP_NAME) {
  dispatch(isLoading());
  try {
    var response = await API.post("authentication.php", {
      action: 'generatePassword',
      empId: state.empId.value,
      password: state.password.value,
      email: state.email.value,
      url: Client_URL,
      appName: APP_NAME,
    }, {}, false);
    if (response.status === 'True') {
      Alert("success", response.message);
      handleClose()
    }
    else {
      handleClose()
    }
  } catch (error) {
    handleClose()
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}