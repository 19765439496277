// userprofile/services/createPayement.js (CREATE)

import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoading, isLoaded } from "../actions";
import { updatePaidGenerateLicense } from "./updatePaidGeneratedLicense";

export async function createPayement(state, dispatch, getUser, paymentId, orderId, license_validity, handleClose) {
  var dig1 = 'AGILE';
  var dig2 = Math.floor(Math.random() * 900000000) + 10000;
  var transactionId = dig1.concat(dig2).toUpperCase();

  dispatch(isLoading());
  try {
    const data = {
      corp: getUser.corp,
      empId: getUser.empId,
      paymentId: paymentId,
      orderId: orderId,
      transactionId: transactionId
    };
    const response = await API.post("payement.php", data, {}, false);
    if (response.status === 'True') {
      updatePaidGenerateLicense(state, dispatch, getUser, transactionId, license_validity, handleClose);
    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
}
