import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";


export async function addCommentUserStory(getUser,userStoryId,message,cardId) {
    try {
      const response = await API.post(
        "story_comments.php",
        {
          action: "add_story_comment",
          storyId: userStoryId,
          corp: getUser.corp,
          userId: getUser.empId,
          message: message,
          cardId: cardId,
        },
        {},
        false
      );
      if (response.status === "True") {
      } else {
        Alert("warning", response.message);
      }
    } catch (error) {
      Alert("error", error.message);
    }
  }

  export async function addTasksComment(getUser, taskId, cardId, message) {
    try {
      await API.post("task_comments.php", {
        action: "add_tasks_comments",
        taskId: taskId,
        corp: getUser.corp,
        userId: getUser.empId,
        message: message,
        cardId: cardId
      }, {}, false);
    }
    catch (error) {
      Alert('error', error.message)
    }
  }