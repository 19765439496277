import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

export async function modifyRoadBlock(state,dispatch,getUser,taskId,roadblockId,handleClose){
    let EstHours = Number(state.days.value * 24) + Number(state.hours.value);

    if (state.roadblockTitle.value !== "" && state.userSelected.value!== "") {
        dispatch(isLoading());
        try {
            var response = await API.post("userRoadblocks.php", {
                crop: getUser.user.corp,
                action:'modifyRoadblock',
                subTaskId: taskId,
                days:state.days.value,
                hours: state.hours.value,
                assignedBy: getUser.user.empId,
                EstimatedHours: EstHours,
                assignedTo: state.userSelected.value,
                roadblockId: roadblockId,
                roadBlockDescription: state.roadblockTitle.value,
                prioritySelected:state.prioritySelected.value,
                severitySelected:state.severitySelected.value
                // targetDate:
            },{},false);
            if(response.status === "True"){
              Alert("success",response.message) 
            }else{

            }
            handleClose();
        } catch (error) {
            Alert("error",error.message)
        }
        handleClose();
    } else {
        Alert("warning", "please add description");
    }
    dispatch(isLoaded());
}