import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { Client_URL } from "../../../Common/config";
import { isLoaded, isLoading } from "../actions";

//For Get Employees List
export async function activeEmployee(dispatch, getUser, empId, email, handleClose, handleModalClose, APP_NAME, name) {
  dispatch(isLoading());
  try {
    var response = await API.post("manage_user.php", {
      "corp": getUser.corp,
      "action": 'activate',
      "empId": empId,
      "projectId": getUser.projectId,
      url: Client_URL,
      appName: APP_NAME,
      newEmail: email,
      newName: name
    }, {}, false);
    if (response.status === 'True') {
      getGroupDetails(getUser)
      Alert("success", 'Squad Member is Activated')
      handleClose()
    }
    else {
      handleClose()
    }
  } catch (error) {
    handleClose()
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}

export async function getGroupDetails(getUser) {
  try {
    var response = await API.post("group_chat.php", {
      "crop": getUser.corp,
      created_by: getUser.userName,
      action: 'getGroupDetails',
      projectId: getUser.projectId
    }, {}, false);
    if (response.status === 'True') {
      let dataResult = response.data.filter(item => {
        return item.group_name === getUser.corp
      })
      getEmployees(getUser, dataResult[0].id)
    }
  } catch (error) {
    console.log('error', error.message);
  }
}

export async function getEmployees(getUser, groupId) {
  try {
    var response = await API.post("agile_squads.php", {
      "crop": getUser.corp,
      projectId: getUser.projectId,
    }, {}, false);
    if (response.status === 'True') {
      let resultEmp = response.data.filter(emp => emp.workingStatus === "Active").map((emp) => {
        return (
          emp.email !== getUser.userName ?
            {
              'value': emp.email, 'label': emp.name
            }
            : ""
        );
      })

      let values = resultEmp.filter(x => x.value !== undefined).map(x => x.value)
      let members = resultEmp.filter(x => x.label !== undefined).map(x => x.label)

      API.post("group_chat.php", {
        crop: getUser.corp,
        action: "update",
        group_name: getUser.corp,
        group_Id: groupId,
        // for members names
        members: getUser.fullName + "," + members.toString(),
        // for members email
        label: getUser.userName + "," + values.toString(),
      }, {}, false);
    }
  } catch (error) {
    console.log('error', error.message);
  }
}