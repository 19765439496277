//accounts/services/getSquadList (READ)
import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import * as actions from '../actions';



export async function getSquadList(dispatch, getUser) {
    const [username, extension] = (getUser.userName).split('@')
    dispatch(isLoading());
    try {
      var response = await API.post("get_user.php", {
        username: username,
        extension: extension,
        action : "get_squads_list"
      }, {}, false);
      if (response.status === 'True') {
        dispatch(actions.squadList(response.data))
      }
      else {
        dispatch(actions.squadList([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }