import { isLoaded, isLoading } from "../actions";
import Alert from "../../Alert";
import API from "../../Network/API";
import { logEntry } from "../../logEntry";
//import {  addCommentUserStory} from '../../../Common/TasksModals/Services/addCommentUserStory';

//For Add MainTask
export async function createMainTask(state, dispatch, getUser, handleClose, MAINTASK_ADD, MAINTASK, PENDING_SUBTASKS, TODO, group_task_type, activities) {
  if (state.userSelected.from === 'group') {
    if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
      if (state.agileProjectSelected) {
        try {
          const start_time = Date.now();
          var response = await API.post("manage_userstories.php", {
            crop: getUser.corp,
            action: "addGroup",
            title: state.taskTitle.value,
            description: state.taskDescription.value,
            added_to: getUser.empId,
            added_by: getUser.empId,
            acceptanceCriteria: state.acceptanceCriteria.value,
            storyPoints: state.storySelected === "" ? '0' : state.storySelected,
            priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
            idea_id: state.epicSelected,
            projectId: state?.agileProjectSelected !== undefined ? state?.agileProjectSelected : getUser.projectId,
            device_id: state.device_id,
            player_id: state.player_id,
            story_type: state.userSelected.from,
            main_task_id: 0,
            group_id: state.userSelected.value,
            group_task_type: group_task_type,
            message: `This Group ${MAINTASK} added in ${PENDING_SUBTASKS ? PENDING_SUBTASKS : TODO}`,
            module_id: state.sprints.length > 0 ? state.sprints[0]?.moduleId : null,
            target_date: state.sprints.length > 0 ? state.sprints[0]?.targetDate : null,
            event_id: 'null',


          }, {}, false);
          const end_time = Date.now();

          activities && logEntry({
            user_id: getUser.userName, logging_level: 3, activity_id: activities["Task Management"],
            sub_activity: "Create",
            response_time: (end_time - start_time), task_meta_data: { story_id: '' }
          },getUser)

          if (response.status === 'True') {
            getUserIdWithEmails(state.userSelected.members_email, state, dispatch, getUser, handleClose, response.us_id,
              PENDING_SUBTASKS, TODO, group_task_type
            )
            // const message = action === "addUserStory" ? MAINTASK_ADD : ADD_TO_TODO_PROJECTS;
            // Alert("success", message);

          }
        } catch (error) {
          Alert('error', error.message);
          //error log
          activities && logEntry({
            user_id: getUser.userName, logging_level: 2, activity_id: activities["Task Management"],
            sub_activity: "Create",
            error_message: error.message
          },getUser)
          dispatch(isLoaded());
        }
        handleClose()
      }
      else {
        Alert("warning", "Please select the Project ")
        dispatch(isLoaded());
      }


    }
  } else {
    dispatch(isLoading());
    if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
      if (state.agileProjectSelected) {
        try {
          // eslint-disable-next-line
          var response = await API.post("manage_userstories.php", {
            crop: getUser.corp,
            action: "add",
            title: state.taskTitle.value,
            description: state.taskDescription.value,
            added_to: state.userSelected.value,
            added_by: getUser.empId,
            acceptanceCriteria: state.acceptanceCriteria.value,
            storyPoints: state.storySelected === "" ? '0' : state.storySelected,
            priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
            idea_id: state.epicSelected,
            projectId: state?.agileProjectSelected !== undefined ? state?.agileProjectSelected : getUser.projectId,
            device_id: state.device_id,
            player_id: state.player_id,
            story_type: state.userSelected.from,
            main_task_id: 0,
            //  message: `This ${MAINTASK} added by ${getUser.fullName}`
            message: `This ${MAINTASK} added in ${PENDING_SUBTASKS}`,
            module_id: state.sprints.length > 0 ? state.sprints[0].moduleId : null,
            target_date: state.sprints.length > 0 ? state.sprints[0]?.targetDate : null,
            event_id: 'null',


          }, {}, false);
          if (response.status === 'True') {
            Alert("success", MAINTASK_ADD);
          }
        } catch (error) {
          Alert('error', error.message);
          dispatch(isLoaded());
        }
        handleClose()
      }

      else {
        Alert("warning", "Please enter required fields ")
        dispatch(isLoaded());
      }
    }
    else {
      Alert("warning", "Please select the Project ")
      dispatch(isLoaded());
    }
  }

}

export async function createGroupTaskFromTemplate(state, dispatch, getUser, handleClose, storyTitle, MAINTASK_ADD, MAINTASK, PENDING_SUBTASKS, TODO, group_task_type) {
  if (storyTitle !== state.taskTitle.value) {
    if (state.userSelected.from === 'group') {
      if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
        try {
          var response = await API.post("manage_userstories.php", {
            crop: getUser.corp,
            action: "add",
            title: state.taskTitle.value,
            description: state.taskDescription.value,
            added_to: getUser.empId,
            added_by: getUser.empId,
            acceptanceCriteria: state.acceptanceCriteria.value,
            storyPoints: state.storySelected === "" ? '0' : state.storySelected,
            priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
            idea_id: state.epicSelected,
            projectId: state?.agileProjectSelected !== undefined ? state?.agileProjectSelected : getUser.projectId,
            device_id: state.device_id,
            player_id: state.player_id,
            story_type: state.userSelected.from,
            main_task_id: 0,
            group_id: state.userSelected.value,
            group_task_type: group_task_type,
            message: `This Group ${MAINTASK} added in ${PENDING_SUBTASKS}`,
            module_id: state.sprints.length > 0 ? state.sprints[0].moduleId : null,
            target_date: state.sprints.length > 0 ? state.sprints[0]?.targetDate : null,
            event_id: 'null',


            //  message : `This Group ${MAINTASK} added in ${PENDING_SUBTASKS ? PENDING_SUBTASKS : 'Mytasker'}`
          }, {}, false);
          if (response.status === 'True') {
            Alert("success", MAINTASK_ADD);
            getUserIdWithEmails(state.userSelected.members_email, state, dispatch, getUser, handleClose, response.us_id, PENDING_SUBTASKS, TODO, group_task_type)
          }
        } catch (error) {
          Alert('error', error.message);
          dispatch(isLoaded());
        }
        handleClose()
      }
    } else {
      dispatch(isLoading());
      if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
        try {
          // eslint-disable-next-line
          var response = await API.post("manage_userstories.php", {
            crop: getUser.corp,
            action: "add",
            title: state.taskTitle.value,
            description: state.taskDescription.value,
            added_to: state.userSelected.value,
            added_by: getUser.empId,
            acceptanceCriteria: state.acceptanceCriteria.value,
            storyPoints: state.storySelected === "" ? '0' : state.storySelected,
            priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
            idea_id: state.epicSelected,
            projectId: state?.agileProjectSelected !== undefined ? state?.agileProjectSelected : getUser.projectId,
            device_id: state.device_id,
            player_id: state.player_id,
            story_type: state.userSelected.from,
            main_task_id: 0,
            module_id: state.sprints.length > 0 ? state.sprints[0].moduleId : null,
            target_date: state.sprints.length > 0 ? state.sprints[0]?.targetDate : null,
            event_id: 'null',



          }, {}, false);
          if (response.status === 'True') {
            Alert("success", MAINTASK_ADD);
          }
        } catch (error) {
          Alert('error', error.message);
          dispatch(isLoaded());
        }
        handleClose()
      }

      else {
        Alert("warning", "Please enter required fields ")
        dispatch(isLoaded());
      }
    }
  } else {
    Alert("warning", "Please change user story title ")
    dispatch(isLoaded());
  }

}

const getUserIdWithEmails = async (members_email, state, dispatch, getUser, handleClose, us_id, PENDING_SUBTASKS, TODO, group_task_type) => {
  const emails = members_email.match(/[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g);
  // Remove duplicates by converting the array to a Set
  const uniqueEmailsSet = new Set(emails);
  // Convert Set back to array if needed
  const uniqueEmailsArray = [...uniqueEmailsSet];
  const filteredEmails = uniqueEmailsArray.filter(email => email !== getUser.userName);
  try {
    var response = await API.post("get_user.php", {
      action: "get_user_by_email",
      emailArray: filteredEmails,
      projectId: state?.agileProjectSelected !== undefined ? state?.agileProjectSelected : getUser.projectId
    }, {}, false);
    if (response.status === 'True') {
      response.data.map(item => {
        return createGroupMainTask(state, dispatch, getUser, item.empid, parseInt(us_id),
          PENDING_SUBTASKS,
          TODO,
          group_task_type
        )
      })
      handleClose()

    }
  } catch (error) {
    console.log(error)
  }
}

export async function createGroupMainTask(state, dispatch, getUser, empId, us_id, PENDING_SUBTASKS, TODO, group_task_type) {
  dispatch(isLoading());
  if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
    try {

      var response = await API.post("manage_userstories.php",
        {
          crop: getUser.corp,
          action: "add",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          added_to: empId,
          added_by: getUser.empId,
          acceptanceCriteria: state.acceptanceCriteria.value,
          storyPoints: state.storySelected === "" ? '0' : state.storySelected,
          priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
          idea_id: state.epicSelected,
          projectId: state?.agileProjectSelected !== undefined ? state?.agileProjectSelected : getUser.projectId,
          device_id: state.device_id,
          player_id: state.player_id,
          story_type: 'group',
          main_task_id: us_id,
          group_task_type: group_task_type,
          message: `This Group task added in ${PENDING_SUBTASKS ? PENDING_SUBTASKS : TODO}`,
          module_id: state.sprints.length > 0 ? state.sprints[0].moduleId : null,
          target_date: state.sprints.length > 0 ? state.sprints[0]?.targetDate : null,
          event_id: 'null',


        }, {}, false);
      if (response.status === 'True') {
        console.log("Success")
      }
    } catch (error) {
      console.log("error")
    }
  }

  else {
    console.log("error")
  }
}