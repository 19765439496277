import API from "../../../Common/Network/API";
import {  roadblocks, isLoading,  isLoaded} from "../actions";
import Alert from "../../Alert";

export async function getRoadBlocks(dispatch,getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("userRoadblocks.php", {
        "crop": getUser.corp,
        "empId":getUser.empId,
        "action": "userRoadblocks"
      }, {}, false);
      // console.log(response)
      if(response.message === "No Data Available"){
        dispatch(roadblocks([]));
      }else{
        dispatch(roadblocks(response.data));
      }
    } catch (error) {
      Alert("error",error.message)
   }
    dispatch(isLoaded());
  }
