import API from "../../Network/API";
import { isLoading, isLoaded, activeSprint,events } from "../action";
import Alert from '../../Alert';
import { Client_URL } from "../../config";
import { updateUserStory } from "../../../UserModule/ConsolidatedToDo/Services/updateUserStory";

//for auto delete past events
const filterToDeleteEvents=async(deleteEvents,getUser,APP_NAME,activities)=>{
  try {
    const deletePromises = deleteEvents.map(async item => {
        const response = await API.post("calendar.php", {
            action: "Delete",
            current: item,
            corp_code: item.project_name,
            projectId: item.ProjectName,
            fullName: getUser.fullName,
            userName: getUser.userName,
            url: Client_URL,
            appName: APP_NAME,
            deleteType:"auto_delete"
        }, {}, false);

        if (response.status === "True" && response.task_details?.length > 0) {
            response.task_details.forEach(task => {
                if (task.active_status === "1") {
                  let data = { task_id: item.task_id, story_id: item.story_id, task_type: item.task_type, project_name: item.project_name, main_task_id: item.main_task_id, story_type: item.story_type, us_id: item.us_id, story_title: item.story_title, blocked_id: item.blocked_id, userStoryId: item.userStoryId,calendarId:item?.calendar_id };
                  let message = `This meeting, This Task is completed`;
                  updateUserStory(activities,getUser.user, data.story_id, "handleClose", message, "TASKS WARNING", "ROADBLOCK WARNING", data.story_type, data.us_id, data.main_task_id, "MAINTASK",data.calendarId);
                }
            });
        }
    });

        await Promise.all(deletePromises);
    } catch (error) {
        console.error('Error deleting some events:', error);
    }
};


//to get event
export async function getEvent(getUser,dispatch, APP_NAME,activities) {
  dispatch(isLoading());
  try {
    var response = await API.post("calendar.php", {
      action: "GetEvent",
      corp_code: getUser.corp,
      projectId: getUser.projectId,
      "userType": getUser.role,
      userName: getUser.userName,
      url: Client_URL,
      appName: APP_NAME,
  }, {}, false);
  if (response.status === 'True') {
    dispatch(events(response.data))
    dispatch(isLoaded());

    //for auto delete past events
    if(response.data.length>0){
      // eslint-disable-next-line
      const deleteEvents=response.data.filter(item=>{
          if(new Date(item.EndTime) < new Date()){
              return item
          }
      })
      if(deleteEvents.length>0){
        await filterToDeleteEvents(deleteEvents,getUser,APP_NAME,activities)
      }
    }
  }
  else {
    dispatch(events([]))
  }
  } catch (error) {
      console.log(error)
      Alert('error', error.message);
  }
  dispatch(isLoaded());
}

//for getting active sprint id
export async function getActiveSprints(dispatch, getUser) {
  dispatch(isLoading());
  try {
    // Current Active Sprint Name with epic by -->GNK -->01 on version 1.0.4 start
    // var response = await API.post("getSprints.php", {
    //   "crop": getUser.corp,
    //   "userType": getUser.role,
    //   "empId": getUser.empId,
    //   "action": "getActiveSprint"
    // }, {}, false);
    // Current Active Sprint Name with epic by -->GNK -->01 on version 1.0.4 end

    // Current Active Sprint witout epic by -->SS -->01 on version 1.0.6 start
    var response = await API.post("getUpdateSprint.php", {
      "crop": getUser.corp,
      "action": "get_sprints",
      "projectId": getUser.projectId
    }, {}, false);
    // Current Active Sprint witout epic by -->SS -->01 on version 1.0.6 end
    if (response.status === 'True') {
      dispatch(activeSprint(response.data[0]))
    }
    else {
      // getUnactiveSprintTasks(dispatch, getUser)
      dispatch(activeSprint([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}