import { isLoaded, isLoading, projects } from "../actions";
import Alert from "../../Alert";
import API from "../../Network/API";

export async function getProjects(dispatch, getUser, project_id) {
    dispatch(isLoading());
    try {
      var response = await API.post("get_epics.php", {
        "corp": getUser.corp,
        "userType": "all",
        "empId": getUser.empId,
        "action": "get_epics",
        "projectId": project_id || getUser.projectId
      }, {}, false);
      if (response.status === 'True') {
        dispatch(projects(response.data))
      }
      else {
        dispatch(projects([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }