import API from "../../../Common/Network/API";
import { subtasks,  isLoading,  isLoaded } from "../actions";
import Alert from "../../Alert";

//For ToDo and Doing Subtask List based on task active status
export async function getSubTasks(dispatch,getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("getUserPendingSprints.php", {
        "crop": getUser.corp,
        "empId": getUser.empId,
      }, {}, false); 
      dispatch(subtasks(response.data));
    } catch (error) {
      Alert('error',error.message);
    }
    dispatch(isLoaded());
  }