import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading, checkCurrentSprint } from "../actions";

//Check Active Sprint
export async function checkActiveSprint(dispatch,getUser,moduleId) {
    dispatch(isLoading());
    try {
      var response = await API.post("get_epics.php", {
        "corp": getUser.corp,
        "action": "check_current_sprint",
        "projectId": getUser.projectId,
        "sprintId":moduleId
      },{},false);
      if(response.status === 'True') {
          dispatch(checkCurrentSprint(response.data))  
      }
      else{
        dispatch(checkCurrentSprint([]))
      }
    } catch (error) {
      Alert('error',error.message);
    }
    dispatch(isLoaded());
  }