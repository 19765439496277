// common/services/addMainTask.js  (CREATE) from MyTasker

import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

import { createMainTask } from "../../../Common/TasksModals/Services/createMainTask";
import { logEntry } from "../../logEntry";



export async function addMainTask(activities, state, dispatch, getUser, handleClose, pt_id, storyTitle, ADD_TO_TODO_PROJECTS, action, MAINTASK_ADD, MAINTASK, PENDING_SUBTASKS, TODO, group_task_type) {
  if (state.userSelected.from === 'group') {
    createMainTask(state, dispatch, getUser, handleClose, MAINTASK_ADD, MAINTASK, PENDING_SUBTASKS, TODO, group_task_type)
  }
  else if (state.taskTitle.value && state.taskTitle.value.trim() !== "" && state.taskDescription.value && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value && state.acceptanceCriteria.value.trim() !== "") {
    if (state.agileProjectSelected !== "") {
      dispatch(isLoading());
      const start_time = Date.now();
      try {
        var response = await API.post("private_todo.php", {
          crop: getUser.corp,
          action: "add",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          added_by: getUser.empId,
          acceptanceCriteria: state.acceptanceCriteria.value,
          added_to: state.userSelected === "" ? (state.id.value || getUser.empId) : state.userSelected.value,
          storyPoints: state.storySelected === "" ? state.storyPoints.value : state.storySelected,
          priorityLevel: state.prioritySelected === "" ? state.priorityLevel.value : state.prioritySelected,
          idea_id: state.epicSelected === "" ? (state.ideaId.value || '') : state.epicSelected,
          projectId: state.agileProjectSelected,
          device_id: state.device_id,
          pt_id: pt_id,
          // message: `This ${MAINTASK} is added/created`
          message: `This ${MAINTASK} is added in ${TODO}`,
          module_id: state.sprints.length > 0 ? state.sprints[0].moduleId : null,
          target_date: state.sprints.length > 0 ? state.sprints[0]?.targetDate : null,
        }, {}, false);
        const end_time = Date.now();
        if (response.status === 'True') {
          const message = action === "addUserStory" ? MAINTASK_ADD : ADD_TO_TODO_PROJECTS;
          Alert("success", message);
          activities && logEntry({
            user_id: getUser.userName, logging_level: 3, activity_id: activities["Task Management"],
            sub_activity: "Create",
            response_time: (end_time - start_time),

          },getUser)
        }
      } catch (error) {
        Alert('error', error.message);
        //error log
        activities && logEntry({
          user_id: getUser.userName, logging_level: 2, activity_id: activities["Task Management"],
          sub_activity: "Create",
          error_message: error.message
        },getUser)
        dispatch(isLoaded());
      }
      handleClose()
    }

    else {
      Alert("warning", "Please select the Project ")
      dispatch(isLoaded());
    }
  } else {
    Alert("warning", "Please enter required fields ")
    dispatch(isLoaded());
  }
} 