import { isLoaded, isLoading } from "../actions";
import Alert from "../../Alert";
import API from "../../Network/API";
import { createMainTask } from "./createMainTask";
import { updateRoadblockTask } from "../../../UserModule/Backlogs/Services/updateRoadblockTask";
import { logEntry } from "../../logEntry";

//For Add MainTask as template
export async function createMainTaskTemplate(state, dispatch, getUser, handleClose, MAINTASK_ADD, is_workflow = 0, props, MAINTASK, PENDING_SUBTASKS, TODO, group_task_type, activities) {
  if (state.userSelected.from === 'group') {
    createMainTask(state, dispatch, getUser, handleClose, MAINTASK_ADD, MAINTASK, PENDING_SUBTASKS, TODO, group_task_type, activities)
  } else {
    dispatch(isLoading());
    if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
      try {
        const start_time = Date.now();
        var response = await API.post("manage_template.php", {
          crop: getUser.corp,
          action: "add",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          added_to: state.userSelected.value,
          added_by: getUser.empId,
          acceptanceCriteria: state.acceptanceCriteria.value,
          storyPoints: state.storySelected === "" ? '0' : state.storySelected,
          priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
          idea_id: state.epicSelected,
          "projectId": getUser.projectId,
          device_id: state.device_id,
          player_id: state.player_id,
          is_workflow: is_workflow,
          // message: `This ${MAINTASK} added by ${getUser.fullName}`
          message: `This ${MAINTASK} added in ${PENDING_SUBTASKS}`,
          module_id: state.sprints.length > 0 ? state.sprints[0].moduleId : null,
          target_date: state.sprints.length > 0 ? state.sprints[0]?.targetDate : null,
        }, {}, false);
        const end_time = Date.now();
        if (response.status === 'True') {
          if (props.combine_tasks === true) {
            updateRoadblockTask(getUser, response.last_insert_id, props.data.selectedRoadblocks, props.handleClose)
          } else {
            Alert("success", MAINTASK_ADD);
            handleClose();
            // console.log('G-called')
            activities && logEntry({
              user_id: getUser.userName, logging_level: 3, activity_id: activities["Task Management"],
              sub_activity: "Create",
              response_time: (end_time - start_time), task_meta_data: { story_id: '' }

            },getUser)
          }
        }
        else if (response.status === 'false' && response.message === "TemplateExisted") {
          dispatch(isLoaded());
          Alert("warning", "Template already existed!, Please change user template title");
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());

        activities && logEntry({
          user_id: getUser.userName, logging_level: 2, activity_id: activities["Task Management"],
          sub_activity: "Create",
          error_message: error.message
        },getUser)

      }
    }

    else {
      Alert("warning", "Please enter required fields ")
      dispatch(isLoaded());
    }
  }
}