import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";



//network call to move into archive
export async function moveToArchive(state, dispatch, getUser, sprintId, handleClose, handleClose1) {
  dispatch(isLoading());
  try {
    const data = {
      moduleId: sprintId,
      action: "move_to_archive",
      crop: getUser.corp,
      empId: getUser.empId
    };
    const response = await API.post("manage_sprints.php", data, {}, false);
    if (response.status === 'True') {
      //dispatch(getModules(dispatch,getUser))
      //  updateDeletedSprintUserStories(dispatch,getUser,sprintId)
      Alert("success", 'Moved Successfully');
      handleClose()
    }
    else {
      Alert("warning", response.message);
     // console.log('warning message:',response.message);
      handleClose1()
    }
  } catch (error) {
    Alert("error", error.message);
  }

  dispatch(isLoaded());
}